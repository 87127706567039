import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

// HELPERS
import { convertStartDate } from "./activityLog.helpers";

export interface IActivityLog {
	orderId?: number;
	orderNumber?: number;
	orderType: string;
	activity: string;
	timeStamp: string;
	extraMessageType: number;
	extraMessage: string;
}

const initialActivityLog = {
	logs: [] as IActivityLog[],
	isLoading: false as boolean,
	startDate: convertStartDate(),
	endDate: DateTime.local().toFormat("yyyy-LL-dd"),
	error: "" as string,
};

const activityLogSlice = createSlice({
	name: "ACTIVITYLOG",
	initialState: initialActivityLog,
	reducers: {
		fetchActivityLogStart: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				startDate: payload.days
					? convertStartDate(payload.days)
					: convertStartDate(),
				error: "",
			};
		},
		fetchActivityLogComplete: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				logs: payload,
				error: "",
			};
		},
		fetchActivityLogFail: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				error: payload,
			};
		},
		resetActivityLog: (state, { payload }) => {
			return {
				...state,
				logs: [] as IActivityLog[],
			};
		},
	},
});

export const {
	fetchActivityLogStart,
	fetchActivityLogComplete,
	fetchActivityLogFail,
	resetActivityLog,
} = activityLogSlice.actions;

export default activityLogSlice.reducer;
