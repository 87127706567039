import { createSlice } from "@reduxjs/toolkit";
import {
	billingHistoryReducer,
	billingHistoryStates,
} from "./reducers/billingHistory.reducer";
import { flagStatusReducer, flagStatusStates } from "./reducers/flagStatus.reducer";
import { layoutStates, layoutReducer } from "./reducers/layout.reducer";
import { pricingFeeReducer, pricingFeeStates } from "./reducers/pricingFee.reducer";
import { subscriptionReducer, subscriptionStates } from "./reducers/subscription.reducer";
import { transientReducer, transientStates } from "./reducers/transient.reducer";
import {
	validationsStates,
	validationsStatusReducer,
} from "./reducers/validation.reducer";
import { plansStates, plansReducer } from "./reducers/plans.reducer";

const intitalCustomerMembership = {
	...layoutStates,
	...billingHistoryStates,
	...flagStatusStates,
	...pricingFeeStates,
	...subscriptionStates,
	...validationsStates,
	...transientStates,
	...plansStates,
};

const customerMembershipSlice = createSlice({
	name: "CUSTOMER_MEMBERSHIP",
	initialState: intitalCustomerMembership,
	reducers: {
		...layoutReducer(),
		...billingHistoryReducer(),
		...flagStatusReducer(),
		...pricingFeeReducer(),
		...subscriptionReducer(),
		...validationsStatusReducer(),
		...transientReducer(),
		...plansReducer(),

		resetStoreCustomerMembership: (state) => {
			return {
				...intitalCustomerMembership,
			};
		},
	},
});

export const {
	resetStoreCustomerMembership,

	updateLayout,

	fetchBillingHistoryStart,
	fetchBillingHistoryComplete,
	fetchBillingHistoryFail,
	fetchOrderAccountStart,
	fetchOrderAccountComplete,
	fetchOrderAccountFail,
	fetchReceiptHistoryContentHtmlStart,
	fetchReceiptHistoryContentHtmlSuccess,
	fetchReceiptHistoryContentHtmlFail,
	sendReceiptEmailStart,
	sendReceiptEmailSuccess,
	sendReceiptEmailFail,

	fetchPricingFeesStart,
	fetchPricingFeesComplete,
	fetchPricingFeesFail,
	fetchPricingFeesShouldFire,

	fetchPlansStart,
	fetchPlansComplete,
	fetchPlansFail,

	fetchSubscriptionStart,
	fetchSubscriptionComplete,
	fetchSubscriptionFail,
	fetchSubscriptionListStart,
	fetchSubscriptionListComplete,
	fetchSubscriptionListFail,
	addMembershipPlanStart,
	addMembershipPlanSuccess,
	addMembershipPlanFail,
	removeSubscriptionStart,
	removeSubscriptionComplete,
	removeSubscriptionFail,
	cancelSubscriptionStart,
	cancelSubscriptionComplete,
	cancelSubscriptionFail,
	cancelImmediatelyStart,
	cancelImmediatelyComplete,
	cancelImmediatelyFail,
	saveSubscriptionStart,
	saveSubscriptionComplete,
	saveSubscriptionFail,
	saveSubscriptionShouldFire,
	releaseSubscriptionSaveHold,
	resumeSubscriptionStart,
	resumeSubscriptionComplete,
	resumeSubscriptionFail,
	paySubscriptionStart,
	paySubscriptionFail,

	updateDeletingMemberAccountId,
	fetchEmailDuplicatedStart,
	fetchEmailDuplicatedComplete,
	fetchEmailDuplicatedFail,

	resetTransientReducer,
	updateTransientMembersByArray,
	updateTransientMembers,
	fetchTransientBillingInformationStart,
	fetchTransientBillingInformationComplete,
	fetchTransientBillingInformationFail,
	fetchTransientOwnerAccountStart,
	fetchTransientOwnerAccountComplete,
	fetchTransientOwnerAccountFail,

	// accountToken
	addTokenToAccountLoading,
	addTokenToAccountStart,
	addTokenToAccountComplete,
	addTokenToAccountFailure,
	canDeleteTokenCheckStart,
	canDeleteTokenCheckSuccess,
	canDeleteTokenCheckFailure,
	resetCanDeleteCheck,
	removeTokenToAccountStart,
	removeTokenToAccountComplete,
	removeTokenToAccountFailure,
	clearSaveTokenStateLoadingState,

	// billing information
	updateTransientBillingCycle,
	updateTransientAccounToken,
	// updateTransientAccountOwner,

	updateDeactivatedPlanAndUnlocked,
	refreshBillingCardFlags,
	refreshMemberCardFlags,

	fetchTransientInfoStart,
	fetchTransientInfoComplete,
} = customerMembershipSlice.actions;

export default customerMembershipSlice.reducer;
