import React, { useState } from "react";
import ChevronDown from "mdi-material-ui/ChevronDown";
import ChevronUp from "mdi-material-ui/ChevronUp";

// ASSETS
import useStyles from "./FilterItem.styles";

interface FilterItemProps {
	children: React.ReactNode;
	title: string;
}

const FilterItem: React.FC<FilterItemProps> = ({ title, children }) => {
	// HOOKS
	const classes = useStyles();
	const [open, setOpen] = useState(true);

	// EVENTS
	const handleOpen = () => {
		setOpen(!open);
	};

	return (
		<div className={classes.container}>
			<div className={classes.header} onClick={handleOpen} role="presentation">
				<p>{title}</p>
				<button type="button">{open ? <ChevronUp /> : <ChevronDown />}</button>
			</div>
			{open && <div className={classes.content}>{children}</div>}
		</div>
	);
};

export default FilterItem;
