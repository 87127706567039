import { takeLatest, call, put } from "redux-saga/effects";
import {
	productCategoriesRequest,
	productCategoriesSuccess,
	productCategoriesFailure,
} from "./productCategories.slice";
import ProductsApi from "./productCategories.api";

export function* getProductCategoriesList({ payload }): any {
	try {
		const response = yield call(ProductsApi.GetProductCategories, payload);

		yield put(productCategoriesSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(productCategoriesFailure({ error }));
	}
}

export function* watchProductCategories() {
	return [yield takeLatest(productCategoriesRequest, getProductCategoriesList)];
}
