import { createSlice } from "@reduxjs/toolkit";

type StateLoading = {
	started?: boolean;
	loading: boolean;
	success: boolean;
	hasError: boolean;
	errorMessage: "";
};

const userSlice = createSlice({
	name: "USER",
	initialState: {
		userRoles: [] as string[],
		isLoading: false,
		error: "",
		userGroupsState: {
			fetchStateLoading: {} as StateLoading,
			groups: [] as string[],
		},
		userDiscountsState: {
			fetchStateLoading: {} as StateLoading,
			canApplyDiscounts: false,
		},
	},
	reducers: {
		fetchUserRolesStart: (state) => {
			return {
				...state,
				isLoading: true,
				error: "",
			};
		},
		fetchUserRolesSuccess: (state, { payload }) => {
			return {
				...state,
				userRoles: payload.data,
				isLoading: false,
				error: "",
			};
		},
		fetchUserRolesFailure: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				error: payload,
			};
		},
		fetchUserGroupsStart: (state) => {
			return {
				...state,
				userGroupsState: {
					...state.userGroupsState,
					fetchStateLoading: {
						started: true,
						success: false,
						loading: true,
						errorMessage: "",
						hasError: false,
					},
				},
			};
		},
		fetchUserGroupsSuccess: (state, { payload }) => {
			return {
				...state,
				userGroupsState: {
					...state.userGroupsState,
					fetchStateLoading: {
						...state.userGroupsState.fetchStateLoading,
						success: true,
						loading: false,
					},
					groups: payload.data,
				},
			};
		},
		fetchUserGroupsFailure: (state, { payload }) => {
			return {
				...state,
				userGroupsState: {
					...state.userGroupsState,
					fetchStateLoading: {
						...state.userGroupsState.fetchStateLoading,
						success: false,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		fetchUserDiscountsStart: (state) => {
			return {
				...state,
				userDiscountsState: {
					...state.userDiscountsState,
					fetchStateLoading: {
						started: true,
						success: false,
						loading: true,
						errorMessage: "",
						hasError: false,
					},
				},
			};
		},
		fetchUserDiscountsCompleted: (state, { payload }) => {
			return {
				...state,
				userDiscountsState: {
					...state.userDiscountsState,
					fetchStateLoading: {
						...state.userDiscountsState.fetchStateLoading,
						success: true,
						loading: false,
					},
					canApplyDiscounts: payload.data,
				},
			};
		},
		fetchUserDiscountsFailure: (state, { payload }) => {
			return {
				...state,
				userDiscountsState: {
					...state.userDiscountsState,
					fetchStateLoading: {
						...state.userDiscountsState.fetchStateLoading,
						success: false,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
	},
});

export const {
	fetchUserRolesStart,
	fetchUserRolesSuccess,
	fetchUserRolesFailure,
	fetchUserGroupsStart,
	fetchUserGroupsSuccess,
	fetchUserGroupsFailure,
	fetchUserDiscountsStart,
	fetchUserDiscountsCompleted,
	fetchUserDiscountsFailure,
} = userSlice.actions;

export default userSlice.reducer;
