import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {
			borderRadius: 4,
			minWidth: 24,
			minHeight: 24,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			color: theme.palette.NEUTRAL000,
			transition: "all .2s ease-in",
		},
		clickable: {
			cursor: "pointer",
			"&:hover": {
				transform: "scale(1.05)",
			},
		},
	};
});

export default useStyles;
