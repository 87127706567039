import React from "react";
import Magnify from "mdi-material-ui/Magnify";
import { useDispatch } from "react-redux";
import classNames from "classnames";

// ACTIONS
import { openSearch } from "../../../../store/modules/headerNav/headerNav.slice";

// ASSETS
import useStyles from "./SearchInput.styles";
import { useLocation } from "react-router-dom";

const SearchInput = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const location = useLocation();

	// EVENTS
	const handleInputClick = () => {
		if (location.pathname.includes("menu")) {
			window.parent.postMessage(
				{
					action: "openFullScreenModal",
					component: "GlobalSearch",
				},
				"*"
			);
		} else {
			dispatch(openSearch());
		}
	};

	// STYLES
	const cssSearch = classNames(classes.search);

	return (
		<div className={classes.searchContainer}>
			<div className={cssSearch} onClick={handleInputClick} role="presentation">
				<div className={classes.searchInput}>
					<span className={classes.searchIcon}>
						<Magnify fontSize="inherit" />
					</span>
					<span className={classes.searchText}>Search</span>
				</div>
			</div>
		</div>
	);
};

export default SearchInput;
