export interface IValidateFormatPhone {
	phone: string;
	valid: boolean;
	errorMessage: string;
	isInternational: boolean;
	hasExtension: boolean;
}

// Phone Sanitization by character
export const sanitizeCharacter = (fullPhone: string): string => {
	if (fullPhone === null || fullPhone === undefined) {
		return fullPhone;
	}
	let acc = [...fullPhone.split("-").join("")];
	acc = acc.map((a) => a.replace(/([a-zA-Z])*/, ""));
	const char = acc.pop() || "";

	if (char === "+" && !!acc && !acc.includes("+")) return acc.join("") + char;
	if (char >= "0" && char <= "9") return acc.join("") + char;
	return acc.join("");
};

// Phone validation and masking
export const validateFormatPhone = (inValue: string): IValidateFormatPhone => {
	const validator = validate(inValue);
	return validator;
};

const validate = (phoneNumber: string): IValidateFormatPhone => {
	let error = "";
	const phoneRegex = new RegExp(/^[+]?([0-9])*([x]([0-9])*)?$/i);
	const sanitizedNumber = sanitizeCharacter(phoneNumber);
	let isValid = phoneRegex.test(sanitizedNumber);

	if (!isValid)
		error =
			"Invalid characters. Phone may contain one plus, numbers, and dashes only.";
	else if (
		sanitizedNumber.length > 0 &&
		sanitizedNumber.replace("-", "").replace("+", "").length < 2
	) {
		isValid = false;
		error = "Phone numbers should contain at least 2 digits.";
	}
	const hasExtension = sanitizedNumber ? sanitizedNumber.includes("x") : false;
	const isInternational = sanitizedNumber ? sanitizedNumber.includes("+") : false;

	const validator: IValidateFormatPhone = {
		phone: sanitizedNumber,
		errorMessage: error,
		valid: isValid,
		hasExtension,
		isInternational,
	};
	if (validator.phone) {
		validator.phone = maskPhone(validator);
	}
	return validator;
};

const maskPhone = (validator: IValidateFormatPhone): string => {
	let [value, ext] = validator.phone.split("x");

	if (validator.hasExtension) ext = ["x", ...ext].join("");
	if (validator.isInternational) value = value.replace("+", "");

	if (validator.isInternational) {
		if (value.length >= 12) {
			value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
		} else if (value.length >= 8) {
			value = value.replace(/(\d{2})(\d{3})(\d{3})/, "$1-$2-$3-");
		} else if (value.length >= 5) {
			value = value.replace(/(\d{2})(\d{3})/, "$1-$2-");
		} else if (value.length >= 2) {
			value = value.replace(/(\d{2})/, "$1-");
		}
	} else if (value.length === 12) {
		value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
	} else if (value.length === 11) {
		value = value.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4");
	} else if (value.length === 10) {
		value = value.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
	} else if (value.length >= 8) {
		value = value.replace(/(\d{3})(\d{3})/, "$1-$2-");
	} else if (value.length === 7) {
		value = value.replace(/(\d{3})(\d{4})/, "$1-$2");
	} else if (value.length >= 3) {
		value = value.replace(/(\d{3})/, "$1-");
	}

	return `${validator.isInternational ? "+" : ""}${value}${ext || ""}`;
};
