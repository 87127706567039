import produce from "immer";
import { createSlice } from "@reduxjs/toolkit";

import { searchReducer, searchStates } from "./reducers/search.reducer";
import { helpReducer, helpStates } from "./Help/help.reducer";

const headerNavSlice = createSlice({
	name: "HEADERNAV",
	initialState: {
		userActins: {
			isOpen: false,
		},
		...searchStates,
		...helpStates,
	},
	/* eslint-disable no-param-reassign */
	reducers: {
		...searchReducer(),
		...helpReducer(),
		openSearch: produce((state) => {
			state.search.isOpen = true;
		}),
		closeSearch: produce((state) => {
			state.search.isOpen = false;
		}),
		openUserActions: produce((state) => {
			state.userActins.isOpen = true;
		}),
		closeUserActions: produce((state) => {
			state.userActins.isOpen = false;
		}),
	},
	/* eslint-enable no-param-reassign */
});

export const {
	openSearch,
	closeSearch,
	openUserActions,
	closeUserActions,

	orderSearchStart,
	orderSearchComplete,
	orderSearchFail,
	fetchLastTenOrdersVisitedStart,
	fetchLastTenOrdersVisitedComplete,
	fetchLastTenOrdersVisitedFail,
	clearOrderSearch,

	fetchHelpStart,
	fetchHelpComplete,
	fetchHelpFail,
	openHelp,
	closeHelp,
	acknowledgeHelpStart,
	acknowledgeHelpComplete,
	acknowledgeHelpFail,
	selectAcknowledgeCommunication,
	openAcknowledgeModal,
	closeAcknowledgeModal,
} = headerNavSlice.actions;

export default headerNavSlice.reducer;
