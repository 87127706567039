import React from "react";

// SELECTORS
import { getSearchText } from "../../../../store/modules/headerNav/headerNav.selector";

// ASSETS
import classNames from "classnames";
import useStyles from "./OrderResultItem.styles";
import Highlighter from "react-highlight-words";
import { truncate } from "lodash-es";

// TYPINGS
import { Payment } from "../../../../store/modules/headerNav/headerNav.typings";
import { formatCurrency, formatDateTime } from "../../../../utils";
import { getCustomerProfileLink, getOrderLink } from "../../../../utils/classic.helper";
import { useSelector } from "react-redux";
import { useMediaQuery, useTheme } from "@agilekit/ui";

interface OrderResultItemProps {
	orderId: string;
	orderNumber: string;
	orderStatus: string;
	orderType: string;
	customerId: string;
	customer: string;
	orderDate: Date;
	payments?: Array<Payment>;
}

const OrderResultItem: React.FC<OrderResultItemProps> = ({
	orderId,
	orderNumber,
	orderStatus,
	orderType,
	customerId,
	customer,
	orderDate,
	payments = [],
}) => {
	const classes = useStyles();
	const searchText = useSelector(getSearchText);
	const theme = useTheme();
	const screenXS = useMediaQuery(theme.breakpoints.down("xs"));

	const getNameOnChekOrNameOnCard = (payment: Payment) => {
		if (
			payment.nameOnCard &&
			payment.nameOnCard.length > 0 &&
			(payment.lastFour === null || payment.lastFour.length === 0)
		) {
			return "Name on Check";
		}
		return "Name on Card";
	};

	// RENDERS
	const renderPayment = (payment: Payment): React.ReactNode => {
		return (
			<div className={classes.paymentItem}>
				<div>
					<strong>Last Transaction:</strong>{" "}
					{formatDateTime(payment.paymentDate, true)}
				</div>
				{!screenXS && <>&nbsp;-&nbsp;</>}
				<div>
					<strong>Amount:</strong>{" "}
					<span
						style={{
							color:
								!isNaN(payment.amount) && payment.amount < 0 ? "red" : "",
						}}
					>
						{formatCurrency(payment.amount)}
					</span>
				</div>
				{payment.nameOnCard && payment.nameOnCard.length > 0 && (
					<>
						{!screenXS && <>&nbsp;-&nbsp;</>}
						<div>
							<strong>{getNameOnChekOrNameOnCard(payment)}:</strong>
							<Highlighter
								highlightClassName={classes.highlight}
								searchWords={[searchText]}
								textToHighlight={truncate(payment.nameOnCard, {
									length: 20,
								})}
							/>
						</div>
					</>
				)}
				{payment.lastFour && payment.lastFour.length > 0 && (
					<>
						{!screenXS && <>&nbsp;-&nbsp;</>}
						<div>
							<strong>Last Four:</strong>
							<Highlighter
								highlightClassName={classes.highlight}
								searchWords={[searchText]}
								textToHighlight={payment.lastFour}
							/>
						</div>
					</>
				)}
			</div>
		);
	};

	// STYLES
	const cssStatus = classNames([classes.status], {
		[classes.statusOpen]: orderStatus === "Open Order" || orderStatus === "Quotes",
		[classes.statusCancelled]: orderStatus === "Cancelled",
		[classes.statusPendingReview]: orderStatus === "Pending Review",
		// [classes.statusQuote]: orderStatus === "Quotes",
	});

	return (
		<div className={classes.container}>
			<div className={classes.main}>
				<div className={classes.mainFirstItem}>
					<div className={cssStatus}>
						<span>{orderType}</span>
					</div>
				</div>
				<div className={classes.mainItem}>
					<div style={{ width: "100px" }}>
						<a
							href={getOrderLink(orderId, orderType === "Point of Sale")}
							target="_parent"
						>
							#
							<Highlighter
								highlightClassName={classes.highlight}
								searchWords={[searchText]}
								textToHighlight={orderNumber.toString()}
							/>
						</a>
					</div>
				</div>
				<div className={classes.mainItem}>
					<div style={{ width: "200px" }}>
						{customer.toLowerCase() === "invoice quick" ||
						customer.toLowerCase() === "sale point of" ||
						customer === "" ||
						customer === undefined ||
						customer === null ? (
							<></>
						) : (
							<a href={getCustomerProfileLink(customerId)} target="_parent">
								<Highlighter
									highlightClassName={classes.highlight}
									searchWords={[searchText]}
									textToHighlight={truncate(customer, {
										length: screenXS ? 12 : 23,
									})}
								/>
							</a>
						)}
					</div>
				</div>
				<div>
					<div style={{ width: "160px" }}>
						<span>{formatDateTime(orderDate, true)}</span>
					</div>
				</div>
			</div>
			{payments && payments.length > 0 && (
				<div className={classes.payments}>
					{payments.length > 0 && renderPayment(payments[0])}
				</div>
			)}
		</div>
	);
};

export default OrderResultItem;
