import { createSlice } from "@reduxjs/toolkit";
import { IPointOfSaleState } from "./pointOfSale.typings";

// HELPERS
import profilesReducer, { profilesState } from "./reducers/profiles.reducer";
import editScreenReducer, { editScreenState } from "./reducers/editScreen.reducer";
import buttonReducer, { buttonsState } from "./reducers/buttons.reducer";
import salesReducer, { salesState } from "./reducers/sales.reducer";
import giftCardReducer, { giftCardState } from "./reducers/giftCard.reducer";
import lookupPrepaidReducer, { lookupPrepaid } from "./reducers/lookupPrepaid.reducer";
import interCardReducer, { interCardState } from "./reducers/interCard.reducer";

const initialPosState = {
	isLoading: false,
	error: "",
	mode: "default",
	isSelectProfileOpen: false,
	isPrepaidBalanceOpen: false,
	layout: {
		viewImage: false,
		hideProductListMobile: true,
	},
	...profilesState,
	...editScreenState,
	...buttonsState,
	...salesState,
	...giftCardState,
	...interCardState,
	...lookupPrepaid,
} as unknown as IPointOfSaleState;

const pointOfSaleSlice = createSlice({
	name: "POINTOFSALE",
	initialState: initialPosState,
	reducers: {
		// ACTIONS
		setDefaultMode: (state) => {
			return {
				...state,
				mode: "default",
			};
		},
		setPaymentMode: (state) => {
			return {
				...state,
				mode: "payment",
			};
		},
		setSelectProfileOpen: (state, { payload }) => {
			state.isSelectProfileOpen = payload;
		},
		setPrepaidBalanceOpen: (state, { payload }) => {
			state.isPrepaidBalanceOpen = payload;
		},
		setHideProductListMobile: (state, { payload }) => {
			state.layout.hideProductListMobile = payload;
		},
		...profilesReducer(),
		...editScreenReducer(),
		...buttonReducer(),
		...salesReducer(),
		...giftCardReducer(),
		...interCardReducer(),
		...lookupPrepaidReducer(),
	},
});

export const {
	setPaymentMode,
	setDefaultMode,
	changeButtonSlot,
	updateScreenName,
	updateScreenPosition,
	updateScreenColumns,
	updateScreenRows,
	updateScreenShowImage,
	updateDefaultColorButton,
	openButtonDrawer,
	closeButtonDrawer,
	openEditScreenForm,
	closeEditScreenForm,
	resetDeletedFlag,
	fetchProfilesStart,
	fetchProfilesComplete,
	fetchProfilesFail,
	setProfileAddNewMode,
	addNewProfileStart,
	addNewProfileCompleted,
	addNewProfileFailure,
	updateProfileStart,
	updatedProfileCompleted,
	updateProfileFailure,
	deleteProfileStart,
	deleteProfileCompleted,
	deleteProfileFailure,
	duplicateProfileStart,
	duplicateProfileCompleted,
	duplicateProfileFailure,
	saveScreenStart,
	saveScreenCompleted,
	saveScreenFailure,
	buildScreenDefaultGridSlots,
	buildScreenDefaultScreen,
	deleteScreenStart,
	deleteScreenCompleted,
	deleteScreenFailure,
	resetDeleteScreenFlag,
	addScreenStart,
	fetchScreenStart,
	fetchScreenCompleted,
	fetchScreenFailure,
	fetchProfileDetailsStart,
	fetchProfileDetailsCompleted,
	fetchProfileDetailsFailure,
	onCancelChanges,
	saveScreenPositionStart,
	saveScreenPositionCompleted,
	saveScreenPositionFailure,
	setSelectProfileOpen,
	setPrepaidBalanceOpen,
	setHideProductListMobile,
	openSalesOverlayAction,
	fetchProductsStart,
	fetchProductsComplete,
	fetchProductsFail,
	addNewScreenButtonStart,
	addNewScreenButtonCompleted,
	addNewScreenButtonFail,
	addButtonToScreen,
	updateButtonOnScreen,
	removeButtonFromScreen,
	setSelectedEditButton,
	unsetSelectedEditButton,
	updateScreenButtonStart,
	updateScreenButtonCompleted,
	updateScreenButtonFail,
	deleteScreenButtonStart,
	deleteScreenButtonCompleted,
	deleteScreenButtonFail,
	resetAddedButton,
	saveChangeButtonSlotStart,
	saveChangeButtonSlotCompleted,
	saveChangeButtonSlotFail,
	updateButtonImageStart,
	updateButtonImageCompleted,
	updateButtonImageFail,
	openCustomerDrawer,
	closeCustomerDrawer,
	fetchDiscountsStarted,
	fetchDiscountsCompleted,
	fetchDiscountsFailed,
	setSelectedOrderItem,
	unsetSelectedOrderItem,
	openPaymentDrawer,
	closePaymentDrawer,
	setIsProcessingPayment,
	setHasFailedPayment,
	setIsPaymentComplete,
	setIsIssuePrepaidCards,
	setTipAddedSuccess,
	setSelectedGiftCardOrderItem,
	setSelectedInterCardOrderItem,
	unsetSelectedGiftCardOrderItem,
	unsetSelectedInterCardOrderItem,
	fetchGiftCardStart,
	fetchGiftCardComplete,
	fetchGiftCardFail,
	fetchInterCardStart,
	fetchInterCardComplete,
	fetchInterCardFail,
	sendEmailGiftCardStart,
	sendEmailGiftCardSuccess,
	sendEmailGiftCardFail,
	validatePosItemStart,
	validatePosItemSuccess,
	validatePosItemFail,
	addItemToRefund,
	removeItemFromRefund,
	resetStateRefund,
	activateGiftCardStart,
	activateGiftCardSuccess,
	loadInterCardStart,
	loadInterCardSuccess,
	loadInterCardFail,
	activateGiftCardFail,
	getLookupPrepaiFail,
	getLookupPrepaiStart,
	getLookupPrepaiSuccess,
	resetLookupPrepaid,
	updateItemFromRefund,
	fillRefundableItems,
	fillRefundableTransactions,
	updateRefundableTransaction,
	addTransactionToRefund,
	removeTransactionFromRefund,
	updateTransactionFromRefund,
	handleOpenCloseTransactionNumpad,
	updateRefundableItem,
	handleOpenCloseRefundableItemNumpad,
	handleOpenCloseRefundableItemPackage,
	resetTransactionsSelected,
	setIsTaxExemptConfirmationOpen,
	validateButtonComplete,
	validateButtonFail,
	validateButtonStart,
	resetReunfdStateItems,
	reloadGiftCardFail,
	reloadGiftCardStart,
	reloadGiftCardSuccess,
} = pointOfSaleSlice.actions;

export default pointOfSaleSlice.reducer;
