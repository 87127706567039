import { MenuOptions } from "../../utils/classic.helper";
import PointOfSale from "mdi-material-ui/PointOfSale";
import ChartBox from "mdi-material-ui/ChartBox";
import AccountMultiple from "mdi-material-ui/AccountMultiple";
import Cog from "mdi-material-ui/Cog";
import Cash from "mdi-material-ui/Cash";
import Calendar from "mdi-material-ui/Calendar";
import Clock from "mdi-material-ui/Clock";
import store from "../../store/configureStore";
import { userHaveRole } from "../../utils/roles";
import { isDesktop } from "../../utils";

export interface NavMenuOption {
	title: string;
	type: MenuOptions;
	Icon?: any;
	visible?: boolean;
}

export const navMenuOptions = (): NavMenuOption[] => {
	const { featuresOn, merchantTrackEnabled } =
		store.getState().enviroment.facilitySettings;
	return [
		{
			title: "Schedule",
			type: "Schedule",
			Icon: AccountMultiple,
			visible: userHaveRole("Tab Access: Schedule"),
		},
		{
			title: "Orders",
			type: "Orders",
			Icon: AccountMultiple,
			visible: userHaveRole("Tab Access: Orders"),
		},
		{
			title: "Point of Sale",
			type: "POS",
			Icon: PointOfSale,
			visible:
				userHaveRole("Tab Access: Point Of Sale") && featuresOn.pointOfSaleOn,
		},
		{
			title: "Reports",
			type: "Reports",
			Icon: ChartBox,
			visible: userHaveRole("Tab Access: Reports"),
		},
		{
			title: "Customers",
			type: "Customers",
			Icon: AccountMultiple,
			visible: userHaveRole("Tab Access: Customers"),
		},
		{
			title: "Admin",
			type: "Admin",
			Icon: Cog,
			visible: userHaveRole("Tab Access: Admin"),
		},
		{
			title: "My Schedule",
			type: "MySchedule",
			Icon: Calendar,
			visible: userHaveRole("Tab Access: My Schedule"),
		},
		{
			title: "Time Clock",
			type: "TimeClock",
			Icon: Clock,
			visible: userHaveRole("Tab Access: Time Clock"),
		},
		{
			title: "PC PAY Merchant Track",
			type: "MerchantTrack",
			Icon: Cash,
			visible:
				userHaveRole("Tab Access: PC Pay MerchantTrack") && merchantTrackEnabled,
		},
	];
};

export const showNavCount = (screenSize) => {
	if (screenSize <= 650 && isDesktop()) {
		return 0;
	} else if (screenSize <= 750 && isDesktop()) {
		return 1;
	} else if (screenSize <= 900 && isDesktop()) {
		return 2;
	} else if (screenSize <= 1024 && isDesktop()) {
		return 3;
	}

	if (screenSize <= 1024) {
		return 9;
	} else if (screenSize <= 1100) {
		return 3;
	} else if (screenSize <= 1280) {
		return 6;
	} else if (screenSize <= 1440) {
		return 7;
	} else {
		return 9;
	}
};

export const getNavMenuOptions = (screenSize: any) => {
	const navCount = showNavCount(screenSize);

	const barMenu = navMenuOptions()
		.filter((x) => x.visible)
		.slice(0, navCount);
	const hiddenMenu = navMenuOptions()
		.filter((x) => x.visible)
		.slice(navCount);

	return { barMenu, hiddenMenu };
};
