import { takeLatest, put, call } from "redux-saga/effects";
import {
	fetchWaiverStart,
	fetchWaiverComplete,
	fetchWaiverFail,
	fetchActiveWaiverStart,
	fetchActiveWaiverComplete,
	fetchActiveWaiverFail,
} from "./waiver.slice";
import WaiverAPI from "./waiver.api";

export function* fetchWaiver({ payload }) {
	try {
		const response = yield call(WaiverAPI.fetchWaiver, payload.waiverId as number);

		yield put(fetchWaiverComplete(response.data));
	} catch (e: any) {
		yield put(fetchWaiverFail(e.message));
	}
}

export function* fetchActivehWaiver({ payload }) {
	try {
		const response = yield call(WaiverAPI.fetchActiveWaiver);

		yield put(fetchActiveWaiverComplete(response.data));
	} catch (e: any) {
		yield put(fetchActiveWaiverFail(e.message));
	}
}

// Root Sagas
export function* watchWaiver() {
	return [
		yield takeLatest(fetchWaiverStart, fetchWaiver),
		yield takeLatest(fetchActiveWaiverStart, fetchActivehWaiver),
	];
}
