export const stripeCredentitalState = {
	stripeCredentials: {
		fetchStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		publicKey: "",
	},
};

export const stripeCredentitalReducer = () => {
	return {
		fetchStripeCredentialStart: (state) => {
			return {
				...state,
				stripeCredentials: {
					fetchStateLoading: {
						...state.fetchStateLoading,
						loading: true,
					},
				},
			};
		},
		fetchStripeCredentialComplete: (state, { payload }) => {
			return {
				...state,
				stripeCredentials: {
					fetchStateLoading: {
						...state.fetchStateLoading,
						loading: false,
						success: true,
					},
					publicKey: payload.data.publicKey,
				},
			};
		},
		fetchStripeCredentialFail: (state, { payload }) => {
			return {
				...state,
				stripeCredentials: {
					fetchStateLoading: {
						...state.fetchStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
	};
};
