import { AxiosPromise } from "axios";

// NPM PACKAGES
import Http from "../../../utils/restClient";

export default class CustomersAPI {
	public static fetchCustomers(queryString: string) {
		return Http.axios().get(`/api/v1/customers/${queryString}`) as AxiosPromise;
	}

	public static fetchOrganization(accountId: number, queryString: string) {
		return Http.axios().get(
			`/api/v1/organizations/associations/${accountId}${queryString}`
		) as AxiosPromise;
	}
}
