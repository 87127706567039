import { RootState } from "../../configureStore";

export const isSearchNavOpen = (state: RootState) => state.headerNav.search.isOpen;

export const getSearchResults = (state: RootState) => state.headerNav.search.results;

export const getSearchText = (state: RootState) => state.headerNav.search.searchText;

export const getLastTenOrdersVisited = (state: RootState) =>
	state.headerNav.search.lastTenViewed;

export const getSearchFetchStateLoading = (state: RootState) =>
	state.headerNav.search.fetchStateLoading;

export const getHelpState = (state: RootState) => state.headerNav.help;

export const getHelpFetchStateLoading = (state: RootState) =>
	state.headerNav.help.fetchStateLoading;

export const getHelpAcknowledgeState = (state: RootState) =>
	state.headerNav.help.acknowledgeState;

export const getCommunicationsNotYetAcknowledgedCount = (state: RootState) =>
	state.headerNav.help.communicationsNotYetAcknowledged.length;

export const isHelpOpen = (state: RootState) => state.headerNav.help.isOpen;

export const isUserActionsOpen = (state: RootState) => state.headerNav.userActins.isOpen;

export const isAcknowledgeModalOpen = (state: RootState) =>
	state.headerNav.help.acknowledgeModal.isOpen;

export const getAcknowledgeState = (state: RootState) =>
	state.headerNav.help.acknowledgeState;
