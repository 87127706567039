import { createSlice } from "@reduxjs/toolkit";

const initialCustomerSearch = {
	items: [],
	isLoading: false,
	isFetchComplete: false,
	error: "",
	searchText: "",
	onlyCustomers: false,
	excludeAssociatedToAccountId: "",
};

const customerSearchSlice = createSlice({
	name: "CUSTOMERSEARCH",
	initialState: initialCustomerSearch,
	reducers: {
		fetchCustomerSearchStart: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				isFetchComplete: false,
				searchText: payload.searchText,
				onlyCustomers: payload.onlyCustomers,
				excludeAssociatedToAccountId: payload.excludeAssociatedToAccountId,
			};
		},
		fetchCustomerSearchComplete: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				isFetchComplete: true,
				items: payload.data.results,
			};
		},
		fetchCustomerSearchFail: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				isFetchComplete: true,
				error: payload,
			};
		},
		resetIsFetchComplete: (state) => {
			return {
				...state,
				isFetchComplete: false,
			};
		},
	},
});

export const {
	fetchCustomerSearchStart,
	fetchCustomerSearchComplete,
	fetchCustomerSearchFail,
	resetIsFetchComplete,
} = customerSearchSlice.actions;

export default customerSearchSlice.reducer;
