import React from "react";
import classnames from "classnames";
import Alert from "mdi-material-ui/Alert";
import ChevronDown from "mdi-material-ui/ChevronDown";
import ChevronUp from "mdi-material-ui/ChevronUp";
import { useTheme, Box, ThemeAgile } from "@agilekit/ui";

// ASSETS
import useStyles from "./CollapsibleItem.styles";

interface CollapisbleItemProps {
	title: string;
	actions?: React.ReactNode;
	children: React.ReactNode;
	open: boolean;
	closable?: boolean;
	error?: string;
	onCollapse?: () => void;
	onClick?: () => void;
}

const CollapsibleItem: React.FC<CollapisbleItemProps> = ({
	title,
	actions,
	children,
	open = false,
	closable = true,
	error = "",
	onCollapse = () => null,
	onClick = () => null,
}) => {
	// HOOKS
	const theme: ThemeAgile = useTheme();
	const classes = useStyles();

	// EVENTS
	const handleCollapse = () => {
		onCollapse();
	};

	// STYLES
	const cssHeader = classnames(
		classes.visibleContainer,
		{ [classes.opened]: open || !closable },
		{ [classes.closed]: !open },
		{ [classes.error]: error.length > 0 }
	);

	return (
		<div className={classes.root}>
			<div className={cssHeader}>
				<Box
					px={4}
					display="flex"
					justifyContent="space-between"
					width="100%"
					alignItems="center"
					onClick={handleCollapse}
					data-testid="collapseItem"
				>
					<div
						className={classes.titleContainer}
						onClick={onClick}
						role="presentation"
					>
						{error && !open && (
							<Box mr={4}>
								<Alert htmlColor={theme.palette.RED400} />
							</Box>
						)}
						<p className={classes.title} role="presentation">
							{title}
						</p>
					</div>
					<div className={classes.actions}>
						{(!open || !closable) && actions}
						{closable && (
							<button
								className={classes.chevronIcon}
								type="button"
								onClick={handleCollapse}
							>
								{!open ? <ChevronDown /> : <ChevronUp />}
							</button>
						)}
					</div>
				</Box>
			</div>
			{open && <Box>{children}</Box>}
		</div>
	);
};

export default CollapsibleItem;
