import { SupportCommunication } from "../headerNav.typings";

export const helpStates = {
	help: {
		fetchStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
			noResults: false,
		},
		acknowledgeState: {
			selectedCommunication: {} as SupportCommunication,
			errorMessage: "",
			loading: false,
			success: false,
		},
		acknowledgeModal: {
			isOpen: false,
		},
		communicationsByKey: [] as SupportCommunication[],
		releaseNote: {} as SupportCommunication,
		urgentCommunications: {} as SupportCommunication[],
		normalCommunications: {} as SupportCommunication[],
		communicationsNotYetAcknowledged: [] as SupportCommunication[],
		userTargetCommunications: [] as SupportCommunication[],
		isOpen: false,
	},
};

export const helpReducer = () => {
	return {
		fetchHelpStart: (state, { payload }) => {
			return {
				...state,
				help: {
					...state.help,
					fetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						noResults: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchHelpComplete: (state, { payload }) => {
			const hasError = payload.data.error.length > 0;
			// const filteredCommunicationsNotYetAcknowledged =
			// 	payload.data.communicationsNotYetAcknowledged.filter(
			// 		(x) =>
			// 			x.id === payload.data.releaseNote.id ||
			// 			payload.data.urgentCommunication.id ||
			// 			payload.data.normalCommunication.id
			// 	);
			return {
				...state,
				help: {
					...state.help,
					fetchStateLoading: {
						...state.help.fetchStateLoading,
						errorMessage: payload.data.error,
						loading: false,
						success: hasError,
					},
					communicationsByKey: payload.data.communicationsByKey,
					releaseNote: payload.data.releaseNote,
					urgentCommunications: payload.data.urgentCommunications,
					normalCommunications: payload.data.normalCommunications,
					communicationsNotYetAcknowledged:
						payload.data.communicationsNotYetAcknowledged,
					userTargetCommunications: payload.data.userTargetCommunications,
				},
			};
		},
		fetchHelpFail: (state, { payload }) => {
			return {
				...state,
				help: {
					...state.help,
					fetchStateLoading: {
						...state.plans.fetchStateLoading,
						loading: false,
						hasError: true,
						noResults: true,
						errorMessage: payload,
					},
				},
			};
		},
		openHelp: (state) => {
			state.help.isOpen = true;
		},
		closeHelp: (state) => {
			state.help.isOpen = false;
		},
		acknowledgeHelpStart: (state, { payload }) => {
			return {
				...state,
				help: {
					...state.help,
					acknowledgeState: {
						...state.help.acknowledgeState,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		acknowledgeHelpComplete: (state, { payload }) => {
			const newCommunicationsNotYetAcknowledged =
				state.help.communicationsNotYetAcknowledged.filter(
					(x) => x.id !== payload.data.id
				);
			return {
				...state,
				help: {
					...state.help,
					acknowledgeState: {
						...state.help.acknowledgeState,
						loading: false,
						success: true,
						hasError: false,
						errorMessage: "",
					},
					communicationsNotYetAcknowledged: newCommunicationsNotYetAcknowledged,
				},
			};
		},
		acknowledgeHelpFail: (state, { payload }) => {
			return {
				...state,
				help: {
					...state.help,
					acknowledgeState: {
						...state.plans.acknowledgeState,
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		selectAcknowledgeCommunication: (state, { payload }) => {
			state.help.acknowledgeState.selectedCommunication = payload;
		},
		openAcknowledgeModal: (state, { payload }) => {
			state.help.acknowledgeState.selectedCommunication = payload;
			state.help.acknowledgeModal.isOpen = true;
		},
		closeAcknowledgeModal: (state) => {
			state.help.acknowledgeState.selectedCommunication = {};
			state.help.acknowledgeModal.isOpen = false;
		},
	};
};
