import { AxiosPromise } from "axios";

// HELPERS
import Http from "../../../utils/restClient";
import { IActivateGiftCard, ILoadInterCard, IScreen } from "./pointOfSale.typings";

export default class PointOfSaleApi {
	public static GetProfiles() {
		return Http.axios().get(`/api/v1/profiles`) as AxiosPromise<any>;
	}

	public static GetProfileDetail(profileIdentifier: any) {
		return Http.axios().get(
			`/api/v1/profiles/${profileIdentifier}`
		) as AxiosPromise<any>;
	}

	public static AddProfile(profile: any) {
		return Http.axios().post(`/api/v1/profiles`, profile);
	}

	public static UpdateProfile(profile: any) {
		return Http.axios().put(`/api/v1/profiles`, profile);
	}

	public static DeleteProfile(profileIdentifier: any) {
		return Http.axios().delete(`/api/v1/profiles/${profileIdentifier}`);
	}

	public static DuplicateProfile(profileIdentifier: any) {
		return Http.axios().put(`/api/v1/profiles/${profileIdentifier}/duplicate`);
	}

	public static SaveScreen(posScreen: IScreen) {
		return Http.axios().post(`/api/v1/screens`, posScreen) as AxiosPromise<any>;
	}

	public static SaveScreenPositions(screenByPosition: any) {
		return Http.axios().post(`/api/v1/screens/positions`, {
			screenPositions: screenByPosition,
		}) as AxiosPromise<any>;
	}

	public static DeleteScreen(screenIdentifier: any) {
		return Http.axios().delete(`/api/v1/screens/${screenIdentifier}`);
	}

	public static GetScreen(screenIdentifier: any) {
		return Http.axios().get(`/api/v1/screens/${screenIdentifier}`);
	}

	public static GetProducts() {
		return Http.axios().get(`/api/v1/products/pos`);
	}

	public static GetDiscounts() {
		return Http.axios().get(`/api/v1/discounts/pos`);
	}

	public static SaveScreenButton(screenButton, screenIdentifier) {
		return Http.axios().post(`/api/v1/screens/${screenIdentifier}/buttons`, {
			posScreenButton: screenButton,
		});
	}

	public static UpdateScreenButton(screenButton, hasRemovedImage, screenIdentifier) {
		return Http.axios().put(`/api/v1/screens/${screenIdentifier}/buttons`, {
			posScreenButton: screenButton,
			hasRemovedImage: hasRemovedImage,
		});
	}

	public static UpdateScreenButtonImage(buttonId, blobName) {
		return Http.axios().put(`/api/v1/screens/buttons/${buttonId}`, {
			blobName,
		});
	}

	public static DeleteScreenButton(screenButtonId, screenIdentifier) {
		return Http.axios().delete(
			`/api/v1/screens/${screenIdentifier}/buttons/${screenButtonId}`
		);
	}

	public static GetGiftCard(orderItemId) {
		return Http.axios().get(`/api/v1/giftCard/${orderItemId}/email/log`);
	}

	public static AddEmailGiftCard(orderItemId, emailAddress) {
		return Http.axios().post(`/api/v1/giftCard/${orderItemId}/email`, {
			emailAddress,
		});
	}

	public static ActivateGiftCard(
		activateGiftCardRequest: IActivateGiftCard,
		orderItemId: number
	) {
		return Http.axios().post(
			`/api/v1/giftCard/${orderItemId}/activate`,
			activateGiftCardRequest
		);
	}

	public static ReloadGiftCard(
		activateGiftCardRequest: IActivateGiftCard,
		orderItemId: number
	) {
		return Http.axios().post(
			`/api/v1/giftCard/${orderItemId}/reload`,
			activateGiftCardRequest
		);
	}

	public static ValidatePosPackage(productId) {
		return Http.axios().get(`/api/v1/products/validate/${productId}`);
	}

	public static GetlookupPrepaid(cardNumber, pcPayChecked) {
		if (pcPayChecked) {
			return Http.axios().get(`/api/v1/giftCard/${cardNumber}/balance`);
		}
		return Http.axios().get(`/api/v1/intercard/${cardNumber}/balance`);
	}

	public static GetValidadeButton(posScreenIdentifier, productId) {
		return Http.axios().get(
			`/api/v1/screens/${posScreenIdentifier}/buttons/${productId}/validate`
		);
	}

	public static GetInterCard(orderItemId) {
		return Http.axios().get(`/api/v1/intercard/${orderItemId}`);
	}

	public static LoadInterCard(
		loadInterCardRequest: ILoadInterCard,
		orderItemId: number
	) {
		return Http.axios().post(
			`/api/v1/intercard/${orderItemId}/load`,
			loadInterCardRequest
		);
	}
}
