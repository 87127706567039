import { createSlice } from "@reduxjs/toolkit";
import {
	ICustomer,
	TypeRelatedOrganization,
	TypeRelatedPerson,
} from "../customer/customer.typings";
import customerDependentsReducer from "./reducers/customerDependents.reducer";
import customerOrganizationsReducer from "./reducers/customerOrganizations.reducer";
import customerReducer from "./reducers/customer.reducer";
import selectedProfileReducer from "./reducers/selectedProfile.reducer";
import layoutReducer from "./reducers/layout.reducer";

export interface ICustomerWaiverStatus {
	accountId: number;
	waiverStatus: string;
}

const initialState = {
	selectedCustomer: {} as unknown as ICustomer,
	relatedOrganizations: [] as TypeRelatedOrganization[],
	relatedPeople: [] as TypeRelatedPerson[],
	error: "",
	layout: {
		showSelectedCustomer: false,
		showAssociationTabs: false,
		showEditContent: false,
		waiverActive: false,
	},
	loadings: {
		findAccountsLoading: false,
	},
	selectedProfileId: 0,
	selectedProfileSuccess: false,
	selectedCustomerEditing: false,
	selectedProfile: {} as any,
	selectedProfileWaiverStatus: [] as ICustomerWaiverStatus[],
	isLoadingCustomerProfile: false,
};

const scheduleCustomerSlice = createSlice({
	name: "SCHEDULECUSTOMER",
	initialState,
	reducers: {
		...customerReducer(),
		...customerDependentsReducer(),
		...customerOrganizationsReducer(),
		...selectedProfileReducer(),
		...layoutReducer(),
		resetStore: (state, { payload }) => {
			return {
				...state,
				selectedCustomer: {} as unknown as ICustomer,
				relatedOrganizations: [] as TypeRelatedOrganization[],
				relatedPeople: [] as TypeRelatedPerson[],
				selectedProfileId: 0,
				selectedProfileSuccess: false,
				selectedCustomerEditing: false,
				selectedProfile: {} as any,
				selectedProfileWaiverStatus: [] as ICustomerWaiverStatus[],
			};
		},

		// Viewing/Editing Customer
		changeSelectedCustomerDetailsShown: (state, { payload }) => {
			return {
				...state,
				selectedCustomerEditing: payload,
			};
		},
		setScheduleSelectedCustomer: (state, { payload }) => {
			return {
				...state,
				selectedCustomer: payload,
			};
		},
		resetScheduleSelectedCustomer: (state, { payload }) => {
			return {
				...state,
				selectedCustomer: {} as ICustomer,
			};
		},
		updateScheduleSelectedCustomerPicture: (state, { payload }) => {
			let newPeopleList;
			let newOrganizationList;

			if (
				state.selectedCustomer.accountId !== payload.accountId &&
				payload.isOrganization === false
			) {
				const updatedPerson = [payload] as TypeRelatedPerson[];

				newPeopleList = state.selectedCustomer.people.map(
					(person) =>
						updatedPerson.find((p) => p.personId === person.personId) ||
						person
				);
			} else {
				newPeopleList = [...state.selectedCustomer.people];
			}

			if (
				state.selectedCustomer.accountId !== payload.accountId &&
				payload.isOrganization
			) {
				const updatedOrganization = [payload] as TypeRelatedOrganization[];

				newOrganizationList = state.selectedCustomer.organizations.map(
					(org) =>
						updatedOrganization.find(
							(o) => o.organizationId === org.organizationId
						) || org
				);
			} else {
				newOrganizationList = [...state.selectedCustomer.organizations];
			}

			return {
				...state,
				selectedCustomer: {
					...state.selectedCustomer,
					people: newPeopleList,
					organizations: newOrganizationList,
					profilePictureName:
						state.selectedCustomer.accountId === payload.accountId
							? payload.profilePictureName
							: state.selectedCustomer.profilePictureName,
					avatar:
						state.selectedCustomer.accountId === payload.accountId
							? payload.avatar
							: state.selectedCustomer.avatar,
				},
			};
		},
	},
});

export const {
	updateCustomerRequest,
	updateCustomerSuccess,
	updateCustomerFailure,

	updateAssociationRequest,
	updateAssociationSuccess,
	updateAssociationFailure,

	updateOrganizationRequest,
	updateOrganizationSuccess,
	updateOrganizationFailure,
	addSelectedCustomerOrganization,

	setShowSelectedCustomer,
	setShowAssociationTabs,
	changeSelectedCustomerDetailsShown,
	setShowEditContent,
	setWaiverActive,

	setScheduleSelectedCustomer,
	addSelectedCustomerPeople,
	resetScheduleSelectedCustomer,
	updateScheduleSelectedCustomerPicture,

	getSelectedProfileStart,
	getSelectedProfileSuccess,
	getSelectedProfileFailure,
	resetSelectedProfile,
	setSelectedProfile,
	setCustomerWaiverStatus,
	resetCustomerWaiverStatus,
	updateCustomerWaiverStatus,
	updateWaiverStatusToExpired,

	resetStore,
} = scheduleCustomerSlice.actions;

export default scheduleCustomerSlice.reducer;
