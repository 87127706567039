export const plansStates = {
	plans: {
		fetchStateLoading: {
			errorMessage: false,
			hasError: false,
			loading: false,
			success: false,
		},
		items: [] as any,
	},
};

export const plansReducer = () => {
	return {
		fetchPlansStart: (state) => {
			return {
				...state,
				plans: {
					...state.plans,
					fetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchPlansComplete: (state, { payload }) => {
			return {
				...state,
				plans: {
					fetchStateLoading: {
						...state.plans.fetchStateLoading,
						loading: false,
						success: true,
					},
					items: payload,
				},
			};
		},
		fetchPlansFail: (state, { payload }) => {
			return {
				...state,
				plans: {
					...state.plans,
					fetchStateLoading: {
						...state.plans.fetchStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
	};
};
