import { ApiResult, ApiResultError } from "../../helpers/typings";
import { IOrderItem, StateLoading } from "../order.typings";

export type IUpdateOrderItemQuantity = {
	orderId: number;
	itemId: number;
	model: { quantity: number };
};

export type IUpdatePackageOrderItemQuantity = IUpdateOrderItemQuantity & {
	parentOrderItemId: number;
};

export type IUpdatePackageOrderItemResponse = {
	parentItem: IOrderItem;
	packageItem: IOrderItem;
};

export const orderItemsState = {
	applyOrderItemDiscount: {
		orderItemId: 0,
		loading: false,
		success: false,
		started: false,
		hasError: false,
		errorMessage: "",
	} as any,
	updateOrderItemNotes: {
		loading: false,
		success: false,
		started: false,
		hasError: false,
		errorMessage: "",
	} as StateLoading,
	updateOrderItemPrice: {
		loading: false,
		success: false,
		started: false,
		hasError: false,
		errorMessage: "",
	} as StateLoading,
};

const orderItemsReducer = () => {
	return {
		// ADD ORDER ITEM
		addOrderItemRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				message: "",
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
				orderItems: [...state.orderItems, payload.itemToAdd],
			};
		},
		addOrderItemSuccess: (state, { payload }) => {
			return {
				...state,
				orderItem: payload.data.results,
				message: payload.data.message,
				isLoading: false,
				error: "",
				addOrderSuccess: false,
				addOrderItemSuccess: true,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
			};
		},
		addOrderItemFailure: (state, { payload }) => {
			const addedItemIndex = state.orderItems.findIndex(
				(x) =>
					x.item.productId === payload.error.response.data.results.productId &&
					x.requested
			);
			const newOrderItemsArray = [...state.orderItems];
			newOrderItemsArray[addedItemIndex] = {
				...newOrderItemsArray[addedItemIndex],
				requested: false,
				error: payload.error.response.data.message,
				itemIsLoading: false,
			};
			return {
				...state,
				isLoading: false,
				message: "",
				orderItem: {} as IOrderItem,
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
				orderItems: newOrderItemsArray,
			};
		},
		// REMOVE ORDER ITEM
		removeOrderItemRequest: (state, { payload }) => {
			const removedItemIndex = state.orderItems.findIndex(
				(x) => x.item.id === payload.itemId
			);
			const newOrderItemsArray = [...state.orderItems];
			newOrderItemsArray[removedItemIndex] = {
				...newOrderItemsArray[removedItemIndex],
				requested: true,
				itemIsLoading: true,
			};
			return {
				...state,
				isLoading: true,
				message: "",
				addOrderSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
				orderItems: newOrderItemsArray,
			};
		},
		removeOrderItemSuccess: (state, { payload }) => {
			let newOrderItemsArray = [] as any[];

			if (payload.data.isDiscount) {
			} else {
				const addedItemIndex = state.orderItems.findIndex(
					(x) => x.item.id === payload.data.results.id
				);

				newOrderItemsArray = [...state.orderItems];
				newOrderItemsArray.splice(addedItemIndex, 1);
			}
			return {
				...state,
				isLoading: false,
				error: "",
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: true,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
				orderItems: newOrderItemsArray,
			};
		},
		removeOrderItemFailure: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				error: payload.error.response.data.errors,
				message: payload.error.response.data.message,
				orderItem: {} as IOrderItem,
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
			};
		},
		removeItemWithError: (state, { payload }) => {
			const addedItemIndex = state.orderItems.findIndex(
				(x) =>
					x.item.id === 0 && x.error.length > 0 && x.item.productId === payload
			);
			const newOrderItemsArray = [...state.orderItems];
			newOrderItemsArray.splice(addedItemIndex, 1);
			return {
				...state,
				isLoading: false,
				error: "",
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: true,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
				orderItems: newOrderItemsArray,
			};
		},
		// UPDATE ORDER ITEM QUANTITY
		updateOrderItemQuantityRequest: (
			state,
			{ payload }: { payload: IUpdateOrderItemQuantity }
		) => {
			// const updatedItemIndex = state.orderItems.findIndex(
			// 	(x) => x.item.id === payload.itemId
			// );
			// const newOrderItemsArray = [...state.orderItems];
			// newOrderItemsArray[updatedItemIndex] = {
			// 	...newOrderItemsArray[updatedItemIndex],
			// 	requested: true,
			// 	itemIsLoading: true,
			// };
			return {
				...state,
				isLoading: true,
				message: "",
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
				// orderItems: newOrderItemsArray,
			};
		},
		updateOrderItemQuantitySuccess: (
			state,
			{ payload }: { payload: ApiResult<IOrderItem> }
		) => {
			return {
				...state,
				orderItem: payload.data.results,
				message: payload.data.message,
				isLoading: false,
				error: "",
				addOrderSuccess: false,
				addOrderItemSuccess: true,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: true,
				orderInfoRequested: false,
			};
		},
		updateOrderItemQuantityFailure: (
			state,
			{ payload }: { payload: ApiResultError<IOrderItem> }
		) => {
			const updatedItemIndex = state.orderItems.findIndex(
				(x) => x.item.id === payload.error.response.data.results.id && x.requested
			);
			const newOrderItemsArray = [...state.orderItems];
			newOrderItemsArray[updatedItemIndex] = {
				...newOrderItemsArray[updatedItemIndex],
				requested: false,
				error: payload.error.response.data.message,
				itemIsLoading: false,
			};
			return {
				...state,
				isLoading: false,
				error: payload.error.response.data.errors,
				message: payload.error.response.data.message,
				orderItem: {} as IOrderItem,
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
				orderItems: newOrderItemsArray,
			};
		},
		// UPDATE ORDER ITEM QUANTITY
		updatePackageOrderItemQuantityRequest: (
			state,
			{ payload }: { payload: IUpdatePackageOrderItemQuantity }
		) => {
			const updatedItemIndex = state.orderItems.findIndex(
				(x) => x.item.id === payload.parentOrderItemId
			);
			const newOrderItemsArray = [...state.orderItems];
			newOrderItemsArray[updatedItemIndex] = {
				...newOrderItemsArray[updatedItemIndex],
				requested: true,
				itemIsLoading: true,
			};
			return {
				...state,
				isLoading: true,
				message: "",
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
				orderItems: newOrderItemsArray,
			};
		},
		updatePackageOrderItemQuantitySuccess: (
			state,
			{ payload }: { payload: ApiResult<IUpdatePackageOrderItemResponse> }
		) => {
			return {
				...state,
				orderItem: payload.data.results,
				message: payload.data.message,
				isLoading: false,
				error: "",
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: true,
				orderInfoRequested: false,
			};
		},
		updatePackageOrderItemQuantityFailure: (
			state,
			{ payload }: { payload: ApiResultError<IUpdatePackageOrderItemResponse> }
		) => {
			const updatedItemIndex = state.orderItems.findIndex(
				(x) =>
					x.item.id === payload.error.response.data.results.packageItem.id &&
					x.requested
			);
			const newOrderItemsArray = [...state.orderItems];
			newOrderItemsArray[updatedItemIndex] = {
				...newOrderItemsArray[updatedItemIndex],
				requested: false,
				error: payload.error.response.data.message,
				itemIsLoading: false,
			};
			return {
				...state,
				isLoading: false,
				error: payload.error.response.data.errors,
				message: payload.error.response.data.message,
				orderItem: {} as IOrderItem,
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
				orderItems: newOrderItemsArray,
			};
		},
		applyOrderItemDiscountStart: (state, { payload }) => {
			return {
				...state,
				applyOrderItemDiscount: {
					orderItemId: payload.orderItemId,
					started: true,
					errorMessage: "",
					hasError: false,
					loading: true,
					success: false,
				},
			};
		},
		applyOrderItemDiscountCompleted: (state, { payload }) => {
			return {
				...state,
				applyOrderItemDiscount: {
					...state.applyOrderItemDiscount,
					orderItemId: 0,
					started: false,
					success: true,
					loading: false,
				},
			};
		},
		applyOrderItemDiscountFailed: (state, { payload }) => {
			return {
				...state,
				applyOrderItemDiscount: {
					...state.applyOrderItemDiscount,
					hasError: true,
					loading: false,
					errorMessage: payload.error,
				},
			};
		},
		updateOrderItemNotesStart: (state, { payload }) => {
			return {
				...state,
				updateOrderItemNotes: {
					started: true,
					errorMessage: "",
					hasError: false,
					loading: true,
					success: false,
				},
			};
		},
		updateOrderItemNotesCompleted: (state, { payload }) => {
			return {
				...state,
				updateOrderItemNotes: {
					...state.updateOrderItemNotes,
					started: false,
					success: true,
				},
			};
		},
		updateOrderItemNotesFailed: (state, { payload }) => {
			return {
				...state,
				updateOrderItemNotes: {
					...state.updateOrderItemNotes,
					hasError: true,
					errorMessage: payload.error,
				},
			};
		},
		updateOrderItemPriceStart: (state, { payload }) => {
			let updatedItemIndex = 0;
			if (payload.insidePackage) {
				updatedItemIndex = state.orderItems.findIndex(
					(x) => x.item.id === payload.parentOrderItemId
				);
			} else {
				updatedItemIndex = state.orderItems.findIndex(
					(x) => x.item.id === payload.orderItemId
				);
			}
			const newOrderItemsArray = [...state.orderItems];
			newOrderItemsArray[updatedItemIndex] = {
				...newOrderItemsArray[updatedItemIndex],
				requested: true,
				itemIsLoading: true,
			};
			return {
				...state,
				isLoading: true,
				updateOrderItemPrice: {
					started: true,
					errorMessage: "",
					hasError: false,
					loading: true,
					success: false,
				},
				orderItems: newOrderItemsArray,
			};
		},
		updateOrderItemPriceCompleted: (state, { payload }) => {
			return {
				...state,
				updateOrderItemPrice: {
					...state.updateOrderItemPrice,
					started: false,
					loading: false,
					success: true,
				},
				isLoading: false,
				error: "",
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
			};
		},
		updateOrderItemPriceFailed: (state, { payload }) => {
			return {
				...state,
				updateOrderItemPrice: {
					...state.updateOrderItemPrice,
					hasError: true,
					errorMessage: payload.error,
				},
			};
		},
		unsetDiscountAndNotesState: (state) => {
			state.applyOrderItemDiscount.success = false;
			state.updateOrderItemNotes.success = false;
			state.applyOrderDiscount.success = false;
		},
	};
};

export default orderItemsReducer;
