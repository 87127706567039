import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		userActionsContainer: {
			position: "relative",
			minWidth: 140,
			right: 5,
			[theme.breakpoints.down("xs")]: {
				minWidth: "unset",
			},
		},
		userActions: {
			minWidth: 100,
			[theme.breakpoints.down("xs")]: {
				minWidth: "unset",
			},
		},
		userActionsOpen: {
			"& $userActionsInput": {
				backgroundColor: theme.palette.NEUTRAL000,
				boxShadow: theme.shadows[1],
				"& input": {
					color: theme.palette.NEUTRAL800,
					"&::placeholder": {
						color: theme.palette.NEUTRAL080,
					},
				},
			},
			"& $userActionsIcon": {
				color: theme.palette.NEUTRAL800,
			},
		},
		userActionsListContainer: {
			minWidth: 170,
			maxWidth: 210,
			position: "absolute",
			right: 10,
			top: 35,
			backgroundColor: theme.palette.NEUTRAL000,
			marginTop: theme.spacing(2),
			padding: theme.spacing(2, 0),
			borderRadius: 4,
			boxShadow: theme.shadows[3],
		},
		userActionsIcon: {
			fontSize: theme.typography.pxToRem(20),
			color: theme.palette.NEUTRAL000,
			marginRight: theme.spacing(3),
		},
		userActionsPanel: {},
		sectionRightButton: {
			color: theme.palette.NEUTRAL000,
			backgroundColor: "transparent",
			border: "none",
			borderRadius: 4,
			padding: theme.spacing(1.5, 2),
			cursor: "pointer",
			"&:hover": {
				backgroundColor: theme.palette.NEUTRAL600,
			},
		},
		userActionsList: {
			padding: theme.spacing(0, 2),
			maxHeight: 500,
		},
		sectionContainer: {
			borderBottom: `1px solid ${theme.palette.NEUTRAL040}`,
			paddingTop: 5,
		},
		lastSectionContainer: {
			paddingTop: 5,
		},
		main: {
			display: "flex",
			justifyContent: "space-between",
			color: theme.palette.NEUTRAL600,
			fontSize: theme.typography.pxToRem(14),
			"& strong": {
				color: theme.palette.NEUTRAL900,
				fontWeight: 500,
				borderBottom: "none",
			},
			"& button": {
				color: theme.palette.NEUTRAL800,
				backgroundColor: "transparent",
				border: "none",
				borderRadius: 4,
				padding: theme.spacing(1.5, 2),
				cursor: "pointer",
				width: "100%",
				textAlign: "left",
				"&:hover": {
					backgroundColor: theme.palette.NEUTRAL020,
				},
			},
			"& > div": {
				display: "flex",
				alignItems: "center",
			},
		},
		mainItem: {
			display: "flex",
			width: "260px",
		},
		textItem: {
			padding: theme.spacing(1.5, 2),
			width: "100%",
			fontSize: theme.typography.pxToRem(14),
			color: theme.palette.NEUTRAL900,
		},
		smallText: {
			fontSize: theme.typography.pxToRem(11),
		},
		jsFiles: {
			border: `1px solid ${theme.palette.YELLOW100}`,
			padding: theme.spacing(2),
			marginTop: theme.spacing(2),
			backgroundColor: theme.palette.YELLOW050,
			"& p": {
				color: theme.palette.YELLOW700,
				fontWeight: 500,
			},
		},
	};
});

export default useStyles;
