import React from "react";
import { Loader } from "@agilekit/ui";

// ASSETS
import useStyles from "./PageLoader.styles";

const PageLoader = () => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<Loader type="square" />
		</div>
	);
};

export default PageLoader;
