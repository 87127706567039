import React from "react";
import classnames from "classnames";
import Close from "mdi-material-ui/Close";
import MenuDown from "mdi-material-ui/MenuDown";

// ASSETS
import useStyles from "./FilterDropdown.styles";

interface FilterDropdownProps {
	label?: string;
	value: string | React.ReactNode;
	active?: boolean;
	onClick?: any;
	onRemove?: any;
	size?: "medium" | "small";
}

// EXPERINMENTAL Component - Just in storybook not shared to the app
const FilterDropdown: React.FC<FilterDropdownProps> = ({
	label,
	value,
	active,
	onClick,
	onRemove,
	size = "medium",
}) => {
	const classes = useStyles();

	// STYLES
	const cssRoot = classnames(classes.root, {
		[classes.rootActive]: active,
		[classes.rootSmall]: size === "small",
	});
	return (
		<div className={cssRoot}>
			<div>
				<div
					onClick={onClick}
					className={classes.filterButton}
					tabIndex={-1}
					data-allow-shortcuts="false"
					role="presentation"
					style={{ width: "unset" }}
				>
					{label && <span className={classes.filterLabel}>{label}:</span>}
					<strong className={classes.filterValue}>{value}</strong>
					<MenuDown />
				</div>
			</div>
			<div className={classes.filterClose} onClick={onRemove} role="presentation">
				<Close fontSize="inherit" />
			</div>
		</div>
	);
};

export default FilterDropdown;
