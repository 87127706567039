import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		header: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			fontSize: theme.typography.pxToRem(18),
			fontWeight: 500,
			padding: theme.spacing(4.5, 8),
			borderBottom: `1px solid ${theme.palette.NEUTRAL020}`,
			"& p": {
				marginBottom: 0,
			},
		},
	};
});

export default useStyles;
