import { takeLatest, call, put } from "redux-saga/effects";

import {
	membershipPlanFailure,
	membershipPlanListRequest,
	membershipPlanListSuccess,
	membershipPlanAddRequest,
	membershipPlanAddSuccess,
	membershipPlanDeleteRequest,
	membershipPlanDeleteSuccess,
	membershipPlanSelectRequest,
	membershipPlanSelectSuccess,
	membershipPlanUpdateRequest,
	membershipPlanUpdateSuccess,
	fetchAllMembershipPlans,
	fetchAllMembershipPlansFailure,
	fetchAllMembershipPlanstSuccess,
} from "./memberships.slice";
import MembershipApi from "./memberships.api";

// MEMBERSHIPS
export function* getMembershipPlans(): any {
	try {
		const response = yield call(MembershipApi.GetMembershipPlans);

		yield put(membershipPlanListSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(membershipPlanFailure({ error }));
	}
}

export function* addMembershipPlan({ payload }): any {
	try {
		const response = yield call(MembershipApi.AddMembershipPlan, payload);

		yield put(membershipPlanAddSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(membershipPlanFailure({ error }));
	}
}

export function* deleteMembershipPlan({ payload }): any {
	try {
		yield call(MembershipApi.DeleteMembershipPlan, payload);
		yield put(membershipPlanDeleteSuccess({ planId: payload.planId }));
	} catch (error: any) {
		yield put(membershipPlanFailure({ error }));
	}
}

export function* selectMembershipPlan({ payload }): any {
	try {
		yield put(membershipPlanSelectSuccess(payload.plan));
	} catch (error: any) {
		yield put(membershipPlanFailure({ error }));
	}
}

export function* updateMembershipPlan({ payload }): any {
	try {
		const response = yield call(MembershipApi.UpdateMembershipPlan, payload);
		yield put(membershipPlanUpdateSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(membershipPlanFailure({ error }));
	}
}

export function* getAllMembershipPlans() {
	try {
		const { data } = yield call(MembershipApi.fetchAllMembershipPlans);

		yield put(fetchAllMembershipPlanstSuccess(data));
	} catch (e: any) {
		yield put(fetchAllMembershipPlansFailure(e.message));
	}
}

export function* watchMemberships() {
	return [
		// MEMBERSHIPS
		yield takeLatest(membershipPlanListRequest, getMembershipPlans),
		yield takeLatest(membershipPlanAddRequest, addMembershipPlan),
		yield takeLatest(membershipPlanDeleteRequest, deleteMembershipPlan),
		yield takeLatest(membershipPlanSelectRequest, selectMembershipPlan),
		yield takeLatest(membershipPlanUpdateRequest, updateMembershipPlan),
		yield takeLatest(fetchAllMembershipPlans, getAllMembershipPlans),
	];
}
