import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {
			boxSizing: "border-box",
			border: "1px solid #DFE1E6",
			marginTop: "15px",
		},
	};
});

export default useStyles;
