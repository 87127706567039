import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {
			borderRadius: 4,
			boxShadow: theme.shadows[1],
			backgroundColor: theme.palette.NEUTRAL000,
			borderTop: `5px solid ${theme.palette.YELLOW400}`,
		},
		header: {
			padding: theme.spacing(6),
		},
		headerTitle: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
		},
		titleContainer: {
			display: "flex",
			alignItems: "center",
		},
		title: {
			fontWeight: 500,
			fontSize: theme.typography.pxToRem(16),
			lineHeight: theme.typography.pxToRem(20),
			color: theme.palette.NEUTRAL700,
			margin: 0,
		},
		contextMenuButton: {
			backgroundColor: "transparent",
			border: "none",
			cursor: "pointer",
			"&:focus": {
				outline: "none",
			},
		},
		body: {
			backgroundColor: theme.palette.NEUTRAL010,
			padding: theme.spacing(6),
			borderTop: `1px solid ${theme.palette.NEUTRAL040}`,
			borderBottom: `1px solid ${theme.palette.NEUTRAL040}`,
			overflow: "auto",
		},
		footer: {
			padding: theme.spacing(4, 6),
			display: "flex",
			alignItem: "center",
			justifyContent: "space-between",
		},
		totalModifiers: {
			borderRadius: 4,
			padding: theme.spacing(1.75),
			backgroundColor: theme.palette.NEUTRAL030,
			fontSize: theme.typography.pxToRem(14),
			lineHeight: theme.typography.pxToRem(14),
			color: theme.palette.NEUTRAL700,
			"& span": {
				fontWeight: 500,
			},
		},
		addModifier: {
			backgroundColor: "transparent",
			border: "none",
			color: theme.palette.BLUE400,
			fontSize: theme.typography.pxToRem(14),
			lineHeight: theme.typography.pxToRem(14),
			fontWeight: 500,
			cursor: "pointer",
			"&:focus": {
				outline: "none",
			},
		},
	};
});

export default useStyles;
