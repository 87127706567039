import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {
			display: "flex",
			alignItems: "center",
			backgroundColor: theme.palette.NEUTRAL010,
			border: `1px solid ${theme.palette.NEUTRAL040}`,
			borderRadius: 4,
			padding: theme.spacing(4),
		},
		icon: {
			fontSize: theme.typography.pxToRem(44),
			display: "flex",
			marginRight: theme.spacing(4),
			color: theme.palette.NEUTRAL100,
		},
		content: {
			fontSize: theme.typography.pxToRem(16),
			color: theme.palette.NEUTRAL500,
			"& p": {
				margin: 0,
			},
		},
	};
});

export default useStyles;
