import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import {
	orderInfoRequest,
	orderInfoSuccess,
	orderInfofailure,
	addOrderRequest,
	addOrderSuccess,
	addOrderFailure,
	addOrderItemRequest,
	addOrderItemSuccess,
	addOrderItemFailure,
	removeOrderItemRequest,
	removeOrderItemSuccess,
	removeOrderItemFailure,
	clearOrderFailure,
	clearOrderRequest,
	clearOrderSuccess,
	updateOrderItemQuantityFailure,
	updateOrderItemQuantityRequest,
	updateOrderItemQuantitySuccess,
	addOrderToCustomerRequest,
	addOrderToCustomerSuccess,
	addOrderToCustomerFailure,
	visitOrderRequest,
	visitOrderFailure,
	visitOrderSuccess,
	applyOrderItemDiscountStart,
	applyOrderItemDiscountCompleted,
	applyOrderItemDiscountFailed,
	updateOrderItemNotesStart,
	updateOrderItemNotesCompleted,
	updateOrderItemNotesFailed,
	updateOrderCustomerRequest,
	updateOrderCustomerSuccess,
	updateOrderCustomerFailure,
	applyOrderDiscountStart,
	applyOrderDiscountCompleted,
	applyOrderDiscountFailed,
	updatePackageOrderItemQuantityRequest,
	updatePackageOrderItemQuantitySuccess,
	updatePackageOrderItemQuantityFailure,
	updateOrderRequest,
	updateOrderSuccess,
	updateOrderFail,
	addTipStarted,
	addTipSuccess,
	addTipFailure,
	fetchPaymentHistoryStarted,
	fetchPaymentHistorySuccess,
	fetchPaymentHistoryFailure,
	updateOrderItemPriceStart,
	updateOrderItemPriceCompleted,
	updateOrderItemPriceFailed,
	refundOrderItemsStart,
	refundOrderItemsCompleted,
	refundOrderItemsFailed,
} from "./order.slice";
import OrderApi from "./order.api";
import { IRefundRequest } from "./order.typings";
import { IAddOrderRequest } from "../../../containers/pages/PointOfSale/sections/Sales/components/SalesGrid/SalesGrid.helper";

export function* getOrderInfo({ payload }): any {
	try {
		const response = yield call(OrderApi.GetOrderInfo, payload);
		yield put(orderInfoSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(orderInfofailure({ error }));
	}
}

export function* addOrderToCustomer({ payload }): any {
	try {
		const response = yield call(OrderApi.AddOrder, payload);

		yield put(addOrderToCustomerSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(addOrderToCustomerFailure({ error }));
	}
}

export function* updateOrderCustomer({ payload }): any {
	try {
		const response = yield call(OrderApi.UpdateOrderCustomer, payload);

		yield put(updateOrderCustomerSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(updateOrderCustomerFailure({ error }));
	}
}

export function* addOrder({ payload }: { payload: IAddOrderRequest }): any {
	try {
		const response = yield call(OrderApi.AddOrder, payload);

		yield put(addOrderSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(addOrderFailure({ error }));
	}
}

export function* updateOrder({ payload }): any {
	try {
		const response = yield call(OrderApi.UpdateOrder, payload);

		yield put(updateOrderSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(updateOrderFail({ error }));
	}
}

export function* addOrderItem({ payload }): any {
	try {
		const response = yield call(OrderApi.AddItem, payload);

		yield put(addOrderItemSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(addOrderItemFailure({ error }));
	}
}

export function* updateOrderItemQuantity({ payload }): any {
	try {
		const response = yield call(OrderApi.UpdateQuantity, payload);

		yield put(updateOrderItemQuantitySuccess({ data: response.data }));
	} catch (error: any) {
		yield put(updateOrderItemQuantityFailure({ error }));
	}
}

export function* updatePackageOrderItemQuantity({ payload }): any {
	try {
		const response = yield call(OrderApi.UpdateQuantity, payload);

		yield put(updatePackageOrderItemQuantitySuccess({ data: response.data }));
	} catch (error: any) {
		yield put(updatePackageOrderItemQuantityFailure({ error }));
	}
}

export function* removeOrderItem({ payload }): any {
	try {
		const response = yield call(OrderApi.RemoveItem, payload);

		const data = response.data;
		data.isDiscount = payload.isDiscount;

		yield put(removeOrderItemSuccess({ data }));
	} catch (error: any) {
		yield put(removeOrderItemFailure({ error }));
	}
}

export function* clearOrder({ payload }): any {
	try {
		const response = yield call(OrderApi.ClearOrder, payload);

		yield put(clearOrderSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(clearOrderFailure({ error }));
	}
}

export function* visitOrderOrder({ payload }): any {
	try {
		const response = yield call(OrderApi.VisitOrder, payload);

		yield put(visitOrderSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(visitOrderFailure({ error }));
	}
}

export function* discountOrderItem({ payload }): any {
	try {
		const { orderId, discountId, orderItemId } = payload;

		const response = yield call(
			OrderApi.DiscountOrderItem,
			orderId,
			discountId,
			orderItemId
		);

		if (response.data.ok) {
			yield put(applyOrderItemDiscountCompleted({ data: response.data }));
		} else {
			yield put(
				applyOrderItemDiscountFailed({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(applyOrderItemDiscountFailed({ error }));
	}
}

export function* discountOrder({ payload }): any {
	try {
		const response = yield call(OrderApi.DiscountOrder, payload);

		if (response.data.ok) {
			yield put(applyOrderDiscountCompleted({ data: response.data }));
		} else {
			yield put(
				applyOrderDiscountFailed({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(applyOrderDiscountFailed({ error }));
	}
}

export function* updateOrderItemNotes({ payload }): any {
	try {
		const { orderId, noteText, orderItemId, displayNotesOnReceipt } = payload;

		const response = yield call(
			OrderApi.UpdateOrderItemNotes,
			orderId,
			noteText,
			orderItemId,
			displayNotesOnReceipt
		);

		if (response.data.ok) {
			yield put(updateOrderItemNotesCompleted({ data: response.data }));
		} else {
			yield put(
				updateOrderItemNotesFailed({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(updateOrderItemNotesFailed({ error: error.toString() }));
	}
}

export function* updateOrderItemPrice({ payload }): any {
	try {
		const { updatedPrice, orderId, orderItemId } = payload;

		const response = yield call(
			OrderApi.UpdateOrderItemPrice,
			orderId,
			updatedPrice,
			orderItemId
		);

		if (response.data.ok) {
			yield put(updateOrderItemPriceCompleted({ data: response.data }));
		} else {
			yield put(
				updateOrderItemPriceFailed({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(updateOrderItemPriceFailed({ error: error.toString() }));
	}
}

export function* addTipToOrder({ payload }): any {
	try {
		const { orderId, tipAmount, paymentModuleTransactionId } = payload;

		const response = paymentModuleTransactionId
			? yield call(
					OrderApi.AddCreditTip,
					orderId,
					tipAmount,
					paymentModuleTransactionId
			  )
			: yield call(OrderApi.AddTip, orderId, tipAmount);

		if (response.data.ok) {
			yield put(addTipSuccess({ data: response.data }));
		} else {
			yield put(
				addTipFailure({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(addTipFailure({ error }));
	}
}

export function* fetchPaymentHistory({ payload }): any {
	const { orderId } = payload;
	try {
		const response = yield call(OrderApi.GetPaymentHistory, orderId);
		yield put(fetchPaymentHistorySuccess({ data: response.data }));
	} catch (error: any) {
		yield put(fetchPaymentHistoryFailure({ error }));
	}
}

export function* postRefundOrderItems({
	payload,
}: {
	payload: {
		orderId: number;
		refundRequest: IRefundRequest;
	};
}): any {
	const { orderId, refundRequest } = payload;
	try {
		const response = yield call(OrderApi.RefundOrderItems, orderId, refundRequest);
		yield put(refundOrderItemsCompleted({ data: response.data }));
	} catch (error: any) {
		yield put(refundOrderItemsFailed({ error }));
	}
}

export function* watchOrder() {
	return [
		yield takeLatest(orderInfoRequest, getOrderInfo),
		yield takeLatest(addOrderRequest, addOrder),
		yield takeLatest(updateOrderRequest, updateOrder),
		yield takeLatest(addOrderToCustomerRequest, addOrderToCustomer),
		yield takeLatest(updateOrderCustomerRequest, updateOrderCustomer),
		yield takeEvery(addOrderItemRequest, addOrderItem),
		yield takeEvery(removeOrderItemRequest, removeOrderItem),
		yield takeLatest(clearOrderRequest, clearOrder),
		yield takeLatest(updateOrderItemQuantityRequest, updateOrderItemQuantity),
		yield takeLatest(
			updatePackageOrderItemQuantityRequest,
			updatePackageOrderItemQuantity
		),
		yield takeLatest(visitOrderRequest, visitOrderOrder),
		yield takeLatest(applyOrderItemDiscountStart, discountOrderItem),
		yield takeLatest(applyOrderDiscountStart, discountOrder),
		yield takeLatest(updateOrderItemNotesStart, updateOrderItemNotes),
		yield takeLatest(updateOrderItemPriceStart, updateOrderItemPrice),
		yield takeLatest(addTipStarted, addTipToOrder),
		yield takeLatest(fetchPaymentHistoryStarted, fetchPaymentHistory),
		yield takeLatest(refundOrderItemsStart, postRefundOrderItems),
	];
}
