import { IPaymentCompletedResponse } from "../payments.typings";

export const giftPayState = {
	giftCardPay: {
		applyStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			started: false,
			success: false,
		},
		paymentResponse: {} as IPaymentCompletedResponse,
	},
};

const giftPayReducer = () => {
	return {
		postGiftPayStart: (state, { payload }) => {
			return {
				...state,
				giftCardPay: {
					...state.giftCardPay,
					applyStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		//different start but same complete and fail
		postInterCardPayStart: (state, { payload }) => {
			return {
				...state,
				giftCardPay: {
					...state.giftCardPay,
					applyStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		postGiftPayComplete: (state, { payload }) => {
			return {
				...state,
				giftCardPay: {
					...state.giftCardPay,
					applyStateLoading: {
						...state.giftCardPay.applyStateLoading,
						started: true,
						loading: false,
						success: true,
					},
					paymentResponse: payload.data.results,
				},
			};
		},
		postGiftPayFail: (state, { payload }) => {
			return {
				...state,
				giftCardPay: {
					...state.giftCardPay,
					applyStateLoading: {
						...state.giftCardPay.applyStateLoading,
						started: true,
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		resetGiftPaymentState: (state) => {
			return {
				...state,
				giftCardPay: {
					applyStateLoading: {
						started: false,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
					paymentResponse: {} as IPaymentCompletedResponse,
				},
			};
		},
	};
};
export default giftPayReducer;
