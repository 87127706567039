import { AxiosPromise } from "axios";

// NPM PACKAGES
import Http from "../../../utils/restClient";

const terminalUrl = "http://localhost:5050";

export default class TerminalAPI {
	public static print(payload) {
		console.log("API: ", terminalUrl);
		console.log("Body: ", payload);
		return Http.axios().post(
			`${terminalUrl}/printer/receipt`,
			payload
		) as AxiosPromise<any>;
	}

	public static getSettings(payload) {
		return Http.axios().get(
			`/api/v1/agileTerminal/setting/${payload}`
		) as AxiosPromise<any>;
	}
}
