export const editScreenState = {
	editScreen: {
		layout: {
			buttonDrawerOpen: false,
			editScreenFormOpen: false,
		},
		settings: {
			openSlotId: 0,
			openSlotRow: 0,
			openSlotColumn: 0,
		},
	},
};

const editScreenReducer = () => {
	return {
		openButtonDrawer: (state, { payload }) => {
			state.editScreen.layout.buttonDrawerOpen = true;

			state.editScreen.settings.openSlotId = payload.slotId;
			state.editScreen.settings.openSlotColumn = payload.column;
			state.editScreen.settings.openSlotRow = payload.row;
		},
		closeButtonDrawer: (state) => {
			state.editScreen.layout.buttonDrawerOpen = false;
		},
		openEditScreenForm: (state) => {
			state.editScreen.layout.editScreenFormOpen = true;
		},
		closeEditScreenForm: (state) => {
			state.editScreen.layout.editScreenFormOpen = false;
		},
	};
};

export default editScreenReducer;
