import { takeLatest, put, call } from "redux-saga/effects";
import {
	fetchCustomerSearchStart,
	fetchCustomerSearchComplete,
	fetchCustomerSearchFail,
} from "./customerSearch.slice";
import {
	fetchOrganizationAssociationsStart,
	fetchOrganizationAssociationsFail,
	fetchOrganizationAssociationsComplete,
} from "../customer/customer.slice";
import CustomersAPI from "../customers/customers.api";
import store from "../../configureStore";
import { IFacilitySettings } from "../enviroment/enviroment.slice";

export function* fetchCustomerSearch() {
	try {
		const { searchText, onlyCustomers, excludeAssociatedToAccountId } =
			store.getState().customerSearch;
		const { timezone }: IFacilitySettings =
			store.getState().enviroment.facilitySettings;

		const sanitizedString = searchText.replace("+", "%2B");
		let query = `?searchText=${sanitizedString}&filterByCustomerName=true&orderBy=Name&orderDirection=asc&facilityTimeZone=${timezone}&pageSize=30&customerStatus=Active`;

		if (onlyCustomers) {
			query = `?searchText=${sanitizedString}&filterByCustomerName=true&orderBy=Name&orderDirection=asc&facilityTimeZone=${timezone}&pageSize=30&customerStatus=Active&customerTypes=Customer`;
		}

		if (excludeAssociatedToAccountId) {
			query += `&ExcludeAssociatedToAccountId=${excludeAssociatedToAccountId}`;
		}

		const { data } = yield call(CustomersAPI.fetchCustomers, query);

		yield put(fetchCustomerSearchComplete({ data }));
	} catch (e: any) {
		yield put(fetchCustomerSearchFail(e.message));
	}
}

export function* fetchOrganizationAssociationsSearch({ payload }) {
	try {
		const { accountId } = payload;
		const { organizationAssociationsPagination } = store.getState().customer;
		const pagedSearch = `&pageSize=${organizationAssociationsPagination.pageSize}&pageNumber=${organizationAssociationsPagination.pageNumber}`;
		const searchText = `?searchText=${organizationAssociationsPagination.searchText}`;
		const query = searchText + pagedSearch;
		const { data } = yield call(CustomersAPI.fetchOrganization, accountId, query);

		yield put(fetchOrganizationAssociationsComplete({ data }));
	} catch (e: any) {
		yield put(fetchOrganizationAssociationsFail(e.message));
	}
}

// Root Sagas
export function* watchCustomerSearch() {
	return [
		yield takeLatest(fetchCustomerSearchStart, fetchCustomerSearch),
		yield takeLatest(
			fetchOrganizationAssociationsStart,
			fetchOrganizationAssociationsSearch
		),
	];
}
