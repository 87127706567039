import React, { useCallback, useState } from "react";
import { NavMenuOption } from "../../HeaderNav.helpers";
import { Popover } from "@agilekit/ui";

// HELPERS
import { getMenuLink } from "../../../../utils/classic.helper";

import useStyles from "./MoreMenuList.styles";

interface MoreMenuListProps {
	menuOptions: NavMenuOption[];
}

const MoreMenuList: React.FC<MoreMenuListProps> = ({ menuOptions }) => {
	const [contextMenu, setContextMenu] = useState(false);
	const classes = useStyles();

	// EVENTS
	const toggleContextMenu = useCallback(() => {
		setContextMenu(!contextMenu);
	}, [contextMenu]);

	// RENDERS
	const customerActions = (
		<li className="list-menu-item" onClick={toggleContextMenu} role="presentation">
			<div>More</div>
		</li>
	);

	const menuListItem = () => {
		const menu = [] as JSX.Element[];
		menuOptions.forEach(({ title, type, Icon }) => {
			menu.push(
				<div className={classes.menuItem}>
					<div className={classes.startIcon}>
						<Icon fontSize="inherit" />
					</div>
					<div>
						<a
							href={getMenuLink(type)}
							target="_parent"
							style={{
								color: "black",
								display: "flex",
								fontSize: "0.875rem",
								alignItems: "center",
								lineHeight: "1.45",
								whiteSpace: "nowrap",
								borderColor: "transparent",
							}}
						>
							{title}
						</a>
					</div>
				</div>
			);
		});
		return menu;
	};

	return (
		<Popover
			renderReference={customerActions}
			isOpen={contextMenu}
			onOutsideClickClose={() => {
				setContextMenu(false);
			}}
			px={0}
			py={0}
		>
			<div>{menuListItem()}</div>
		</Popover>
	);
};

export default MoreMenuList;
