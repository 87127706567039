import Http from "../../../utils/restClient";

export default class SearchApi {
	// MPLANS
	public static GetOrderSearch(searchRequest) {
		return Http.axios().post(`/api/v1/search/order`, searchRequest);
	}

	public static FetchLastTenOrdersVisited() {
		return Http.axios().get(`/api/v1/search/last-ten-visited`);
	}

	public static FetchSupportCommunications(payload) {
		return Http.axios().get(`/api/v1/support/communications/load`, {
			params: payload.payload,
		});
	}

	public static AcknowledgeSupportCommunication(payload) {
		return Http.axios().post(
			`/api/v1/support/communications/acknowledge/${payload.communicationId}`
		);
	}
}
