import { IPaymentHistory, IRefundRequest } from "../order.typings";

export const paymentsState = {
	tip: {
		addStateLoading: {
			loading: false,
			success: false,
			started: false,
			hasError: false,
			errorMessage: "",
		},
	},
	payments: {
		fetchStateLoading: {
			loading: false,
			success: false,
			started: false,
			hasError: false,
			errorMessage: "",
		},
		responseStatus: false,
		paymentList: [] as IPaymentHistory[],
	},
	refund: {
		fetchStateLoading: {
			loading: false,
			success: false,
			started: false,
			hasError: false,
			errorMessage: "",
		},
	},
};

const paymentsReducer = () => {
	return {
		addTipStarted: (state, { payload }) => {
			return {
				...state,
				tip: {
					addStateLoading: {
						loading: false,
						success: false,
						started: true,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		addTipSuccess: (state, { payload }) => {
			return {
				...state,
				tip: {
					addStateLoading: {
						...state.tip.addStateLoading,
						success: true,
					},
				},
			};
		},
		addTipFailure: (state, { payload }) => {
			return {
				...state,
				tip: {
					addStateLoading: {
						...state.tip.addStateLoading,
						hasError: true,
						errorMessage: payload.error,
					},
				},
			};
		},
		fetchPaymentHistoryStarted: (state, { payload }) => {
			return {
				...state,
				payments: {
					...state.payments,
					fetchStateLoading: {
						loading: true,
						success: false,
						started: true,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchPaymentHistorySuccess: (state, { payload }) => {
			return {
				...state,
				payments: {
					...state.payments,
					fetchStateLoading: {
						...state.payments.fetchStateLoading,
						success: true,
						loading: false,
					},
					responseStatus: payload.data.ok,
					paymentList: payload.data.results,
				},
			};
		},
		fetchPaymentHistoryFailure: (state, { payload }) => {
			return {
				...state,
				payments: {
					...state.payments,
					fetchStateLoading: {
						...state.payments.fetchStateLoading,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		refundOrderItemsStart: (
			state,
			{
				payload,
			}: {
				payload: { orderId: number; refundRequest: IRefundRequest };
			}
		) => {
			return {
				...state,
				refund: {
					...state.refund,
					fetchStateLoading: {
						loading: true,
						success: false,
						started: true,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		refundOrderItemsCompleted: (state, { payload }) => {
			return {
				...state,
				refund: {
					...state.refund,
					fetchStateLoading: {
						...state.refund.fetchStateLoading,
						success: true,
						loading: false,
					},
					paymentList: payload.data.results,
				},
			};
		},
		refundOrderItemsFailed: (state, { payload }) => {
			return {
				...state,
				refund: {
					...state.refund,
					fetchStateLoading: {
						...state.refund.fetchStateLoading,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
	};
};

export default paymentsReducer;
