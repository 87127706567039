import jwt_decode from "jwt-decode";
import cookie from "cookie";

export interface ICookiesInfo {
	userName: string;
	userFirstLastName: string;
	userId: string;
	facilityId: string;
	facilityName: string;
	posTerminalExists: boolean;
	hostDomain: string;
	printer2Exists: boolean;
	posTerminalId: string;
}

export const cookiesInfoStates = {
	cookiesInfo: {
		userId: "",
		userName: "",
		userFirstLastName: "",
		facilityId: "",
		facilityName: "",
	} as ICookiesInfo,
};

const cookiesInfoReducer = () => {
	return {
		getCookiesInfo: (state) => {
			const parsedCookies = cookie.parse(document.cookie);
			const jwtInfo = jwt_decode<any>(parsedCookies["classic-bridge"]);

			return {
				...state,
				cookiesInfo: {
					...state.cookiesInfo,
					userName: jwtInfo["user.name"],
					userId: jwtInfo["user.id"],
					userFirstLastName: jwtInfo["user.firsLast"],
					facilityId: jwtInfo["facility.id"],
					facilityName: jwtInfo["facility.name"],
					posTerminalExists: parsedCookies.PosTerminalExists === "True",
					hostDomain: parsedCookies.HostDomain,
					printer2Exists: parsedCookies.Printer2Exists === "True",
					posTerminalId: parsedCookies.PosTerminalId ?? "",
				},
			};
		},
	};
};

export default cookiesInfoReducer;
