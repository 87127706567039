import React from "react";
import { Checkbox, Box } from "@agilekit/ui";
import DotsHorizontal from "mdi-material-ui/DotsHorizontal";

// ASSETS
import useStyles from "./ModifierItem.styles";

interface ModifierItemProps {
	title: string;
	price: string;
	selectionMode?: boolean;
}

const ModifierItem: React.FC<ModifierItemProps> = ({ title, price, selectionMode }) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<div className={classes.titleContainer}>
				{selectionMode && (
					<Box mr={2} display="flex">
						<Checkbox label="Select Item" labelHidden />
					</Box>
				)}
				<p className={classes.title}>{title}</p>
			</div>
			<Box display="flex" alignItems="center">
				<p>{price}</p>
				<button type="button">
					<DotsHorizontal />
				</button>
			</Box>
		</div>
	);
};

export default ModifierItem;
