import store from "../../configureStore";

export default function buildCustomersQueryString() {
	const { pageSize, pageNumber } = store.getState().customers.page;
	const { searchText, orderBy, orderDirection } = store.getState().customers;
	const {
		customerStatuses,
		waiverStatuses,
		partyDateStart,
		partyDateEnd,
		customerLabels,
		customerTypes,
		membershipPlans,
		membershipStatuses,
	} = store.getState().customers.filters;
	const sanitizedString = searchText.replace("+", "%2B");
	let queryString = `?action=customers&pageSize=${pageSize}&pageNumber=${pageNumber}&searchText=${sanitizedString}&filterByCustomerName=false&orderBy=${orderBy}&orderDirection=${orderDirection}&partyDateStart=${partyDateStart}&partyDateEnd=${partyDateEnd}`;

	if (customerStatuses) {
		customerStatuses.forEach((status) => {
			queryString += status === "" ? "" : `&customerStatus=${status}`;
		});
	}

	if (waiverStatuses) {
		waiverStatuses.forEach((status) => {
			queryString += status === "" ? "" : `&waiverStatus=${status}`;
		});
	}

	if (customerLabels) {
		customerLabels.forEach((label) => {
			queryString +=
				label === "" ? "" : `&customerLabels=${encodeURIComponent(label)}`;
		});
	}

	if (customerTypes) {
		customerTypes.forEach((type) => {
			queryString += type === "" ? "" : `&customerTypes=${type}`;
		});
	}

	if (membershipPlans) {
		membershipPlans.forEach((plan) => {
			queryString +=
				plan === "" ? "" : `&membershipPlans=${encodeURIComponent(plan)}`;
		});
	}

	if (membershipStatuses) {
		membershipStatuses.forEach((status) => {
			queryString +=
				status === "" ? "" : `&membershipStatuses=${encodeURIComponent(status)}`;
		});
	}

	return queryString;
}

export function buildFilterObject() {
	const { pageSize, pageNumber } = store.getState().customers.page;
	const { searchText, orderBy, orderDirection } = store.getState().customers;
	const {
		customerStatuses,
		waiverStatuses,
		partyDateStart,
		partyDateEnd,
		customerLabels,
		customerTypes,
		membershipPlans,
		membershipStatuses,
	} = store.getState().customers.filters;
	// const { expandedView } = store.getState().customers.layout;

	return {
		pageSize,
		pageNumber,
		searchText,
		orderBy,
		orderDirection,
		customerStatuses,
		waiverStatuses,
		partyDateStart,
		partyDateEnd,
		customerLabels,
		customerTypes,
		membershipPlans,
		membershipStatuses,
		// expandedView
	};
}

export function parseQueryStringToObject(searchParams: URLSearchParams) {
	return {
		searchText: searchParams.get("searchText"),
		partyDateStart: searchParams.get("partyDateStart"),
		partyDateEnd: searchParams.get("partyDateEnd"),
		waiverStatuses: searchParams.getAll("waiverStatus"),
		customerStatuses: searchParams.getAll("customerStatus"),
		customerTypes: searchParams.getAll("customerTypes"),
		customerLabels: searchParams.getAll("customerLabels"),
		membershipPlans: searchParams.getAll("membershipPlans"),
		membershipStatuses: searchParams.getAll("membershipStatuses"),
		pageSize: searchParams.get("pageSize"),
		pageNumber: searchParams.get("pageNumber"),
		orderBy: searchParams.get("orderBy"),
		orderDirection: searchParams.get("orderDirection"),
	};
}
