// utils/deviceCheck.js or wherever you keep your utility functions

/**
 * Checks if the current device is likely to be a desktop.
 * This is a simple heuristic and might need to be adjusted based on your application's needs.
 * @returns {boolean} true if the device is likely a desktop.
 */
export default function isDesktop() {
	return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);
}
