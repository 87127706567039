import { makeStyles, ThemeAgile } from "@agilekit/ui";
import { isDesktop } from "../../utils";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {
			position: "fixed",
			top: 0,
			zIndex: 10,
			backgroundColor: theme.palette.NEUTRAL800,
			width: "100vw",
			display: "flex",
			justifyContent: "space-between",
			height: 56,
			...(isDesktop()
				? {}
				: {
						[theme.breakpoints.down(1024)]: {
							zIndex: 999,
						},
				  }),
		},
		sectionLeft: {
			display: "flex",
		},
		sectionRight: {
			display: "flex",
			minWidth: 440,
			...(isDesktop()
				? {}
				: {
						[theme.breakpoints.down(1024)]: {
							minWidth: "unset",
						},
				  }),
			"& ul": {
				display: "flex",
				padding: 0,
				margin: 0,
				width: "100%",
				justifyContent: "space-between",
			},
			"& li": {
				color: theme.palette.NEUTRAL000,
				listStyleType: "none",
				marginLeft: theme.spacing(2),
				fontSize: theme.typography.pxToRem(12),
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				position: "relative",
			},
			"& p": {
				color: theme.palette.BLUE075,
				paddingLeft: theme.spacing(1),
				margin: theme.spacing(0),
			},
		},
		sectionRightItem: {
			display: "flex",
			color: theme.palette.NEUTRAL000,
		},
		sectionRightButton: {
			color: theme.palette.NEUTRAL000,
			backgroundColor: "transparent",
			border: "none",
			borderRadius: 4,
			padding: theme.spacing(1.5, 2),
			fontSize: theme.typography.pxToRem(22),
			cursor: "pointer",
			"&:hover": {
				backgroundColor: theme.palette.NEUTRAL600,
			},
		},
		logo: {
			fontSize: theme.typography.pxToRem(30),
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginLeft: theme.spacing(5),
			paddingRight: theme.spacing(5),
			position: "relative",
			"&::after": {
				content: "''",
				height: 24,
				width: 1,
				position: "absolute",
				right: 0,
				backgroundColor: theme.palette.NEUTRAL000,
			},
		},
		menu: {
			display: "flex",
			"& .menu-list": {
				display: "flex",
				padding: 0,
				margin: 0,
			},
			"& .list-menu-item": {
				color: theme.palette.NEUTRAL000,
				listStyleType: "none",
				marginLeft: theme.spacing(6),
				fontSize: theme.typography.pxToRem(14),
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				position: "relative",
				"&::after": {
					content: "''",
					height: 3,
					width: "100%",
					position: "absolute",
					bottom: 0,
				},
				"&.selected": {
					"&::after": {
						backgroundColor: theme.palette.NEUTRAL000,
					},
				},
				"& > div": {
					color: theme.palette.NEUTRAL000,
					backgroundColor: "transparent",
					border: "none",
					borderRadius: 4,
					padding: theme.spacing(1.5, 2),
					cursor: "pointer",
					"&:hover": {
						backgroundColor: theme.palette.NEUTRAL600,
					},
				},
			},
			"& .menu-list button": {
				color: theme.palette.NEUTRAL000,
				backgroundColor: "transparent",
				border: "none",
				borderRadius: 4,
				padding: theme.spacing(1.5, 2),
				cursor: "pointer",
				"&:hover": {
					backgroundColor: theme.palette.NEUTRAL600,
				},
			},
			"& a": {
				textDecoration: "none",
				textWrap: "nowrap",
				color: theme.palette.NEUTRAL000,
			},
		},
		searchContainer: {},
		search: {
			border: `1px solid ${theme.palette.NEUTRAL000}`,
			borderRadius: 4,
			paddingLeft: theme.spacing(2),
			"& input": {
				border: "none",
				backgroundColor: "transparent",
				color: theme.palette.NEUTRAL000,
				fontSize: theme.typography.pxToRem(14),
				paddingLeft: theme.spacing(0),
				"&:focus": {
					outline: "none",
				},
				"&::placeholder": {
					color: theme.palette.NEUTRAL000,
				},
			},
		},
		searchIcon: {
			fontSize: theme.typography.pxToRem(20),
			color: theme.palette.NEUTRAL000,
			marginRight: theme.spacing(3),
		},
		userActionsContainer: {
			minWidth: "80px",
			display: "flex",
			justifyContent: "space-between",
		},
		menuMobile: {
			color: theme.palette.NEUTRAL000,
			fontSize: theme.typography.pxToRem(24),
			paddingLeft: theme.spacing(4),
			display: "flex",
			alignItems: "center",
			paddingRight: theme.spacing(4),
		},
		mobileMenuModal: {
			position: "fixed",
			top: 55,
			left: 0,
			width: "100vw",
			height: "calc(100vh - 55px)",
			backgroundColor: theme.palette.NEUTRAL020,
			zIndex: 999,
			pointerEvents: "none",
			opacity: 0,

			"&.show": {
				opacity: 1,
				pointerEvents: "all",
			},

			"& > ul": {
				display: "flex",
				flexDirection: "column",
				gap: theme.spacing(4),
				padding: theme.spacing(6),
			},

			"& li": {
				listStyleType: "none",
				backgroundColor: theme.palette.NEUTRAL000,
				padding: theme.spacing(2, 4),
				borderRadius: 4,
				boxShadow: theme.shadows[1],

				"& a": {
					color: theme.palette.NEUTRAL600,
				},
			},
		},
	};
});

export default useStyles;
