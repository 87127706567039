import React from "react";
import AccountOff from "mdi-material-ui/AccountOff";

// ASSETS
import useStyles from "./NoCustomerFound.styles";

const OnlyAssociatedFound = () => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<div className={classes.icon}>
				<AccountOff fontSize="inherit" />
			</div>
			<div className={classes.content}>
				<p>The only results found are already associated.</p>
			</div>
		</div>
	);
};

export default OnlyAssociatedFound;
