import React, { RefAttributes } from "react";
import Close from "mdi-material-ui/Close";
import classNames from "classnames";
import { OutsideClickDetector } from "@agilekit/ui";

// ASSETS
import useStyles from "./CardModal.styles";

export type Size = "small" | "medium" | "large" | "xlarge" | "fullwidth";

const getModalSize = (size) => {
	let finalSize;

	switch (size) {
		case "fullwidth":
			finalSize = "100vw";
			break;
		case "small":
			finalSize = 400;
			break;
		case "medium":
			finalSize = 600;
			break;
		case "large":
			finalSize = 800;
			break;
		case "xlarge":
		default:
			finalSize = 968;
	}

	return finalSize;
};

interface ICardModalProps extends RefAttributes<HTMLDivElement> {
	/** Whether the modal is open or not */
	open: boolean;
	/** The content for the title of the modal */
	title?: string | React.ReactNode;
	/** Inner content of the footer */
	footer?: React.ReactNode;
	/** Controls the size of the modal */
	size?: Size;
	/** Callback when the modal is closed */
	type?: "modal" | "drawer";
	/** Controls the size of the modal */
	width?: number;
	onClose(): void;
	children: React.ReactNode;
}

const CardModal = React.forwardRef<HTMLDivElement, ICardModalProps>(
	({ title, children, footer, onClose, size, type = "modal", width }, ref) => {
		const classes = useStyles();

		// STYLES
		const cssPaper = classNames(classes.paper, {
			[classes.paperDrawer]: type === "drawer",
		});

		return (
			<OutsideClickDetector onOutsideClick={onClose}>
				<div
					id="cardModal"
					ref={ref}
					className={cssPaper}
					style={{ width: width ?? getModalSize(size) }}
				>
					<div className={classes.container}>
						{title && (
							<div className={classes.modalHeader}>
								<div className={classes.modalTitle}>{title}</div>
								<div>
									<Close
										onClick={onClose}
										className={classes.modalCloseIcon}
										fontSize="inherit"
									/>
								</div>
							</div>
						)}
						<div className={classes.modalBody}>{children}</div>
						{footer && <div className={classes.modalFooter}>{footer}</div>}
					</div>
				</div>
			</OutsideClickDetector>
		);
	}
);

CardModal.displayName = "CardModal";

export default CardModal;
