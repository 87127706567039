import React from "react";

import { getMenuLink } from "../../../../utils/classic.helper";
import { NavMenuOption } from "../../HeaderNav.helpers";

const NavMenuItem: React.FC<NavMenuOption> = ({ type, title }) => {
	return (
		<li className="list-menu-item">
			<div>
				<a href={getMenuLink(type)} target="_parent">
					{title}
				</a>
			</div>
		</li>
	);
};

export default NavMenuItem;
