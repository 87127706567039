import { AxiosPromise } from "axios";
import Http from "../../../utils/restClient";
import { IUpdateCustomer } from "../customer/customer.typings";

export default class ScheduleCustomerApi {
	public static updateCustomer(customer: IUpdateCustomer) {
		return Http.axios().put(`/api/v1/customers`, customer);
	}

	public static updateOrganization(organization: IUpdateCustomer) {
		return Http.axios().put(`/api/v1/organizations`, organization);
	}

	public static updateDependent(customer: any) {
		return Http.axios().put(`/api/v1/customers/dependent`, customer) as AxiosPromise;
	}
}
