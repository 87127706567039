import React from "react";
import { Avatar, useTheme, ThemeAgile } from "@agilekit/ui";
import { AccountOutline, OfficeBuilding } from "mdi-material-ui";

interface AvatarPlaceholderProps {
	type?: "user" | "organization";
	size: number;
	borderRadius?: number;
	boxShadow?: number;
}

const AvatarPlaceholder: React.FC<AvatarPlaceholderProps> = ({
	type,
	size,
	borderRadius,
	boxShadow,
}) => {
	// HOOKS
	const theme: ThemeAgile = useTheme();

	// RENDERS
	let finalIcon;
	switch (type) {
		case "organization":
			finalIcon = (
				<OfficeBuilding fontSize="inherit" htmlColor={theme.palette.NEUTRAL000} />
			);
			break;
		case "user":
		default:
			finalIcon = (
				<AccountOutline fontSize="inherit" htmlColor={theme.palette.NEUTRAL000} />
			);
	}

	return (
		<Avatar
			icon={finalIcon}
			size={size}
			borderRadius={borderRadius}
			boxShadow={boxShadow}
			bgColor={theme.palette.NEUTRAL080}
		/>
	);
};

export default AvatarPlaceholder;
