import { createSlice } from "@reduxjs/toolkit";
import {
	IDiscountItem,
	IOrder,
	IOrderInfo,
	IOrderInfoRounded,
	IOrderItem,
	IPaymentItem,
	IReceiptItem,
	IRoundedTaxes,
	ITaxItem,
	StateLoading,
} from "./order.typings";
import orderItemsReducer, { orderItemsState } from "./reducers/orderItems.reducer";
import orderCustomerReducer, {
	orderCustomerState,
} from "./reducers/orderCustomer.reducer";
import { ICustomer } from "../customer/customer.typings";
import paymentsReducer, { paymentsState } from "./reducers/payments.reducer";

type OrderContext = "POS" | "POS_V2" | "Schedule" | "Online" | "None";

const initialState = {
	orderContext: "None" as OrderContext,
	order: { id: 0, orderNumber: 0 } as IOrder,
	orderLoadingState: {
		addOrderIsLoading: false,
	},
	customer: { accountId: 0 } as ICustomer,
	orderInfo: {
		id: 0,
		items: [] as Array<IOrderItem>,
		balanceDue: 0,
		subTotal: 0,
		total: 0,
		tip: 0,
		totalWithTip: 0,
		itemsTotal: 0,
		taxesTotal: 0,
		totalPayments: 0,
		totalRefunds: 0,
		totalOrderDiscount: 0,
		totalItemDiscounts: 0,
		taxes: [] as Array<ITaxItem>,
		discounts: [] as Array<IDiscountItem>,
		payments: [] as Array<IPaymentItem>,
		roundedTotals: {
			subTotal: 0,
			total: 0,
			balanceDue: 0,
			totalWithTip: 0,
			notIncludedTaxes: [] as Array<IRoundedTaxes>,
		} as IOrderInfoRounded,
	} as IOrderInfo,
	orderItem: { id: 0 } as IOrderItem,
	orderItems: [] as Array<IReceiptItem>,
	message: "" as string,
	orderInfoRequested: false as boolean,
	orderInfoSuccess: false as boolean,
	addOrderSuccess: false as boolean,
	addOrderItemSuccess: false as boolean,
	updateOrderItemQuantitySuccess: false as boolean,
	removeItemSuccess: false as boolean,
	clearOrSucess: false as boolean,
	isLoadingClearOrder: false as boolean,
	isLoading: false as boolean,
	updateOrderSuccess: false as boolean,
	error: {} as any,
	hasError: false,
	visitOrderRequest: {
		loading: false,
		success: false,
		error: "",
	},
	applyOrderDiscount: {
		loading: false,
		success: false,
		started: false,
		hasError: false,
		errorMessage: "",
	} as StateLoading,
	...orderItemsState,
	...orderCustomerState,
	...paymentsState,
};

const orderItemsSlice = createSlice({
	name: "ORDER",
	initialState,
	reducers: {
		...orderItemsReducer(),
		...orderCustomerReducer(),
		...paymentsReducer(),
		setOrderContext: (state, { payload }) => {
			return {
				...state,
				orderContext: payload,
			};
		},

		// ORDER INFO
		orderInfofailure: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				error: payload,
				hasError: true,
				orderInfoSuccess: false,
				orderInfoRequested: true,
			};
		},
		orderInfoRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				orderInfoRequested: true,
				orderInfoSuccess: false,
				removeItemSuccess: false,
				addOrderToCustomerSuccess: false,
				updateOrderCustomerSuccess: false,
			};
		},
		orderInfoSuccess: (state, { payload }) => {
			const orderInfoItems = payload.data.items;
			let newOrderItemsArray = [...state.orderItems];

			const hasAppliedOrderItemDiscount = state.applyOrderItemDiscount.success;
			const hasUpdatedNotes = state.updateOrderItemNotes.success;
			const hasUpdatedOrderItemQuantity = state.updateOrderItemQuantitySuccess;
			const hasAddedItem = state.addOrderItemSuccess;
			const hasUpdatedOrderItemPrice = state.updateOrderItemPrice.success;
			const hasRefundedItems = state.refund.fetchStateLoading.success;
			const hasUpdatedOrder = state.updateOrderSuccess;
			if (
				newOrderItemsArray.length === 0 ||
				hasAppliedOrderItemDiscount ||
				hasUpdatedNotes ||
				hasUpdatedOrderItemQuantity ||
				hasAddedItem ||
				hasUpdatedOrderItemPrice ||
				hasRefundedItems ||
				hasUpdatedOrder
			) {
				newOrderItemsArray = [] as IReceiptItem[];

				orderInfoItems.forEach((orderItem) => {
					newOrderItemsArray.push({
						requested: false,
						added: true,
						error: "",
						item: orderItem as IOrderItem,
						itemIsLoading: false,
					} as IReceiptItem);
				});
			}

			return {
				...state,
				orderInfo: payload.data,
				customer: payload.data.customer,
				order: {
					...state.order,
					accountId: payload.data.customer.accountId,
					id: payload.data.id,
					orderNumber: payload.data.number,
					orderType: payload.data.orderType,
					orderTypeDescription: payload.data.orderTypeDescription,
					status: payload.data.status,
					statusDescription: payload.data.statusDescription,
				},
				orderItems: newOrderItemsArray,
				isLoading: false,
				orderInfoRequested: true,
				error: "",
				orderInfoSuccess: true,
				addOrderItemSuccess: false,
				updateOrderItemQuantitySuccess: false,
				updateOrderSuccess: false,
			};
		},
		// ADD ORDER
		addOrderRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				orderLoadingState: {
					...state.orderLoadingState,
					addOrderIsLoading: true,
				},
				message: "",
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
			};
		},
		addOrderSuccess: (state, { payload }) => {
			// const addedItemIndex = state.orderItems.findIndex(
			// 	(x) =>
			// 		x.item.productId === payload.data.results.orderItem.productId &&
			// 		x.requested
			// );
			// const newOrderItemsArray = [...state.orderItems];

			// if (addedItemIndex) {
			// 	newOrderItemsArray[addedItemIndex] = {
			// 		...newOrderItemsArray[addedItemIndex],
			// 		requested: false,
			// 		added: true,
			// 		item: payload.data.results.orderItem,
			// 		itemIsLoading: false,
			// 	};
			// }

			return {
				...state,
				order: payload.data.results.order,
				orderLoadingState: {
					...state.orderLoadingState,
					addOrderIsLoading: false,
				},
				orderItem: payload.data.results.orderItem,
				message: payload.data.message,
				isLoading: false,
				error: payload.data.errors,
				addOrderSuccess: true,
				addOrderItemSuccess: payload.data.ok,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
				// orderItems: newOrderItemsArray,
			};
		},
		addOrderFailure: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				orderLoadingState: {
					...state.orderLoadingState,
					addOrderIsLoading: false,
				},
				error: payload,
				message: "",
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
			};
		},
		// CLEAR ORDER
		clearOrderRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				isLoadingClearOrder: true,
				message: "",
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
			};
		},
		clearOrderSuccess: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				isLoadingClearOrder: false,
				error: "",
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: true,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
				orderItems: [] as Array<IReceiptItem>,
			};
		},
		clearOrderFailure: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				error: payload.error.response.data.errors,
				message: payload.error.response.data.message,
				addOrderSuccess: false,
				addOrderItemSuccess: false,
				removeItemSuccess: false,
				clearOrSucess: false,
				updateOrderItemQuantitySuccess: false,
				orderInfoRequested: false,
			};
		},
		// VISIT ORDER
		visitOrderRequest: (state, { payload }) => {
			return {
				...state,
				visitOrderRequest: {
					...state.visitOrderRequest,
					loading: true,
					success: false,
					error: "",
				},
			};
		},
		visitOrderFailure: (state, { payload }) => {
			return {
				...state,
				visitOrderRequest: {
					...state.visitOrderRequest,
					loading: false,
					success: false,
					error: payload,
				},
			};
		},
		visitOrderSuccess: (state, { payload }) => {
			return {
				...state,
				visitOrderRequest: {
					...state.visitOrderRequest,
					loading: false,
					success: true,
					error: "",
				},
			};
		},
		applyOrderDiscountStart: (state, { payload }) => {
			return {
				...state,
				applyOrderDiscount: {
					started: true,
					errorMessage: "",
					hasError: false,
					loading: false,
					success: false,
				},
			};
		},
		applyOrderDiscountCompleted: (state, { payload }) => {
			return {
				...state,
				applyOrderDiscount: {
					...state.applyOrderDiscount,
					started: false,
					success: true,
				},
			};
		},
		applyOrderDiscountFailed: (state, { payload }) => {
			return {
				...state,
				applyOrderDiscount: {
					...state.applyOrderDiscount,
					hasError: true,
					errorMessage: payload.error,
				},
			};
		},
		updateOrderRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				updateOrderSuccess: false,
			};
		},
		updateOrderSuccess: (state, { payload }) => {
			return {
				...state,
				updateOrderSuccess: true,
				isLoading: false,
				order: payload.data.results.order,
				error: "",
			};
		},
		updateOrderFail: (state, { payload }) => {
			return {
				...state,
				updateOrderSuccess: false,
				isLoading: false,
				error: payload,
			};
		},
	},
});

export const {
	setOrderContext,
	orderInfofailure,
	orderInfoRequest,
	orderInfoSuccess,
	addOrderRequest,
	addOrderSuccess,
	addOrderFailure,
	addOrderItemRequest,
	addOrderItemSuccess,
	addOrderItemFailure,
	removeOrderItemRequest,
	removeOrderItemSuccess,
	removeOrderItemFailure,
	removeItemWithError,
	clearOrderFailure,
	clearOrderRequest,
	clearOrderSuccess,
	updateOrderItemQuantityRequest,
	updateOrderItemQuantitySuccess,
	updateOrderItemQuantityFailure,
	updatePackageOrderItemQuantityRequest,
	updatePackageOrderItemQuantitySuccess,
	updatePackageOrderItemQuantityFailure,
	addOrderToCustomerRequest,
	addOrderToCustomerSuccess,
	addOrderToCustomerFailure,
	visitOrderRequest,
	visitOrderFailure,
	visitOrderSuccess,
	setCustomer,
	applyOrderItemDiscountStart,
	applyOrderItemDiscountCompleted,
	applyOrderItemDiscountFailed,
	applyOrderDiscountStart,
	applyOrderDiscountCompleted,
	applyOrderDiscountFailed,
	setCustomerOrderContext,
	updateOrderItemNotesStart,
	updateOrderItemNotesCompleted,
	updateOrderItemNotesFailed,
	unsetDiscountAndNotesState,
	updateOrderCustomerRequest,
	updateOrderCustomerSuccess,
	updateOrderCustomerFailure,
	updateOrderRequest,
	updateOrderSuccess,
	updateOrderFail,
	addTipStarted,
	addTipSuccess,
	addTipFailure,
	fetchPaymentHistoryStarted,
	fetchPaymentHistorySuccess,
	fetchPaymentHistoryFailure,
	updateOrderItemPriceStart,
	updateOrderItemPriceCompleted,
	updateOrderItemPriceFailed,
	refundOrderItemsStart,
	refundOrderItemsCompleted,
	refundOrderItemsFailed,
} = orderItemsSlice.actions;

export default orderItemsSlice.reducer;
