/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CurrencyUsd from "mdi-material-ui/CurrencyUsd";
import Menu from "mdi-material-ui/Menu";
import { useMediaQuery, useTheme } from "@agilekit/ui";

// COMPONENTS
import { MoreMenuList, NavMenuItem, SearchInput } from "./components";
import Help from "./components/Help/Help";
import UserActions from "./components/UserActions/UserActions";

// SELECTORS
import { getCookiesInfoState } from "../../store/modules/enviroment/enviroment.selector";

// ASSETS
import useStyles from "./HeaderNav.styles";
import LogoPCSWhite from "../../assets/images/logoPCSWhite";

// HELPERS
import { getNavMenuOptions, NavMenuOption } from "./HeaderNav.helpers";
import { userHaveRole } from "../../utils/roles";
import { isDesktop } from "../../utils";
import { getCookiesInfo } from "../../store/modules/enviroment/enviroment.slice";

const HeaderNav = () => {
	// ? HOOKS
	const classes = useStyles();
	const [screenSize, setScreenSize] = useState(window.innerWidth);
	const dispatch = useDispatch();
	const { posTerminalExists } = useSelector(getCookiesInfoState);
	const theme = useTheme();
	const screenDown1024 = useMediaQuery(theme.breakpoints.down(1024));

	// ? EFFECTS
	useEffect(() => {
		const handleResize = () => {
			setScreenSize(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (navigator.cookieEnabled) {
			dispatch(getCookiesInfo());
		}
	}, []);

	const openCashDrawer = () => {
		window.parent.postMessage(
			{
				action: "openCashDrawer",
			},
			"*"
		);
	};

	// ? EVENTS
	const handleMenuMobile = () => {
		const mobileMenuModal = document.querySelector("#mobileMenuModal");
		if (mobileMenuModal) {
			mobileMenuModal.classList.toggle("show");
		}
	};

	// ? RENDERS
	const buildNavMenu = (item: NavMenuOption) => {
		return <NavMenuItem key={item.title} type={item.type} title={item.title} />;
	};

	const buildMobileNavMenu = (item: NavMenuOption) => {
		return <NavMenuItem key={item.title} type={item.type} title={item.title} />;
	};

	const { barMenu, hiddenMenu } = getNavMenuOptions(screenSize);

	// const menuOptionsCallback = useCallback(() => {
	// 	return getNavMenuOptions(screenSize);
	// }, [store.getState().enviroment.facilitySettings]);

	// const { barMenu, hiddenMenu } = menuOptionsCallback();

	return (
		<nav className={classes.container}>
			<div className={classes.sectionLeft}>
				{isDesktop() && (
					<>
						<div className={classes.logo}>
							<LogoPCSWhite />
						</div>
						<div className={classes.menu}>
							<ul className="menu-list">
								{barMenu.map((item) => {
									return buildNavMenu(item);
								})}
							</ul>
							{hiddenMenu.length > 0 && (
								<MoreMenuList menuOptions={hiddenMenu} />
							)}
						</div>
					</>
				)}
				{screenDown1024 && !isDesktop() && (
					<div
						className={classes.menuMobile}
						onClick={handleMenuMobile}
						role="presentation"
					>
						<Menu fontSize="inherit" />
					</div>
				)}
			</div>
			<div className={classes.sectionRight}>
				<ul>
					<li>
						<SearchInput />
					</li>
					<li>
						{posTerminalExists &&
							userHaveRole(
								"POS Terminal: Open Cash Drawer Without Sale"
							) && (
								<button
									type="button"
									className={classes.sectionRightButton}
									onClick={openCashDrawer}
								>
									<CurrencyUsd />
								</button>
							)}
					</li>
					<li>
						<Help />
					</li>
					<li>
						<UserActions />
					</li>
				</ul>
			</div>
			<div id="mobileMenuModal" className={classes.mobileMenuModal}>
				<ul className="menu-list">
					{barMenu.map((item) => {
						return buildNavMenu(item);
					})}
				</ul>
			</div>
		</nav>
	);
};

export default HeaderNav;
