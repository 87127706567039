import { takeLatest, put, call } from "redux-saga/effects";

import {
	imageResourcesRequest,
	imageResourcesPosRequest,
	imageResourcesSuccess,
	imageResourcesFailure,
} from "./imageResources.slice";

import ImageResourcesAPI from "./imageResources.api";

export function* fetchImageResources() {
	try {
		const { data } = yield call(ImageResourcesAPI.fetchImageResources);

		yield put(imageResourcesSuccess({ data }));
	} catch (error: any) {
		yield put(imageResourcesFailure({ error }));
	}
}

export function* fetchImageResourcesPos() {
	try {
		const { data } = yield call(ImageResourcesAPI.fetchImageResourcesPos);

		yield put(imageResourcesSuccess({ data }));
	} catch (error: any) {
		yield put(imageResourcesFailure({ error }));
	}
}

export function* watchImageResources() {
	return [
		yield takeLatest(imageResourcesRequest, fetchImageResources),
		yield takeLatest(imageResourcesPosRequest, fetchImageResourcesPos),
	];
}
