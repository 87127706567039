import { History, Location } from "history";

export enum ErrorType {
	unauthorised,
	pageNotFound,
	warning,
	renderError,
}

export interface IError {
	history?: History;
	location?: Location;
	type: ErrorType;
}
