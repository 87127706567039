const emailTemplateReducer = () => {
	return {
		setEmailTemplateModalOpen: (state, { payload }) => {
			const layout = { ...state.layout };
			const finalLayout = {
				...layout,
				isEmailTemplateModalOpen: true,
				selectedTemplateId: payload.selectedTemplateId,
				selectedTemplateType: payload.selectedTemplateType,
			};

			return { ...state, layout: { ...finalLayout } };
		},
		setEmailTemplateModalClose: (state) => {
			const layout = { ...state.layout };
			const finalLayout = {
				...layout,
				isEmailTemplateModalOpen: false,
				selectedTemplateId: 0,
				selectedTemplateType: "",
			};

			return { ...state, layout: { ...finalLayout } };
		},
		setSelectedEmailTheme: (state, { payload }) => {
			return {
				...state,
				selectedEmailTheme: payload,
			};
		},
	};
};

export default emailTemplateReducer;
