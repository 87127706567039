import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		content: {
			display: "flex",
			alignItems: "center",
			textAlign: "left",
			width: "95%",
		},
		title: {
			fontSize: theme.typography.pxToRem(14),
			fontWeight: 500,
			lineHeight: theme.typography.pxToRem(24),
		},
		disabledMessage: {
			fontSize: theme.typography.pxToRem(13),
			fontWeight: 700,
			lineHeight: theme.typography.pxToRem(24),
			color: theme.palette.RED400,
			paddingLeft: "15px",
		},
		description: {
			fontSize: theme.typography.pxToRem(12),
			fontWeight: 400,
			lineHeight: theme.typography.pxToRem(14),
		},
		container: {
			fontSize: theme.typography.pxToRem(14),
			fontWeight: 500,
			lineHeight: theme.typography.pxToRem(24),
			display: "flex",
			alignItems: "center",
			textAlign: "left",
			borderRadius: "5px",
			boxSizing: "border-box",
			margin: "8px",
			padding: "10px",
			border: `1px solid ${theme.palette.NEUTRAL000}`,
		},
		containerEnabled: {
			color: theme.palette.NEUTRAL700,
			"&:hover": {
				[theme.breakpoints.up("sm")]: {
					border: `1px solid ${theme.palette.BLUE400}`,
					boxShadow:
						"0 2px 14px 0 rgba(60,66,87,0.1), 0 1px 6px 0 rgba(0,0,0,0.07)",
					cursor: "pointer",
				},
			},
			"&$containerHover": {
				border: `1px solid ${theme.palette.BLUE400}`,
				boxShadow:
					"0 2px 14px 0 rgba(60,66,87,0.1), 0 1px 6px 0 rgba(0,0,0,0.07)",
				cursor: "pointer",
			},
			"&$containerHover:hover": {
				border: `1px solid ${theme.palette.RED400}`,
			},
			"&:hover $selectIcon svg": {
				[theme.breakpoints.up("sm")]: {
					display: "block",
				},
			},
			"&$containerHover $selectIcon svg": {
				display: "block",
			},
		},
		containerHover: {
			cursor: "pointer",
		},
		disabled: {
			color: theme.palette.NEUTRAL200,
			cursor: "default !important",
		},
		selectIcon: {
			boxSizing: "border-box",
			height: "20px",
			width: "20px",
			borderRadius: "50%",
			border: "1px solid #505F79",
			cursor: "pointer",
			display: "flex",
			alignItems: "center",
			"& svg": {
				display: "none",
			},
		},
	};
});

export default useStyles;
