/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// ACTIONS
import {
	acknowledgeHelpStart,
	closeAcknowledgeModal,
} from "../../../../../store/modules/headerNav/headerNav.slice";

// SELECTORS
import {
	getAcknowledgeState,
	getHelpState,
} from "../../../../../store/modules/headerNav/headerNav.selector";

// ASSETS
import { Box, Button, Modal, Row } from "@agilekit/ui";
import { renderHtml } from "../../../../../containers/core/CustomerWaiver/CustomerWaiver.helpers";

// TYPINGS
import { SupportCommunication } from "../../../../../store/modules/headerNav/headerNav.typings";

interface AcknowledgeModalProps {
	communication: SupportCommunication;
	open: boolean;
}

const AcknowledgeModal: React.FC<AcknowledgeModalProps> = ({ communication, open }) => {
	const dispatch = useDispatch();
	const { loading } = useSelector(getAcknowledgeState);
	const { communicationsNotYetAcknowledged } = useSelector(getHelpState);
	const [redirect, setRedirect] = useState(false);

	const needAcknowledgment = useMemo(
		() => communicationsNotYetAcknowledged.some((x) => x.id === communication.id),
		[communicationsNotYetAcknowledged]
	);

	useEffect(() => {
		if (redirect && !loading) {
			window.parent.postMessage({ action: "openTab", url: communication.url }, "*");
			closeModal();
		}
	}, [redirect, loading]);

	useEffect(() => {
		setRedirect(false);
		if (
			open &&
			communication.supportCommunicationType.communicationType !== "Urgent" &&
			needAcknowledgment
		) {
			dispatch(acknowledgeHelpStart({ communicationId: communication.id }));
		}
	}, [communication]);

	const acknowledgeEvent = () => {
		if (
			communication.supportCommunicationType.communicationType === "Urgent" &&
			needAcknowledgment
		) {
			dispatch(acknowledgeHelpStart({ communicationId: communication.id }));
		}
		setRedirect(true);
	};

	const closeModal = () => {
		dispatch(closeAcknowledgeModal());
	};

	return (
		<div>
			<Modal
				open={open}
				title={communication.title}
				onClose={closeModal}
				disableBackdropClick
				size="medium"
				footer={
					<Row justify="end" type="flex">
						<Box pr={1}>
							<Button subtle onClick={closeModal}>
								Cancel
							</Button>
						</Box>
						<Box pl={1}>
							<Button
								primary
								onClick={acknowledgeEvent}
								disabled={loading}
								loading={loading}
							>
								{communication.confirmButtonText ?? "Learn More"}
							</Button>
						</Box>
					</Row>
				}
			>
				<Box p={6}>
					<div
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={renderHtml(communication.description)}
					/>
				</Box>
			</Modal>
		</div>
	);
};

export default AcknowledgeModal;
