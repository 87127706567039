const calendarReducer = () => {
	return {
		updateCalendarEventsStart: (state) => {
			return {
				...state,
				calendarUpdateEvents: {
					...state.calendarUpdateEvents,
					loading: true,
				},
			};
		},
		updateCalendarEventsSuccess: (state) => {
			return {
				...state,
				calendarUpdateEvents: {
					...state.calendarUpdateEvents,
					loading: false,
					success: true,
				},
			};
		},
		updateCalendarEventsFailure: (state, { payload }) => {
			return {
				...state,
				calendarUpdateEvents: {
					...state.calendarUpdateEvents,
					loading: false,
					success: false,
					error: payload.message,
				},
			};
		},
	};
};

export default calendarReducer;
