import { takeLatest, call, put } from "redux-saga/effects";
import {
	fetchUserRolesFailure,
	fetchUserRolesStart,
	fetchUserRolesSuccess,
	fetchUserGroupsStart,
	fetchUserGroupsSuccess,
	fetchUserGroupsFailure,
	fetchUserDiscountsStart,
	fetchUserDiscountsCompleted,
	fetchUserDiscountsFailure,
} from "./user.slice";
import UserApi from "./user.api";

export function* getfetchUserRoles({ payload }): any {
	try {
		const response = yield call(UserApi.GetUserRoles);
		yield put(fetchUserRolesSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(fetchUserRolesFailure({ error }));
	}
}

export function* getUserGroups({ payload }): any {
	try {
		const response = yield call(UserApi.GetUserGroups);
		yield put(fetchUserGroupsSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(fetchUserGroupsFailure({ error }));
	}
}

export function* getUserDiscounts({ payload }): any {
	try {
		const response = yield call(UserApi.GetUserDiscounts);
		yield put(fetchUserDiscountsCompleted({ data: response.data }));
	} catch (error: any) {
		yield put(fetchUserDiscountsFailure({ error }));
	}
}

export function* watchUser() {
	return [
		yield takeLatest(fetchUserRolesStart, getfetchUserRoles),
		yield takeLatest(fetchUserGroupsStart, getUserGroups),
		yield takeLatest(fetchUserDiscountsStart, getUserDiscounts),
	];
}
