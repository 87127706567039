import { takeLatest, put } from "redux-saga/effects";

import { authRequest, authFailure, authSuccess } from "./auth.slice";

// import { LoginAPI } from './auth.api';

export function* authLoginUser(): any {
	try {
		// const response = yield call(LoginAPI.login, 'test', 'test');
		// const user = yield response.json();
		yield put(authSuccess({ email: "user@gmail.com " }));
	} catch (error: any) {
		yield put(authFailure({ error: "Could not login" }));
	}
}

export function* watchAuth() {
	return [yield takeLatest(authRequest, authLoginUser)];
}
