import { createSlice } from "@reduxjs/toolkit";

export interface IWaiver {
	waiverId: number;
	name: string;
	waiverText: IWaverText;
	questionSet: {
		questions: IQuestionSet[];
	};
}

export interface IWaverText {
	text: string;
	textDecoded: string;
}

export interface IQuestionSet {
	questionId: number;
	questionOrder: number;
	questionText: string;
	questionTextDecoded: string;
	questionType: QuestionType;
	answerSize: number;
	answerText: string[];
}

export enum QuestionType {
	InputText = 1,
	RadioButton = 2,
	Checkbox = 3,
	Select = 4,
}

const initialWaiver = {
	waiver: {} as IWaiver,
	isLoading: false as boolean,
	error: "" as string,
	isLoadingActive: false,
};

const waiverSlice = createSlice({
	name: "WAIVER",
	initialState: initialWaiver,
	reducers: {
		fetchWaiverStart: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
			};
		},
		fetchWaiverComplete: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				waiver: payload,
			};
		},
		fetchWaiverFail: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				error: payload,
			};
		},
		fetchActiveWaiverStart: (state) => {
			return {
				...state,
				isLoadingActive: true,
			};
		},
		fetchActiveWaiverComplete: (state, { payload }) => {
			return {
				...state,
				isLoadingActive: false,
				waiver: payload,
			};
		},
		fetchActiveWaiverFail: (state, { payload }) => {
			return {
				...state,
				isLoadingActive: false,
				error: payload,
			};
		},
		resetWaiver: (state, { payload }) => {
			return {
				...state,
				waiver: {} as IWaiver,
			};
		},
	},
});

export const {
	fetchWaiverStart,
	fetchWaiverComplete,
	fetchWaiverFail,
	resetWaiver,
	fetchActiveWaiverStart,
	fetchActiveWaiverComplete,
	fetchActiveWaiverFail,
} = waiverSlice.actions;

export default waiverSlice.reducer;
