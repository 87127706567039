import { createSlice } from "@reduxjs/toolkit";
import cookie from "cookie";

const parsedCookies = cookie.parse(document.cookie);

const authSlice = createSlice({
	name: "AUTH",
	initialState: {
		authToken: parsedCookies["classic-bridge"] || "",
		user: {},
		// isLogged: parsedCookies["classic-bridge"] ? true : false,
		isLogged: true,
		isLogging: false,
		error: "",
	},
	reducers: {
		authRequest: (state) => {
			return {
				...state,
				isLogging: true,
			};
		},
		authSuccess: (state, { payload }) => {
			return {
				...state,
				isLogging: false,
				isLogged: true,
				error: "",
				user: payload,
			};
		},
		authFailure: (state, { payload }) => {
			return {
				...state,
				isLogging: false,
				error: payload,
			};
		},
		authLogout: (state) => {
			return {
				...state,
				isLogging: false,
				isLogged: false,
			};
		},
	},
});

export const { authRequest, authSuccess, authFailure, authLogout } = authSlice.actions;

export default authSlice.reducer;
