import { ICustomerWaiver } from "../customer.typings";

const waiverReducer = () => {
	return {
		fetchCustomerWaiverStart: (state, { payload }) => {
			return {
				...state,
				isCustomerWaiverLoading: true,
			};
		},
		fetchCustomerWaiverComplete: (state, { payload }) => {
			return {
				...state,
				isCustomerWaiverLoading: false,
				customerWaiver: payload,
			};
		},
		fetchCustomerWaiverFail: (state, { payload }) => {
			return {
				...state,
				isCustomerWaiverLoading: false,
				error: payload,
			};
		},
		confirmCustomerWaiverStart: (state, { payload }) => {
			return {
				...state,
				confirmCustomerWaiverSuccess: false,
			};
		},
		confirmCustomerWaiverSuccess: (state, { payload }) => {
			const updatedCustomerWaiver = [payload] as ICustomerWaiver[];
			return {
				...state,
				confirmCustomerWaiverSuccess: true,
				customerWaiver: payload,
				waiversTab: {
					...state.waiversTab,
					waivers: state.waiversTab.waivers?.map(
						(customerWaiver: ICustomerWaiver) =>
							updatedCustomerWaiver.find(
								(cw) =>
									cw.customerWaiverId ===
									customerWaiver.customerWaiverId
							) || customerWaiver
					),
				},
			};
		},
		confirmCustomerWaiverFail: (state, { payload }) => {
			return {
				...state,
				confirmCustomerWaiverSuccess: false,
				error: payload,
			};
		},
		expireCustomerWaiverStart: (state, { payload }) => {
			return {
				...state,
				confirmCustomerWaiverSuccess: false,
			};
		},
		expireCustomerWaiverSuccess: (state, { payload }) => {
			const updatedCustomerWaiver = [payload] as ICustomerWaiver[];
			return {
				...state,
				expireCustomerWaiverSuccess: true,
				waiversTab: {
					...state.waiversTab,
					waivers: state.waiversTab.waivers?.map(
						(customerWaiver: ICustomerWaiver) =>
							updatedCustomerWaiver.find(
								(cw) =>
									cw.customerWaiverId ===
									customerWaiver.customerWaiverId
							) || customerWaiver
					),
				},
				customerWaiver: payload,
			};
		},
		expireCustomerWaiverFail: (state, { payload }) => {
			return {
				...state,
				expireCustomerWaiverSuccess: false,
				error: payload,
			};
		},
		signCustomerWaiverStart: (state, { payload }) => {
			return {
				...state,
				signWaiverCustomerSuccess: false,
			};
		},
		signWaiverCustomerSuccess: (state, { payload }) => {
			return {
				...state,
				signWaiverCustomerSuccess: true,
				customerWaiver: payload.customerWaiver,
				customer: payload.customer,
			};
		},
		signWaiverCustomerFailure: (state, { payload }) => {
			return {
				...state,
				signWaiverCustomerSuccess: false,
			};
		},
		setCustomerWaiverBasic: (state, { payload }) => {
			return {
				...state,
				customerWaiver: payload,
			};
		},
		resetWaiverFlags: (state, { payload }) => {
			return {
				...state,
				confirmCustomerWaiverSuccess: false,
				expireCustomerWaiverSuccess: false,
			};
		},
	};
};

export default waiverReducer;
