import { makeStyles, ThemeAgile } from "@agilekit/ui";
import { isDesktop } from "../../../../utils";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		searchContainer: {
			minWidth: 220,
			height: 32,
			position: "relative",
			...(isDesktop()
				? {}
				: {
						[theme.breakpoints.down(1024)]: {
							minWidth: 500,
						},
						[theme.breakpoints.down("xs")]: {
							minWidth: 155,
						},
				  }),
		},
		search: {
			cursor: "pointer",
			pointerEvent: "all",
		},
		searchText: {
			fontSize: theme.typography.pxToRem(14),
		},
		searchInput: {
			border: `1px solid ${theme.palette.NEUTRAL000}`,
			borderRadius: 4,
			paddingLeft: theme.spacing(2),
			"&:hover": {
				backgroundColor: theme.palette.NEUTRAL500,
			},
		},
		searchIcon: {
			fontSize: theme.typography.pxToRem(20),
			color: theme.palette.NEUTRAL000,
			marginRight: theme.spacing(3),
		},
	};
});

export default useStyles;
