import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {},
		header: {
			cursor: "pointer",
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			marginBottom: theme.spacing(6),
			"& p": {
				fontSize: theme.typography.pxToRem(16),
				fontWeight: 500,
				color: theme.palette.NEUTRAL700,
				margin: 0,
			},
			"& button": {
				backgroundColor: "transparent",
				border: "none",
				padding: 0,

				"&:focus": {
					outline: "none",
				},
			},
		},
		content: {},
	};
});

export default useStyles;
