import { IPaymentCompletedResponse } from "../payments.typings";

export const creditState = {
	creditPay: {
		applyStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			started: false,
			success: false,
		},
		paymentResponse: {} as IPaymentCompletedResponse,
	},
};

const creditPayReducer = () => {
	return {
		postCreditPayStart: (state, { payload }) => {
			return {
				...state,
				creditPay: {
					...state.creditPay,
					applyStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		postCreditPayComplete: (state, { payload }) => {
			return {
				...state,
				creditPay: {
					...state.creditPay,
					applyStateLoading: {
						...state.creditPay.applyStateLoading,
						started: true,
						loading: false,
						success: true,
					},
					paymentResponse: payload.data.results,
				},
			};
		},
		postCreditPayFail: (state, { payload }) => {
			return {
				...state,
				creditPay: {
					...state.creditPay,
					applyStateLoading: {
						...state.creditPay.applyStateLoading,
						started: true,
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		resetCreditPaymentState: (state) => {
			return {
				...state,
				creditPay: {
					applyStateLoading: {
						started: false,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
					paymentResponse: {} as IPaymentCompletedResponse,
				},
			};
		},
	};
};
export default creditPayReducer;
