import Http from "../../../utils/restClient";

export default class MembershipPlanApi {
	// MPLANS
	public static GetMembershipPlans(pageSize = 50) {
		return Http.axios().get(`/api/v1/plans/?PageSize=${pageSize}`);
	}

	public static AddMembershipPlan({ plan }) {
		return Http.axios().post("/api/v1/plan/", plan);
	}

	public static DeleteMembershipPlan({ planId }) {
		return Http.axios().delete(`/api/v1/plan/${planId}`);
	}

	public static UpdateMembershipPlan({ plan }) {
		return Http.axios().put(`/api/v1/plan/${plan.planId}`, plan);
	}

	public static fetchAllMembershipPlans() {
		return Http.axios().get(`/api/v1/plans/all`);
	}

	public static getMembershipPlan(planId) {
		return Http.axios().get(`/api/v1/plan/${planId}`);
	}
}
