import { IOrderItem } from "../../order/order.typings";
import {
	IInterCardDetails,
	IInterCardResults,
	ILoadInterCardParams,
	StateLoading,
} from "../pointOfSale.typings";

export const interCardState = {
	interCardDetails: {
		interCardResult: [] as Array<IInterCardResults>,
		selectedOrderItem: {} as IOrderItem,
		fetchStateLoading: {
			started: false,
			loading: false,
			success: false,
			hasError: false,
			errorMessage: "",
		} as StateLoading,
	} as IInterCardDetails,
	loadInterCard: {
		loadParams: [] as ILoadInterCardParams[],
		selectedOrderItemId: 0,
		loadStateLoading: {
			started: false,
			loading: false,
			success: false,
			hasError: false,
			errorMessage: "",
		} as StateLoading,
	},
};

const interCardReducer = () => {
	return {
		setSelectedInterCardOrderItem: (state, { payload }: { payload: IOrderItem }) => {
			state.interCardDetails.selectedOrderItem = payload;
		},
		unsetSelectedInterCardOrderItem: (state) => {
			state.interCardDetails.selectedOrderItem = null;
		},
		fetchInterCardStart: (state, { payload }) => {
			return {
				...state,
				interCardDetails: {
					...state.interCardDetails,
					fetchStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchInterCardComplete: (state, { payload }) => {
			return {
				...state,
				interCardDetails: {
					...payload.data.results,
					...state.interCardDetails,
					fetchStateLoading: {
						...state.interCardDetails.fetchStateLoading,
						loading: false,
						success: true,
					},
					interCardResult: [
						...state.interCardDetails.interCardResult,
						payload.data.results,
					],
				},
			};
		},
		fetchInterCardFail: (state, { payload }) => {
			return {
				...state,
				interCardDetails: {
					...state.interCardDetails,
					fetchStateLoading: {
						...state.interCardDetails.fetchStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload.error.response.data,
					},
				},
			};
		},
		loadInterCardStart: (state, { payload }) => {
			return {
				...state,
				loadInterCard: {
					...state.loadInterCard,
					selectedOrderItemId: payload.orderItemId,
					loadStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		loadInterCardSuccess: (state, { payload }) => {
			return {
				...state,
				loadInterCard: {
					...state.loadInterCard,
					loadStateLoading: {
						...state.loadInterCard.loadStateLoading,
						success: payload.data.ok,
					},
					selectedOrderItemId: 0,
					loadParams: [...state.loadInterCard.loadParams, payload.data],
				},
			};
		},
		loadInterCardFail: (state, { payload }) => {
			return {
				...state,
				loadInterCard: {
					...state.loadInterCard,
					loadStateLoading: {
						...state.loadInterCard.loadStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
	};
};

export default interCardReducer;
