import store from "../store/configureStore";
import { IOrganization } from "../store/modules/customer/customer.typings";
import { MembershipPlan } from "../store/modules/memberships/memberships.typings";
import { OrderType } from "./orderType";

export enum Feature {
	CUSTOMER = "content.asp?action=customers",
	ORDER = "order_search.asp",
	SCHEDULE = "schedule.asp",
	POS = "pos_v2.asp?action=pointofsale",
	ADMIN = "admin.asp",
	MERCHANTTRACK = "merchantTrack_sso.asp",
	TIMECLOCK = "clock.asp",
	REPORTS = "reports.asp",
	RECEIPT = "pos_v2.asp?action=receipt",
	MYSCHEDULE = "schedule_hosts.asp",
	LOGOUT = "logout.html",
	USERS = "users.asp",
}

export type MenuOptions =
	| "POS"
	| "Schedule"
	| "Orders"
	| "Reports"
	| "Admin"
	| "Customers"
	| "TimeClock"
	| "MerchantTrack"
	| "Logout"
	| "UserAccount"
	| "Receipt"
	| "MySchedule";

export const getManagementOrderUrl = (
	orderType: OrderType,
	orderId: string,
	isPosV2 = false
): string => {
	let facilityUrl = "";
	if (navigator.cookieEnabled) {
		facilityUrl = `${store.getState().enviroment.cookiesInfo.hostDomain}`;
	} else {
		facilityUrl = `https://${
			store.getState().enviroment.facilitySettings.facilityManagementDomain
		}/`;
	}

	if (!facilityUrl || facilityUrl === "undefined") {
		const facilityId = store.getState().enviroment.cookiesInfo.facilityId;
		const facilityAlias = store
			.getState()
			.enviroment.facilityAlias.results.find(
				(item) => item.facilityId === facilityId
			);

		let hostedDomain;
		if (facilityAlias) {
			hostedDomain = facilityAlias.hostedDomain;
		} else {
			hostedDomain =
				store.getState().enviroment.facilitySettings.facilityManagementDomain;
		}

		facilityUrl = `https://${hostedDomain}/`;
	}

	switch (orderType) {
		case OrderType.IN_HOUSE_PART_ORDER:
		case OrderType.ONLINE_BOOKING:
		case OrderType.ONLINE_STORE: {
			return `${facilityUrl}order_info.asp?order_id=${orderId}`;
		}
		case OrderType.PONIT_OF_SALE:
			return isPosV2
				? `${facilityUrl}pos_v2.asp?action=pointofsale/sale/${orderId}`
				: `${facilityUrl}point_of_sale.asp?order_id=${orderId}`;
		default:
			return "";
	}
};

export const getScheduleUrl = (dateFiltered: Date): string => {
	let facilityUrl = "";
	if (navigator.cookieEnabled) {
		facilityUrl = `${store.getState().enviroment.cookiesInfo.hostDomain}`;
	} else {
		facilityUrl = `https://${
			store.getState().enviroment.facilitySettings.facilityManagementDomain
		}/`;
	}

	if (dateFiltered !== undefined) {
		const convert = new Date(dateFiltered);

		const filter = `${Feature.SCHEDULE}?range=${
			convert.getMonth() + 1
		}|${convert.getDate()}|${convert.getFullYear()}&date_display_1=${
			convert.getMonth() + 1
		}|${convert.getDate()}|${convert.getFullYear()}&RangeType=day&perm_del=&filter=&isOneTime=`;

		return facilityUrl + filter;
	}

	return facilityUrl;
};

export const getCustomerProfileLink = (customerId: string): string => {
	let facilityUrl = "";
	if (navigator.cookieEnabled) {
		facilityUrl = `${
			store.getState().enviroment.cookiesInfo.hostDomain
		}content.asp?action=customer&id=${customerId}`;
	} else {
		facilityUrl = `https://${
			store.getState().enviroment.facilitySettings.facilityManagementDomain
		}/content.asp?action=customer&id=${customerId}`;
	}

	return facilityUrl;
};

export const getMembershipLink = (plan: MembershipPlan, landing = false): string => {
	let facilityUrl = "";
	if (navigator.cookieEnabled) {
		facilityUrl = `${store.getState().enviroment.cookiesInfo.hostDomain}`;
	} else {
		facilityUrl = `https://${
			store.getState().enviroment.facilitySettings.facilityManagementDomain
		}/`;
	}

	if (plan.planId !== undefined && !landing) {
		return `${facilityUrl}content.asp?action=admin/membership&id=${plan.planId}`;
	}
	return `${facilityUrl}content.asp?action=admin/membership`;
};

export const getOrganizationProfileLink = (organization: IOrganization): string => {
	let facilityUrl = "";
	if (navigator.cookieEnabled) {
		facilityUrl = `${
			store.getState().enviroment.cookiesInfo.hostDomain
		}content.asp?action=organization&id=${organization.accountId}`;
	} else {
		facilityUrl = `https://${
			store.getState().enviroment.facilitySettings.facilityManagementDomain
		}/content.asp?action=organization&id=${organization.accountId}`;
	}

	return facilityUrl;
};

export const getCustomerSearchLink = (queryString: string): string => {
	let facilityUrl = "";
	if (navigator.cookieEnabled) {
		facilityUrl = `${
			store.getState().enviroment.cookiesInfo.hostDomain
		}content.asp${queryString}`;
	} else {
		facilityUrl = `https://${
			store.getState().enviroment.facilitySettings.facilityManagementDomain
		}/content.asp${queryString}`;
	}

	return facilityUrl;
};

export const getEmailCustomerLink = (accountId, isOrganization): string => {
	// 4 -> return to customer
	// 5 -> return to organization
	const returnTo = isOrganization ? 5 : 4;

	let facilityUrl = "";
	if (navigator.cookieEnabled) {
		facilityUrl = `${
			store.getState().enviroment.cookiesInfo.hostDomain
		}email_customer.asp?acct_id=${accountId}&return=${returnTo}`;
	} else {
		facilityUrl = `https://${
			store.getState().enviroment.facilitySettings.facilityManagementDomain
		}/email_customer.asp?acct_id=${accountId}&return=${returnTo}`;
	}

	return facilityUrl;
};

export const getOrderLink = (orderId, isPOS): string => {
	const orderPage = isPOS ? "point_of_sale" : "order_info";

	let facilityUrl = "";
	if (navigator.cookieEnabled) {
		facilityUrl = `${
			store.getState().enviroment.cookiesInfo.hostDomain
		}${orderPage}.asp?order_id=${orderId}`;
	} else {
		facilityUrl = `https://${
			store.getState().enviroment.facilitySettings.facilityManagementDomain
		}/${orderPage}.asp?order_id=${orderId}`;
	}

	return facilityUrl;
};

export const getOrderSearchLink = (): string => {
	let facilityUrl = "";
	if (navigator.cookieEnabled) {
		facilityUrl = `${store.getState().enviroment.cookiesInfo.hostDomain}${
			Feature.ORDER
		}`;
	} else {
		facilityUrl = `https://${
			store.getState().enviroment.facilitySettings.facilityManagementDomain
		}/${Feature.ORDER}`;
	}

	return facilityUrl;
};

export const getMenuLink = (type: MenuOptions, id?: string): string => {
	let facilityUrl = "";

	if (navigator.cookieEnabled) {
		facilityUrl = store.getState().enviroment.cookiesInfo.hostDomain;
	} else {
		const facilityId = store.getState().enviroment.cookiesInfo.facilityId;
		const facilityAlias = store
			.getState()
			.enviroment.facilityAlias.results.find(
				(item) => item.facilityId === facilityId
			);

		let hostedDomain;
		if (facilityAlias) {
			hostedDomain = facilityAlias.hostedDomain;
		} else {
			hostedDomain =
				store.getState().enviroment.facilitySettings.facilityManagementDomain;
		}

		facilityUrl = `https://${hostedDomain}/`;
	}

	switch (type) {
		case "Receipt":
			return facilityUrl + Feature.RECEIPT;
		case "POS":
			return facilityUrl + Feature.POS + "/sale";
		case "Schedule":
			return facilityUrl + Feature.SCHEDULE;
		case "Orders":
			return facilityUrl + Feature.ORDER;
		case "Reports":
			return facilityUrl + Feature.REPORTS;
		case "Admin":
			return facilityUrl + Feature.ADMIN;
		case "Customers":
			return facilityUrl + Feature.CUSTOMER;
		case "TimeClock":
			return facilityUrl + Feature.TIMECLOCK;
		case "MerchantTrack":
			return facilityUrl + Feature.MERCHANTTRACK;
		case "Logout":
			return facilityUrl + Feature.LOGOUT;
		case "UserAccount":
			return facilityUrl + Feature.USERS + `?action=edit&id=${id}`;
		case "MySchedule":
			return facilityUrl + Feature.MYSCHEDULE;
	}
};

export const getCreatedPOSOrderLink = (orderId: number): string => {
	return `https://${
		store.getState().enviroment.facilitySettings.facilityManagementDomain
	}/pos_v2.asp?action=pointofsale/sale/${orderId}`;
};

export const getClassicPosLink = () => {
	let facilityUrl = "";
	if (navigator.cookieEnabled) {
		facilityUrl = `${store.getState().enviroment.cookiesInfo.hostDomain}`;
	} else {
		facilityUrl = `https://${
			store.getState().enviroment.facilitySettings.facilityManagementDomain
		}/`;
	}

	if (!facilityUrl || facilityUrl === "undefined") {
		const facilityId = store.getState().enviroment.cookiesInfo.facilityId;
		const facilityAlias = store
			.getState()
			.enviroment.facilityAlias.results.find(
				(item) => item.facilityId === facilityId
			);

		let hostedDomain;
		if (facilityAlias) {
			hostedDomain = facilityAlias.hostedDomain;
		} else {
			hostedDomain =
				store.getState().enviroment.facilitySettings.facilityManagementDomain;
		}

		facilityUrl = `https://${hostedDomain}/`;
	}

	return `${facilityUrl}point_of_sale.asp`;
};
