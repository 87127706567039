import {
	ICustomerDrawer,
	IDiscountsState,
	IGiftCardDetails,
	IInterCardDetails,
	IRefundItemStateModel,
	IRefundTransactionStateModel,
	IValidatePosItem,
	OverlayAction,
} from "../pointOfSale.typings";
import { IOrderItem, IPaymentItem } from "../../order/order.typings";
import { ApiResult, ApiResultError } from "../../helpers/typings";
import { getProductRefundQuantity } from "../../../../containers/pages/PointOfSale/sections/Sales/components/OverlayActions/components/RefundFirstStep/RefundFirstStep.helpers";

export interface IValidatePosItemResponse {
	isValid: boolean;
	message: string;
}

export const salesState = {
	overlayAction: "none",
	customerDrawer: {
		isOpen: false,
	} as ICustomerDrawer,
	isPaymentDrawerOpen: false,
	isProcessingPayment: false,
	isTaxExemptConfirmationOpen: false,
	isIssuePrepaidCards: false,
	hasFailedPayment: false,
	isPaymentComplete: false,
	isAddedTipSuccess: false,
	refund: {
		itemsSelected: [] as Array<IRefundItemStateModel>,
		refundableItems: [] as Array<IRefundItemStateModel>,
		transactionsSelected: [],
		refundableTransactions: [],
	},
	discounts: {
		productDiscounts: [],
		orderDiscounts: [],
		fetchStateLoading: {
			loading: false,
			success: false,
			started: false,
			hasError: false,
			errorMessage: "",
		},
		applyStateLoading: {
			loading: false,
			success: false,
			started: false,
			hasError: false,
			errorMessage: "",
		},
		selectedOrderItem: {} as IOrderItem,
	} as IDiscountsState,
	giftCardDetails: {
		selectedOrderItem: {} as IOrderItem,
	} as IGiftCardDetails,
	interCardDetails: {
		selectedOrderItem: {} as IOrderItem,
	} as IInterCardDetails,
	validatePosItemState: {
		isValid: undefined,
		message: "",
		loading: false,
	} as IValidatePosItem,
};

const salesReducer = () => {
	return {
		openSalesOverlayAction: (state, { payload }: { payload: OverlayAction }) => {
			state.overlayAction = payload;
		},
		setSelectedOrderItem: (state, { payload }: { payload: IOrderItem }) => {
			state.discounts.selectedOrderItem = payload;
		},
		unsetSelectedOrderItem: (state) => {
			state.discounts.selectedOrderItem = null;
		},
		// PAYMENT DRAWER
		openPaymentDrawer: (state) => {
			state.isPaymentDrawerOpen = true;
		},
		closePaymentDrawer: (state) => {
			state.isPaymentDrawerOpen = false;
		},
		setIsProcessingPayment: (state, { payload }) => {
			state.isProcessingPayment = payload;
		},
		setTipAddedSuccess: (state, { payload }) => {
			state.isAddedTipSuccess = payload;
		},
		setHasFailedPayment: (state, { payload }) => {
			state.hasFailedPayment = payload;
		},
		setIsPaymentComplete: (state, { payload }) => {
			state.isPaymentComplete = payload;
		},
		setIsTaxExemptConfirmationOpen: (state, { payload }) => {
			state.isTaxExemptConfirmationOpen = payload;
		},
		setIsIssuePrepaidCards: (state, { payload }) => {
			state.isIssuePrepaidCards = payload;
		},
		// CUSTOMER DRAWER
		openCustomerDrawer: (state) => {
			state.customerDrawer.isOpen = true;
		},
		closeCustomerDrawer: (state) => {
			state.customerDrawer.isOpen = false;
		},
		fetchDiscountsStarted: (state) => {
			return {
				...state,
				discounts: {
					...state.discounts,
					fetchStateLoading: {
						started: true,
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
				},
			};
		},
		fetchDiscountsCompleted: (state, { payload }) => {
			return {
				...state,
				discounts: {
					...state.discounts,
					fetchStateLoading: {
						...state.discounts.fetchStateLoading,
						success: true,
					},
					productDiscounts: payload.data.results.productDiscounts,
					orderDiscounts: payload.data.results.orderDiscounts,
				},
			};
		},
		fetchDiscountsFailed: (state, { payload }) => {
			return {
				...state,
				discounts: {
					...state.discounts,
					fetchStateLoading: {
						...state.discounts.fetchStateLoading,
						hasError: true,
						errorMessage: payload.error,
					},
				},
			};
		},

		// VALIDATE POS PACKAGE
		validatePosItemStart: (state, { payload }) => {
			return {
				...state,
				validatePosItemState: {
					...state.validatePosItemState,
					loading: true,
					isValid: undefined,
					error: "",
				},
			};
		},
		validatePosItemSuccess: (
			state,
			{ payload }: { payload: ApiResult<IValidatePosItemResponse> }
		) => {
			const isValid = payload.data.results.isValid;
			return {
				...state,
				validatePosItemState: {
					...state.validatePosItemState,
					loading: false,
					isValid: isValid,
					message: payload.data.results.message,
				},
			};
		},
		validatePosItemFail: (
			state,
			{ payload }: { payload: ApiResultError<IValidatePosItemResponse> }
		) => {
			return {
				...state,
				validatePosItemState: {
					...state.validatePosItemState,
					loading: false,
					isValid: false,
					error: payload.error.response.data.message,
				},
			};
		},
		fillRefundableItems: (
			state,
			{ payload }: { payload: Array<IRefundItemStateModel> }
		) => {
			const refundItems = [] as Array<IRefundItemStateModel>;
			payload.forEach((i) => {
				refundItems.push({
					id: i.id.toString(),
					item: i.item,
					total: i.listPrice!,
					numpadOpen: false,
					quantity: i.quantity,
					type: "product",
					isPackage: i.isPackage,
					price: i.price,
					listPrice: i.listPrice,
					selected: i.selected,
					insidePackage: i.insidePackage,
					pennyDifference: i.pennyDifference,
					thousandthsDifference: i.thousandthsDifference,
					totalFullPrice: i.totalFullPrice,
					hasNoTax: i.hasNoTax,
					isLastItem: i.isLastItem,
				});
			});
			state.refund.refundableItems = [...refundItems];
		},
		resetReunfdStateItems: (state) => {
			return {
				...state,
				refund: {
					...state.refund,
					itemsSelected: [],
					refundableItems: [],
				},
			};
		},
		updateRefundableItem: (
			state,
			{ payload }: { payload: IRefundItemStateModel }
		) => {
			const updatedIndex = state.refund.refundableItems.findIndex(
				(x) => x.item.id === payload.item.id
			);
			const itemsArray = [...state.refund.refundableItems];
			itemsArray[updatedIndex] = payload;

			const itemsSelectedIndex = state.refund.itemsSelected.findIndex(
				(x) => x.item.id === payload.item.id
			);

			if (itemsSelectedIndex >= 0) {
				const selectedItemsArray = [...state.refund.itemsSelected];
				selectedItemsArray[itemsSelectedIndex] = payload;
				state.refund.itemsSelected = [...selectedItemsArray];
			}

			state.refund.refundableItems = [...itemsArray];
		},
		handleOpenCloseRefundableItemNumpad: (
			state,
			{ payload }: { payload: { id: number; open: boolean } }
		) => {
			const updatedIndex = state.refund.refundableItems.findIndex(
				(x) => x.item.id === payload.id
			);
			const itemsArray = [...state.refund.refundableItems];
			itemsArray[updatedIndex] = {
				...itemsArray[updatedIndex],
				numpadOpen: payload.open,
			};

			state.refund.refundableItems = [...itemsArray];
		},
		handleOpenCloseRefundableItemPackage: (
			state,
			{ payload }: { payload: { id: number; open: boolean } }
		) => {
			const updatedIndex = state.refund.refundableItems.findIndex(
				(x) => x.item.id === payload.id
			);
			const itemsArray = [...state.refund.refundableItems];
			itemsArray[updatedIndex] = {
				...itemsArray[updatedIndex],
				isPackageOpen: payload.open,
			};

			state.refund.refundableItems = [...itemsArray];
		},
		addItemToRefund: (state, { payload }: { payload: IRefundItemStateModel }) => {
			state.refund.itemsSelected.push(payload);
		},
		removeItemFromRefund: (
			state,
			{ payload }: { payload: IRefundItemStateModel }
		) => {
			return {
				...state,
				refund: {
					...state.refund,
					itemsSelected: state.refund.itemsSelected.filter(
						(item) => item.item.id !== payload.item.id
					),
				},
			};
		},
		updateItemFromRefund: (
			state,
			{ payload }: { payload: IRefundItemStateModel }
		) => {
			state.refund.itemsSelected = state.refund.itemsSelected.filter(
				(item) => item.item.id !== payload.item.id
			);
			state.refund.itemsSelected.push(payload);
		},
		fillRefundableTransactions: (
			state,
			{ payload }: { payload: Array<IPaymentItem> }
		) => {
			const refundTransactions = [] as Array<IRefundTransactionStateModel>;
			payload.forEach((t) => {
				refundTransactions.push({
					id: t.id,
					item: t,
					total: 0,
					numpadOpen: false,
				});
			});
			state.refund.refundableTransactions = [...refundTransactions];
		},
		updateRefundableTransaction: (
			state,
			{ payload }: { payload: IRefundTransactionStateModel }
		) => {
			const updatedIndex = state.refund.refundableTransactions.findIndex(
				(x) => x.id === payload.id
			);
			const transactionArray = [...state.refund.refundableTransactions];
			transactionArray[updatedIndex] = payload;

			const transactionsSelectedIndex = state.refund.transactionsSelected.findIndex(
				(x) => x.id === payload.id
			);

			if (transactionsSelectedIndex >= 0) {
				const selectedTransactionArray = [...state.refund.transactionsSelected];
				selectedTransactionArray[transactionsSelectedIndex] = payload;
				state.refund.transactionsSelected = [...selectedTransactionArray];
			}

			state.refund.refundableTransactions = [...transactionArray];
		},
		handleOpenCloseTransactionNumpad: (
			state,
			{ payload }: { payload: { id: number; open: boolean } }
		) => {
			const updatedIndex = state.refund.refundableTransactions.findIndex(
				(x) => x.id === payload.id
			);
			const transactionArray = [...state.refund.refundableTransactions];
			transactionArray[updatedIndex] = {
				...transactionArray[updatedIndex],
				numpadOpen: payload.open,
			};

			state.refund.refundableTransactions = [...transactionArray];
		},
		addTransactionToRefund: (
			state,
			{ payload }: { payload: IRefundTransactionStateModel }
		) => {
			state.refund.transactionsSelected.push(payload);
		},
		removeTransactionFromRefund: (
			state,
			{ payload }: { payload: IRefundTransactionStateModel }
		) => {
			state.refund.transactionsSelected = state.refund.transactionsSelected.filter(
				(item) => item.id !== payload.id
			);
		},
		updateTransactionFromRefund: (
			state,
			{ payload }: { payload: IRefundTransactionStateModel }
		) => {
			state.refund.transactionsSelected = state.refund.transactionsSelected.filter(
				(item) => item.id !== payload.id
			);
			state.refund.transactionsSelected.push(payload);
		},
		resetTransactionsSelected: (state) => {
			state.refund.transactionsSelected = [];
		},
		resetStateRefund: (state) => {
			state.refund.itemsSelected = [];
			state.refund.refundableItems = [];
			state.refund.transactionsSelected = [];
			state.refund.refundableTransactions = [];
		},
	};
};

export default salesReducer;
