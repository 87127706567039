import React from "react";
import classnames from "classnames";
import Check from "mdi-material-ui/Check";
import { useTheme, ThemeAgile } from "@agilekit/ui";

// ASSETS
import useStyles from "./ChoiceItem.styles";

interface ChoiceItemProps {
	inputRef?: any;
	id: string;
	group: string;
	text: string;
	horizontal?: boolean;
	fullWidth?: boolean;
	selected?: boolean;
	picture?: React.ReactNode;
	onChange(e: React.ChangeEvent<any> | string, id?: string): void;
}

const ChoiceItem: React.FC<ChoiceItemProps> = ({
	id,
	group,
	picture,
	selected = false,
	fullWidth = false,
	horizontal = false,
	text,
	onChange,
	inputRef,
}) => {
	const classes = useStyles();
	const theme: ThemeAgile = useTheme();

	const cssRoot = classnames(classes.root, {
		[classes.fullWidth]: fullWidth,
	});

	const cssField = classnames(
		classes.field,
		{ [classes.fieldSelected]: selected },
		{ [classes.fieldHorizontal]: horizontal },
		{ [classes.fullWidth]: fullWidth }
	);

	const cssText = classnames(classes.text, {
		// [classes.textSelected]: selected,
		[classes.textHorizontal]: horizontal,
	});

	const cssPicture = classnames(classes.picture, {
		[classes.pictureSelected]: selected,
		[classes.pictureHorizontal]: horizontal,
	});

	const cssIconCheck = classnames(classes.iconChecked, {
		[classes.iconCheckedHorizontal]: horizontal,
		[classes.iconUnchecked]: !selected,
	});

	const cssTextWrapper = classnames(classes.textWrapper, {
		[classes.textWrapperHorizontal]: horizontal,
	});

	return (
		<div className={cssRoot}>
			<input
				id={id}
				className={classes.input}
				type="radio"
				value={id}
				name={group}
				onClick={onChange}
				ref={inputRef}
			/>
			<label htmlFor={id} className={cssField}>
				<span className={cssIconCheck}>
					{selected && (
						<Check htmlColor={theme.palette.NEUTRAL000} fontSize="inherit" />
					)}
				</span>
				<div className={classes.innerField}>
					<div className={cssPicture}>{picture}</div>
				</div>
				<div className={cssTextWrapper}>
					<span className={cssText}>{text}</span>
				</div>
			</label>
		</div>
	);
};

export default ChoiceItem;
