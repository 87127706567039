import { createSlice } from "@reduxjs/toolkit";

export interface IImageResources {
	sharedAccessSignature: string;
	containerName: string;
	blobName: string;
	accountName: string;
	blobEndpoint: string;
	imageBase64: string;
}

const initialImageResources = {
	imageResources: {} as IImageResources,
	imageResourcesSuccess: false as boolean,
	error: "" as string,
};

const imageResourcesSlice = createSlice({
	name: "IMAGE_RESOURCES",
	initialState: initialImageResources,
	reducers: {
		imageResourcesRequest: (state, { payload }) => {
			return {
				...state,
				imageResources: {
					...state.imageResources,
					imageBase64: payload,
				},
				imageResourcesSuccess: false,
			};
		},
		imageResourcesPosRequest: (state, { payload }) => {
			return {
				...state,
				imageResources: {
					...state.imageResources,
					imageBase64: payload,
				},
				imageResourcesSuccess: false,
			};
		},
		imageResourcesSuccess: (state, { payload }) => {
			return {
				...state,
				imageResourcesSuccess: true,
				imageResources: payload.data,
			};
		},
		imageResourcesFailure: (state, { payload }) => {
			return {
				...state,
				imageResourcesSuccess: false,
				error: payload,
			};
		},
	},
});

export const {
	imageResourcesRequest,
	imageResourcesPosRequest,
	imageResourcesSuccess,
	imageResourcesFailure,
} = imageResourcesSlice.actions;

export default imageResourcesSlice.reducer;
