import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {
			height: "100vh",
		},
		menu: {
			backgroundColor: theme.palette.NEUTRAL800,
		},
	};
});

export default useStyles;
