import { AxiosPromise } from "axios";

// NPM PACKAGES
import Http from "../../../utils/restClient";

export default class WaiverAPI {
	public static fetchWaiver(waiverId: number) {
		return Http.axios().get(`/api/v1/waiver/${waiverId}`) as AxiosPromise;
	}

	public static fetchActiveWaiver() {
		return Http.axios().get(`/api/v1/waiver/active`) as AxiosPromise;
	}
}
