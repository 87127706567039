import Http from "../../../utils/restClient";
import { IEmailTemplate, EmailThemeType, ISendTestEmail } from "./emailTemplate.typings";

export default class EmailTemplateApi {
	public static GetEmailTemplates(emailTemplateType: EmailThemeType) {
		return Http.axios().get(`/api/v1/email-template/${emailTemplateType}`);
	}

	public static GetEmailThemes(emailTemplateType: EmailThemeType) {
		return Http.axios().get(
			`/api/v1/email-template/email-theme/${emailTemplateType}`
		);
	}

	public static GetEmailTemplateById(emailTemplateId: number) {
		return Http.axios().get(`/api/v1/${emailTemplateId}/email-template/`);
	}

	public static AddEmailTemplate(emailTemplate: IEmailTemplate) {
		return Http.axios().post("/api/v1/email-template/", emailTemplate);
	}

	public static UpdateEmailTemplate(emailTemplate: IEmailTemplate) {
		return Http.axios().put("/api/v1/email-template/", emailTemplate);
	}

	public static RemoveEmailTemplate(templateType: string, emailTemplateId: number) {
		return Http.axios().delete(
			`/api/v1/email-template/${templateType}/${emailTemplateId}`
		);
	}

	public static SendTestEmail(sendTestEmail: ISendTestEmail) {
		return Http.axios().post("/api/v1/email-template/send-test/", sendTestEmail);
	}
}
