import { AccountType } from "../partySchedule.typings";

const gohReducer = () => {
	return {
		addGoHOrganizations: (state, { payload }) => {
			const filteredGoH = state.partyGoH.filter((item) => {
				return payload.accountId === item.accountId;
			});
			if (filteredGoH.length > 0) {
				return {
					...state,
				};
			}
			return {
				...state,
				partyGoH: [
					...state.partyGoH,
					{
						accountId: payload.accountId,
						orderId: state.scheduleDetail.orderId,
						type: AccountType.ORGANIZATION,
						birthDate: payload.birthDate,
						firstName: payload.firstName,
						lastName: payload.lastName,
						fullName: payload.fullName,
						gender: payload.gender,
						avatar: payload.avatar,
						isDependent: payload.isDependent,
						isOrganization: payload.isOrganization,
					},
				],
			};
		},
		addGoHDependents: (state, { payload }) => {
			const filteredGoH = state.partyGoH.filter((item) => {
				return payload.accountId === item.accountId;
			});
			if (filteredGoH.length > 0) {
				return {
					...state,
				};
			}
			return {
				...state,
				partyGoH: [
					...state.partyGoH,
					{
						accountId: payload.accountId,
						orderId: state.scheduleDetail.orderId,
						type: AccountType.DEPENDENT,
						birthDate: payload.birthDate,
						firstName: payload.firstName,
						lastName: payload.lastName,
						fullName: payload.fullName,
						gender: payload.gender,
						avatar: payload.avatar,
						isDependent: payload.isDependent,
						isOrganization: payload.isOrganization,
					},
				],
			};
		},
		addGoHCustomer: (state, { payload }) => {
			const filteredGoH = state.partyGoH.filter((item) => {
				return payload.accountId === item.accountId;
			});

			if (filteredGoH.length > 0) {
				return {
					...state,
				};
			}
			return {
				...state,
				partyGoH: [
					...state.partyGoH,
					{
						accountId: payload.accountId,
						orderId: state.scheduleDetail.orderId,
						type: AccountType.CUSTOMER,
						birthDate: payload.birthDate,
						firstName: payload.firstName,
						lastName: payload.lastName,
						fullName: payload.fullName,
						gender: payload.gender,
						avatar: payload.avatar,
						isDependent: payload.isDependent,
						isOrganization: payload.isOrganization,
					},
				],
			};
		},
		removeGoHCustomer: (state, { payload }) => {
			return {
				...state,
				partyGoH: state.partyGoH.filter((a) => a.accountId !== payload.accountId),
			};
		},
		resetGoH: (state, { payload }) => {
			return {
				...state,
				partyGoH: [],
			};
		},
	};
};

export default gohReducer;
