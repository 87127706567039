import store from "../store/configureStore";
import { IFacilitySettings } from "../store/modules/enviroment/enviroment.slice";

export const getFirstLetter = (string: string): string => {
	if (string) {
		return string.trim().charAt(0).toUpperCase();
	}

	return "";
};

export const formatGender = (gender: string, genderOther?: string): string => {
	switch (gender) {
		case "m":
			return "Male";
		case "f":
			return "Female";
		case "o":
			if (
				genderOther !== undefined &&
				genderOther !== null &&
				genderOther.length > 0
			) {
				return genderOther;
			}
			return "Other";
		default:
			return "-";
	}
};

export const cleanseSpecialCharacters = (incoming: string): string => {
	if (incoming === null || incoming === undefined || typeof incoming !== "string") {
		return "";
	}
	const sanitizedValue = incoming.replace(/[^a-zA-Z0-9\s-!@%&"',.;:/+#()$?]/gi, "");
	return sanitizedValue;
};

export const truncateString = (
	value: string | undefined | null,
	maxLength: number,
	elipses = " ..."
): string => {
	if (value === null || value === undefined) {
		return "";
	}
	let returnValue = value;
	if (value.length > maxLength) {
		returnValue = value.slice(0, maxLength) + elipses;
	}
	return returnValue;
};

export const sanitizeProductName = (name) => {
	if (name) {
		return name
			.replace("&amp;", "&")
			.replace("&quot;", '"')
			.replace("&lt;", "<")
			.replace("&gt;", ">");
	}

	return name;
};

export const emailRegex = new RegExp(
	// eslint-disable-next-line
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const getCurrencySign = (currencySymbol: string): string => {
	let symbol = "$";
	switch (currencySymbol.toUpperCase()) {
		case "AED": // United Arab Emirates Dirham
			symbol = "د.إ.‏ ";
			break;
		case "AUD": // Australia Dollar
			symbol = "$ ";
			break;
		case "BBD": // Barbados Dollar
			symbol = "$ ";
			break;
		case "BOV": // Boliviano Mvdol
			symbol = "Bs.";
			break;
		case "CAD": // Canada Dollar
			symbol = "$";
			break;
		case "FRENCH CAD": // Canada Dollar
			symbol = " $";
			break;
		case "COP": // Colombia Peso
			symbol = "$";
			break;
		case "EUR": // Euro
			symbol = "€";
			break;
		case "GBP": // Great Britain Pound
			symbol = "£";
			break;
		case "JMD": // Jamaica Dollar
			symbol = "$";
			break;
		case "MXN": // Mexico Peso
			symbol = "$ ";
			break;
		case "NGN": // Nigeria Naira
			symbol = "₦ ";
			break;
		case "PHP": // Philippines Peso
			symbol = "₱ ";
			break;
		case "RON": // Romania Leu
			symbol = " lei";
			break;
		case "SGD": // Singapore Dollar
			symbol = "$";
			break;
		case "USD": // United States Dollar
			symbol = "$";
			break;
		default:
			symbol = "$";
			break;
	}
	return symbol;
};

export const formatCurrency = (
	amount: number | string | undefined,
	decimalPlaces?: number | undefined
): string => {
	if (amount === undefined) return "";

	const { currencySymbol }: IFacilitySettings =
		store.getState().enviroment.facilitySettings;
	let dp = decimalPlaces;
	if (dp === undefined) {
		dp = 2;
	}
	let returnValue = amount;
	if (typeof returnValue === "string") {
		returnValue = parseFloat(returnValue);
		if (isNaN(returnValue)) {
			return "";
		}
	}
	returnValue = returnValue
		.toFixed(dp)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	let symbol = "$";
	let location = "before";
	switch (currencySymbol.toUpperCase()) {
		case "AED": // United Arab Emirates Dirham
			symbol = "د.إ.‏ ";
			break;
		case "AUD": // Australia Dollar
			symbol = "$ ";
			break;
		case "BBD": // Barbados Dollar
			symbol = "$ ";
			break;
		case "BOV": // Boliviano Mvdol
			symbol = "Bs.";
			break;
		case "CAD": // Canada Dollar
			symbol = "$";
			break;
		case "FRENCH CAD": // Canada Dollar
			symbol = " $";
			location = "after";
			break;
		case "COP": // Colombia Peso
			symbol = "$";
			break;
		case "EUR": // Euro
			symbol = "€";
			break;
		case "GBP": // Great Britain Pound
			symbol = "£";
			break;
		case "JMD": // Jamaica Dollar
			symbol = "$";
			break;
		case "MXN": // Mexico Peso
			symbol = "$ ";
			break;
		case "NGN": // Nigeria Naira
			symbol = "₦ ";
			break;
		case "PHP": // Philippines Peso
			symbol = "₱ ";
			break;
		case "RON": // Romania Leu
			symbol = " lei";
			location = "after";
			break;
		case "SGD": // Singapore Dollar
			symbol = "$";
			break;
		case "USD": // United States Dollar
			symbol = "$";
			break;
		default:
			symbol = "$";
			break;
	}
	if (!isNaN(parseFloat(returnValue)) && parseFloat(returnValue) < 0) {
		returnValue = `(${returnValue.slice(1)})`;
	}
	if (location === "before") {
		returnValue = symbol + returnValue;
	} else {
		returnValue += symbol;
	}
	return returnValue;
};
