import { takeLatest, put, call } from "redux-saga/effects";
import {
	fetchActivityLogStart,
	fetchActivityLogComplete,
	fetchActivityLogFail,
} from "./activityLog.slice";
import ActivityLogAPI from "./activityLog.api";

// HELPERS
import { getActivityLogQueryString } from "./activityLog.helpers";

export function* fetchActivityLogForCustomer({ payload }) {
	try {
		const queryString = getActivityLogQueryString();
		const { accountId } = payload;

		const { data } = yield call(
			ActivityLogAPI.fetchActivityLogForCustomer,
			accountId,
			queryString
		);

		yield put(fetchActivityLogComplete(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(fetchActivityLogFail(e.message));
		}
	}
}

// Root Sagas
export function* watchActivityLog() {
	return [yield takeLatest(fetchActivityLogStart, fetchActivityLogForCustomer)];
}
