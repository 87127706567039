import { IBillingHistory, IBillingHistoryState } from "../customerMembership.typings";

export const billingHistoryStates = {
	billingHistory: {
		fetchStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		items: [] as Array<IBillingHistory>,
		paginationSettings: {
			currentPage: 0,
			firstRowOnPage: 0,
			lastRowOnPage: 0,
			pageNumber: 0,
			pageSize: 0,
			totalItems: 0,
			totalPages: 0,
		},
		receiptActions: {
			fetchStateLoading: {
				errorMessage: "",
				hasError: false,
				loading: false,
				success: false,
			},
			orderAccount: {
				accountId: 0,
				email: "",
				fetchStateLoading: {
					errorMessage: "",
					hasError: false,
					loading: false,
					success: false,
				},
			},
			receiptContentHtml: "",
			sendStateLoading: {
				errorMessage: "",
				hasError: false,
				loading: false,
				success: false,
			},
		},
	} as IBillingHistoryState,
};

export const billingHistoryReducer = () => {
	return {
		fetchBillingHistoryStart: (state, { payload }) => {
			return {
				...state,
				billingHistory: {
					...state.billingHistory,
					fetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
					pagiationSettings: {
						pageSize: payload.pageSize,
						pageNumber: payload.pageNumber,
					},
				},
			};
		},
		fetchBillingHistoryComplete: (state, { payload }) => {
			return {
				...state,
				billingHistory: {
					...state.billingHistory,
					fetchStateLoading: {
						loading: false,
						success: true,
					},
					paginationSettings: {
						totalItems: payload.totalItems,
						totalPages: payload.totalPages,
						firstRowOnPage: payload.firstRowOnPage,
						lastRowOnPage: payload.lastRowOnPage,
						currentPage: payload.currentPage,
					},
					items: payload.results,
				},
			};
		},
		fetchBillingHistoryFail: (state, { payload }) => {
			return {
				...state,
				billingHistory: {
					...state.billingHistory,
					fetchStateLoading: {
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		/// /

		fetchOrderAccountStart: (state, { payload }) => {
			return {
				...state,
				billingHistory: {
					...state.billingHistory,
					receiptActions: {
						...state.billingHistory.receiptActions,
						orderAccount: {
							...state.billingHistory.receiptActions.orderAccount,
							fetchStateLoading: {
								loading: true,
								success: false,
								hasError: false,
								errorMessage: "",
							},
						},
					},
				},
			};
		},
		fetchOrderAccountComplete: (state, { payload }) => {
			return {
				...state,
				billingHistory: {
					...state.billingHistory,
					receiptActions: {
						...state.billingHistory.receiptActions,
						orderAccount: {
							...payload,
							fetchStateLoading: {
								loading: false,
								success: true,
								hasError: false,
								errorMessage: "",
							},
						},
					},
				},
			};
		},
		fetchOrderAccountFail: (state, { payload }) => {
			return {
				...state,
				billingHistory: {
					...state.billingHistory,
					receiptActions: {
						...state.billingHistory.receiptActions,
						orderAccount: {
							...state.billingHistory.receiptActions.orderAccount,
							fetchStateLoading: {
								loading: false,
								success: false,
								hasError: true,
								errorMessage: payload,
							},
						},
					},
				},
			};
		},
		fetchReceiptHistoryContentHtmlStart: (state, { payload }) => {
			return {
				...state,
				billingHistory: {
					...state.billingHistory,
					receiptActions: {
						...state.billingHistory.receiptActions,
						fetchStateLoading: {
							loading: true,
							success: false,
							hasError: false,
							errorMessage: "",
						},
						receiptContentHtml: "",
					},
				},
			};
		},
		fetchReceiptHistoryContentHtmlSuccess: (state, { payload }) => {
			return {
				...state,
				billingHistory: {
					...state.billingHistory,
					receiptActions: {
						...state.billingHistory.receiptActions,
						fetchStateLoading: {
							loading: false,
							success: true,
							hasError: false,
							errorMessage: "",
						},
						receiptContentHtml: payload.contentHTML,
					},
				},
			};
		},
		fetchReceiptHistoryContentHtmlFail: (state, { payload }) => {
			return {
				...state,
				billingHistory: {
					...state.billingHistory,
					receiptActions: {
						...state.billingHistory.receiptActions,
						fetchStateLoading: {
							loading: false,
							success: false,
							hasError: true,
							errorMessage: payload,
						},
						receiptContentHtml: "",
					},
				},
			};
		},
		sendReceiptEmailStart: (state, { payload }) => {
			return {
				...state,
				billingHistory: {
					...state.billingHistory,
					receiptActions: {
						...state.billingHistory.receiptActions,
						sendStateLoading: {
							errorMessage: "",
							hasError: false,
							loading: true,
							success: false,
						},
					},
				},
			};
		},
		sendReceiptEmailSuccess: (state, { payload }) => {
			return {
				...state,
				billingHistory: {
					...state.billingHistory,
					receiptActions: {
						...state.billingHistory.receiptActions,
						sendStateLoading: {
							errorMessage: "",
							hasError: false,
							loading: false,
							success: true,
						},
					},
				},
			};
		},
		sendReceiptEmailFail: (state, { payload }) => {
			return {
				...state,
				billingHistory: {
					...state.billingHistory,
					receiptActions: {
						...state.billingHistory.receiptActions,
						sendStateLoading: {
							errorMessage: payload,
							hasError: true,
							loading: false,
							success: false,
						},
					},
				},
			};
		},
	};
};
