import { AxiosPromise } from "axios";

// HELPERS
import Http from "../../../utils/restClient";
import { ISubscriptionSaveType } from "./customerMembership.typings";

export default class CustomerMembershipAPI {
	public static fetchCustomerMembership(accountId: number, subscriptionId: number) {
		return Http.axios().get(
			`/api/v1/customers/${accountId}/subscription/${subscriptionId}`
		);
	}

	public static fetchCustomerMemberships(accountId: number) {
		return Http.axios().get(
			`/api/v1/customers/${accountId}/subscriptions`
		) as AxiosPromise;
	}

	public static fetchAvailablePlans() {
		return Http.axios().get(`/api/v1/customers/subscription/plans`) as AxiosPromise;
	}

	public static addMembershipPlanToCustomer(accountId: number, planId: number[]) {
		return Http.axios().post(`/api/v1/customers/${accountId}/membership/plans`, {
			planId,
		}) as AxiosPromise;
	}

	public static addTokenToAccount(payload: any) {
		return Http.axios().post(
			`/api/v1/customers/${payload.accountId}/subscription/token`,
			payload
		);
	}

	public static removeTokenToAccount(payload: any) {
		return Http.axios().put(
			`/api/v1/customers/${payload.accountId}/subscription/token/remove`,
			payload
		);
	}

	public static canDeleteAccountToken(payload: any) {
		return Http.axios().get(
			`/api/v1/customers/${payload.accountId}/subscription/token/${payload.accountTokenId}`,
			payload
		);
	}

	public static fetchTransientInfo(accountId: number, subscriptionId: number) {
		return Http.axios().get(
			`/api/v1/customers/${accountId}/subscription/${subscriptionId}/transientInfo`
		) as AxiosPromise;
	}

	public static cancelMembershipPlan(accountId: number, subscriptionId: number) {
		return Http.axios().put(
			`/api/v1/customers/${accountId}/membership/${subscriptionId}/cancel`
		);
	}

	public static cancelSubscriptionImmediately(
		accountId: number,
		subscriptionId: number
	) {
		return Http.axios().put(
			`/api/v1/customers/${accountId}/membership/${subscriptionId}/immediateCancel`
		);
	}

	public static calculatePricing(payload: any) {
		return Http.axios().post(`/api/v1/customers/subscription/calculate`, payload);
	}

	public static resumeSubscription(accountId: number, subscriptionId: number) {
		return Http.axios().post(
			`/api/v1/customers/${accountId}/subscription/${subscriptionId}/resume`
		) as AxiosPromise;
	}

	public static saveMembership(saveModel: ISubscriptionSaveType) {
		return Http.axios().post(
			`/api/v1/customers/membership/save`,
			saveModel
		) as AxiosPromise;
	}

	public static removeSubscription(accountId: number, subscriptionId: number) {
		return Http.axios().delete(
			`/api/v1/customers/${accountId}/membership/${subscriptionId}`
		);
	}

	public static fetchBillingHistory(
		accountId: number,
		subscriptionId: number,
		pageSize: number,
		pageNumber: number
	) {
		return Http.axios().get(
			`/api/v1/customers/${accountId}/subscription/${subscriptionId}/billingHistory?pageSize=${pageSize}&pageNumber=${pageNumber}`
		);
	}

	public static fetchOrderAccount(orderId: number) {
		return Http.axios().get(
			`/api/v1/customers/subscription/order/${orderId}/account`
		);
	}

	public static fetchMailHistory(
		accountId: number,
		subscriptionId: number,
		subscriptionPaymentHistoryId: number
	) {
		return Http.axios().get(
			`/api/v1/customers/${accountId}/subscription/${subscriptionId}/mailingHistory/${subscriptionPaymentHistoryId}`
		);
	}

	public static sendReceiptEmail(
		accountId: number,
		subscriptionId: number,
		subscriptionPaymentHistoryId: number,
		mailTo: string
	) {
		const model = {
			subscriptionPaymentHistoryId,
			mailTo,
		};
		return Http.axios().post(
			`/api/v1/customers/${accountId}/subscription/${subscriptionId}/email/send`,
			model
		) as AxiosPromise;
	}

	public static paySubscription(saveModel: ISubscriptionSaveType) {
		return Http.axios().post(
			`/api/v1/customers/subscription/pay`,
			saveModel
		) as AxiosPromise;
	}

	public static getAccountTokens(accountId: number) {
		return Http.axios().get(
			`/api/v1/customers/${accountId}/subscription/tokens`
		) as AxiosPromise;
	}
}
