import { DateTime } from "luxon";

import store from "../../configureStore";

export const getActivityLogQueryString = () => {
	const { startDate, endDate } = store.getState().activityLog;

	const queryString = `?startDate=${startDate}&endDate=${endDate}`;

	return queryString;
};

export const convertStartDate = (daysParam = 30) => {
	return DateTime.local().minus({ days: daysParam }).toFormat("yyyy-LL-dd");
};
