import { AxiosPromise } from "axios";

// HELPERS
import Http from "../../../utils/restClient";

export default class ActivityLogAPI {
	public static fetchActivityLogForCustomer(accountId: number, queryString: string) {
		/*
			Querying by date example: 
			/api/v1/activity/customer/${accountId}?startDate=2015-01-01&endDate=2020-05-05
		*/
		return Http.axios().get(
			`/api/v1/activity/customer/${accountId}${queryString}`
		) as AxiosPromise;
	}
}
