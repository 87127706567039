const layoutReducer = () => {
	return {
		setShowSelectedCustomer: (state, { payload }) => {
			return {
				...state,
				layout: {
					...state.layout,
					showSelectedCustomer: payload,
				},
			};
		},
		setShowAssociationTabs: (state, { payload }) => {
			return {
				...state,
				layout: {
					...state.layout,
					showAssociationTabs: payload,
				},
			};
		},
		setShowEditContent: (state, { payload }) => {
			return {
				...state,
				layout: {
					...state.layout,
					showEditContent: payload,
				},
			};
		},

		setWaiverActive: (state, { payload }) => {
			return {
				...state,
				layout: {
					...state.layout,
					waiverActive: payload,
				},
			};
		},
	};
};

export default layoutReducer;
