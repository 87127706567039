import Http from "../../../utils/restClient";

export default class UserApi {
	public static GetUserRoles() {
		return Http.axios().get("/api/v1/user/roles");
	}

	public static GetUserGroups() {
		return Http.axios().get("/api/v1/user/groups");
	}

	public static GetUserDiscounts() {
		return Http.axios().get("/api/v1/user/hasDiscounts");
	}
}
