import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {},
		collpased: {
			"& $header": {
				marginBottom: theme.spacing(0),
			},
			"& $content": {
				display: "none",
			},
		},
		error: {
			"& $header": {
				borderTop: `3px solid ${theme.palette.RED500}`,
				backgroundColor: theme.palette.RED400,
				"&:hover": {
					backgroundColor: theme.palette.RED500,
				},
			},
		},
		icon: {
			color: theme.palette.NEUTRAL700,
			backgroundColor: theme.palette.NEUTRAL000,
			boxShadow: theme.shadows[1],
			borderRadius: 4,
			padding: theme.spacing(1),
			marginRight: theme.spacing(4),
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			"$error &": {
				color: theme.palette.RED400,
			},
		},
		content: {
			marginBottom: theme.spacing(8),
		},
		headerTitle: {
			display: "flex",
			alignItems: "center",
		},
		title: {
			color: theme.palette.NEUTRAL000,
			fontSize: theme.typography.pxToRem(14),
			lineHeight: theme.typography.pxToRem(16),
			fontWeight: 500,
			textTransform: "uppercase",
			margin: 0,
			"$error &": {
				color: theme.palette.NEUTRAL000,
			},
		},
		header: {
			display: "flex",
			cursor: "pointer",
			justifyContent: "space-between",
			alignItems: "center",
			padding: theme.spacing(3),
			marginBottom: theme.spacing(6),
			backgroundColor: theme.palette.NEUTRAL300,
			color: theme.palette.NEUTRAL000,
			borderTop: `3px solid ${theme.palette.NEUTRAL500}`,
			borderBottom: `1px solid ${theme.palette.NEUTRAL040}`,
			"&:hover": {
				backgroundColor: theme.palette.NEUTRAL500,
			},
		},
	};
});

export default useStyles;
