import { ILabel } from "../customer.typings";

const labelsReducer = () => {
	return {
		addLabelStart: (state, { payload }) => {
			return {
				...state,
				addLabelSuccess: false,
				error: "",
			};
		},
		addLabelSuccess: (state, { payload }) => {
			return {
				...state,
				addLabelSuccess: true,
				customer: payload.customer,
				labels: [...state.labels.concat(payload.labels)],
				error: "",
			};
		},
		addLabelFailure: (state, { payload }) => {
			return {
				...state,
				error: payload,
				addLabelSuccess: false,
			};
		},
		removeLabelFromCustomerStart: (state, { payload }) => {
			return {
				...state,
				isRemovingLabelFromCustomer: true,
				error: "",
			};
		},
		removeLabelFromCustomerSuccess: (state, { payload }) => {
			return {
				...state,
				isRemovingLabelFromCustomer: false,
				customer: payload,
				error: "",
			};
		},
		removeLabelFromCustomerFailure: (state, { payload }) => {
			return {
				...state,
				error: payload,
				isRemovingLabelFromCustomer: false,
			};
		},
		fetchLabelsStart: (state) => {
			return {
				...state,
				isLoadingLabels: true,
				error: "",
			};
		},
		fetchLabelsSuccess: (state, { payload }) => {
			return {
				...state,
				isLoadingLabels: false,
				labels: payload,
				error: "",
			};
		},
		fetchLabelsFailure: (state, { payload }) => {
			return {
				...state,
				error: payload,
				isLoadingLabels: false,
			};
		},
		removeLabelsStart: (state, { payload }) => {
			return {
				...state,
				removeLabelsSuccess: false,
				error: "",
			};
		},
		removeLabelsSuccess: (state, { payload }) => {
			return {
				...state,
				removeLabelsSuccess: true,
				labels: payload,
				error: "",
			};
		},
		removeLabelsFailure: (state, { payload }) => {
			return {
				...state,
				error: payload,
				removeLabelsSuccess: false,
			};
		},
		updateCustomerLabelsList: (state, { payload }) => {
			return {
				...state,
				customer: {
					...state.customer,
					customerLabels: state.customer.customerLabels.filter(
						(l) => l.labelId !== payload
					),
				},
			};
		},
		fetchLabelRelationsStart: (state, { payload }) => {
			return {
				...state,
				fetchLabelRelationsSuccess: false,
				error: "",
			};
		},
		fetchLabelRelationsSuccess: (state, { payload }) => {
			return {
				...state,
				fetchLabelRelationsSuccess: true,
				totalLabelsRelated: payload,
				error: "",
			};
		},
		fetchLabelRelationsFailure: (state, { payload }) => {
			return {
				...state,
				error: payload,
				fetchLabelRelationsSuccess: false,
			};
		},
		addLabelToCustomerStart: (state, { payload }) => {
			return {
				...state,
				isAddingLabelToCustomer: true,
				error: "",
			};
		},
		addLabelToCustomerSuccess: (state, { payload }) => {
			return {
				...state,
				isAddingLabelToCustomer: false,
				customer: payload.customer,
				error: "",
			};
		},
		addLabelToCustomerFailure: (state, { payload }) => {
			return {
				...state,
				error: payload,
				isAddingLabelToCustomer: false,
			};
		},
		setEditLabel: (state, { payload }) => {
			return {
				...state,
				editLabel: state.labels.find((label) => label.labelId === payload),
			};
		},
		unSetEditLabel: (state) => {
			return {
				...state,
				editLabel: {},
			};
		},
		updateLabelStart: (state, { payload }) => {
			return {
				...state,
				updateLabelSuccess: false,
			};
		},
		updateLabelSuccess: (state, { payload }) => {
			const updateLabel = [payload] as ILabel[];
			return {
				...state,
				updateLabelSuccess: true,
				labels: state.labels.map(
					(label) =>
						updateLabel.find((p) => p.labelId === label.labelId) || label
				),
				customer: {
					...state.customer,
					customerLabels: state.customer.customerLabels.map(
						(label) =>
							updateLabel.find((p) => p.labelId === label.labelId) || label
					),
				},
				editLabel: {},
			};
		},
		updateLabelFailure: (state, { payload }) => {
			return {
				...state,
				updateLabelSuccess: false,
			};
		},
	};
};

export default labelsReducer;
