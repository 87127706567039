import { ICustomerWaiverStatus } from "../scheduleCustomer.slice";

const selectedProfileReducer = () => {
	return {
		getSelectedProfileStart: (state, { payload }) => {
			return {
				...state,
				selectedProfileSuccess: false,
				isLoadingCustomerProfile: true,
				selectedProfile: payload,
				error: "",
			};
		},
		getSelectedProfileSuccess: (state, { payload }) => {
			const { selectedCustomer } = state;

			return {
				...state,
				selectedProfileSuccess: true,
				isLoadingCustomerProfile: false,
				selectedProfile: payload.data,
				selectedCustomer:
					payload.data.isDependent || payload.data.isOrganization
						? selectedCustomer
						: payload.data,
				error: "",
				layout: {
					...state.layout,
					showSelectedCustomer: true,
					showAssociationTabs: false,
				},
			};
		},
		getSelectedProfileFailure: (state, { payload }) => {
			return {
				...state,
				selectedProfileSuccess: false,
				isLoadingCustomerProfile: false,
				selectedProfile: {},
				error: payload,
			};
		},
		resetSelectedProfile: (state, { payload }) => {
			return {
				...state,
				selectedProfile: {},
			};
		},
		setSelectedProfile: (state, { payload }) => {
			return {
				...state,
				selectedProfile: payload,
				selectedProfileSuccess: true,
				layout: {
					...state.layout,
					showSelectedCustomer: true,
					showAssociationTabs: false,
				},
			};
		},
		setCustomerWaiverStatus: (state, { payload }) => {
			const customerWaiverStatus: ICustomerWaiverStatus[] = [
				{
					accountId: payload.accountId,
					waiverStatus: payload.waiverInfo?.waiverStatus,
				},
			];

			payload.people.forEach((person) => {
				customerWaiverStatus.push({
					accountId: person.accountId,
					waiverStatus: person.waiverStatus,
				});
			});

			return {
				...state,
				selectedProfileWaiverStatus: customerWaiverStatus,
			};
		},
		resetCustomerWaiverStatus: (state, { payload }) => {
			return {
				...state,
				selectedProfileWaiverStatus: {},
			};
		},
		updateCustomerWaiverStatus: (state, { payload }) => {
			const { customerWaiver, waiverStatus } = payload;

			const newSelectedProfileWaiverStatus = state.selectedProfileWaiverStatus.map(
				(cWaiverStatus) => {
					const newCustomerWaiverStatus = { ...cWaiverStatus };

					// update the customer who signed the waiver status
					if (cWaiverStatus.accountId === customerWaiver.accountId) {
						newCustomerWaiverStatus.waiverStatus = waiverStatus;
					}

					// update the dependents related to the waiver
					if (
						customerWaiver.persons.find(
							(person) => person.accountId === cWaiverStatus.accountId
						)
					) {
						newCustomerWaiverStatus.waiverStatus = waiverStatus;
					}

					return newCustomerWaiverStatus;
				}
			);

			return {
				...state,
				selectedProfileWaiverStatus: newSelectedProfileWaiverStatus,
			};
		},
		updateWaiverStatusToExpired: (state, { payload }) => {
			const newSelectedProfileWaiverStatus = state.selectedProfileWaiverStatus.map(
				(cWaiverStatus) => {
					const newCustomerWaiverStatus = { ...cWaiverStatus };

					if (payload.includes(cWaiverStatus.accountId)) {
						newCustomerWaiverStatus.waiverStatus = "Expired";
					}
					return newCustomerWaiverStatus;
				}
			);

			return {
				...state,
				selectedProfileWaiverStatus: newSelectedProfileWaiverStatus,
			};
		},
	};
};

export default selectedProfileReducer;
