import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		divider: {
			backgroundColor: theme.palette.NEUTRAL040,
			width: "100%",
			height: 1,
		},
	};
});

export default useStyles;
