import { createSlice } from "@reduxjs/toolkit";
import { ApiResult } from "../helpers/typings";

export interface IAgileTerminalSetting {
	stationSettingId: number;
	stationId: string;
	settingName: string;
	settingValue: string;
}

export const PosTerminalPaperWidthSettingName = "RECEIPT_PAPER_COLUMN_WIDTH_POS";
export const PosTerminalFooterTextSettingName = "RECEIPT_FOOTER_TEXT";
export const PosTerminalDisplayTipLineSettingName = "RECEIPT_TIP_LINE";
export const PosTerminalqtyPrintedCashSettingName = "RECEIPTS_QTY_PRINTED_ON_CASH_POS";
export const PosTerminalqtyPrintedCheckSettingName = "RECEIPTS_QTY_PRINTED_ON_CHECK_POS";
export const PosTerminalqtyPrintedCardSettingName = "RECEIPTS_QTY_PRINTED_ON_CARD_POS";
export const PosTerminalqtyPrintedPrepaidSettingName =
	"RECEIPTS_QTY_PRINTED_ON_PREPAID_POS";
export const PopCashDrawerOnCreditTransactionsSettingName =
	"POP_CASH_DRAWER_ON_CREDIT_TRANSACTION";

export type PaymentMethodOptions = "CARD" | "CASH" | "CHECK" | "PREPAID" | "DEFAULT";

const agileTerminal = createSlice({
	name: "AGILETERMINAL",
	initialState: {
		isLoading: false,
		error: "",
		terminalSettings: {
			paperWidth: 35,
			displayTipLine: false,
			footerText: "",
			qtyPrintDefault: 1,
			qtyPrintCash: 1,
			qtyPrintCheck: 1,
			qtyPrintCard: 1,
			qtyPrintPrepaid: 1,
			popCashDrawerOnCreditTransactions: false,
		},
		printAction: {
			isPrinting: false,
			success: false,
			error: false,
		},
		isSave: true,
		selectedPaymentMethod: "DEFAULT" as PaymentMethodOptions,
	},
	reducers: {
		clearPrintState: (state) => {
			return {
				...state,
				printAction: {
					...state.printAction,
					isPrinting: false,
					error: false,
					success: false,
				},
				error: "",
			};
		},
		printRequest: (state, { payload }) => {
			return {
				...state,
				printAction: {
					...state.printAction,
					isPrinting: true,
					error: false,
					success: false,
				},
				error: "",
			};
		},
		printSuccess: (state, { payload }) => {
			return {
				...state,
				printAction: {
					...state.printAction,
					isPrinting: false,
					error: false,
					success: true,
				},
				error: "",
			};
		},
		printFailure: (state, { payload }) => {
			return {
				...state,
				printAction: {
					...state.printAction,
					isPrinting: false,
					error: true,
					success: false,
				},
				error: payload,
			};
		},
		getTerminalSettingsRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
			};
		},
		getTerminalSettingsSuccess: (
			state,
			{ payload }: { payload: ApiResult<Array<IAgileTerminalSetting>> }
		) => {
			const PosTerminalPaperWidth = payload.data.results.find(
				(x) =>
					x.settingName.toLowerCase() ===
					PosTerminalPaperWidthSettingName.toLowerCase()
			);
			let posPaperWidth = 35;
			if (
				PosTerminalPaperWidth &&
				!isNaN(Number(PosTerminalPaperWidth.settingValue))
			) {
				posPaperWidth = parseInt(PosTerminalPaperWidth.settingValue);
			}

			let displayTipLine = false;
			const PosTerminalDisplayTipLine = payload.data.results.find(
				(x) =>
					x.settingName.toLocaleLowerCase() ===
					PosTerminalDisplayTipLineSettingName.toLocaleLowerCase()
			);
			if (
				PosTerminalDisplayTipLine &&
				PosTerminalDisplayTipLine.settingValue.toLowerCase() === "true"
			) {
				displayTipLine = true;
			}

			let footerText = "";
			const PosTerminalFooterText = payload.data.results.find(
				(x) =>
					x.settingName.toLocaleLowerCase() ===
					PosTerminalFooterTextSettingName.toLocaleLowerCase()
			);
			if (PosTerminalFooterText) {
				footerText = PosTerminalFooterText.settingValue;
			}

			let qtyPrintCash = 1;
			const PosTerminalqtyPrintedCash = payload.data.results.find(
				(x) =>
					x.settingName.toLocaleLowerCase() ===
					PosTerminalqtyPrintedCashSettingName.toLocaleLowerCase()
			);
			if (
				PosTerminalqtyPrintedCash &&
				!isNaN(Number(PosTerminalqtyPrintedCash.settingValue))
			) {
				qtyPrintCash = parseInt(PosTerminalqtyPrintedCash.settingValue);
			}

			let qtyPrintCheck = 1;
			const PosTerminalqtyPrintedCheck = payload.data.results.find(
				(x) =>
					x.settingName.toLocaleLowerCase() ===
					PosTerminalqtyPrintedCheckSettingName.toLocaleLowerCase()
			);
			if (
				PosTerminalqtyPrintedCheck &&
				!isNaN(Number(PosTerminalqtyPrintedCheck.settingValue))
			) {
				qtyPrintCheck = parseInt(PosTerminalqtyPrintedCheck.settingValue);
			}

			let qtyPrintCard = 1;
			const PosTerminalqtyPrintedCard = payload.data.results.find(
				(x) =>
					x.settingName.toLocaleLowerCase() ===
					PosTerminalqtyPrintedCardSettingName.toLocaleLowerCase()
			);
			if (
				PosTerminalqtyPrintedCard &&
				!isNaN(Number(PosTerminalqtyPrintedCard.settingValue))
			) {
				qtyPrintCard = parseInt(PosTerminalqtyPrintedCard.settingValue);
			}

			let qtyPrintPrepaid = 1;
			const PosTerminalqtyPrintedPrepaid = payload.data.results.find(
				(x) =>
					x.settingName.toLocaleLowerCase() ===
					PosTerminalqtyPrintedPrepaidSettingName.toLocaleLowerCase()
			);
			if (
				PosTerminalqtyPrintedPrepaid &&
				!isNaN(Number(PosTerminalqtyPrintedPrepaid.settingValue))
			) {
				qtyPrintPrepaid = parseInt(PosTerminalqtyPrintedPrepaid.settingValue);
			}

			let popCashDrawerOnCreditTransactions = false;
			const PopCashDrawerOnCreditTransactions = payload.data.results.find(
				(x) =>
					x.settingName.toLocaleLowerCase() ===
					PopCashDrawerOnCreditTransactionsSettingName.toLocaleLowerCase()
			);
			if (
				PopCashDrawerOnCreditTransactions &&
				PopCashDrawerOnCreditTransactions.settingValue.toLowerCase() === "true"
			) {
				popCashDrawerOnCreditTransactions = true;
			}

			return {
				...state,
				terminalSettings: {
					paperWidth: posPaperWidth,
					displayTipLine: displayTipLine,
					footerText: footerText,
					qtyPrintCash: qtyPrintCash,
					qtyPrintCheck: qtyPrintCheck,
					qtyPrintCard: qtyPrintCard,
					qtyPrintPrepaid: qtyPrintPrepaid,
					qtyPrintDefault: 1,
					popCashDrawerOnCreditTransactions: popCashDrawerOnCreditTransactions,
				},
				isLoading: false,
				error: "",
			};
		},
		getTerminalSettingsFailure: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				error: payload,
			};
		},
		setSelectedMethodState: (
			state,
			{ payload }: { payload: PaymentMethodOptions }
		) => {
			state.selectedPaymentMethod = payload;
		},
		setIsSaveState: (state, { payload }: { payload: boolean }) => {
			state.isSave = payload;
		},
	},
});

export const {
	clearPrintState,
	printRequest,
	printSuccess,
	printFailure,
	getTerminalSettingsRequest,
	getTerminalSettingsSuccess,
	getTerminalSettingsFailure,
	setSelectedMethodState,
	setIsSaveState,
} = agileTerminal.actions;

export default agileTerminal.reducer;
