import { AxiosPromise } from "axios";

// HELPERS
import Http from "../../../utils/restClient";

export default class EmailLogAPI {
	public static getEmailLogById(emailId: number) {
		return Http.axios().get(`/api/v1/emailLog/${emailId}`) as AxiosPromise;
	}
}
