export interface IFacilityAlias {
	hostedDomain: string;
	facilityName: string;
	defaultAddressCountry: string;
	facilityId: string;
	userGuid: string;
}

export interface IFacilityAliasState {
	loading: boolean;
	error: string;
	hasError: boolean;
	success: boolean;
	isFacilityAliasModalOpen: boolean;
	results: IFacilityAlias[];
}

export const facilityAliasStates = {
	facilityAlias: {
		loading: false,
		error: "",
		hasError: false,
		success: false,
		isFacilityAliasModalOpen: false,
		results: [] as IFacilityAlias[],
	} as IFacilityAliasState,
};

const facilityAliasReducer = () => {
	return {
		getfacilityAlias: (state) => {
			return {
				...state,
				facilityAlias: {
					...state.facilityAlias,
					loading: true,
					error: "",
					hasError: false,
					success: false,
				},
			};
		},
		getfacilityAliasComplete: (state, { payload }) => {
			return {
				...state,
				facilityAlias: {
					...state.facilityAlias,
					loading: true,
					error: "",
					hasError: false,
					success: false,
					results: payload.data,
				},
			};
		},
		getfacilityAliasFail: (state, { payload }) => {
			return {
				...state,
				facilityAlias: {
					...state.facilityAlias,
					loading: false,
					error: payload,
					hasError: true,
					success: false,
				},
			};
		},
		openFacilityAliasModal: (state) => {
			return {
				...state,
				facilityAlias: {
					...state.facilityAlias,
					isFacilityAliasModalOpen: true,
				},
			};
		},
		closeFacilityAliasModal: (state) => {
			return {
				...state,
				facilityAlias: {
					...state.facilityAlias,
					isFacilityAliasModalOpen: false,
				},
			};
		},
	};
};

export default facilityAliasReducer;
