import Http from "../../../utils/restClient";

export default class EnviromentAPI {
	public static fetchFacilitySettings() {
		return Http.axios().get("/api/v1/facility/settings");
	}

	public static fetchCustomerRequiredFields() {
		return Http.axios().get("/api/v1/facility/customer/required-fields");
	}

	public static fetchFacilityReferrals() {
		return Http.axios().get("/api/v1/facility/referrals");
	}

	public static fetchFacilitySocialMedias() {
		return Http.axios().get("/api/v1/facility/social-medias");
	}

	public static fetchPaymentsSettings() {
		return Http.axios().get("/api/v1/payment/fullsteam/hostedcontrols/url");
	}

	public static fetchFacilityAlias() {
		return Http.axios().get("/api/v1/facility/aliases");
	}

	public static fetchStripePublishableKey() {
		return Http.axios().get("/api/v1/stripe/publishable-key");
	}
}
