import React from "react";

// ASSETS
import useStyles from "./ListContainer.styles";
import classnames from "classnames";

const ListContainer = ({ children }) => {
	const classes = useStyles();
	return <div className={classes.container}>{children}</div>;
};

export default ListContainer;
