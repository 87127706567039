import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => ({
	root: {
		display: "inline-flex",
		background: theme.palette.NEUTRAL000,
	},
	input: {
		opacity: 0,
		margin: 0,
		borderRadius: 0,
		"-webkit-appearance": "none",
	},
	field: {
		display: "flex",
		cursor: "pointer",
		position: "relative",
		boxSizing: "content-box",
		paddingTop: theme.spacing(8),
		paddingBottom: theme.spacing(4),
		transitionProperty: "all",
		transitionDuration: "200ms",
		transitionTimingFunction: "ease",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		userSelect: "none",
		borderRadius: 4,
		borderWidth: 1,
		borderStyle: "solid",
		borderImage: "initial",
		borderColor: theme.palette.NEUTRAL060,
		[theme.breakpoints.down(1024)]: {
			paddingLeft: theme.spacing(3),
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(3),
			flexDirection: "row",
			justifyContent: "flex-start",
		},
		"&:hover": {
			borderColor: theme.palette.BLUE400,
		},
	},
	fieldSelected: {
		borderColor: theme.palette.BLUE400,
		"&::before": {
			borderColor: theme.palette.BLUE400,
		},
	},
	fieldHorizontal: {
		flexDirection: "row",
		justifyContent: "flex-start",
		padding: theme.spacing(1.75, 2.5),
	},
	iconChecked: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: theme.palette.BLUE400,
		minWidth: 20,
		width: 20,
		height: 20,
		position: "absolute",
		left: "auto",
		top: 16,
		right: 16,
		boxSizing: "border-box",
		borderRadius: "50%",
	},
	iconCheckedHorizontal: {
		position: "static",
		marginRight: theme.spacing(2.5),
	},
	iconUnchecked: {
		backgroundColor: theme.palette.NEUTRAL010,
		borderWidth: 2,
		borderStyle: "solid",
		borderRadius: "50%",
		borderColor: theme.palette.NEUTRAL040,
	},
	innerField: {
		position: "relative",
		display: "inline-block",
	},
	picture: {
		opacity: 0.7,
		fontSize: theme.typography.pxToRem(100),
		[theme.breakpoints.down(1024)]: {
			fontSize: theme.typography.pxToRem(70),
		},
	},
	pictureSelected: {
		opacity: 1,
	},
	pictureHorizontal: {
		fontSize: theme.typography.pxToRem(25),
	},
	textWrapper: {
		display: "block",
		position: "relative",
		marginTop: theme.spacing(4),
		whiteSpace: "pre-wrap",
		textOverflow: "ellipsis",
		overflow: "hidden",
		[theme.breakpoints.down(1024)]: {
			marginTop: theme.spacing(0),
			paddingLeft: theme.spacing(3),
		},
	},
	textWrapperHorizontal: {
		marginLeft: theme.spacing(1),
		marginTop: 0,
	},
	text: {
		display: "inline-block",
		color: theme.palette.NEUTRAL400,
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 500,
		lineHeight: theme.typography.pxToRem(24),
	},
	textHorizontal: {
		fontSize: theme.typography.pxToRem(14),
		lineHeight: theme.typography.pxToRem(22),
	},
	textSelected: {
		color: theme.palette.BLUE400,
	},
	fullWidth: {
		width: "100%",
	},
}));

export default useStyles;
