import React from "react";
import { TextField } from "@agilekit/ui";
import { useFormContext } from "react-hook-form";
import { validateFormatPhone } from "../../utils/validateFormatPhone";

interface PhoneProps {
	isRequired: boolean;
	isPrimaryComponent: boolean;
	id: string;
	name: string;
	label: string;
	inputProps?: Record<string, any>;
	onBlurOverride?: any;
	onChangeOverride?: any;
	errorOverride?: string | boolean | Error | undefined;
	errorText?: React.ReactNode;
	defaultValue?: string | number | undefined;
	onBlur?: any;
	disabled?: boolean;
}

const Phone: React.FC<PhoneProps> = ({
	isRequired = true,
	id,
	name,
	label,
	inputProps,
	onBlurOverride,
	onChangeOverride,
	errorOverride,
	errorText,
	defaultValue,
	isPrimaryComponent = true,
	onBlur,
	disabled = false,
}) => {
	const { register, errors, getValues, setValue, setError, clearErrors } =
		useFormContext();

	const onBlurPhone = (e: any) => {
		if (onBlurOverride) onBlurOverride(e);
		else {
			const targetPhone = e.target.getAttribute("id");
			const value = getValues(targetPhone);
			clearErrors(targetPhone);
			const validatePhone = validateFormatPhone(value);
			if (validatePhone.valid) {
				setValue(targetPhone, validatePhone.phone);

				if (onBlur) {
					onBlur(e);
				}

				return;
			}
			setError(targetPhone, {
				message: validatePhone.errorMessage,
			});
		}
	};

	const onChangePhone = (e: any) => {
		if (onChangeOverride) onChangeOverride(e);
		else if (e.key !== "Backspace") {
			const targetPhone = e.target.getAttribute("id");
			const value = getValues(targetPhone);
			const validatePhone = validateFormatPhone(value);
			if (value === getValues(targetPhone)) {
				setValue(targetPhone, validatePhone.phone);
			}
		}
	};

	const errorResolution = () => {
		// if (errorOverride !== undefined && !isPrimaryComponent) return errorOverride;
		// return !!errors.phone;
		if (!isPrimaryComponent) return errorOverride;
		return !!errors.phone;
	};

	const helperTextResolution = () => {
		if (!isPrimaryComponent) return errorText;
		return errors.phone && errors.phone.message;
	};

	return (
		<>
			<TextField
				inputRef={register()}
				defaultValue={defaultValue}
				id={id}
				name={name}
				label={label}
				inputProps={inputProps}
				onBlur={onBlurPhone}
				onKeyUp={onChangePhone}
				error={errorResolution()}
				helperText={helperTextResolution()}
				displayRequired={isRequired}
				data-testid={isPrimaryComponent ? "primaryPhone" : "aditionalPhone"}
				autoComplete="new-password"
				disabled={disabled}
			/>
		</>
	);
};

export default Phone;
