const socialMediasReducer = () => {
	return {
		fetchSocialMediasStart: (state) => {
			return {
				...state,
				isSocialMediaLoading: true,
				error: "",
			};
		},
		fetchSocialMediasSuccess: (state, { payload }) => {
			return {
				...state,
				isSocialMediaLoading: false,
				facilitySettings: {
					...state.facilitySettings,
					socialMedias: payload.data,
				},
				error: "",
			};
		},
		fetchSocialMediasFailure: (state, { payload }) => {
			return {
				...state,
				error: payload,
				isSocialMediaLoading: false,
			};
		},
	};
};

export default socialMediasReducer;
