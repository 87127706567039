import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {
			position: "fixed",
			width: "100%",
			display: "flex",
			justifyContent: "center",
			top: 200,
			transform: "scale(1.5)",
		},
	};
});

export default useStyles;
