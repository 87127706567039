import { createSlice } from "@reduxjs/toolkit";

export interface IcustomerWrapperStates {
	customerId: number;
	displayCustomerWrapper: boolean;
}

const initialCustomerWrapperState = {
	customerId: 0,
	displayCustomerWrapper: false,
} as IcustomerWrapperStates;

const customerWrapperSlice = createSlice({
	name: "CUSTOMERWRAPPER",
	initialState: initialCustomerWrapperState,
	reducers: {
		setCustomerWrapperId: (state, { payload }) => {
			return {
				...state,
				customerId: payload,
			};
		},
		setDisplayCustomerWrapper: (state, { payload }) => {
			return {
				...state,
				displayCustomerWrapper: payload,
			};
		},
	},
});

export const { setCustomerWrapperId, setDisplayCustomerWrapper } =
	customerWrapperSlice.actions;

export default customerWrapperSlice.reducer;
