import React from "react";
import { Checkbox, Box } from "@agilekit/ui";
import DotsVertical from "mdi-material-ui/DotsVertical";

// ASSETS
import useStyles from "./ModifierGroup.styles";

interface ModifierGroupProps {
	children: React.ReactNode;
	title: string;
	color: string;
	totalModifiers: number;
	selectionMode?: boolean;
	onAddModifier?: any;
	headerExtra?: React.ReactNode;
	bodyStyle?: React.CSSProperties;
}

const ModifierGroup: React.FC<ModifierGroupProps> = ({
	title,
	color,
	children,
	totalModifiers,
	selectionMode,
	onAddModifier,
	headerExtra,
	bodyStyle,
}) => {
	const classes = useStyles();

	// EVENTS
	const handleAddModifier = () => {
		if (onAddModifier) {
			onAddModifier();
		}
	};

	return (
		<div className={classes.container} style={{ borderTopColor: color }}>
			<div className={classes.header}>
				<div className={classes.headerTitle}>
					<div className={classes.titleContainer}>
						{selectionMode && (
							<Box mr={2} display="flex">
								<Checkbox label="Select Group" labelHidden />
							</Box>
						)}
						<p className={classes.title}>{title}</p>
					</div>
					<button type="button" className={classes.contextMenuButton}>
						<DotsVertical />
					</button>
				</div>
				{headerExtra}
			</div>
			<div className={classes.body} style={bodyStyle}>
				{children}
			</div>
			<div className={classes.footer}>
				<div className={classes.totalModifiers}>
					<span>{totalModifiers}</span> modifiers
				</div>
				<div>
					<button
						className={classes.addModifier}
						type="button"
						onClick={handleAddModifier}
					>
						+ Add modifier
					</button>
				</div>
			</div>
		</div>
	);
};

export default ModifierGroup;
