import { OrderSearchItem } from "../headerNav.typings";

export const searchStates = {
	search: {
		fetchStateLoading: {
			errorMessage: false,
			hasError: false,
			loading: false,
			success: false,
			noResults: false,
		},
		searchText: "",
		results: [] as OrderSearchItem[],
		lastTenViewed: [] as OrderSearchItem[],
		isOpen: false,
	},
};

export const searchReducer = () => {
	return {
		orderSearchStart: (state, { payload }) => {
			return {
				...state,
				search: {
					...state.search,
					searchText: payload.searchText,
					fetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						noResults: false,
						errorMessage: "",
					},
				},
			};
		},
		orderSearchComplete: (state, { payload }) => {
			return {
				...state,
				search: {
					...state.search,
					fetchStateLoading: {
						...state.search.fetchStateLoading,
						loading: false,
						success: true,
						noResults: payload.data.results.length === 0,
					},
					results: payload.data.results,
				},
			};
		},
		orderSearchFail: (state, { payload }) => {
			return {
				...state,
				search: {
					...state.search,
					fetchStateLoading: {
						...state.plans.fetchStateLoading,
						loading: false,
						hasError: true,
						noResults: true,
						errorMessage: payload,
					},
				},
			};
		},
		clearOrderSearch: (state) => {
			return {
				...state,
				search: {
					...state.search,
					searchText: "",
					fetchStateLoading: {
						...state.search.fetchStateLoading,
						loading: false,
						noResults: false,
					},
					results: [] as OrderSearchItem[],
				},
			};
		},
		fetchLastTenOrdersVisitedStart: (state) => {
			return {
				...state,
				search: {
					...state.search,
					fetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchLastTenOrdersVisitedComplete: (state, { payload }) => {
			return {
				...state,
				search: {
					...state.search,
					fetchStateLoading: {
						...state.search.fetchStateLoading,
						loading: false,
						success: true,
					},
					lastTenViewed: payload.data.results,
				},
			};
		},
		fetchLastTenOrdersVisitedFail: (state, { payload }) => {
			return {
				...state,
				search: {
					...state.search,
					fetchStateLoading: {
						...state.search.fetchStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
	};
};
