import { TypeRelatedPerson } from "../customer.typings";

const dependentReducer = () => {
	return {
		addDependentToAccountStart: (state, { payload }) => {
			return {
				...state,
				addDependentSuccess: false,
				addOrganizationSuccess: false,
				addCustomerSuccess: false,
				addLinkedAccountsSuccess: false,
				addOrgazanitionToAccountSuccess: false,
				addCustomerAssociatedSuccess: false,
				updateCustomerSuccess: false,
				updateCustomerAssociatedSuccess: false,
				updateOrganizationSuccess: false,
				updateDependentSuccess: false,
			};
		},
		addDependentToAccountComplete: (state, { payload }) => {
			const newDependent = {
				accountStatus: payload.accountStatus,
				firstName: payload.firstName,
				lastName: payload.lastName,
				personId: payload.personId,
				accountId: payload.accountId,
				avatar: payload.avatar,
				isDependent: payload.isDependent,
				birthdate: payload.birthDate,
			} as TypeRelatedPerson;

			let newCustomer = { ...state.customer };
			if (state.customer.people) {
				newCustomer = {
					...state.customer,
					people: [...state.customer.people.concat(newDependent)],
				};
			}

			return {
				...state,
				addDependentSuccess: true,
				dependent: payload,
				customer: newCustomer,
				relatedCustomer: payload, // add related customer as an option for the redux
			};
		},
		addDependentToAccountFromSearchComplete: (state, { payload }) => {
			return {
				...state,
				addDependentSuccess: true,
				relatedCustomer: payload,
			};
		},
		addDependentToOrganizationFromSearchComplete: (state, { payload }) => {
			const newRelatedCustomer = {
				accountStatus: payload.accountStatus,
				firstName: payload.firstName,
				lastName: payload.lastName,
				name: `${payload.firstName} ${payload.lastName}`,
				personId: payload.personId,
				accountId: payload.accountId,
				email: payload.email,
				emailId: payload.emailId,
				gender: payload.gender,
				avatar: payload.avatar,
				isDependent: payload.isDependent,
				birthdate: payload.birthDate,
			} as TypeRelatedPerson;

			let updatedOrganization = { ...state.organization };

			if (state.organization.people) {
				updatedOrganization = {
					...state.organization,
					people: [...state.organization.people.concat(newRelatedCustomer)],
				};
			}

			return {
				...state,
				addDependentAssociatedToOrganizationSuccess: true,
				organization: updatedOrganization,
				relatedCustomer: payload,
			};
		},
		addDependentToAccountFail: (state, { payload }) => {
			return {
				...state,
				addDependentSuccess: false,
				addDependentAssociatedToOrganizationSuccess: false,
				error: payload,
			};
		},
		updateDependentStart: (state, { payload }) => {
			return {
				...state,
				updateDependentSuccess: false,
				updateCustomerError: false,
			};
		},
		updateDependentComplete: (state, { payload }) => {
			return {
				...state,
				updateDependentSuccess: true,
				customer: payload.customer,
				dependent: payload.customer,
				hasExpiredWaiver: payload.hasExpiredAnyWaiver,
				expiredWaiverAccountIds: payload.expiredWaiverAccountIds,
				updateCustomerError: false,
			};
		},
		updateDependentFail: (state, { payload }) => {
			return {
				...state,
				updateDependentSuccess: false,
				error: payload,
				updateCustomerError: true,
			};
		},
	};
};

export default dependentReducer;
