import { IPaymentSettings } from "../enviroment.slice";

export const paymentSetttingsStates = {
	paymentSettings: {
		isLoading: false,
		hostedControlsUrl: "",
	} as IPaymentSettings,
};

const paymentSettingsReducer = () => {
	return {
		fetchPaymentSettingsStart: (state) => {
			return {
				...state,
				paymentSettings: {
					...state.paymentSettings,
					isLoading: true,
				},
				error: "",
			};
		},
		fetchPaymentSettingsSuccess: (state, { payload }) => {
			return {
				...state,
				paymentSettings: {
					isLoading: false,
					hostedControlsUrl: payload.data,
				},
				error: "",
			};
		},
		fetchPaymentSettingsFailure: (state, { payload }) => {
			return {
				...state,
				error: payload,
				paymentSettings: {
					...state.paymentSettings,
					isLoading: false,
				},
			};
		},
	};
};

export default paymentSettingsReducer;
