const orderCustomerReducer = () => {
	return {
		addCustomerToOrderStart: (state, { payload }) => {
			return {
				...state,
				updateOrderCustomerSuccess: false,
			};
		},
		addCustomerToOrderSuccess: (state, { payload }) => {
			return {
				...state,
				updateOrderCustomerSuccess: true,
			};
		},
		addCustomerToOrderFailure: (state, { payload }) => {
			return {
				...state,
				updateOrderCustomerSuccess: false,
			};
		},
		removeCustomerFromOrderStart: (state, { payload }) => {
			return {
				...state,
				updateOrderCustomerSuccess: false,
			};
		},
		removeCustomerFromOrderSuccess: (state, { payload }) => {
			return {
				...state,
				updateOrderCustomerSuccess: true,
			};
		},
		removeCustomerFromOrderFailure: (state, { payload }) => {
			return {
				...state,
				updateOrderCustomerSuccess: false,
			};
		},
	};
};

export default orderCustomerReducer;
