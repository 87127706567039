export function cleanSessionStorage() {
	window.sessionStorage.removeItem("customersFilter");
}

window.addEventListener(
	"message",
	(event) => {
		if (navigator.cookieEnabled) {
			cleanSessionStorage();
		}
	},
	false
);

export default {};
