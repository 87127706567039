import { TypeRelatedOrganization } from "../../customer/customer.typings";

const customerOrganizationsReducer = () => {
	return {
		updateOrganizationRequest: (state, { payload }) => {
			return {
				...state,
				requestSuccess: false,
				error: "",
			};
		},
		updateOrganizationSuccess: (state, { payload }) => {
			return {
				...state,
				requestSuccess: true,
				error: "",
			};
		},
		updateOrganizationFailure: (state, { payload }) => {
			return {
				...state,
				requestSuccess: false,
				error: payload,
			};
		},
		addSelectedCustomerOrganization: (state, { payload }) => {
			return {
				...state,
				selectedCustomer: {
					...state.selectedCustomer,
					organizations: [
						...state.selectedCustomer.organizations,
						{
							organizationId: payload.organizationId,
							name: payload.organizationName,
							accountId: payload.accountId,
						} as TypeRelatedOrganization,
					],
				},
			};
		},
	};
};

export default customerOrganizationsReducer;
