import { createSlice } from "@reduxjs/toolkit";
import {
	MembershipPlan,
	MembershipPlanPricing,
	MembershipTaxes,
} from "./memberships.typings";

const initialMembership = {
	// PLANS
	plans: [] as MembershipPlan[],
	allPlans: [] as MembershipPlan[],
	plan: {
		planId: 0,
		planName: "",
		isDeleted: false,
		isActive: false,
		isDeactivating: false,
		addnew: false,
		activationFee: 0,
		activationFeeName: "",
		activationFeePerPerson: false,
		expirationMonthsForFailedPayment: 1,
		welcomeEmailTemplateId: 0,
		receiptEmailTemplateId: 0,
		membershipTaxes: [] as MembershipTaxes[],
		membershipPrices: [
			{
				planPriceId: 0,
				startAge: 0,
				endAge: 0,
				monthlyPrice: 0,
				yearlyPrice: 0,
			} as unknown as MembershipPlanPricing,
		] as MembershipPlanPricing[],
	} as unknown as MembershipPlan,
	isLoadingPlans: false,
	isLoading: false,
	error: "" as any,
	planUpdateSuccess: false,
	planAddSuccess: false,
	planDeleteSuccess: false,
	fetchallplansSuccess: false,
	isLoadingAllPlans: false,
};

const membershipPlanSlice = createSlice({
	name: "MEMBERSHIPS",
	initialState: initialMembership,
	reducers: {
		membershipPlanFailure: (state, { payload }) => {
			return {
				...state,
				isLoadingPlans: false,
				error: payload,
				planUpdateSuccess: false,
				planAddSuccess: false,
				planDeleteSuccess: false,
			};
		},
		// GET MEMBERSHIP PLANS
		membershipPlanListRequest: (state) => {
			return {
				...state,
				isLoadingPlans: true,
				error: "",
			};
		},
		membershipPlanListSuccess: (state, { payload }) => {
			return {
				...state,
				plans: payload.data.results,
				isLoadingPlans: false,
				error: "",
			};
		},
		// ADD MEMBERSHIP PLAN
		membershipPlanAddRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				planAddSuccess: false,
				error: "",
			};
		},
		membershipPlanAddSuccess: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				plans: [...state.plans, payload.data],
				plan: payload.data,
				error: "",
				planAddSuccess: true,
			};
		},
		// UPDATE PLAN STATE
		membershipPlanSelectRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				plan: payload,
			};
		},
		membershipPlanSelectSuccess: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				plan: payload,
				error: "",
			};
		},
		// DELETE MEMBERSHIP PLAN
		membershipPlanDeleteRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				error: "",
			};
		},
		membershipPlanDeleteSuccess: (state, { payload }) => {
			const newPlans = state.plans.filter(
				({ planId }) => payload.planId !== planId
			);

			return {
				...state,
				isLoading: false,
				plans: newPlans,
				plan: newPlans[0],
				error: "",
				planDeleteSuccess: true,
			};
		},
		// UPDATE MEMBERSHIP PLAN
		membershipPlanUpdateRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				planUpdateSuccess: false,
			};
		},
		membershipPlanUpdateSuccess: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				plan: payload.data,
				error: "",
				planUpdateSuccess: true,
			};
		},
		// GET ALL MEMBERSHIP PLANS
		fetchAllMembershipPlans: (state) => {
			return {
				...state,
				isLoadingAllPlans: true,
				fetchallplansSuccess: false,
				error: "",
			};
		},
		fetchAllMembershipPlansFailure: (state, { payload }) => {
			return {
				...state,
				isLoadingAllPlans: false,
				error: payload,
				fetchallplansSuccess: false,
			};
		},
		fetchAllMembershipPlanstSuccess: (state, { payload }) => {
			return {
				...state,
				allPlans: payload,
				isLoadingAllPlans: false,
				fetchallplansSuccess: false,
				error: "",
			};
		},
	},
});

export const {
	membershipPlanFailure,
	membershipPlanListRequest,
	membershipPlanListSuccess,
	membershipPlanAddRequest,
	membershipPlanAddSuccess,
	membershipPlanDeleteRequest,
	membershipPlanDeleteSuccess,
	membershipPlanSelectRequest,
	membershipPlanSelectSuccess,
	membershipPlanUpdateRequest,
	membershipPlanUpdateSuccess,
	fetchAllMembershipPlans,
	fetchAllMembershipPlansFailure,
	fetchAllMembershipPlanstSuccess,
} = membershipPlanSlice.actions;

export default membershipPlanSlice.reducer;
