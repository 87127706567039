import Http from "./restClient";

export async function checkForNewVersion() {
	try {
		// Create an Axios instance using the Http class
		const axiosInstance = Http.axios();

		// Fetch the current hash from the endpoint
		const response = await axiosInstance.get("/api/v1/react/build/version");
		console.log("/api/v1/react/build/version => response", response);
		const currentHash = response.data.toString();

		// Extract the hash from the current script URL
		const scriptTag = document.querySelector('script[src*="/static/js/main."]');

		console.log("Script Tag:", scriptTag);

		if (scriptTag) {
			const currentScriptUrl = scriptTag.getAttribute("src");

			console.log("Current Script Url:", currentScriptUrl);

			if (currentScriptUrl) {
				const regex = /main\.(.+)\.js/;
				const match = currentScriptUrl.match(regex);
				const currentScriptHash = match ? match[1].toString() : null;

				console.log("Hash -> Local Browser:", currentScriptHash);
				console.log("Hash -> Endpoint:", currentHash);

				// Compare the current script hash with the fetched hash
				if (currentHash && currentScriptHash !== currentHash) {
					// If different, update the script tag to load the new version
					const newScriptUrl = currentScriptUrl.replace(
						regex,
						`main.${currentHash}.js`
					);

					console.log("New Script Url:", newScriptUrl);
					scriptTag.setAttribute("src", newScriptUrl);

					// Force a page reload to use the new script
					window.location.reload();
				}
			}
		}
	} catch (error) {
		console.error("Error checking for new version:", error);
	}
}
