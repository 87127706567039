import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => ({
	root: {
		boxShadow: theme.shadows[2],
		borderRadius: 4,
		overflow: "hidden",
	},
	visibleContainer: {
		backgroundColor: theme.palette.NEUTRAL010,
		display: "flex",
		height: 40,
		alignItems: "center",
	},
	error: {
		borderTop: `3px solid ${theme.palette.RED400}`,
		height: 43,
	},
	titleContainer: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		cursor: "pointer",
	},
	title: {
		fontSize: theme.typography.pxToRem(14),
		lineHeight: theme.typography.pxToRem(24),
		color: theme.palette.NEUTRAL700,
		margin: 0,
	},
	actions: {
		display: "flex",
		alignItems: "center",
		color: theme.palette.NEUTRAL200,
	},
	content: {
		width: "100%",
	},
	collapseMove: {
		cursor: "pointer",
	},
	opened: {
		borderTop: "none",
		backgroundColor: theme.palette.NEUTRAL020,
		borderBottom: `1px solid ${theme.palette.NEUTRAL040}`,
		// "& $title, & $actions": {
		// 	color: "#fff"
		// }
	},
	closed: {
		borderBottom: 0,
	},
	chevronIcon: {
		backgroundColor: "transparent",
		border: "none",
		color: theme.palette.NEUTRAL700,
		cursor: "pointer",
		padding: 0,
		"&:focus": {
			outline: "none",
		},
		"$closed &": {
			display: "none",
		},
	},
}));

export default useStyles;
