import React from "react";
// import { SeverityLevel } from "@microsoft/applicationinsights-web";

import Error from "./Error";
import { ErrorType } from "./Error.helpers";
// import store from "../../store/configureStore";

interface IProps {
	showPage?: boolean;
	onError?: any;
	children: React.ReactNode;
}

class ErrorBoundary extends React.Component<IProps> {
	state = { hasError: false };

	// componentDidCatch(error, info) {
	// 	const { appInsights } = store.getState().appInsights as any;
	// 	this.setState({ hasError: true });
	// 	appInsights &&
	// 		appInsights.trackException({
	// 			error,
	// 			exception: error,
	// 			severityLevel: SeverityLevel.Error,
	// 			properties: { ...info },
	// 		});
	// }

	render() {
		if (this.state.hasError) {
			const { showPage, onError } = this.props;
			return showPage != undefined && showPage ? (
				<Error data-test="error_page" type={ErrorType.renderError} />
			) : onError == undefined ? (
				"Some error occured"
			) : typeof onError === "function" ? (
				onError()
			) : (
				React.createElement(onError)
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
