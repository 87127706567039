export enum WaiverStatusIconColor {
	CONFIRMED = "GREEN400",
	UNCONFIRMED = "YELLOW500",
	EXPIRED = "RED400",
}

const waiverStatusStyles = (waiverStatus) => {
	let waiverStatusIconColor: any;
	switch (waiverStatus) {
		case "Confirmed":
			waiverStatusIconColor = WaiverStatusIconColor.CONFIRMED;
			break;
		case "Expired":
			waiverStatusIconColor = WaiverStatusIconColor.EXPIRED;
			break;
		case "Unconfirmed":
			waiverStatusIconColor = WaiverStatusIconColor.UNCONFIRMED;
			break;
		case "None":
		default:
			waiverStatusIconColor = "";
	}

	return {
		waiverStatusIconColor,
		waiverStatusIcon: "",
	};
};

export default waiverStatusStyles;
