import { takeLatest, put, call } from "redux-saga/effects";

import {
	printRequest,
	printFailure,
	printSuccess,
	getTerminalSettingsRequest,
	getTerminalSettingsSuccess,
	getTerminalSettingsFailure,
} from "./agileTerminal.slice";
import TerminalAPI from "./agileTerminal.api";

export function* sendPrintRequest({ payload }): any {
	try {
		const response = yield call(TerminalAPI.print, payload);
		yield put(printSuccess(response.data));
	} catch (error: any) {
		yield put(printFailure(error.message));
	}
}

export function* getTerminalSettings({ payload }): any {
	try {
		const response = yield call(TerminalAPI.getSettings, payload);
		yield put(getTerminalSettingsSuccess(response));
	} catch (error: any) {
		yield put(getTerminalSettingsFailure(error.message));
	}
}

export function* watchAgileTerminal() {
	return [
		yield takeLatest(printRequest, sendPrintRequest),
		yield takeLatest(getTerminalSettingsRequest, getTerminalSettings),
	];
}
