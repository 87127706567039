import React from "react";
import Check from "mdi-material-ui/Check";
import classnames from "classnames";

// ASSETS
import useStyles from "./ColorSquareItem.styles";

interface ColorSquareItemProps {
	color: string;
	selected?: boolean;
	onClick?: any;
}

const ColorSquareItem: React.FC<ColorSquareItemProps> = ({
	color,
	selected,
	onClick,
}) => {
	const classes = useStyles();
	const handleClick = (data: string) => {
		if (onClick) {
			onClick(data);
		}
	};

	// STYLES
	const cssContainer = classnames(classes.container, {
		[classes.clickable]: onClick,
	});

	return (
		<div
			className={cssContainer}
			style={{ backgroundColor: color }}
			onClick={() => handleClick(color)}
			role="button"
			tabIndex={0}
		>
			{selected && <Check />}
		</div>
	);
};

export default ColorSquareItem;
