const customerReducer = () => {
	return {
		updateCustomerRequest: (state, { payload }) => {
			return {
				...state,
				requestSuccess: false,
				selectedCustomer: payload,
				error: "",
			};
		},
		updateCustomerSuccess: (state, { payload }) => {
			return {
				...state,
				requestSuccess: true,
				selectedCustomer: payload.customer,
				error: "",
			};
		},
		updateCustomerFailure: (state, { payload }) => {
			return {
				...state,
				requestSuccess: false,
				error: payload,
			};
		},
	};
};

export default customerReducer;
