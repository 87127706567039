import { IPaymentCompletedResponse } from "../payments.typings";

export const cashState = {
	cashPay: {
		applyStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			started: false,
			success: false,
		},
		paymentResponse: {} as IPaymentCompletedResponse,
	},
};

const cashReducer = () => {
	return {
		postCashPayStart: (state, { payload }) => {
			return {
				...state,
				cashPay: {
					...state.cashPay,
					applyStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		postCashPayComplete: (state, { payload }) => {
			return {
				...state,
				cashPay: {
					...state.cashPay,
					applyStateLoading: {
						...state.cashPay.applyStateLoading,
						started: true,
						loading: false,
						success: true,
					},
					paymentResponse: payload.data.results,
				},
			};
		},
		postCashPayFail: (state, { payload }) => {
			return {
				...state,
				cashPay: {
					...state.cashPay,
					applyStateLoading: {
						...state.cashPay.applyStateLoading,
						started: true,
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		resetCashPaymentState: (state) => {
			return {
				...state,
				cashPay: {
					applyStateLoading: {
						started: false,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
					paymentResponse: {} as IPaymentCompletedResponse,
				},
			};
		},
	};
};
export default cashReducer;
