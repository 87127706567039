import React from "react";

// ASSETS
import useStyles from "./SidebarHeader.styles";

interface SidebarHeaderProps {
	title: string;
	actions?: React.ReactNode;
}

const SidebarHeader: React.FC<SidebarHeaderProps> = ({ title, actions }) => {
	const classes = useStyles();

	return (
		<div className={classes.header}>
			<p>{title}</p>
			<div>{actions}</div>
		</div>
	);
};

export default SidebarHeader;
