const statusReducer = () => {
	return {
		updateCustomerStatusStart: (state, { payload }) => {
			return {
				...state,
				updateCustomerStatusLoading: true,
			};
		},
		updateCustomerStatusSuccess: (state, { payload }) => {
			const { accountStatusId, accountStatus } = payload;
			return {
				...state,
				updateCustomerStatusLoading: false,
				customer: {
					...state.customer,
					accountStatusId,
					accountStatus,
				},
			};
		},
		updateCustomerStatusFail: (state, { payload }) => {
			return {
				...state,
				updateCustomerStatusLoading: false,
				error: payload,
			};
		},
		updateOrganizationStatusStart: (state, { payload }) => {
			return {
				...state,
				updateCustomerStatusLoading: true,
			};
		},
		updateOrganizationStatusSuccess: (state, { payload }) => {
			const { accountStatusId, accountStatus } = payload;
			return {
				...state,
				updateCustomerStatusLoading: false,
				organization: {
					...state.organization,
					accountStatusId,
					accountStatus,
				},
			};
		},
		updateOrganizationStatusFail: (state, { payload }) => {
			return {
				...state,
				updateCustomerStatusLoading: false,
				error: payload,
			};
		},
	};
};

export default statusReducer;
