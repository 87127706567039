import { takeLatest, call, put } from "redux-saga/effects";

import {
	addEmailTemplateFailure,
	addEmailTemplateStart,
	addEmailTemplateSuucess,
	fetchEmailTemplatesFailure,
	fetchEmailTemplatesStart,
	fetchEmailTemplatesSuccess,
	fetchEmailThemeStart,
	fetchEmailThemeSuccess,
	fetchEmailThemeFailure,
	getEmailTemplateByIdFailure,
	getEmailTemplateByIdStart,
	getEmailTemplateByIdSuccess,
	removeEmailTemplateFailure,
	removeEmailTemplateStart,
	removeEmailTemplateSuccess,
	updateEmailTemplateFailure,
	updateEmailTemplateStart,
	updateEmailTemplateSuccess,
	sendTemplateTestStart,
	sendTemplateTestSuccess,
	sendTemplateTestFailure,
} from "./emailTemplate.slice";

import EmailTemplateApi from "./emailTemplate.api";

export function* addEmailTemplate({ payload }): any {
	try {
		const response = yield call(EmailTemplateApi.AddEmailTemplate, payload);

		yield put(addEmailTemplateSuucess({ data: response.data }));
	} catch (error: any) {
		yield put(addEmailTemplateFailure({ error }));
	}
}

export function* fetchEmailTemplates({ payload }) {
	try {
		const { data } = yield call(EmailTemplateApi.GetEmailTemplates, payload);

		yield put(fetchEmailTemplatesSuccess(data));
	} catch (e: any) {
		yield put(fetchEmailTemplatesFailure(e));
	}
}

export function* fetchEmailThemes({ payload }) {
	try {
		const { data } = yield call(EmailTemplateApi.GetEmailThemes, payload);

		yield put(fetchEmailThemeSuccess(data));
	} catch (e: any) {
		yield put(fetchEmailThemeFailure(e));
	}
}

export function* getEmailTemplateById({ payload }) {
	try {
		const { data } = yield call(EmailTemplateApi.GetEmailTemplateById, payload);

		yield put(getEmailTemplateByIdSuccess(data));
	} catch (e: any) {
		yield put(getEmailTemplateByIdFailure(e));
	}
}

export function* updateEmailTemplate({ payload }): any {
	try {
		const { data } = yield call(EmailTemplateApi.UpdateEmailTemplate, payload);

		yield put(updateEmailTemplateSuccess(data));
	} catch (error: any) {
		yield put(updateEmailTemplateFailure({ error }));
	}
}

export function* removeEmailTemplate({ payload }): any {
	try {
		const { data } = yield call(
			EmailTemplateApi.RemoveEmailTemplate,
			payload.templateType,
			payload.emailTemplateId
		);

		yield put(removeEmailTemplateSuccess(data));
	} catch (error: any) {
		yield put(removeEmailTemplateFailure({ error }));
	}
}

export function* sendEmailTemplateTest({ payload }): any {
	try {
		const { data } = yield call(EmailTemplateApi.SendTestEmail, payload);

		yield put(sendTemplateTestSuccess(data));
	} catch (error: any) {
		yield put(sendTemplateTestFailure({ error }));
	}
}

export function* watchEmailTemplate() {
	return [
		yield takeLatest(addEmailTemplateStart, addEmailTemplate),
		yield takeLatest(updateEmailTemplateStart, updateEmailTemplate),
		yield takeLatest(removeEmailTemplateStart, removeEmailTemplate),
		yield takeLatest(fetchEmailTemplatesStart, fetchEmailTemplates),
		yield takeLatest(getEmailTemplateByIdStart, getEmailTemplateById),
		yield takeLatest(fetchEmailThemeStart, fetchEmailThemes),
		yield takeLatest(sendTemplateTestStart, sendEmailTemplateTest),
	];
}
