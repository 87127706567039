import { createSlice } from "@reduxjs/toolkit";

const productsSlice = createSlice({
	name: "PRODUCTS",
	initialState: {
		categories: [],
		currentPage: 1,
		totalPages: 1,
		pageSize: 10,
		isLoading: false,
		error: "",
	},
	reducers: {
		// PRODUCT CATEGORIES
		productCategoriesRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
			};
		},
		productCategoriesSuccess: (state, { payload }) => {
			return {
				...state,
				categories: payload.data.results,
				currentPage: payload.data.currentPage,
				totalPages: payload.data.totalPages,
				pageSize: payload.data.pageSize,
				isLoading: false,
			};
		},
		productCategoriesFailure: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				error: payload,
			};
		},
	},
});

export const {
	productCategoriesRequest,
	productCategoriesSuccess,
	productCategoriesFailure,
} = productsSlice.actions;

export default productsSlice.reducer;
