import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		listContainer: {
			maxHeight: "49vh",
			overflowY: "auto",
			overflowX: "hidden",
			"&::-webkit-scrollbar-track": {
				boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
				borderRadius: 0,
				backgroundColor: theme.palette.NEUTRAL020,
			},
			"&::-webkit-scrollbar": {
				width: 4,
				backgroundColor: "#F5F5F5",
			},
			"&::-webkit-scrollbar-thumb": {
				borderRadius: 10,
				boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
				backgroundColor: theme.palette.NEUTRAL100,
			},
		},
		sectionContainer: {
			margin: "0px 10px",
			borderBottom: `1px solid ${theme.palette.NEUTRAL040}`,
			paddingTop: 5,
			display: "flex",
			justifyContent: "space-between",
			color: theme.palette.NEUTRAL600,
			fontSize: theme.typography.pxToRem(14),
			"& strong": {
				color: theme.palette.NEUTRAL900,
				fontWeight: 500,
				borderBottom: "none",
			},
			"& button": {
				color: theme.palette.NEUTRAL800,
				backgroundColor: "transparent",
				border: "none",
				borderRadius: 4,
				padding: theme.spacing(1.5, 2),
				cursor: "pointer",
				width: "100%",
				textAlign: "left",
				"&:hover": {
					backgroundColor: theme.palette.NEUTRAL020,
				},
			},
			"& > div": {
				display: "flex",
				alignItems: "center",
			},
		},
		sectionContainerDisabled: {
			margin: "0px 10px",
			paddingTop: 5,
			display: "flex",
			justifyContent: "space-between",
			color: theme.palette.NEUTRAL600,
			fontSize: theme.typography.pxToRem(14),
			"& strong": {
				color: theme.palette.NEUTRAL900,
				fontWeight: 500,
				borderBottom: "none",
			},
			"& button": {
				color: theme.palette.NEUTRAL800,
				border: "none",
				borderRadius: 4,
				padding: theme.spacing(1.5, 2),
				width: "100%",
				textAlign: "left",
				backgroundColor: theme.palette.NEUTRAL020,
			},
			"& > div": {
				display: "flex",
				alignItems: "center",
			},
		},
		mainItem: {
			display: "flex",
			width: "100%",
		},
		textItem: {
			padding: theme.spacing(1.5, 2),
			width: "100%",
			fontSize: theme.typography.pxToRem(14),
			color: theme.palette.NEUTRAL900,
		},
		smallText: {
			fontSize: theme.typography.pxToRem(11),
		},
		titleText: {
			color: theme.palette.NEUTRAL600,
			fontSize: theme.typography.pxToRem(14),
			fontWeight: "bold",
		},
		searchInput: {
			margin: "0px 10px",
			border: `1px solid ${theme.palette.NEUTRAL000}`,
			borderRadius: 4,
			paddingLeft: theme.spacing(2),
			"& input": {
				width: "90%",
				border: "none",
				backgroundColor: "transparent",
				fontSize: theme.typography.pxToRem(14),
				paddingLeft: theme.spacing(0),
				"&:focus": {
					outline: "none",
				},
			},
		},
		searchIcon: {
			fontSize: theme.typography.pxToRem(20),
			marginRight: theme.spacing(3),
		},
		container: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			padding: theme.spacing(2, 4),
			border: `1px solid ${theme.palette.NEUTRAL000}`,
			margin: theme.spacing(0, 1),
			"&:hover": {
				border: `1px solid ${theme.palette.BLUE400}`,
				borderRadius: 6,
			},
			"&:hover, &$containerHover": {
				border: `1px solid ${theme.palette.BLUE400}`,
				boxShadow:
					"0 2px 14px 0 rgba(60,66,87,0.1), 0 1px 6px 0 rgba(0,0,0,0.07)",
				cursor: "pointer",
			},
			"&$containerHover:hover": {
				border: `1px solid ${theme.palette.RED400}`,
			},
			"&:hover $selectIcon svg, &$containerHover $selectIcon svg": {
				display: "block",
			},
		},
		containerHover: {
			cursor: "pointer",
		},
		left: {
			alignItems: "center",
		},
		right: {
			display: "flex",
			alignItems: "center",
			justifyContent: "end",
		},
		icon: {
			fontSize: theme.typography.pxToRem(25),
			width: 36,
			height: 36,
			color: theme.palette.NEUTRAL000,
			backgroundColor: theme.palette.NEUTRAL080,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			borderRadius: "50%",
			marginRight: theme.spacing(2),
		},
		title: {
			alignItems: "center",
			fontSize: theme.typography.pxToRem(14),
			fontWeight: 500,
			lineHeight: "24px",
			color: theme.palette.NEUTRAL700,
			"& p": {
				margin: 0,
			},
			"& span": {
				fontWeight: 400,
				color: theme.palette.NEUTRAL400,
			},
		},
		selectIcon: {
			boxSizing: "border-box",
			height: "20px",
			width: "20px",
			borderRadius: "50%",
			border: "1px solid #505F79",
			cursor: "pointer",
			display: "flex",
			alignItems: "center",
			"& svg": {
				display: "none",
			},
		},
		modalClassic: {
			paddingTop: 0,
		},
		modalClassicPaper: {
			maxHeight: "unset",
			height: "100%",

			"& > div > div:nth-child(2)": {
				maxHeight: "unset",
			},
		},
	};
});

export default useStyles;
