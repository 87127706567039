import { makeStyles, ThemeAgile } from "@agilekit/ui";
import { isDesktop } from "../../utils";

const useStyles = makeStyles((theme: ThemeAgile) => ({
	modal: {
		display: "flex",
		alignItems: "baseline",
		justifyContent: "center",
		paddingTop: theme.spacing(8),
	},
	container: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: 4,
		boxShadow:
			"rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 1px, rgba(9, 30, 66, 0.31) 0px 0px 20px -6px",
		maxHeight: "80vh",
		[theme.breakpoints.down("xs")]: {
			maxHeight: "none",
		},
		...(isDesktop()
			? {
					[theme.breakpoints.down(1024)]: {
						maxWidth: "90vw",
					},
			  }
			: {}),
	},
	paperDrawer: {
		maxHeight: "none",
		height: "100%",
		borderRadius: 0,
	},
	modalTitle: {
		fontSize: theme.typography.pxToRem(16),
		lineHeight: theme.typography.pxToRem(16),
		fontWeight: 500,
		color: theme.palette.NEUTRAL900,
		margin: theme.spacing(0),
	},
	modalCloseIcon: {
		fontSize: theme.typography.pxToRem(16),
		lineHeight: theme.typography.pxToRem(16),
		color: theme.palette.NEUTRAL900,
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.NEUTRAL500,
		},
	},
	modalHeader: {
		padding: theme.spacing(6),
		borderBottom: `1px solid ${theme.palette.NEUTRAL030}`,
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		flex: "0 0 auto",
	},
	modalBody: {
		maxHeight: "65vh",
		overflowY: "auto",
		flex: "1 1 auto",
		[theme.breakpoints.down("xs")]: {
			maxHeight: "80vh",
		},
	},
	modalFooter: {
		backgroundColor: theme.palette.NEUTRAL020,
		padding: theme.spacing(6),
		borderRadius: "0 0 4px 4px",
		flex: "0 0 auto",
	},
}));

export default useStyles;
