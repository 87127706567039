import { Modal, Loader, Box } from "@agilekit/ui";
import React from "react";

const LoadingModal = () => {
	const closeModal = () => {};

	return (
		<div>
			<Modal open={true} onClose={closeModal} size="small">
				<Box display="flex" alignItems="center" justifyContent="center">
					<Loader type="ellipsis" />
				</Box>
			</Modal>
		</div>
	);
};

export default LoadingModal;
