import { AxiosPromise } from "axios";

// NPM PACKAGES
import Http from "../../../utils/restClient";

export default class ProductsApi {
	public static GetProductCategories(payload: any) {
		return Http.axios().get(
			`/api/v1/products/categories?pageNumber=${payload.page}&pageSize=50`
		) as AxiosPromise<any>;
	}
}
