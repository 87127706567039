const posSettingsReducer = () => {
	return {
		setEnvironmentHasPosProfiles: (state, { payload }) => {
			return {
				...state,
				facilitySettings: {
					...state.facilitySettings,
					pointOfSale: {
						hasSetProfiles: payload,
					},
				},
			};
		},
	};
};

export default posSettingsReducer;
