import { takeLatest, call, put, takeEvery } from "redux-saga/effects";
import {
	addNewProfileStart,
	addNewProfileCompleted,
	addNewProfileFailure,
	addNewScreenButtonCompleted,
	addNewScreenButtonFail,
	addNewScreenButtonStart,
	deleteProfileCompleted,
	deleteProfileFailure,
	deleteProfileStart,
	deleteScreenButtonCompleted,
	deleteScreenButtonFail,
	deleteScreenButtonStart,
	deleteScreenCompleted,
	deleteScreenFailure,
	deleteScreenStart,
	duplicateProfileCompleted,
	duplicateProfileFailure,
	duplicateProfileStart,
	fetchDiscountsCompleted,
	fetchDiscountsFailed,
	fetchDiscountsStarted,
	fetchGiftCardComplete,
	fetchGiftCardFail,
	fetchGiftCardStart,
	fetchProductsComplete,
	fetchProductsFail,
	fetchProductsStart,
	fetchProfileDetailsCompleted,
	fetchProfileDetailsFailure,
	fetchProfileDetailsStart,
	fetchProfilesComplete,
	fetchProfilesFail,
	fetchProfilesStart,
	fetchScreenCompleted,
	fetchScreenFailure,
	fetchScreenStart,
	getLookupPrepaiFail,
	getLookupPrepaiStart,
	getLookupPrepaiSuccess,
	reloadGiftCardFail,
	reloadGiftCardStart,
	reloadGiftCardSuccess,
	saveChangeButtonSlotCompleted,
	saveChangeButtonSlotFail,
	saveChangeButtonSlotStart,
	saveScreenCompleted,
	saveScreenFailure,
	saveScreenPositionCompleted,
	saveScreenPositionFailure,
	saveScreenPositionStart,
	saveScreenStart,
	sendEmailGiftCardFail,
	sendEmailGiftCardStart,
	sendEmailGiftCardSuccess,
	updateButtonImageCompleted,
	updateButtonImageFail,
	updateButtonImageStart,
	updatedProfileCompleted,
	updateProfileFailure,
	updateProfileStart,
	updateScreenButtonCompleted,
	updateScreenButtonFail,
	updateScreenButtonStart,
	validateButtonComplete,
	validateButtonFail,
	validateButtonStart,
	validatePosItemFail,
	validatePosItemStart,
	validatePosItemSuccess,
	fetchInterCardStart,
	fetchInterCardComplete,
	fetchInterCardFail,
	loadInterCardStart,
	loadInterCardSuccess,
	loadInterCardFail,
	activateGiftCardSuccess,
	activateGiftCardFail,
	activateGiftCardStart,
} from "./pointOfSale.slice";
import PointOfSaleApi from "./pointOfSale.api";
import { getLastActivePOSProfileId } from "./pointOfSale.helpers";

export function* getPOSProfiles({ payload }): any {
	try {
		const profileID = getLastActivePOSProfileId(payload.facilityId);

		const response = yield call(PointOfSaleApi.GetProfiles);
		let posProfileDetails = null;

		if (profileID) {
			const responseProfileDetail = yield call(
				PointOfSaleApi.GetProfileDetail,
				profileID
			);

			posProfileDetails = responseProfileDetail.data.results.posProfile;
		}

		yield put(
			fetchProfilesComplete({
				posProfiles: response.data.results.posProfiles,
				profileDetails: profileID ? posProfileDetails : null,
				facilityId: payload.facilityId,
			})
		);
	} catch (error: any) {
		yield put(fetchProfilesFail({ error }));
	}
}

export function* getPOSProfileDetail({ payload }): any {
	try {
		const { keepSelectedScreen } = payload;

		const response = yield call(
			PointOfSaleApi.GetProfileDetail,
			payload.profileIdentifier
		);

		// eslint-disable-next-line @typescript-eslint/dot-notation
		response.data["keepSelectedScreen"] = keepSelectedScreen;

		if (response.data.ok) {
			yield put(
				fetchProfileDetailsCompleted({
					data: response.data,
					facilityId: payload.facilityId,
				})
			);
		} else {
			yield put(
				fetchProfileDetailsFailure({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(fetchProfileDetailsFailure({ error }));
	}
}

export function* addPosProfile({ payload }): any {
	try {
		const { hasSetProfiles } = payload;
		const response = yield call(PointOfSaleApi.AddProfile, payload);

		// eslint-disable-next-line @typescript-eslint/dot-notation
		response.data["hasSetProfiles"] = hasSetProfiles;

		if (response.data.ok) {
			yield put(addNewProfileCompleted({ data: response.data }));
		} else {
			yield put(
				addNewProfileFailure({ error: Object.values(response.data.errors)[0] })
			);
		}
	} catch (error: any) {
		yield put(addNewProfileFailure({ error }));
	}
}

export function* updatePosProfile({ payload }): any {
	try {
		const response = yield call(PointOfSaleApi.UpdateProfile, payload);

		if (response.data.ok) {
			yield put(updatedProfileCompleted({ data: response.data }));
		} else {
			yield put(
				updateProfileFailure({ error: Object.values(response.data.errors)[0] })
			);
		}
	} catch (error: any) {
		yield put(updateProfileFailure({ error }));
	}
}

export function* deletePosProfile({ payload }): any {
	try {
		const response = yield call(PointOfSaleApi.DeleteProfile, payload.identifier);
		if (response.data.ok) {
			yield put(
				deleteProfileCompleted({
					data: response.data,
					facilityId: payload.facilityId,
				})
			);
		} else {
			yield put(
				deleteProfileFailure({ error: Object.values(response.data.errors)[0] })
			);
		}
	} catch (error: any) {
		yield put(deleteProfileFailure({ error }));
	}
}

export function* duplicatePosProfile({ payload }): any {
	try {
		const response = yield call(PointOfSaleApi.DuplicateProfile, payload.identifier);
		if (response.data.ok) {
			yield put(duplicateProfileCompleted({ data: response.data }));
		} else {
			yield put(
				duplicateProfileFailure({ error: Object.values(response.data.errors)[0] })
			);
		}
	} catch (error: any) {
		yield put(duplicateProfileFailure({ error }));
	}
}

export function* savePosScreen({ payload }): any {
	try {
		const response = yield call(PointOfSaleApi.SaveScreen, payload);
		if (response.data.ok) {
			yield put(saveScreenCompleted({ data: response.data }));
		} else {
			yield put(
				saveScreenFailure({ error: Object.values(response.data.errors)[0] })
			);
		}
	} catch (error: any) {
		yield put(saveScreenFailure({ error }));
	}
}

export function* savePosScreenPosition({ payload }): any {
	try {
		const response = yield call(PointOfSaleApi.SaveScreenPositions, payload);
		if (response.data.ok) {
			yield put(saveScreenPositionCompleted({ data: response.data }));
		} else {
			yield put(
				saveScreenPositionFailure({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(saveScreenPositionFailure({ error }));
	}
}

export function* deletePosScreen({ payload }): any {
	try {
		const response = yield call(PointOfSaleApi.DeleteScreen, payload);
		if (response.data.ok) {
			yield put(deleteScreenCompleted({ data: response.data }));
		} else {
			yield put(
				deleteScreenFailure({ error: Object.values(response.data.errors)[0] })
			);
		}
	} catch (error: any) {
		yield put(deleteScreenFailure({ error }));
	}
}

export function* getPosScreen({ payload }): any {
	try {
		const response = yield call(PointOfSaleApi.GetScreen, payload);
		if (response.data.ok) {
			yield put(fetchScreenCompleted({ data: response.data }));
		} else {
			yield put(
				fetchScreenFailure({ error: Object.values(response.data.errors)[0] })
			);
		}
	} catch (error: any) {
		yield put(fetchScreenFailure({ error }));
	}
}

export function* getButtonProducts(): any {
	try {
		const response = yield call(PointOfSaleApi.GetProducts);
		if (response.data.ok) {
			yield put(fetchProductsComplete({ data: response.data }));
		} else {
			yield put(
				fetchProductsFail({ error: Object.values(response.data.errors)[0] })
			);
		}
	} catch (error: any) {
		yield put(fetchProductsFail({ error }));
	}
}

export function* saveScreenButton({ payload }): any {
	try {
		const { screenButton, screenIdentifier } = payload;
		const response = yield call(
			PointOfSaleApi.SaveScreenButton,
			screenButton,
			screenIdentifier
		);
		if (response.data.ok) {
			yield put(addNewScreenButtonCompleted({ data: response.data }));
		} else {
			yield put(
				addNewScreenButtonFail({ error: Object.values(response.data.errors)[0] })
			);
		}
	} catch (error: any) {
		yield put(addNewScreenButtonFail({ error }));
	}
}

export function* updateScreenButton({ payload }): any {
	try {
		const { screenButton, hasRemovedImage, screenIdentifier } = payload;
		const response = yield call(
			PointOfSaleApi.UpdateScreenButton,
			screenButton,
			hasRemovedImage,
			screenIdentifier
		);
		if (response.data.ok) {
			yield put(updateScreenButtonCompleted({ data: response.data }));
		} else {
			yield put(
				updateScreenButtonFail({ error: Object.values(response.data.errors)[0] })
			);
		}
	} catch (error: any) {
		yield put(updateScreenButtonFail({ error }));
	}
}

export function* updateScreenButtonSlot({ payload }): any {
	try {
		const { screenButton, screenIdentifier } = payload;
		const response = yield call(
			PointOfSaleApi.UpdateScreenButton,
			screenButton,
			false,
			screenIdentifier
		);
		if (response.data.ok) {
			yield put(saveChangeButtonSlotCompleted({ data: response.data }));
		} else {
			yield put(
				saveChangeButtonSlotFail({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(saveChangeButtonSlotFail({ error }));
	}
}

export function* updateScreenButtonImage({ payload }): any {
	try {
		const { buttonId, blobName } = payload;
		const response = yield call(
			PointOfSaleApi.UpdateScreenButtonImage,
			buttonId,
			blobName
		);
		if (response.data.ok) {
			yield put(updateButtonImageCompleted({ data: response.data }));
		} else {
			yield put(
				updateButtonImageFail({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(updateButtonImageFail({ error }));
	}
}

export function* deleteScreenButton({ payload }): any {
	try {
		const { screenButtonId, screenIdentifier } = payload;

		const response = yield call(
			PointOfSaleApi.DeleteScreenButton,
			screenButtonId,
			screenIdentifier
		);

		yield put(deleteScreenButtonCompleted({ data: response }));
	} catch (error: any) {
		yield put(deleteScreenButtonFail({ error }));
	}
}

export function* getDiscounts(): any {
	try {
		const response = yield call(PointOfSaleApi.GetDiscounts);
		if (response.data.ok) {
			yield put(fetchDiscountsCompleted({ data: response.data }));
		} else {
			yield put(
				fetchDiscountsFailed({ error: Object.values(response.data.errors)[0] })
			);
		}
	} catch (error: any) {
		yield put(fetchDiscountsFailed({ error }));
	}
}

export function* GetGiftCard({ payload }): any {
	try {
		const { orderItemId } = payload;
		const response = yield call(PointOfSaleApi.GetGiftCard, orderItemId);
		yield put(fetchGiftCardComplete({ data: response.data }));
	} catch (error: any) {
		yield put(fetchGiftCardFail({ error }));
	}
}

export function* addEmailGiftCard({ payload }): any {
	try {
		const { orderItemId, emailAddress } = payload;
		const response = yield call(
			PointOfSaleApi.AddEmailGiftCard,
			orderItemId,
			emailAddress
		);
		yield put(sendEmailGiftCardSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(sendEmailGiftCardFail({ error }));
	}
}

export function* activateGiftCard({ payload }): any {
	try {
		const { orderItemId } = payload;
		const response = yield call(
			PointOfSaleApi.ActivateGiftCard,
			payload,
			orderItemId
		);

		if (response.data.ok) {
			yield put(activateGiftCardSuccess({ data: response.data }));
		} else {
			yield put(activateGiftCardFail(response.data.message));
		}
	} catch (error: any) {
		yield put(activateGiftCardFail({ error }));
	}
}

export function* reloadGiftCard({ payload }): any {
	try {
		const { orderItemId } = payload;
		const response = yield call(PointOfSaleApi.ReloadGiftCard, payload, orderItemId);

		if (response.data.ok) {
			yield put(reloadGiftCardSuccess({ data: response.data }));
		} else {
			yield put(reloadGiftCardFail(response.data.message));
		}
	} catch (error: any) {
		yield put(reloadGiftCardFail({ error }));
	}
}

export function* ValidatePosPackage({ payload }): any {
	try {
		const { productId } = payload;
		const response = yield call(PointOfSaleApi.ValidatePosPackage, productId);
		yield put(validatePosItemSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(validatePosItemFail({ error }));
	}
}

export function* GetlookupPrepaid({ payload }): any {
	try {
		const { cardNumber, pcPayChecked } = payload;
		const response = yield call(
			PointOfSaleApi.GetlookupPrepaid,
			cardNumber,
			pcPayChecked
		);

		yield put(getLookupPrepaiSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(getLookupPrepaiFail({ error }));
	}
}

export function* GetValidadeButton({ payload }): any {
	try {
		const { screen, productId } = payload;
		const response = yield call(PointOfSaleApi.GetValidadeButton, screen, productId);

		yield put(validateButtonComplete(response));
	} catch (error: any) {
		yield put(validateButtonFail(payload));
	}
}

export function* GetInterCard(orderItemId): any {
	try {
		const response = yield call(PointOfSaleApi.GetInterCard, orderItemId.payload);

		yield put(fetchInterCardComplete({ data: response.data }));
	} catch (error: any) {
		yield put(fetchInterCardFail({ error }));
	}
}

export function* loadInterCard({ payload }): any {
	try {
		const { orderItemId } = payload;
		const response = yield call(PointOfSaleApi.LoadInterCard, payload, orderItemId);

		yield put(loadInterCardSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(loadInterCardFail({ error }));
	}
}

export function* watchPointOfSale() {
	return [
		yield takeLatest(fetchProfilesStart, getPOSProfiles),
		yield takeLatest(addNewProfileStart, addPosProfile),
		yield takeLatest(updateProfileStart, updatePosProfile),
		yield takeLatest(deleteProfileStart, deletePosProfile),
		yield takeLatest(duplicateProfileStart, duplicatePosProfile),
		yield takeLatest(saveScreenStart, savePosScreen),
		yield takeLatest(saveScreenPositionStart, savePosScreenPosition),
		yield takeLatest(deleteScreenStart, deletePosScreen),
		yield takeLatest(fetchScreenStart, getPosScreen),
		yield takeLatest(fetchProfileDetailsStart, getPOSProfileDetail),
		yield takeLatest(fetchProductsStart, getButtonProducts),
		yield takeLatest(fetchDiscountsStarted, getDiscounts),
		yield takeLatest(addNewScreenButtonStart, saveScreenButton),
		yield takeLatest(updateScreenButtonStart, updateScreenButton),
		yield takeLatest(updateButtonImageStart, updateScreenButtonImage),
		yield takeLatest(saveChangeButtonSlotStart, updateScreenButtonSlot),
		yield takeLatest(deleteScreenButtonStart, deleteScreenButton),
		yield takeLatest(fetchGiftCardStart, GetGiftCard),
		yield takeLatest(sendEmailGiftCardStart, addEmailGiftCard),
		yield takeLatest(activateGiftCardStart, activateGiftCard),
		yield takeLatest(validatePosItemStart, ValidatePosPackage),
		yield takeLatest(getLookupPrepaiStart, GetlookupPrepaid),
		yield takeLatest(validateButtonStart, GetValidadeButton),
		yield takeLatest(reloadGiftCardStart, reloadGiftCard),
		yield takeEvery(fetchInterCardStart, GetInterCard),
		yield takeLatest(loadInterCardStart, loadInterCard),
	];
}
