import { SagaIterator } from "redux-saga";

import { all } from "redux-saga/effects";
import { watchAuth } from "./modules/auth/auth.saga";
// CUSTOMERS
import { watchCustomers } from "./modules/customers/customers.saga";
// CUSTOMER
import { watchCustomer } from "./modules/customer/customer.saga";
// WAIVER
import { watchWaiver } from "./modules/waiver/waiver.saga";
// PRODUCTS
import { watchProductCategories } from "./modules/productCategories/productCategories.saga";
import { watchProducts } from "./modules/products/products.saga";
// CUSTOMER SEARCH
import { watchCustomerSearch } from "./modules/customerSearch/customerSearch.saga";
// POINT OF SALE
import { watchPointOfSale } from "./modules/pointOfSale/pointOfSale.saga";
// ENVIROMENT
import { watchEnviroment } from "./modules/enviroment/enviroment.saga";
// ACTIVITY LOG
import { watchActivityLog } from "./modules/activityLog/activityLog.saga";
// IMAGE RESOURCES
import { watchImageResources } from "./modules/imagesResources/imageResources.saga";
// ORDER
import { watchOrder } from "./modules/order/order.saga";
// MEMBERSHIPS
import { watchMemberships } from "./modules/memberships/memberships.saga";
// CUSTOMER MEMBERSHIP
import { watchCustomerMembership } from "./modules/customerMembership/customerMembership.saga";

import { watchSchedule } from "./modules/partySchedule/partySchedule.saga";

import { watchScheduleCustomer } from "./modules/scheduleCustomer/scheduleCustomer.saga";
// EMAILLOGS
import { watchEmailLog } from "./modules/emailLog/emailLog.saga";

import { watchEmailTemplate } from "./modules/emailTemplate/emailTemplate.saga";

import { watchTaxes } from "./modules/taxes/taxes.saga";

import { watchPayments } from "./modules/payments/payments.saga";
import { watchHeaderNav } from "./modules/headerNav/headerNav.saga";
import { watchUser } from "./modules/user/user.saga";
import { watchAgileTerminal } from "./modules/agileTerminal/agileTerminal.saga";

export default function* rootSaga(): SagaIterator {
	yield all([
		// AUTH
		...watchAuth(),

		// CUSTOMERS
		...watchCustomers(),

		// CUSTOMER
		...watchCustomer(),

		// PRODUCTCATEGORIES
		...watchProductCategories(),

		// PRODUCTS
		...watchProducts(),

		// POINT OF SALE
		...watchPointOfSale(),

		// WAIVER
		...watchWaiver(),

		// CUSTOMER SEARCH
		...watchCustomerSearch(),

		// ENVIROMENT
		...watchEnviroment(),

		// ACTIVITY LOG
		...watchActivityLog(),

		// IMAGE RESOURCES
		...watchImageResources(),

		// ORDER
		...watchOrder(),

		// CUSTOMER MEMBERSHIP
		...watchCustomerMembership(),

		// PARTY SCHEDULE
		...watchSchedule(),

		// MEMBERSHIPS
		...watchMemberships(),

		// SCHEDULE CUSTOMERS
		...watchScheduleCustomer(),

		// EMAIL TEMPLATE
		...watchEmailTemplate(),

		// EMAIL LOG
		...watchEmailLog(),

		// TAXES
		...watchTaxes(),

		// PAYMENTS
		...watchPayments(),

		// HEADER NAV
		...watchHeaderNav(),

		// USER
		...watchUser(),

		// AGILE TERMINAL
		...watchAgileTerminal(),
	]);
}
