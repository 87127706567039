import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		helpContainer: {
			position: "relative",
		},
		help: {},
		helpOpen: {
			"& $helpInput": {
				backgroundColor: theme.palette.NEUTRAL000,
				boxShadow: theme.shadows[1],
				"& input": {
					color: theme.palette.NEUTRAL800,
					"&::placeholder": {
						color: theme.palette.NEUTRAL080,
					},
				},
			},
			"& $helpIcon": {
				color: theme.palette.NEUTRAL800,
			},
		},
		helpIcon: {
			display: "flex",
		},
		helpPanel: {},
		sectionRightButton: {
			color: theme.palette.NEUTRAL000,
			backgroundColor: "transparent",
			border: "none",
			borderRadius: 4,
			padding: theme.spacing(1.5, 2),
			cursor: "pointer",
			"&:hover": {
				backgroundColor: theme.palette.NEUTRAL600,
			},
		},
		helpCountContainer: {
			position: "absolute",
			marginLeft: 23,
			marginTop: 3,
			color: theme.palette.NEUTRAL000,
		},
		helpCount: {
			display: "inline-flex",
			borderRadius: "50%",
			background: theme.palette.RED400,
			paddingLeft: 4,
			width: 16,
			height: 16,
		},
	};
});

export default useStyles;
