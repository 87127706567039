const avatarColor = (theme, gender) => {
	switch (gender) {
		case "m":
			return theme.palette.BLUE400;
		case "f":
			return "#E4228E";
		default:
			return theme.palette.NEUTRAL070;
	}
};

export default avatarColor;
