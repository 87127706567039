import React, { useState, useEffect } from "react";
import classnames from "classnames";
import ChevronDown from "mdi-material-ui/ChevronDown";
import ChevronUp from "mdi-material-ui/ChevronUp";

// ASSETS
import useStyles from "./SectionTitle.styles";

const SectionTitle = ({ title, children, icon, open = true, error = false }) => {
	// HOOKS
	const classes = useStyles();
	const [collapsed, setCollapsed] = useState(!open);

	useEffect(() => {
		setCollapsed(!open);
	}, [open]);

	// EVENTS
	const handleCollapse = () => {
		setCollapsed(!collapsed);
	};

	// STYLES
	const cssContainer = classnames(classes.container, {
		[classes.collpased]: collapsed,
		[classes.error]: error,
	});

	return (
		<div className={cssContainer}>
			<div className={classes.header} onClick={handleCollapse} role="presentation">
				<div className={classes.headerTitle}>
					<div className={classes.icon}>{icon}</div>
					<p className={classes.title}>{title}</p>
				</div>
				<div>{collapsed ? <ChevronDown /> : <ChevronUp />}</div>
			</div>
			<div className={classes.content}>{children}</div>
		</div>
	);
};

export default SectionTitle;
