const waiversReducer = () => {
	return {
		fetchCustomerWaiversStart: (state, { payload }) => {
			return {
				...state,
				waiversTab: {
					...state.waiversTab,
					isLoading: true,
					pageSize: payload.pageSize,
					pageNumber: payload.pageNumber,
				},
			};
		},
		fetchCustomerWaiversComplete: (state, { payload }) => {
			return {
				...state,
				waiversTab: {
					...state.waiversTab,
					isLoading: false,
					waivers: payload.results,
					totalItems: payload.totalItems,
					totalPages: payload.totalPages,
					firstRowOnPage: payload.firstRowOnPage,
					lastRowOnPage: payload.lastRowOnPage,
				},
			};
		},
		fetchCustomerWaiversFail: (state, { payload }) => {
			return {
				...state,
				isWaiversLoading: false,
				error: payload,
			};
		},
		fetchDependentWaiversStart: (state, { payload }) => {
			return {
				...state,
				waiversTab: {
					...state.waiversTab,
					isLoading: true,
					pageSize: payload.pageSize,
					pageNumber: payload.pageNumber,
				},
			};
		},
		fetchDependentWaiversComplete: (state, { payload }) => {
			return {
				...state,
				waiversTab: {
					...state.waiversTab,
					isLoading: false,
					waivers: payload.results,
					totalItems: payload.totalItems,
					totalPages: payload.totalPages,
					firstRowOnPage: payload.firstRowOnPage,
					lastRowOnPage: payload.lastRowOnPage,
				},
			};
		},
		fetchDependentWaiversFail: (state, { payload }) => {
			return {
				...state,
				isWaiversLoading: false,
				error: payload,
			};
		},
	};
};

export default waiversReducer;
