import { takeLatest, put, call } from "redux-saga/effects";
import {
	fetchCustomerStart,
	fetchCustomerComplete,
	fetchCustomerFail,
	fetchCustomerWaiverStart,
	fetchCustomerWaiverComplete,
	fetchCustomerWaiverFail,
	fetchCustomerWaiversStart,
	fetchCustomerWaiversComplete,
	fetchCustomerWaiversFail,
	fetchDependentWaiversStart,
	fetchDependentWaiversComplete,
	fetchDependentWaiversFail,
	addNote,
	addNoteSuccess,
	addNotesFailure,
	updateNote,
	updateNoteSuccess,
	updateNotesFailure,
	deleteNote,
	deleteNoteSuccess,
	deleteNotesFailure,
	fetchCustomerNotesStart,
	fetchCustomerNotesCompleted,
	fetchCustomerNotesFail,
	addDependentToAccountStart,
	addDependentToAccountComplete,
	addDependentToAccountFail,
	addOrganizationStart,
	addOrganizationComplete,
	addOrganizationFail,
	addOrganizationToAccountComplete,
	fetchCustomerLoyaltyStart,
	fetchCustomerLoyaltyComplete,
	fetchCustomerLoyaltyFail,
	addCustomerStart,
	addCustomerFail,
	addCustomerSuccess,
	addCustomerAssociatedSuccess,
	addCustomerAssociatedToOrganizationSuccess,
	updateDependentStart,
	updateDependentComplete,
	updateDependentFail,
	updateCustomerStart,
	updateCustomerSuccess,
	updateCustomerPictureStart,
	updateCustomerPictureSuccess,
	updateCustomerPictureFail,
	updateCustomerStatusStart,
	updateCustomerStatusSuccess,
	updateCustomerStatusFail,
	addLinkedAccountsStart,
	addLinkedAccountsSuccess,
	addLinkedAccountsFail,
	deleteLinkedAccountsStart,
	deleteLinkedAccountsSuccess,
	deleteLinkedAccountsFail,
	checkEmailDuplicatedStart,
	checkEmailDuplicatedSuccess,
	checkEmailDuplicatedFail,
	addLabelStart,
	addLabelSuccess,
	addLabelFailure,
	removeLabelFromCustomerFailure,
	removeLabelFromCustomerSuccess,
	removeLabelFromCustomerStart,
	fetchLabelsStart,
	fetchLabelsSuccess,
	fetchLabelsFailure,
	removeLabelsStart,
	removeLabelsSuccess,
	removeLabelsFailure,
	fetchLabelRelationsStart,
	fetchLabelRelationsFailure,
	fetchLabelRelationsSuccess,
	addLabelToCustomerStart,
	addLabelToCustomerSuccess,
	addLabelToCustomerFailure,
	updateLabelStart,
	updateLabelFailure,
	updateLabelSuccess,
	confirmCustomerWaiverFail,
	confirmCustomerWaiverStart,
	confirmCustomerWaiverSuccess,
	expireCustomerWaiverFail,
	expireCustomerWaiverStart,
	expireCustomerWaiverSuccess,
	signCustomerWaiverStart,
	signWaiverCustomerSuccess,
	signWaiverCustomerFailure,
	fetchOrganizationComplete,
	fetchOrganizationFail,
	fetchOrganizationStart,
	addOrganizationContactStart,
	addOrganizationContactComplete,
	addOrganizationContactFail,
	removeOrganizationContactComplete,
	removeOrganizationContactFail,
	removeOrganizationContactStart,
	updateOrganizationStart,
	updateOrganizationComplete,
	updateOrganizationFail,
	updateCustomerAssociatedSuccess,
	addCustomerAssociatedFromSearchSuccess,
	addDependentToAccountFromSearchComplete,
	updateCustomerAsssociatedFromSearchSuccess,
	updateCustomerAssociatedToOrganizationSuccess,
	checkOrganizationDuplicatedStart,
	checkOrganizationDuplicatedSuccess,
	checkOrganizationDuplicatedFail,
	fetchOrganizationContactsComplete,
	fetchOrganizationContactsFail,
	fetchOrganizationContactsStart,
	addOrganizationLinkedAccountsSuccess,
	addOrganizationLinkedAccountsFail,
	addOrganizationLinkedAccountsStart,
	deleteOrganizationLinkedAccountsFail,
	deleteOrganizationLinkedAccountsStart,
	deleteOrganizationLinkedAccountsSuccess,
	addDependentToOrganizationFromSearchComplete,
	updateOrganizationStatusFail,
	updateOrganizationStatusStart,
	updateOrganizationStatusSuccess,
} from "./customer.slice";
import CustomerAPI from "./customer.api";
import store from "../../configureStore";

export function* fetchCustomer({ payload }) {
	try {
		const { data } = yield call(CustomerAPI.fetchCustomer, payload.accountId);

		yield put(fetchCustomerComplete(data));
	} catch (e: any) {
		yield put(fetchCustomerFail(e.message));
	}
}

export function* fetchCustomerWaiver({ payload }) {
	try {
		const { data } = yield call(
			CustomerAPI.fetchCustomerWaiver,
			payload.accountId,
			payload.customerWaiverId
		);

		yield put(fetchCustomerWaiverComplete(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(fetchCustomerWaiverFail(e.message));
		}
	}
}

export function* fetchCustomerWaivers({ payload }) {
	try {
		const { accountId, pageSize, pageNumber } = payload;

		const { data } = yield call(
			CustomerAPI.fetchCustomerWaivers,
			accountId,
			pageSize,
			pageNumber
		);

		yield put(fetchCustomerWaiversComplete(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(fetchCustomerWaiversFail(e.message));
		}
	}
}

export function* fetchDependentsWaivers({ payload }) {
	try {
		const { accountId, pageSize, pageNumber } = payload;

		const { data } = yield call(
			CustomerAPI.fetchDependentWaivers,
			accountId,
			pageSize,
			pageNumber
		);

		yield put(fetchDependentWaiversComplete(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(fetchDependentWaiversFail(e.message));
		}
	}
}

export function* addNoteRequest({ payload }) {
	try {
		const { data } = yield call(
			CustomerAPI.addNote,
			payload.accountId,
			payload.noteModel
		);

		yield put(addNoteSuccess(data));
	} catch (e: any) {
		yield put(addNotesFailure({ error: e }));
	}
}

export function* updateNoteRequest({ payload }) {
	try {
		const { data } = yield call(CustomerAPI.updateNote, payload);
		yield put(updateNoteSuccess(data));
	} catch (e: any) {
		yield put(updateNotesFailure({ error: e }));
	}
}

export function* deleteNoteRequest({ payload }) {
	try {
		yield call(CustomerAPI.deleteNote, payload);
		yield put(deleteNoteSuccess(payload));
	} catch (e: any) {
		yield put(deleteNotesFailure({ error: e }));
	}
}

export function* fetchCustomerNotes({ payload }) {
	try {
		const { data } = yield call(
			CustomerAPI.fetchCustomerNotes,
			payload.accountId,
			payload.noteType
		);

		yield put(fetchCustomerNotesCompleted(data));
	} catch (e: any) {
		yield put(fetchCustomerNotesFail({ error: e }));
	}
}

export function* fetchOrganization({ payload }) {
	try {
		const { data } = yield call(CustomerAPI.fetchOrganization, payload.accountId);

		yield put(fetchOrganizationComplete(data));
	} catch (e: any) {
		yield put(fetchOrganizationFail(e.message));
	}
}

export function* addDependentToAccount({ payload }) {
	try {
		const { fromSearch, customer, fromOrganization } = payload;
		const { data } = yield call(CustomerAPI.addDependentToAccount, customer);

		if (fromSearch && !fromOrganization) {
			yield put(addDependentToAccountFromSearchComplete(data));
		} else if (fromOrganization) {
			yield put(addDependentToOrganizationFromSearchComplete(data));
		} else {
			yield put(addDependentToAccountComplete(data));
		}
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(addDependentToAccountFail(e.message));
		}
	}
}

export function* addOrganization({ payload }) {
	try {
		const { isAssociating, organization } = payload;
		const { data } = yield call(CustomerAPI.addOrganization, organization);

		if (isAssociating) {
			yield put(addOrganizationToAccountComplete(data));
		} else {
			yield put(addOrganizationComplete(data));
		}
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(addOrganizationFail(e.message));
		}
	}
}

export function* fetchCustomerLoyalty({ payload }) {
	try {
		const queryString = buildCustomersQueryString();

		const { data } = yield call(
			CustomerAPI.fetchCustomerLoyalty,
			payload.accountId,
			queryString
		);

		yield put(fetchCustomerLoyaltyComplete({ data }));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(fetchCustomerLoyaltyFail(e.message));
		}
	}
}

export function* addCustomer({ payload }) {
	try {
		const { isAssociating, customer, fromSearch, fromOrganization } = payload;
		const { data } = yield call(CustomerAPI.addCustomer, customer);

		if (isAssociating) {
			if (fromSearch) {
				yield put(addCustomerAssociatedFromSearchSuccess(data));
			} else if (fromOrganization) {
				yield put(addCustomerAssociatedToOrganizationSuccess(data));
			} else {
				yield put(addCustomerAssociatedSuccess(data));
			}
		} else {
			yield put(addCustomerSuccess(data));
		}
	} catch (e: any) {
		yield put(addCustomerFail(e.message));
	}
}

export function* updateDependent({ payload }) {
	try {
		const response = yield call(CustomerAPI.updateDependent, payload.customer);

		yield put(updateDependentComplete(response.data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "response" in e) {
			if (
				typeof e.response === "object" &&
				e.response !== null &&
				"status" in e.response
			) {
				if (e.response.status === 406) {
					if (
						typeof e.response === "object" &&
						e.response !== null &&
						"data" in e.response
					) {
						yield put(updateDependentFail(e.response.data));
					}
				}
			}
		} else if (typeof e === "object" && e !== null && "message" in e) {
			yield put(updateDependentFail(e.message));
		}
	}
}

export function* updateCustomer({ payload }) {
	try {
		const { isAssociating, customer, fromSearch, fromOrganization } = payload;
		const { data } = yield call(CustomerAPI.updateCustomer, customer);

		if (isAssociating) {
			if (fromSearch) {
				yield put(updateCustomerAsssociatedFromSearchSuccess(data.customer));
			} else if (fromOrganization) {
				yield put(updateCustomerAssociatedToOrganizationSuccess(data.customer));
			} else {
				yield put(updateCustomerAssociatedSuccess(data.customer));
			}
		} else {
			yield put(updateCustomerSuccess(data));
		}
	} catch (e: any) {
		if (e.response.status === 406) {
			yield put(updateDependentFail(e.response.data));
		} else {
			yield put(updateDependentFail(e.message));
		}
	}
}

export function* updateCustomerStatus({ payload }) {
	try {
		const { data } = yield call(CustomerAPI.updateCustomerStatus, payload.customer);

		yield put(updateCustomerStatusSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(updateCustomerStatusFail(e.message));
		}
	}
}

export function* updateOrganizationStatus({ payload }) {
	try {
		const { data } = yield call(
			CustomerAPI.updateOrganizationStatus,
			payload.customer
		);

		yield put(updateOrganizationStatusSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(updateOrganizationStatusFail(e.message));
		}
	}
}

export function* updateCustomerPicture({ payload }) {
	try {
		const { data } = yield call(CustomerAPI.updateCustomerPicutre, payload.customer);

		yield put(updateCustomerPictureSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(updateCustomerPictureFail(e.message));
		}
	}
}

export function* updateOrganization({ payload }) {
	try {
		const { data } = yield call(CustomerAPI.updateOrganization, payload.organization);

		yield put(updateOrganizationComplete(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(updateOrganizationFail(e.message));
		}
	}
}

export function* addLinkedAccounts({ payload }) {
	try {
		const { data } = yield call(
			CustomerAPI.addLinkedAccounts,
			payload.accountId,
			payload.relatedAccountIds
		);
		yield put(addLinkedAccountsSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(addLinkedAccountsFail(e.message));
		}
	}
}

export function* deleteLinkedAccounts({ payload }) {
	try {
		const { data } = yield call(
			CustomerAPI.deleteLinkedAccounts,
			payload.accountId,
			payload.relatedAccountId
		);

		yield put(deleteLinkedAccountsSuccess(data));
	} catch (e: any) {
		const error = {
			error: e.response.data.errors.InvalidAccount,
			failedLinkedAccountId: e.response.data.results[0],
		};
		yield put(deleteLinkedAccountsFail(error));
	}
}

export function* checkEmailDuplicated({ payload }) {
	try {
		const { actionType, modalParent, excludeInactives, email } = payload;
		const replacedEmail = email.replace("+", "%2B");
		const { data } = yield call(
			CustomerAPI.getDuplicatedEmailCheck,
			replacedEmail,
			excludeInactives
		);

		data.actionType = actionType;
		data.modalParent = modalParent;

		yield put(checkEmailDuplicatedSuccess(data));
	} catch (error: any) {
		if (typeof error === "object" && error !== null && "message" in error) {
			yield put(checkEmailDuplicatedFail(error.message));
		}
	}
}

/*
Create a Label and Associate to a Customer at same time
*/
export function* createLabel({ payload }) {
	try {
		const { accountId, label } = payload;

		const { data: createdLabel } = yield call(CustomerAPI.createLabel, label);

		const { data } = yield call(CustomerAPI.addLabelsToCustomer, accountId, [
			createdLabel.labelId,
		]);

		yield put(addLabelSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(addLabelFailure(e.message));
		}
	}
}

export function* removeLabelFromCustomer({ payload }) {
	try {
		const { accountId, labelId } = payload;

		const { data } = yield call(CustomerAPI.removeLabelsFromCustomer, accountId, [
			labelId,
		]);

		yield put(removeLabelFromCustomerSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(removeLabelFromCustomerFailure(e.message));
		}
	}
}

export function* fetchLabels() {
	try {
		const { data } = yield call(CustomerAPI.fetchLabels);

		yield put(fetchLabelsSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(fetchLabelsFailure(e.message));
		}
	}
}

export function* removeLabel({ payload }) {
	try {
		const { data } = yield call(CustomerAPI.removeLabel, payload);

		yield put(removeLabelsSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(removeLabelsFailure(e.message));
		}
	}
}

export function* fetchLabelRelations({ payload }) {
	try {
		const { data } = yield call(CustomerAPI.fetchLabelRelations, payload);
		yield put(fetchLabelRelationsSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(fetchLabelRelationsFailure(e.message));
		}
	}
}

export function* addLabelToCustomer({ payload }) {
	try {
		const { accountId, labelId } = payload;

		const { data } = yield call(CustomerAPI.addLabelsToCustomer, accountId, [
			labelId,
		]);

		yield put(addLabelToCustomerSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(addLabelToCustomerFailure(e.message));
		}
	}
}

export function* updateLabel({ payload }) {
	try {
		const { label } = payload;

		const { data } = yield call(CustomerAPI.updateLabel, label);

		yield put(updateLabelSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(updateLabelFailure(e.message));
		}
	}
}

export function* confirmWaiver({ payload }) {
	try {
		const { data } = yield call(CustomerAPI.confirmWaiver, payload);

		yield put(confirmCustomerWaiverSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(confirmCustomerWaiverFail(e.message));
		}
	}
}

export function* expireWaiver({ payload }) {
	try {
		const { data } = yield call(CustomerAPI.expireWaiver, payload);

		yield put(expireCustomerWaiverSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(expireCustomerWaiverFail(e.message));
		}
	}
}

export function* fetchActiveWaiver({ payload }) {
	try {
		const { data } = yield call(CustomerAPI.expireWaiver, payload);

		yield put(expireCustomerWaiverSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(expireCustomerWaiverFail(e.message));
		}
	}
}

export function* signWaiver({ payload }) {
	try {
		const { data } = yield call(
			CustomerAPI.signWaiver,
			payload.accountId,
			payload.signedWaiver
		);

		yield put(signWaiverCustomerSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(signWaiverCustomerFailure(e.message));
		}
	}
}

export function* addOrganizationContactRequest({ payload }) {
	try {
		const { organizationId, accountId } = payload;

		const { data } = yield call(
			CustomerAPI.addContactToOrganization,
			organizationId,
			accountId
		);

		yield put(addOrganizationContactComplete(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(addOrganizationContactFail({ error: e }));
		}
	}
}

export function* removeOrganizationContactRequest({ payload }) {
	try {
		const { organizationId, accountId } = payload;

		const { data } = yield call(
			CustomerAPI.removeContactFromOrganization,
			organizationId,
			accountId
		);
		yield put(removeOrganizationContactComplete(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(removeOrganizationContactFail({ error: e }));
		}
	}
}

export function* checkOrganizationDuplicated({ payload }) {
	try {
		const { data } = yield call(
			CustomerAPI.getDuplicatedOrganizationCheck,
			payload.organizationId,
			payload.organizationName
		);
		yield put(checkOrganizationDuplicatedSuccess(data));
	} catch (error: any) {
		if (typeof error === "object" && error !== null && "message" in error) {
			yield put(checkOrganizationDuplicatedFail(error.message));
		}
	}
}

export function* fetchOrganizationContact({ payload }) {
	try {
		const { accountId } = payload;
		const { data } = yield call(CustomerAPI.fetchOrganizationContacts, accountId);
		yield put(fetchOrganizationContactsComplete(data));
	} catch (error: any) {
		if (typeof error === "object" && error !== null && "message" in error) {
			yield put(fetchOrganizationContactsFail(error.message));
		}
	}
}

export function* addOrganizationLinkedAccounts({ payload }) {
	try {
		const { data } = yield call(
			CustomerAPI.addOrganizationLinkedAccounts,
			payload.accountId,
			payload.relatedAccountIds,
			payload.includeLinkedAccountsOnOrgResponse
		);

		yield put(addOrganizationLinkedAccountsSuccess(data));
	} catch (e: any) {
		if (typeof e === "object" && e !== null && "message" in e) {
			yield put(addOrganizationLinkedAccountsFail(e.message));
		}
	}
}

export function* deleteOrganizationLinkedAccounts({ payload }) {
	try {
		const { data } = yield call(
			CustomerAPI.deleteOrganizaionLinkedAccounts,
			payload.accountId,
			payload.relatedAccountId
		);

		yield put(deleteOrganizationLinkedAccountsSuccess(data));
	} catch (e: any) {
		const error = {
			error: e.response.data.errors.InvalidAccount,
			failedLinkedAccountId: e.response.data.results[0],
		};
		yield put(deleteOrganizationLinkedAccountsFail(error));
	}
}

// Root Sagas
export function* watchCustomer() {
	return [
		yield takeLatest(fetchCustomerStart, fetchCustomer),
		yield takeLatest(addCustomerStart, addCustomer),
		yield takeLatest(updateCustomerStart, updateCustomer),
		yield takeLatest(updateCustomerStatusStart, updateCustomerStatus),
		yield takeLatest(updateOrganizationStatusStart, updateOrganizationStatus),
		yield takeLatest(fetchCustomerWaiverStart, fetchCustomerWaiver),
		yield takeLatest(fetchCustomerWaiversStart, fetchCustomerWaivers),
		yield takeLatest(fetchDependentWaiversStart, fetchDependentsWaivers),
		yield takeLatest(fetchOrganizationStart, fetchOrganization),
		yield takeLatest(addNote, addNoteRequest),
		yield takeLatest(updateNote, updateNoteRequest),
		yield takeLatest(deleteNote, deleteNoteRequest),
		yield takeLatest(fetchCustomerNotesStart, fetchCustomerNotes),
		yield takeLatest(addDependentToAccountStart, addDependentToAccount),
		yield takeLatest(updateDependentStart, updateDependent),
		yield takeLatest(addOrganizationStart, addOrganization),
		yield takeLatest(updateOrganizationStart, updateOrganization),
		yield takeLatest(fetchCustomerLoyaltyStart, fetchCustomerLoyalty),
		yield takeLatest(updateCustomerPictureStart, updateCustomerPicture),
		yield takeLatest(addLinkedAccountsStart, addLinkedAccounts),
		yield takeLatest(deleteLinkedAccountsStart, deleteLinkedAccounts),
		yield takeLatest(checkEmailDuplicatedStart, checkEmailDuplicated),
		yield takeLatest(addLabelStart, createLabel),
		yield takeLatest(removeLabelFromCustomerStart, removeLabelFromCustomer),
		yield takeLatest(fetchLabelsStart, fetchLabels),
		yield takeLatest(removeLabelsStart, removeLabel),
		yield takeLatest(fetchLabelRelationsStart, fetchLabelRelations),
		yield takeLatest(addLabelToCustomerStart, addLabelToCustomer),
		yield takeLatest(updateLabelStart, updateLabel),
		yield takeLatest(confirmCustomerWaiverStart, confirmWaiver),
		yield takeLatest(expireCustomerWaiverStart, expireWaiver),
		yield takeLatest(signCustomerWaiverStart, signWaiver),
		yield takeLatest(addOrganizationContactStart, addOrganizationContactRequest),
		yield takeLatest(
			removeOrganizationContactStart,
			removeOrganizationContactRequest
		),
		yield takeLatest(checkOrganizationDuplicatedStart, checkOrganizationDuplicated),
		yield takeLatest(fetchOrganizationContactsStart, fetchOrganizationContact),
		yield takeLatest(
			addOrganizationLinkedAccountsStart,
			addOrganizationLinkedAccounts
		),
		yield takeLatest(
			deleteOrganizationLinkedAccountsStart,
			deleteOrganizationLinkedAccounts
		),
	];
}

function buildCustomersQueryString() {
	const { pageSize, pageNumber } = store.getState().customer.loyalty.page;

	const queryString = `?pageSize=${pageSize}&pageNumber=${pageNumber}`;

	return queryString;
}
