import React from "react";
import { useHistory } from "react-router-dom";
import { IError } from "./Error.helpers";

const Error: React.FC<IError> = (props) => {
	const historyObj = useHistory();

	const redirectToDashboard = () => {
		const { history } = props;
		if (history === undefined || history === null) {
			historyObj.push("");
		} else {
			history.push("");
		}
	};

	return (
		<div className="multi_error_block">
			<div className="error_outer_wrap">
				<div className="error_wrapper">
					ERROR
					<div className="btn-wrap">
						<span>
							<button
								data-test="btnDashboard"
								type="button"
								name="btnDashboard"
								onClick={() => redirectToDashboard()}
							>
								BACK
							</button>
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Error;
