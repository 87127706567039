import { ICustomerMembershipValidation } from "../customerMembership.typings";

export const validationsStates = {
	validations: {
		emailDuplicated: false,
		emailDuplicatedFetchStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		deletingMemberAccountId: 0,
	} as ICustomerMembershipValidation,
};

export const validationsStatusReducer = () => {
	return {
		updateDeletingMemberAccountId: (state, { payload }) => {
			const { accountId } = payload;

			return {
				...state,
				validations: {
					...state.validations,
					deletingMemberAccountId: accountId,
				},
			};
		},
		fetchEmailDuplicatedStart: (state, { payload }) => {
			return {
				...state,
				validations: {
					...state.validations,
					emailDuplicatedFetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchEmailDuplicatedComplete: (state, { payload }) => {
			return {
				...state,
				validations: {
					...state.validations,
					emailDuplicated: payload.isDuplicated,
					emailDuplicatedFetchStateLoading: {
						...state.validations.emailDuplicatedFetchStateLoading,
						loading: false,
						success: true,
					},
				},
			};
		},
		fetchEmailDuplicatedFail: (state, { payload }) => {
			return {
				...state,
				validations: {
					...state.validations,
					emailDuplicatedFetchStateLoading: {
						...state.validations.emailDuplicatedFetchStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
	};
};
