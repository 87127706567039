import { AxiosPromise } from "axios";

// HELPERS
import Http from "../../../utils/restClient";
import {
	ICustomer,
	IUpdateCustomer,
	ILabel,
	ICustomerWaiver,
	ICustomerNote,
} from "./customer.typings";

export default class CustomerAPI {
	public static fetchCustomer(accountId: number) {
		return Http.axios().get(`/api/v1/customers/${accountId}`) as AxiosPromise;
	}

	public static fetchOrganization(accountId: number) {
		return Http.axios().get(`/api/v1/organizations/${accountId}`) as AxiosPromise;
	}

	public static fetchCustomerWaiver(accountId: number, customerWaiverId: number) {
		return Http.axios().get(
			`/api/v1/customers/${accountId}/waiver/${customerWaiverId}`
		) as AxiosPromise;
	}

	public static fetchCustomerWaivers(
		accountId: number,
		pageSize: number,
		pageNumber: number
	) {
		return Http.axios().get(
			`/api/v1/customers/${accountId}/waivers?PageSize=${pageSize}&PageNumber=${pageNumber}`
		) as AxiosPromise;
	}

	public static fetchDependentWaivers(
		accountId: number,
		pageSize: number,
		pageNumber: number
	) {
		return Http.axios().get(
			`/api/v1/customers/${accountId}/waivers/dependent?PageSize=${pageSize}&PageNumber=${pageNumber}`
		) as AxiosPromise;
	}

	public static addDependentToAccount(customer: ICustomer) {
		return Http.axios().post(`/api/v1/customers/dependent`, customer) as AxiosPromise;
	}

	public static updateDependent(customer: any) {
		return Http.axios().put(`/api/v1/customers/dependent`, customer) as AxiosPromise;
	}

	public static addOrganization(organization: any) {
		return Http.axios().post(`/api/v1/organizations`, organization) as AxiosPromise;
	}

	public static updateOrganization(organization: IUpdateCustomer) {
		return Http.axios().put(`/api/v1/organizations`, organization);
	}

	public static addOrganizationToAccount(accountId: number, organization: any) {
		return Http.axios().post(
			`/api/v1/customers/${accountId}/organization`,
			organization
		) as AxiosPromise;
	}

	public static addNote(accountId: number, note) {
		return Http.axios().post(
			`/api/v1/customers/${accountId}/note`,
			note
		) as AxiosPromise<any>;
	}

	public static updateNote(note: ICustomerNote) {
		return Http.axios().put(
			`/api/v1/customers/${note.accountId}/note/${note.id}`,
			note
		) as AxiosPromise<any>;
	}

	public static deleteNote(note: ICustomerNote) {
		return Http.axios().delete(
			`/api/v1/customers/${note.accountId}/note/${note.id}`
		) as AxiosPromise<any>;
	}

	public static fetchCustomerNotes(accountId: number, noteType: any) {
		return Http.axios().get(
			`/api/v1/customers/${accountId}/notes?noteType=${noteType}`
		) as AxiosPromise;
	}

	public static fetchCustomerLoyalty(accountId: number, queryString: string) {
		return Http.axios().get(
			`/api/v1/customers/${accountId}/loyalty${queryString}`
		) as AxiosPromise;
	}

	public static addCustomer(customer: ICustomer) {
		return Http.axios().post(`/api/v1/customers`, customer);
	}

	public static updateCustomer(customer: IUpdateCustomer) {
		return Http.axios().put(`/api/v1/customers`, customer);
	}

	public static updateCustomerStatus(customer: ICustomer) {
		return Http.axios().put(`/api/v1/customers/status`, customer);
	}

	public static updateOrganizationStatus(customer: ICustomer) {
		return Http.axios().put(`/api/v1/organizations/status`, customer);
	}

	public static updateCustomerPicutre(customer: ICustomer) {
		return Http.axios().put(`/api/v1/customers/picture`, customer);
	}

	public static addLinkedAccounts(accountId: number, relatedAccountIds: any) {
		return Http.axios().post(
			`/api/v1/customers/${accountId}/linked-accounts`,
			relatedAccountIds
		);
	}

	public static deleteLinkedAccounts(accountId: number, relatedAccountId: number) {
		return Http.axios().delete(
			`/api/v1/customers/${accountId}/linked-accounts/${relatedAccountId}`
		);
	}

	public static getDuplicatedEmailCheck(email: string, excludeInactives?: boolean) {
		const params = excludeInactives
			? `email=${email}&excludeInactives=${excludeInactives}`
			: `email=${email}`;

		return Http.axios().get(
			`/api/v1/customers/duplicated-email-check?${encodeURI(params)}`
		) as AxiosPromise;
	}

	public static getDuplicatedEmailCount(email: string, accountId: number) {
		const params = `email=${email}&accountId=${accountId}`;

		return Http.axios().get(
			`/api/v1/customers/subscription/countByEmail?${encodeURI(params)}`
		);
	}

	public static getDuplicatedOrganizationCheck(
		organizationId: number,
		organizatinName: string
	) {
		return Http.axios().get(
			`/api/v1/organizations/${organizationId}/duplicated-check?organizationName=${organizatinName}`
		) as AxiosPromise;
	}

	public static createLabel(label: ILabel) {
		return Http.axios().post(`/api/v1/customers/labels`, label) as AxiosPromise;
	}

	public static addLabelsToCustomer(accountId: number, labels: number[]) {
		return Http.axios().post(
			`/api/v1/customers/${accountId}/labels`,
			labels
		) as AxiosPromise;
	}

	public static removeLabelsFromCustomer(accountId: number, labels: number[]) {
		return Http.axios().put(
			`/api/v1/customers/${accountId}/labels`,
			labels
		) as AxiosPromise;
	}

	public static fetchLabels() {
		return Http.axios().get(`/api/v1/customers/labels`) as AxiosPromise;
	}

	public static removeLabel(labelId: number) {
		return Http.axios().delete(`/api/v1/customers/labels/${labelId}`) as AxiosPromise;
	}

	public static fetchLabelRelations(labelId: number) {
		return Http.axios().get(
			`/api/v1/customers/labels/relations/${labelId}`
		) as AxiosPromise;
	}

	public static updateLabel(label: ILabel) {
		return Http.axios().put(`/api/v1/customers/labels`, label) as AxiosPromise;
	}

	public static confirmWaiver(customerWaiver: ICustomerWaiver) {
		return Http.axios().put(
			`/api/v1/customers/${customerWaiver.accountId}/waiver/${customerWaiver.customerWaiverId}/confirm`,
			customerWaiver
		) as AxiosPromise;
	}

	public static expireWaiver(customerWaiver: ICustomerWaiver) {
		return Http.axios().put(
			`/api/v1/customers/${customerWaiver.accountId}/waiver/${customerWaiver.customerWaiverId}/expire`,
			customerWaiver
		) as AxiosPromise;
	}

	public static signWaiver(accountId: number, signedWaiver: any) {
		return Http.axios().put(
			`/api/v1/customers/${accountId}/waiver/sign`,
			signedWaiver
		) as AxiosPromise;
	}

	public static addContactToOrganization(organizationId: number, accountId: any) {
		return Http.axios().put(
			`/api/v1/organizations/${organizationId}/contacts/${accountId}`,
			{}
		) as AxiosPromise;
	}

	public static removeContactFromOrganization(organizationId: number, accountId: any) {
		return Http.axios().delete(
			`/api/v1/organizations/${organizationId}/contacts/${accountId}`
		) as AxiosPromise;
	}

	public static fetchOrganizationContacts(organizationId: number) {
		return Http.axios().get(
			`/api/v1/organizations/contacts/${organizationId}`
		) as AxiosPromise;
	}

	public static fetchOrganizationAssociations(organizationId: number) {
		return Http.axios().get(
			`/api/v1/organizations/associations/${organizationId}`
		) as AxiosPromise;
	}

	public static addOrganizationLinkedAccounts(
		accountId: number,
		relatedAccountIds: any,
		includeLinkedAccountsOnOrgResponse: boolean
	) {
		return Http.axios().post(`/api/v1/organization/${accountId}/linked-accounts`, {
			relatedAccountIds,
			includLinkedAccounts: includeLinkedAccountsOnOrgResponse,
		});
	}

	public static deleteOrganizaionLinkedAccounts(
		accountId: number,
		relatedAccountId: number
	) {
		return Http.axios().delete(
			`/api/v1/organization/${accountId}/linked-accounts/${relatedAccountId}`
		);
	}
}
