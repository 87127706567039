import { IPaymentCompletedResponse } from "../payments.typings";

export const checkState = {
	checkPay: {
		applyStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			started: false,
			success: false,
		},
		paymentResponse: {} as IPaymentCompletedResponse,
	},
};

const checkPayReducer = () => {
	return {
		postCheckPayStart: (state, { payload }) => {
			return {
				...state,
				checkPay: {
					...state.checkPay,
					applyStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		postCheckPayComplete: (state, { payload }) => {
			return {
				...state,
				checkPay: {
					...state.checkPay,
					applyStateLoading: {
						...state.checkPay.applyStateLoading,
						started: true,
						loading: false,
						success: true,
					},
					paymentResponse: payload.data.results,
				},
			};
		},
		postCheckPayFail: (state, { payload }) => {
			return {
				...state,
				checkPay: {
					...state.checkPay,
					applyStateLoading: {
						...state.checkPay.applyStateLoading,
						started: true,
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		resetCheckPaymentState: (state) => {
			return {
				...state,
				checkPay: {
					applyStateLoading: {
						started: false,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
					paymentResponse: {} as IPaymentCompletedResponse,
				},
			};
		},
	};
};
export default checkPayReducer;
