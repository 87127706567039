const notesReducer = () => {
	return {
		addNote: (state, { payload }) => {
			return {
				...state,
				addNoteSuccess: false,
				updateNoteSuccess: false,
				deleteNoteSuccess: false,
				error: "",
			};
		},
		addNoteSuccess: (state, { payload }) => {
			return {
				...state,
				addNoteSuccess: true,
				updateNoteSuccess: false,
				deleteNoteSuccess: false,
				note: payload,
				notes: [payload, ...state.notes],
				error: "",
			};
		},
		addNotesFailure: (state, { payload }) => {
			return {
				...state,
				isNotesLoading: false,
				updateNoteSuccess: false,
				deleteNoteSuccess: false,
				error: payload,
				addNoteSuccess: false,
			};
		},
		fetchCustomerNotesStart: (state, { payload }) => {
			return {
				...state,
				isNotesLoading: true,
				updateNoteSuccess: false,
				deleteNoteSuccess: false,
			};
		},
		fetchCustomerNotesCompleted: (state, { payload }) => {
			return {
				...state,
				isNotesLoading: false,
				updateNoteSuccess: false,
				deleteNoteSuccess: false,
				notes: payload,
			};
		},
		fetchCustomerNotesFail: (state, { payload }) => {
			return {
				...state,
				isNotesLoading: false,
				updateNoteSuccess: false,
				deleteNoteSuccess: false,
				error: payload,
			};
		},
		updateNote: (state, { payload }) => {
			return {
				...state,
				addNoteSuccess: false,
				updateNoteSuccess: false,
				deleteNoteSuccess: false,
				error: "",
			};
		},
		updateNoteSuccess: (state, { payload }) => {
			const updatedItemIndex = state.notes.findIndex((x) => x.id === payload.id);
			const newNotesArray = [...state.notes];
			newNotesArray[updatedItemIndex] = payload;
			return {
				...state,
				addNoteSuccess: false,
				updateNoteSuccess: true,
				deleteNoteSuccess: false,
				note: payload,
				notes: newNotesArray,
				error: "",
			};
		},
		updateNotesFailure: (state, { payload }) => {
			return {
				...state,
				isNotesLoading: false,
				updateNoteSuccess: false,
				deleteNoteSuccess: false,
				error: payload,
				addNoteSuccess: false,
			};
		},
		deleteNote: (state, { payload }) => {
			return {
				...state,
				addNoteSuccess: false,
				updateNoteSuccess: false,
				deleteNoteSuccess: false,
				error: "",
			};
		},
		deleteNoteSuccess: (state, { payload }) => {
			const deletedItemIndex = state.notes.findIndex((x) => x.id === payload.id);
			const newNotesArray = [...state.notes];
			newNotesArray.splice(deletedItemIndex, 1);
			return {
				...state,
				addNoteSuccess: false,
				updateNoteSuccess: false,
				deleteNoteSuccess: true,
				note: payload,
				notes: newNotesArray,
				error: "",
			};
		},
		deleteNotesFailure: (state, { payload }) => {
			return {
				...state,
				isNotesLoading: false,
				updateNoteSuccess: false,
				deleteNoteSuccess: false,
				error: payload,
				addNoteSuccess: false,
			};
		},
	};
};

export default notesReducer;
