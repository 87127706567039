import { takeLatest, put, call, takeEvery, all } from "redux-saga/effects";

import {
	getAuthenticationKeyStart,
	getAuthenticationKeyComplete,
	getAuthenticationKeyFail,
	getPaymentAcceptanceComplete,
	getPaymentAcceptanceFail,
	getPaymentAcceptanceStart,
	postCashPayComplete,
	postCashPayFail,
	postCashPayStart,
	postCheckPayStart,
	postCheckPayComplete,
	postCheckPayFail,
	postCreditPayComplete,
	postCreditPayFail,
	postCreditPayStart,
	postHostedCaptureStart,
	postHostedCaptureComplete,
	postHostedCaptureFail,
	postDebitPayComplete,
	postDebitPayFail,
	postDebitPayStart,
	postEmvPayStart,
	postEmvPayComplete,
	postEmvPayFail,
	postSwipedPayStart,
	postSwipedPayComplete,
	postSwipedPayFail,
	postGiftPayStart,
	postGiftPayComplete,
	postGiftPayFail,
	postRefundsStart,
	postRefundsComplete,
	postRefundsFail,
	postSingleRefundComplete,
	postSingleRefundFail,
	getRefundSummaryComplete,
	getRefundSummaryFail,
	getRefundSummaryStart,
	postInterCardPayStart,
} from "./payments.slice";

import PaymentsAPI from "./payments.api";
import {
	IGetRefundSummaryPayload,
	IPostRefundData,
	IPostRefundsPayload,
} from "./reducers/refund.reducer";

export function* getAuthenticationKey({ payload }) {
	try {
		const { isToken, paymentAmount } = payload;

		if (isToken) {
			const { data } = yield call(PaymentsAPI.getAuthenticationKeyToken);
			yield put(getAuthenticationKeyComplete(data));
		} else {
			const { data } = yield call(
				PaymentsAPI.getAuthenticationKeyAuthorization,
				paymentAmount
			);
			yield put(getAuthenticationKeyComplete(data));
		}
	} catch (e: any) {
		yield put(getAuthenticationKeyFail(e.message));
	}
}

export function* getPaymentAcceptance() {
	try {
		const response = yield call(PaymentsAPI.getPaymentAcceptance);

		if (response.data.ok) {
			yield put(getPaymentAcceptanceComplete({ data: response.data }));
		} else {
			yield put(
				getPaymentAcceptanceFail({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(getPaymentAcceptanceFail(error));
	}
}

export function* postCashPay({ payload }) {
	const { paymentAcceptanceId, locationContext, amount, orderId, notes, stationId } =
		payload;
	try {
		const response = yield call(
			PaymentsAPI.postCashPay,
			orderId,
			paymentAcceptanceId,
			locationContext,
			amount,
			notes,
			stationId
		);

		if (response.data.ok) {
			yield put(postCashPayComplete({ data: response.data }));
		} else {
			yield put(
				postCashPayFail({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(postCashPayFail(error));
	}
}

export function* postCheckPay({ payload }) {
	const {
		paymentAcceptanceId,
		locationContext,
		amount,
		orderId,
		notes,
		nameOnCheck,
		checkNumber,
		stationId,
	} = payload;

	try {
		const response = yield call(
			PaymentsAPI.postCheckPay,
			orderId,
			paymentAcceptanceId,
			locationContext,
			amount,
			nameOnCheck,
			checkNumber,
			notes,
			stationId
		);

		if (response.data.ok) {
			yield put(postCheckPayComplete({ data: response.data }));
		} else {
			yield put(
				postCheckPayFail({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(postCheckPayFail(error));
	}
}

export function* postHostedCapture({ payload }) {
	const {
		paymentAcceptanceId,
		locationContext,
		amount,
		orderId,
		transactionId,
		notes,
		stationId,
		isStripePayment,
		token,
		paymentModuleId,
	} = payload;
	try {
		let response;
		if (isStripePayment) {
			response = yield call(
				PaymentsAPI.postStripeHostedCapture,
				orderId,
				paymentAcceptanceId,
				locationContext,
				amount,
				notes,
				stationId,
				token,
				paymentModuleId
			);
		} else {
			response = yield call(
				PaymentsAPI.postFSHostedCapture,
				orderId,
				transactionId,
				paymentAcceptanceId,
				locationContext,
				amount,
				notes,
				stationId
			);
		}
		if (response.data.ok) {
			yield put(postHostedCaptureComplete({ data: response.data }));
		} else {
			yield put(
				postHostedCaptureFail({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(postHostedCaptureFail(error));
	}
}

export function* postCreditPay({ payload }) {
	try {
		const response = yield call(PaymentsAPI.postCreditPay, payload);

		if (response.data.ok) {
			yield put(postCreditPayComplete({ data: response.data }));
		} else {
			yield put(
				postCreditPayFail({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(postCreditPayFail(error));
	}
}

export function* postDebitPay({ payload }) {
	try {
		const response = yield call(PaymentsAPI.postDebitPay, payload);

		if (response.data.ok) {
			yield put(postDebitPayComplete({ data: response.data }));
		} else {
			yield put(
				postDebitPayFail({
					error: Object.values(response.data.errors)[0],
				})
			);
		}
	} catch (error: any) {
		yield put(postDebitPayFail(error));
	}
}

export function* postEmvPay({ payload }) {
	try {
		const response = yield call(PaymentsAPI.postEmvPay, payload);

		if (response.data.ok) {
			yield put(postEmvPayComplete({ data: response.data }));
		} else {
			yield put(postEmvPayFail(response.data.results.message));
		}
	} catch (error: any) {
		yield put(postEmvPayFail(error));
	}
}

export function* postSwipedPay({ payload }) {
	try {
		const response = yield call(PaymentsAPI.postSwipedPay, payload);

		if (response.data.ok) {
			yield put(postSwipedPayComplete({ data: response.data }));
		} else {
			yield put(postSwipedPayFail(response.data.results.message));
		}
	} catch (error: any) {
		yield put(postSwipedPayFail(error));
	}
}

export function* postGiftCardPay({ payload }) {
	try {
		const response = yield call(PaymentsAPI.postGiftCardPay, payload);

		if (response.data.ok) {
			yield put(postGiftPayComplete({ data: response.data }));
		} else {
			yield put(postGiftPayFail(response.data.results.message));
		}
	} catch (error: any) {
		yield put(postGiftPayFail(error));
	}
}

export function* postInterCardPay({ payload }) {
	try {
		const response = yield call(PaymentsAPI.postInterCardPay, payload);

		if (response.data.ok) {
			yield put(postGiftPayComplete({ data: response.data }));
		} else {
			yield put(postGiftPayFail(response.data.results.message));
		}
	} catch (error: any) {
		yield put(postGiftPayFail(error));
	}
}

export function* postRefund({
	payload,
}: {
	payload: { orderId: number; data: IPostRefundData };
}) {
	try {
		const response = yield call(PaymentsAPI.posRefund, payload);

		if (response.data.ok) {
			yield put(postSingleRefundComplete({ data: response.data }));
		} else {
			yield put(postSingleRefundFail(response.data.results.message));
		}
	} catch (error: any) {
		yield put(postSingleRefundFail(error));
	}
}

export function* watchPostRefunds({ payload }: { payload: IPostRefundsPayload }) {
	try {
		yield all(
			payload.transactions.map((data) =>
				call(postRefund, { payload: { orderId: payload.orderId, data } })
			)
		);
		yield put(postRefundsComplete({}));
	} catch (error: any) {
		yield put(postRefundsFail(error));
	}
}

export function* GetrefundSummary({
	payload,
}: {
	payload: IGetRefundSummaryPayload;
}): any {
	try {
		const { orderId, request } = payload;
		const response = yield call(PaymentsAPI.GetRefundSumarry, request, orderId);

		yield put(getRefundSummaryComplete(response));
	} catch (error: any) {
		yield put(getRefundSummaryFail(payload));
	}
}

// Root Sagas
export function* watchPayments() {
	return [
		yield takeLatest(getAuthenticationKeyStart, getAuthenticationKey),
		yield takeLatest(getPaymentAcceptanceStart, getPaymentAcceptance),
		yield takeLatest(postCashPayStart, postCashPay),
		yield takeLatest(postCheckPayStart, postCheckPay),
		yield takeLatest(postHostedCaptureStart, postHostedCapture),
		yield takeLatest(postCreditPayStart, postCreditPay),
		yield takeLatest(postDebitPayStart, postDebitPay),
		yield takeLatest(postEmvPayStart, postEmvPay),
		yield takeLatest(postSwipedPayStart, postSwipedPay),
		yield takeLatest(postGiftPayStart, postGiftCardPay),
		yield takeLatest(postInterCardPayStart, postInterCardPay),
		yield takeEvery(postRefundsStart, watchPostRefunds),
		yield takeLatest(getRefundSummaryStart, GetrefundSummary),
	];
}
