import { SubscriptionStatusEnum } from "./customerMembership-v1.typings";

export type StateLoading = {
	started?: boolean;
	loading: boolean;
	success: boolean;
	hasError: boolean;
	errorMessage: "";
};

export type StateSaving = StateLoading & {
	shouldFire: boolean;
	releaseHold: boolean;
};

export enum BillingCycleEnum {
	Monthly = 1,
	Yearly = 2,
	MonthlyTesting = 3,
	YearlyTesting = 4,
}
export interface ITransientBillingInformationState {
	fetchStateLoading: StateLoading;
	accountOwnerId: number;
	billingCyclePeriod: number;
	nextBillingDate?: Date;
	accountTokenId: number;
}

export interface ITransientAccountOwnerState {
	fetchStateLoading: StateLoading;
	tokenCanBeDeletedStateLoading: StateLoading;
	selectedTokenId: number; // Refers to the selected subscriptionToken. Should be updated in case of changes
	accountId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	email: string;
	// cantDelete: boolean; => Changed to stateLoading because it will be fetched from API => Changed to tokenCanBeDeletedStateLoading to invert logic. OK() is can be deleted and BadRequest shows that token cannot be deleted
	avatar: string;
	accountTokens: IAccountTokenState;
}

export interface IAccountTokenState {
	removeTokenStateLoading: StateLoading;
	saveTokenStateLoading: StateLoading;
	items: Array<IAccountToken>;
}

export interface IAccountToken {
	accountTokenId: number;
	nameOnAccount: string;
	cardFlag: string;
	cardLastFour: string;
}

export interface ITransientMembersState {
	fetchStateLoading: StateLoading;
	localUpdateStateLoading: StateLoading;
	items: Array<ITransientMember>;
}

export interface ITransientMember {
	accountId: number;
	selected: boolean;
	showMemberInDrawer: boolean;
	isAccountOwner: boolean;
	hasAnotherSubscription: boolean;
	hasAtLeastOneSubscription: boolean;
	receivesWelcomeEmail: boolean;
	receivesReceipt: boolean;
	isDeleted: boolean;
	isRemoved: boolean;
	activationFees: ITransientMemberActivationFee;
	person: ITransientMemberPerson;
}

export interface ITransientMemberActivationFee {
	hasPaidActivationFee: boolean;
	activationFeeName: string;
	activationFeePaidOn: Date;
	activationFeePaid: number;
	isPayingActivationFee: boolean;
}

export interface ITransientMemberPerson {
	firstName: string;
	lastName: string;
	fullname: string;
	isDependent: boolean;
	gender: string;
	birthdate: Date;
	email: string;
	avatar: string;
	accountStatus: string;
	phones: Array<ITransientMemberPersonPhone>;
}

export interface ITransientMemberPersonPhone {
	phoneId: number;
	phoneNumber: string;
	extension: string;
	primaryPhone: boolean;
}

/// /

export interface IPricingFee {
	fetchStateLoading: StateSaving;
	recurringBillingAmount: number;
	recurringBillingAmountAfterTaxes: number;
	payingNowAmount: number;
	payingNowAmountAfterTaxes: number;
	pricingTiers: Array<IPricingTier>;
	proRatedPricingTiers: Array<IPricingTier>;
	taxes: Array<IPricingTax>;
}

export interface IPricingTier {
	accountId: number;
	tierPricingAmount: number;
	pricingTierId: number;
	activationFeeAmount: number;
	activationFeeChecked: boolean;
}

export interface IPricingTax {
	productId: number;
	taxId: number;
	productPrice: number;
	taxName: string;
	taxRate: number;
	inclusive: boolean;
	taxValue: number;
}

/// /

export interface ICustomerMembershipValidation {
	emailDuplicated: boolean;
	emailDuplicatedFetchStateLoading: StateLoading;
	deletingMemberAccountId: number;
}

/// /

export interface ICustomerMembershipLayout {
	isAddSubscriptionDrawerOpen: boolean;
	isBillingDrawerOpen: boolean; // renamed from isBillingModalOpen
	isMembersDrawerOpen: boolean; // renamed from isBillingModalOpen
	isPlanOwnerModalOpen: boolean; // renamed from planOwnerModalOpen
	isMemberDeleteModalOpen: boolean; // renamed from membershipDeleteModalOpen
	isTokenDrawerOpen: boolean;
	isPayErrorModalOpen: boolean;
	isPaymentDrawerOpen: boolean;
	isNoRemoveAssociationOpen: boolean;
	isSubscriptionBirthdateError: boolean;
}

/// /

export interface IFlagStatus {
	activationStatus: string;
	billingCardFlags: IBillingCardFlag;
	memberCardFlags: IMemberCardFlag;
}

export interface IBillingCardFlag {
	hasChangedBillingCycle: boolean;
	hasChangedAccountOwner: boolean;
	hasChangedAccountToken: boolean;
	billingCardStatus: "success" | "warning" | "changed" | undefined;
	billingCardWarnningMessage: string;
}

export interface IMemberCardFlag {
	isDeactivatedPlanAndUnlocked: boolean;
	hasNewMembers: boolean;
	hasRemovedMembers: boolean;
	hasChangedActivationFees: boolean;
	hasChangedCommunication: boolean;
	membersCardWarningMessage: string;
	membersCardStatus: "success" | "warning" | "changed" | undefined;
}

export interface IPlanPrices {
	planPriceId: number;
	startAge: number;
	endAge: number;
	monthlyFee: number;
	yearlyFee: number;
	createdOn: Date;
	createdByUserId: number;
	modifiedOn: Date;
	modifiedByUserId: number;
}

export interface IMembershipPlanHeader {
	planId: number;
	planColor: string;
	planName: string;
	activationFee: IMembershipPlanActivationFee;
	isMonthly: boolean;
	isYearly: boolean;
	welcomeTemplateId: number;
	welcomeTemplateName: string;
	receiptTemplateId: number;
	receiptTemplateName: string;
	isActive: boolean;
	// productId: number;
	membershipPrices: Array<IPlanPrices>;
}

export interface IMembershipPlanActivationFee {
	activationFee?: number;
	activationFeeName?: string;
}

export interface ISubscriptionListState {
	fetchStateLoading: StateLoading;
	selectedSubscriptionId: number;
	items: Array<ISubscriptionHeader>;
}

export interface ISubscriptionHeader {
	facilityId: number;
	planId: number;
	accountOwnerId: number;
	subscriptionId: number;
	planColor: string;
	planName: string;
	isActive: boolean;
	isPlanActive: boolean;
	isCancelled: boolean;
	isSetupComplete: boolean;
	accountTokenId: number;
	productId: number;
	isMonthly: boolean;
	isYearly: boolean;
	cancelledOn: Date;
}

export interface ISubscriptionState {
	createStateLoading: StateLoading;
	saveStateLoading: StateSaving;
	fetchStateLoading: StateLoading;
	removeStateLoading: StateLoading;
	expiringStateLoading: StateLoading;
	paymentStateLoading: StateLoading;
	resumeStateLoading: StateLoading;
}

// TODO: REQUIRES Updates in the future
export type ISubscription = ISubscriptionState & {
	facilityId: number;
	subscriptionId: number;
	isActive: boolean;
	isPlanActive: boolean;
	isCancelled: boolean;
	isSetupComplete: boolean;
	createdByUserId: number;
	createdOn?: Date;
	cancelledOn?: Date;
	modifiedByUserId: number;
	modifiedOn?: Date;
	subscriptionStatus: SubscriptionStatusEnum;
	subscriptionStatusDate?: Date;
	expiringDate?: Date;
	nextBillingDate?: Date;
	lastBillAmount: number;
	lastBillOn?: Date;
	autoSave: boolean;
	billingInformation: ISubscriptionBillingInformation;
	members: Array<ISubscriptionMember>;
	plan: IMembershipPlanHeader;
};

export type ISubscriptionBillingInformation = {
	accountOwnerId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	email: string;
	billingCyclePeriod: BillingCycleEnum;
	nextBillingDate?: Date;
	accountTokenId?: number;
	scheduledBillingCycle?: BillingCycleEnum;
};

export type ISubscriptionMember = {
	subscriptionMemberId: number;
	facilityId: number;
	subscriptionId: number;
	receivesWelcomeEmail: boolean;
	receivesReceipt: boolean;
	accountId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	isDependent: boolean;
	createdByUserId: number;
	createdOn: Date;
	hasPaidActivationFee: boolean;
	activationFeeName: string;
	activationFeePaidOn: Date;
	activationFeePaidAmount: number;
	activationFeeChecked: boolean;
	isRemoved: boolean;
	isDeleted: boolean; // isDeleted will be renamed to isRemoved and will control if the member will be removed in the next cycle
	avatar: string;
};
/// /

export interface IBillingHistoryState {
	fetchStateLoading: StateLoading;
	paginationSettings: PaginationSettings;
	items: Array<IBillingHistory>;
	receiptActions: {
		fetchStateLoading: StateLoading;
		sendStateLoading: StateLoading;
		receiptContentHtml: string;
		orderAccount: {
			fetchStateLoading: StateLoading;
			accountId: number;
			email: string;
		};
	};
}

export interface IBillingHistory {
	planName: string;
	planId: number;
	subscriptionId: number;
	facilityId: number;
	receiptEmailTemplateId: number;
	subscriptionStatus: string;
	accountOwnerId: number;
	accountOwnerFirstName: string;
	accountOwnerLastName: string;
	accountOwnerFullName: string;
	subscriptionPaymentHistoryId: number;
	orderId: number;
	orderNumber: number;
	billingDate: Date;
	amount: number;
	paymentsTotal?: number;
	refundedTotal?: number;
	isPaymentSuccess: boolean;
}

/// /

export type PaginationSettings = {
	totalPages: number;
	totalItems: number;
	pageSize: number;
	pageNumber: number;
	firstRowOnPage: number;
	lastRowOnPage: number;
	currentPage: number;
};

export type ActivationStatus =
	| "active"
	| "active with changes"
	| "active with incomplete changes"
	| "inactive"
	| "activatable"
	| "failed payment"
	| "failed payment with changes"
	| "expiring"
	| "expiring with changes"
	| "cancelled"
	| "cancelled with changes"
	| "on hold"
	| "on hold with changes"
	| "hold scheduled";

/// /

export interface ISubscriptionSaveType {
	subscriptionId: number;
	planId: number;
	autoSave: boolean;
	resumeSubscription: boolean;
	profileAccountId: number;
	billingInformation: ISubscriptionSaveBillingInformationType;
	subscriptionMembers: Array<ISubscriptionSaveMemberType>;
}

export interface ICalculateSubscriptionPriceType {
	subscriptionId: number;
	subscriptionPlanId: number;
	billingCyclePeriod: number;
	accountOwnerId: number;
	subscriptionMembers: Array<ISubscriptionSaveMemberType>;
	hasChangedMembers: boolean;
}

export interface ISubscriptionSaveMemberType {
	accountId: number;
	receivesWelcomeEmail: boolean;
	receivesReceipt: boolean;
	isAdded: boolean;
	isDeleted: boolean;
	isHardDelete?: boolean;
	isPayingActivationFee?: boolean;
	hasPaidActivationFee?: boolean;
	isNewMember?: boolean;
	isPaying: boolean;
}

export interface ISubscriptionSaveBillingInformationType {
	accountOwnerId: number;
	billingCyclePeriod: number;
	accountTokenId?: number;
}
