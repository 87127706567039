const isIE = () => {
	const ua = window.navigator.userAgent;

	if (ua.indexOf("Trident") > -1) {
		return true;
	}
	return false;
};

export default isIE;
