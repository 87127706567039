import axios, { AxiosInstance } from "axios";
import cookie from "cookie";

let API_URL = "";
if (process.env.REACT_APP_API_URL) {
	API_URL = process.env.REACT_APP_API_URL;
}

const parsedCookies = cookie.parse(document.cookie);
const params = new URLSearchParams(window.frames.location.search);

export default class Http {
	public static axios(): AxiosInstance {
		const options = {
			baseURL: API_URL,
			headers: {
				Authorization:
					params.get("authToken") || parsedCookies["classic-bridge"] || "",
			},
		};
		// Create instance
		const instance = axios.create(options);

		// Set the AUTH token for any request
		instance.interceptors.request.use((config) => {
			const finalConfig = { ...config };

			if (finalConfig.headers !== undefined) {
				finalConfig.headers.Authorization = "";
				delete finalConfig.headers.Authorization;

				const token =
					params.get("authToken") || parsedCookies["classic-bridge"] || "";
				finalConfig.headers.Authorization = token ? `Bearer ${token}` : "";
			}

			return finalConfig;
		});
		// Send the sessionCheck back to the parent window
		window.parent.postMessage("SessionCheck", "*");
		return instance;
	}
}
