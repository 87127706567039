import { createSlice } from "@reduxjs/toolkit";
import notesReducer from "./reducers/notes.reducer";
import loyaltyReducer from "./reducers/loyalty.reducer";
import waiverReducer from "./reducers/waiver.reducer";
import waiversReducer from "./reducers/waivers.reducer";
import organizationReducer from "./reducers/organization.reducer";
import dependentReducer from "./reducers/dependent.reducer";
import statusReducer from "./reducers/status.reducer";
import linkedAccountsReducer from "./reducers/linkedAccounts.reducer";
import labelsReducer from "./reducers/labels.reducer";

import {
	ICustomer,
	IOrganization,
	ICustomerWaiver,
	ICustomerNote,
	ILabel,
	TypeRelatedPerson,
	IMembershipLoyalty,
} from "./customer.typings";

const initialCustomer = {
	customer: {} as ICustomer,
	addCustomerSuccess: false,
	updateCustomerSuccess: false,
	updateCustomerError: false,
	updateCustomerPictureStatus: false,
	updateCustomerStatusLoading: false,
	dependent: {} as ICustomer,
	relatedCustomer: {} as ICustomer,
	addDependentSuccess: false,
	addDependentAssociatedToOrganizationSuccess: false,
	updateDependentSuccess: false,
	addLinkedAccountsSuccess: false,
	deleteLinkedAccountsSuccess: false,
	deleteLinkedAccountsFailure: false,
	failedLinkedAccountId: 0,
	addOrganizationSuccess: false,
	updateOrganizationSuccess: false,
	updateOrganizationFailure: false,
	organization: {} as IOrganization,
	customerWaiver: {} as ICustomerWaiver,
	isCustomerWaiverLoading: false as boolean,
	waiversTab: {
		waivers: [] as ICustomerWaiver[],
		isLoading: false as boolean,
		totalPages: 0,
		totalItems: 0,
		pageSize: 10,
		pageNumber: 1,
		firstRowOnPage: 0,
		lastRowOnPage: 0,
	},
	isLoading: false as boolean,
	customerRequestsCount: 0,
	error: "" as string,
	note: {} as ICustomerNote,
	notes: [] as ICustomerNote[],
	addNoteSuccess: false,
	updateNoteSuccess: false as boolean,
	deleteNoteSuccess: false as boolean,
	isNotesLoading: false as boolean,
	isLoyaltyLoading: false as boolean,
	loyalty: {
		results: [],
		membershipLoyalty: [] as IMembershipLoyalty[],
		totalPages: 0,
		totalItems: 0,
		page: {
			pageSize: 10,
			pageNumber: 1,
		},
		firstRowOnPage: 0,
		lastRowOnPage: 0,
		hasNextPage: false,
		hasPreviousPage: false,
		firstOrderDate: null,
		lastOrderDate: null,
		totalRevenue: 0,
	},
	emailDuplicated: false,
	checkEmailSuccess: false,
	labels: [] as ILabel[],
	addLabelSuccess: false,
	isRemovingLabelFromCustomer: false,
	isLoadingLabels: false,
	removeLabelsSuccess: false,
	fetchLabelRelationsSuccess: false,
	totalLabelsRelated: 0,
	isAddingLabelToCustomer: false,
	editLabel: {} as ILabel,
	updateLabelSuccess: false,
	confirmCustomerWaiverSuccess: false,
	expireCustomerWaiverSuccess: false,
	signWaiverCustomerSuccess: false,
	addOrganizationContactSuccess: false,
	removeOrganizationContactSuccess: false,
	emailDuplicatedCustomer: {} as ICustomer,
	checkingDuplicatedEmail: false,
	addOrgazanitionToAccountSuccess: false,
	addCustomerAssociatedSuccess: false,
	addCustomerAssociatedToOrganizationSuccess: false,
	updateCustomerAssociatedSuccess: false,
	updatedPictureAccount: {} as any, //might be ICustomer or IOrganization
	triggeredProfileAccountId: 0,
	organizationDuplicated: false,
	checkingDuplicatedOrganization: false,
	checkDuplicatedOrganizationSuccess: false,

	addOrganizationLinkedAccountSuccess: false,
	deleteOrganizationLinkedAccountFailure: false,
	deleteOrganizationLinkedAccountSuccess: false,
	fetchOrganizationAssociationsSuccess: false,
	fetchOrganizationContactsSuccess: false,
	fetchOrganizationSuccess: false,
	organizationContactAndAssociationSearch: "",
	organizationAssociationsPagination: {
		pageSize: 10,
		pageNumber: 1,
		activeIndex: 0,
		totalPages: 0,
		currentPage: 0,
		totalItems: 0,
		searchText: "",
		runPagination: false,
	},

	disableSubmitForm: false,
	//if an update cause waivers to be expired, this flag will true
	hasExpiredWaiver: false,
	expiredWaiverAccountIds: [] as number[],
};

const customerSlice = createSlice({
	name: "CUSTOMER",
	initialState: initialCustomer,
	reducers: {
		...notesReducer(),
		...loyaltyReducer(),
		...waiverReducer(),
		...waiversReducer(),
		...organizationReducer(),
		...dependentReducer(),
		...statusReducer(),
		...linkedAccountsReducer(),
		...labelsReducer(),
		fetchCustomerStart: (state, { payload }) => {
			state.isLoading = true;
			state.customerRequestsCount++;
		},
		fetchCustomerComplete: (state, { payload }) => {
			state.isLoading = false;
			state.customer = payload;
		},
		fetchCustomerFail: (state, { payload }) => {
			state.isLoading = false;
			state.error = payload;
		},
		resetCustomer: (state, { payload }) => {
			return {
				...state,
				...initialCustomer,
			};
		},
		resetEmailDuplicatedCustomer: (state, { payload }) => {
			return {
				...state,
				emailDuplicatedCustomer: {} as ICustomer,
				emailDuplicated: false,
				checkEmailSucces: false,
				checkingDuplicatedEmail: false,
			};
		},
		addCustomerStart: (state, { payload }) => {
			return {
				...state,
				addCustomerSuccess: false,
				addCustomerAssociatedSuccess: false,
			};
		},
		addCustomerSuccess: (state, { payload }) => {
			return {
				...state,
				addCustomerSuccess: true,
				customer: payload,
				emailDuplicatedCustomer: {} as ICustomer,
			};
		},
		addCustomerAssociatedSuccess: (state, { payload }) => {
			const newRelatedCustomer = {
				accountStatus: payload.accountStatus,
				firstName: payload.firstName,
				lastName: payload.lastName,
				name: `${payload.firstName} ${payload.lastName}`,
				personId: payload.personId,
				accountId: payload.accountId,
				avatar: payload.avatar,
				isDependent: payload.isDependent,
				birthdate: payload.birthDate,
			} as TypeRelatedPerson;

			let updatedCustomer = { ...state.customer };

			//if adding from customer detail state.customer must be settled
			if (state.customer.people) {
				updatedCustomer = {
					...state.customer,
					people: [...state.customer.people.concat(newRelatedCustomer)],
				};
			}

			return {
				...state,
				addCustomerAssociatedSuccess: true,
				customer: updatedCustomer,
				relatedCustomer: payload,
			};
		},
		addCustomerAssociatedToOrganizationSuccess: (state, { payload }) => {
			const newRelatedCustomer = {
				accountStatus: payload.accountStatus,
				firstName: payload.firstName,
				lastName: payload.lastName,
				name: `${payload.firstName} ${payload.lastName}`,
				personId: payload.personId,
				accountId: payload.accountId,
				email: payload.email,
				emailId: payload.emailId,
				gender: payload.gender,
				avatar: payload.avatar,
				isDependent: payload.isDependent,
				birthdate: payload.birthDate,
			} as TypeRelatedPerson;

			let updatedOrganization = { ...state.organization };

			//if adding from customer detail state.customer must be settled
			if (state.organization.people) {
				updatedOrganization = {
					...state.organization,
					people: [...state.organization.people.concat(newRelatedCustomer)],
				};
			}

			return {
				...state,
				addCustomerAssociatedToOrganizationSuccess: true,
				organization: updatedOrganization,
				relatedCustomer: payload,
			};
		},
		addCustomerAssociatedFromSearchSuccess: (state, { payload }) => {
			return {
				...state,
				addCustomerAssociatedSuccess: true,
				relatedCustomer: payload,
			};
		},
		addCustomerFail: (state, { payload }) => {
			return {
				...state,
				addCustomerSuccess: false,
				addCustomerAssociatedSuccess: false,
				error: payload,
			};
		},
		updateCustomerStart: (state, { payload }) => {
			return {
				...state,
				updateCustomerSuccess: false,
				updateCustomerAssociatedSuccess: false,
				updateCustomerError: false,
				error: "",
			};
		},
		updateCustomerSuccess: (state, { payload }) => {
			return {
				...state,
				updateCustomerSuccess: true,
				customer: payload.customer,
				hasExpiredWaiver: payload.hasExpiredAnyWaiver,
				expiredWaiverAccountIds: payload.expiredWaiverAccountIds,
				emailDuplicatedCustomer: {} as ICustomer,
				updateCustomerError: false,
				error: "",
			};
		},
		updateCustomerFail: (state, { payload }) => {
			return {
				...state,
				updateCustomerSuccess: false,
				error: payload,
				updateCustomerError: true,
			};
		},
		cleanCustomerError: (state) => {
			return {
				...state,
				updateCustomerError: false,
				error: "",
			};
		},
		updateCustomerAssociatedSuccess: (state, { payload }) => {
			const newRelatedCustomer = {
				accountStatus: payload.accountStatus,
				firstName: payload.firstName,
				lastName: payload.lastName,
				name: `${payload.firstName} ${payload.lastName}`,
				personId: payload.personId,
				accountId: payload.accountId,
				avatar: payload.avatar,
				isDependent: payload.isDependent,
				birthdate: payload.birthDate,
			} as TypeRelatedPerson;

			let updatedCustomer = { ...state.customer };
			if (state.customer.people) {
				updatedCustomer = {
					...state.customer,
					people: [...state.customer.people.concat(newRelatedCustomer)],
				};
			}

			return {
				...state,
				updateCustomerAssociatedSuccess: true,
				customer: updatedCustomer,
				relatedCustomer: payload,
			};
		},
		updateCustomerAssociatedToOrganizationSuccess: (state, { payload }) => {
			const newRelatedCustomer = {
				accountStatus: payload.accountStatus,
				firstName: payload.firstName,
				lastName: payload.lastName,
				name: `${payload.firstName} ${payload.lastName}`,
				personId: payload.personId,
				accountId: payload.accountId,
				avatar: payload.avatar,
				isDependent: payload.isDependent,
				birthdate: payload.birthDate,
			} as TypeRelatedPerson;

			let updatedOrganization = { ...state.organization };

			//if adding from customer detail state.customer must be settled
			if (state.organization.people) {
				updatedOrganization = {
					...state.organization,
					people: [...state.organization.people.concat(newRelatedCustomer)],
				};
			}

			return {
				...state,
				updateCustomerAssociatedSuccess: true,
				organization: updatedOrganization,
				relatedCustomer: payload,
			};
		},
		updateCustomerAsssociatedFromSearchSuccess: (state, { payload }) => {
			return {
				...state,
				updateCustomerSuccess: true,
				relatedCustomer: payload,
			};
		},
		updateCustomerPictureStart: (state, { payload }) => {
			return {
				...state,
				updateCustomerPictureStatus: false,
			};
		},
		updateCustomerPictureSuccess: (state, { payload }) => {
			// let finalPeople;

			const context = payload.isOrganization ? "organization" : "customer";

			// if (state[context].accountId !== payload.accountId) {
			// 	finalPeople = [
			// 		...state[context].people,
			// 		{
			// 			avatar: payload.avatar,
			// 			accountId: payload.accountId,
			// 			name: `${payload.firstName} ${payload.lastName}`
			// 		}
			// 	]

			// } else {
			// 	finalPeople = [...state[context].people]
			// }

			return {
				...state,
				updateCustomerPictureStatus: true,
				updatedPictureAccount: payload,
				[context]: {
					...state[context],
					profilePictureName:
						state[context].accountId === payload.accountId
							? payload.profilePictureName
							: state[context].profilePictureName,
					avatar:
						state[context].accountId === payload.accountId
							? payload.avatar
							: state[context].avatar,
				},
			};
		},
		updateCustomerPictureFail: (state, { payload }) => {
			return {
				...state,
				updateCustomerPictureStatus: false,
				error: payload,
			};
		},
		checkEmailDuplicatedStart: (state, { payload }) => {
			return {
				...state,
				emailDuplicated: false,
				checkEmailSuccess: false,
				checkingDuplicatedEmail: true,
			};
		},
		checkEmailDuplicatedSuccess: (state, { payload }) => {
			let duplicatedCustomer = {} as ICustomer;

			//if is not duplicated we must keep the typed email
			//and is not customer info
			if (payload.modalParent !== "customer-info") {
				if (payload.isDuplicated === false) {
					duplicatedCustomer.email = payload.email;
				} else {
					duplicatedCustomer = payload.customer;
				}
			}

			return {
				...state,
				emailDuplicated: payload.isDuplicated,
				emailDuplicatedCustomer: duplicatedCustomer,
				checkEmailSuccess: true,
				checkingDuplicatedEmail: false,
			};
		},
		checkEmailDuplicatedFail: (state, { payload }) => {
			return {
				...state,
				//I'm setting this as TRUE becuase if it fail, we should verify reason and block the form
				emailDuplicated: true,
				checkEmailSuccess: false,
				checkingDuplicatedEmail: false,
			};
		},
		setCustomerSelected: (state, { payload }) => {
			return {
				...state,
				customer: payload,
			};
		},
		//this method is responsable for trigger the selected customer id when clicking on profiles from within a profile
		triggerSelectedProfile: (state, { payload }) => {
			return {
				...state,
				triggeredProfileAccountId: payload,
			};
		},
		setOrganization: (state, { payload }) => {
			return {
				...state,
				organization: payload,
			};
		},

		setDisableSubmitForm: (state, { payload }) => {
			return {
				...state,
				disableSubmitForm: payload,
			};
		},

		setWaiverExpiredAfperUpdate: (state, { payload }) => {
			return {
				...state,
				hasExpiredWaiver: payload,
			};
		},
	},
});

export const {
	fetchCustomerStart,
	fetchCustomerComplete,
	fetchCustomerFail,
	resetCustomer,
	resetEmailDuplicatedCustomer,
	addCustomerStart,
	addCustomerSuccess,
	addCustomerFail,
	addCustomerAssociatedSuccess,
	addCustomerAssociatedFromSearchSuccess,
	addCustomerAssociatedToOrganizationSuccess,
	updateCustomerStart,
	updateCustomerSuccess,
	updateCustomerAssociatedSuccess,
	updateCustomerAsssociatedFromSearchSuccess,
	updateCustomerAssociatedToOrganizationSuccess,
	updateCustomerFail,
	cleanCustomerError,
	updateCustomerStatusStart,
	updateCustomerStatusSuccess,
	updateCustomerStatusFail,
	updateOrganizationStatusStart,
	updateOrganizationStatusSuccess,
	updateOrganizationStatusFail,
	setCustomerWaiverBasic,
	fetchCustomerWaiverStart,
	fetchCustomerWaiverComplete,
	fetchCustomerWaiverFail,
	fetchCustomerWaiversStart,
	fetchCustomerWaiversComplete,
	fetchCustomerWaiversFail,
	fetchDependentWaiversStart,
	fetchDependentWaiversComplete,
	fetchDependentWaiversFail,
	addNote,
	addNoteSuccess,
	addNotesFailure,
	updateNote,
	updateNoteSuccess,
	updateNotesFailure,
	deleteNote,
	deleteNoteSuccess,
	deleteNotesFailure,
	fetchCustomerNotesStart,
	fetchCustomerNotesCompleted,
	fetchCustomerNotesFail,
	addDependentToAccountStart,
	addDependentToAccountComplete,
	addDependentToAccountFromSearchComplete,
	addDependentToAccountFail,
	updateDependentStart,
	updateDependentComplete,
	updateDependentFail,
	addOrganizationStart,
	addOrganizationComplete,
	addOrganizationFail,
	addOrganizationToAccountComplete,
	updateOrganizationStart,
	updateOrganizationComplete,
	updateOrganizationFail,
	fetchCustomerLoyaltyStart,
	fetchCustomerLoyaltyComplete,
	fetchCustomerLoyaltyFail,
	updateCustomerPictureStart,
	updateCustomerPictureSuccess,
	updateCustomerPictureFail,
	addLinkedAccountsStart,
	addLinkedAccountsSuccess,
	addLinkedAccountsFail,
	deleteLinkedAccountsStart,
	deleteLinkedAccountsSuccess,
	deleteLinkedAccountsFail,
	checkEmailDuplicatedStart,
	checkEmailDuplicatedSuccess,
	checkEmailDuplicatedFail,
	addLabelStart,
	addLabelSuccess,
	addLabelFailure,
	removeLabelFromCustomerFailure,
	removeLabelFromCustomerSuccess,
	removeLabelFromCustomerStart,
	fetchLabelsStart,
	fetchLabelsSuccess,
	fetchLabelsFailure,
	removeLabelsStart,
	removeLabelsSuccess,
	removeLabelsFailure,
	updateCustomerLabelsList,
	fetchLabelRelationsStart,
	fetchLabelRelationsFailure,
	fetchLabelRelationsSuccess,
	addLabelToCustomerStart,
	addLabelToCustomerSuccess,
	addLabelToCustomerFailure,
	setEditLabel,
	unSetEditLabel,
	updateLabelStart,
	updateLabelFailure,
	updateLabelSuccess,
	confirmCustomerWaiverFail,
	confirmCustomerWaiverStart,
	confirmCustomerWaiverSuccess,
	expireCustomerWaiverFail,
	expireCustomerWaiverStart,
	expireCustomerWaiverSuccess,
	signCustomerWaiverStart,
	signWaiverCustomerSuccess,
	signWaiverCustomerFailure,
	fetchOrganizationStart,
	fetchOrganizationComplete,
	fetchOrganizationFail,
	addOrganizationContactComplete,
	addOrganizationContactFail,
	addOrganizationContactStart,
	removeOrganizationContactComplete,
	removeOrganizationContactFail,
	removeOrganizationContactStart,
	setCustomerSelected,
	triggerSelectedProfile,
	checkOrganizationDuplicatedStart,
	checkOrganizationDuplicatedSuccess,
	checkOrganizationDuplicatedFail,
	resetOrganizationDuplicated,

	fetchOrganizationAssociationsStart,
	fetchOrganizationAssociationsComplete,
	fetchOrganizationAssociationsFail,
	fetchOrganizationContactsStart,
	fetchOrganizationContactsComplete,
	fetchOrganizationContactsFail,
	setOrganizationPagination,
	addOrganizationLinkedAccountsStart,
	addOrganizationLinkedAccountsSuccess,
	addOrganizationLinkedAccountsFail,
	deleteOrganizationLinkedAccountsStart,
	deleteOrganizationLinkedAccountsSuccess,
	deleteOrganizationLinkedAccountsFail,

	setOrganization,
	addDependentToOrganizationFromSearchComplete,

	setDisableSubmitForm,
	setWaiverExpiredAfperUpdate,

	resetWaiverFlags,
} = customerSlice.actions;

export default customerSlice.reducer;
