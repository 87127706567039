import { createSlice } from "@reduxjs/toolkit";
import { EmailThemeCategory, IEmailTemplate, IEmailTheme } from "./emailTemplate.typings";
import emailTemplateLayoutReducer from "./reducers/layout.reducer";
import themesReducer from "./reducers/themes.reduces";

const initalEmailTemplate = {
	emailTemplate: {
		emailTemplateId: 0,
		templateName: "",
	} as IEmailTemplate,
	isLoading: false,
	fetchEmailTemplateSuccess: false,
	addEmailTemplateSuccess: false,
	updateEmailTemplateSuccess: false,
	removeEmailTemplateSuccess: false,
	error: "" as any,
	emailTemplates: [] as IEmailTemplate[],
	emailThemes: [] as IEmailTheme[],
	selectedEmailTemplate: {} as IEmailTemplate,
	selectedEmailTheme: {} as IEmailTheme,
	isLoadingTemplate: false,
	layout: {
		isEmailTemplateModalOpen: false,
		selectedTemplateId: 0,
		selectedTemplateType: "" as EmailThemeCategory,
	},
	isLoadingPreview: false,
	previewPageResult: "",
	sendEmailTestSuccess: false,
	cantDeleteEmailTemplate: false,
};

const emailTemplateSlice = createSlice({
	name: "EMAIL_TEMPLATE",
	initialState: initalEmailTemplate,
	reducers: {
		...emailTemplateLayoutReducer(),
		...themesReducer(),
		addEmailTemplateStart: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				addEmailTemplateSuccess: false,
				error: "",
			};
		},
		addEmailTemplateSuucess: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				emailTemplate: payload.data,
				addEmailTemplateSuccess: true,
				error: "",
			};
		},
		addEmailTemplateFailure: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				addEmailTemplateSuccess: false,
				error: payload,
			};
		},
		fetchEmailTemplatesStart: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				fetchEmailTemplateSuccess: false,
				error: "",
			};
		},
		fetchEmailTemplatesSuccess: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				fetchEmailTemplateSuccess: true,
				emailTemplates: payload,
				error: "",
			};
		},
		fetchEmailTemplatesFailure: (state, { payload }) => {
			return {
				...state,
				error: payload,
				fetchEmailTemplateSuccess: false,
				isLoading: false,
			};
		},
		getEmailTemplateByIdStart: (state, { payload }) => {
			return {
				...state,
				isLoadingTemplate: true,
				error: "",
			};
		},
		getEmailTemplateByIdSuccess: (state, { payload }) => {
			return {
				...state,
				isLoadingTemplate: false,
				selectedEmailTemplate: payload,
				error: "",
			};
		},
		getEmailTemplateByIdFailure: (state, { payload }) => {
			return {
				...state,
				isLoadingTemplate: false,
				error: payload,
			};
		},
		unsetSelectedEmailTemplate: (state) => {
			return {
				...state,
				selectedEmailTemplate: {} as IEmailTemplate,
			};
		},
		updateEmailTemplateStart: (state, { payload }) => {
			return {
				...state,
				updateEmailTemplateSuccess: false,
				error: "",
			};
		},
		updateEmailTemplateSuccess: (state, { payload }) => {
			const updatedEmailTemplate = [payload] as IEmailTemplate[];
			return {
				...state,
				emailTemplates: state.emailTemplates.map(
					(emailTemplate) =>
						updatedEmailTemplate.find(
							(p) => p.emailTemplateId === emailTemplate.emailTemplateId
						) || emailTemplate
				),
				updateEmailTemplateSuccess: true,
				error: "",
			};
		},
		updateEmailTemplateFailure: (state, { payload }) => {
			return {
				...state,
				updateEmailTemplateSuccess: false,
				error: payload,
			};
		},
		removeEmailTemplateStart: (state, { payload }) => {
			return {
				...state,
				removeEmailTemplateSuccess: false,
				error: "",
			};
		},
		removeEmailTemplateSuccess: (state, { payload }) => {
			let emailTemplateList = {
				emailTemplates: [...state.emailTemplates],
			};

			if (payload.cantDelete === false) {
				emailTemplateList = {
					emailTemplates: payload.emailTemplateList,
				};
			}

			return {
				...state,
				removeEmailTemplateSuccess: !payload.cantDelete,
				cantDeleteEmailTemplate: payload.cantDelete,
				emailTemplates: emailTemplateList.emailTemplates,
				error: "",
			};
		},
		removeEmailTemplateFailure: (state, { payload }) => {
			return {
				...state,
				error: payload,
				removeEmailTemplateSuccess: false,
			};
		},
		resetCantDelete: (state) => {
			return {
				...state,
				cantDeleteEmailTemplate: false,
			};
		},
		sendTemplateTestStart: (state, { payload }) => {
			return {
				...state,
				sendEmailTestSuccess: false,
				error: "",
			};
		},
		sendTemplateTestSuccess: (state, { payload }) => {
			return {
				...state,
				sendEmailTestSuccess: true,
				error: "",
			};
		},
		sendTemplateTestFailure: (state, { payload }) => {
			return {
				...state,
				sendEmailTestSuccess: false,
				error: payload,
			};
		},
	},
});

export const {
	addEmailTemplateStart,
	addEmailTemplateSuucess,
	addEmailTemplateFailure,
	fetchEmailTemplatesStart,
	fetchEmailTemplatesSuccess,
	fetchEmailTemplatesFailure,
	getEmailTemplateByIdStart,
	getEmailTemplateByIdSuccess,
	getEmailTemplateByIdFailure,
	updateEmailTemplateStart,
	updateEmailTemplateSuccess,
	updateEmailTemplateFailure,
	unsetSelectedEmailTemplate,
	removeEmailTemplateStart,
	removeEmailTemplateFailure,
	removeEmailTemplateSuccess,
	resetCantDelete,
	// LAYOUT
	setEmailTemplateModalOpen,
	setEmailTemplateModalClose,
	setSelectedEmailTheme,
	// THEMES
	fetchEmailThemeStart,
	fetchEmailThemeSuccess,
	fetchEmailThemeFailure,
	// SEND TEST
	sendTemplateTestStart,
	sendTemplateTestSuccess,
	sendTemplateTestFailure,
} = emailTemplateSlice.actions;

export default emailTemplateSlice.reducer;
