import React from "react";
import { Box } from "@agilekit/ui";

export interface ITableCollapseItemList {
	tableRow: React.ReactNode;
	children: React.ReactNode;
}

/** All styling should come from the main component */

const TableCollapseItemList: React.FC<ITableCollapseItemList> = ({
	/** Tablerow is usually each row that will be iterated outside this component */
	tableRow,
	/** Children is usually the table header */
	children,
}) => {
	return (
		<Box mt={6} mb={2}>
			<Box pb={3}>{children}</Box>
			<Box>{tableRow}</Box>
		</Box>
	);
};

export default TableCollapseItemList;
