import { ICustomer, IOrganization } from "../customer.typings";

const linkedAccountsReducer = () => {
	return {
		addLinkedAccountsStart: (state, { payload }) => {
			return {
				...state,
				addLinkedAccountsSuccess: false,
			};
		},
		addLinkedAccountsSuccess: (state, { payload }) => {
			let customerAccout = {} as ICustomer;
			let organizationAccount = {} as IOrganization;
			payload.isOrganization
				? (organizationAccount = payload)
				: (customerAccout = payload);

			return {
				...state,
				addLinkedAccountsSuccess: true,
				customer: customerAccout,
				organization: organizationAccount,
			};
		},
		addLinkedAccountsFail: (state, { payload }) => {
			return {
				...state,
				addLinkedAccountsSuccess: false,
				error: payload,
			};
		},
		deleteLinkedAccountsStart: (state, { payload }) => {
			return {
				...state,
				deleteLinkedAccountsSuccess: false,
				deleteLinkedAccountsFailure: false,
			};
		},
		deleteLinkedAccountsSuccess: (state, { payload }) => {
			let customerAccout = {} as ICustomer;
			let organizationAccount = {} as IOrganization;
			payload.isOrganization
				? (organizationAccount = payload)
				: (customerAccout = payload);
			return {
				...state,
				deleteLinkedAccountsSuccess: true,
				deleteLinkedAccountsFailure: false,
				customer: customerAccout,
				organization: organizationAccount,
			};
		},
		deleteLinkedAccountsFail: (state, { payload }) => {
			return {
				...state,
				deleteLinkedAccountsSuccess: false,
				deleteLinkedAccountsFailure: true,
				error: payload.error,
				failedLinkedAccountId: payload.failedLinkedAccountId,
			};
		},

		// ORGANIZATION LINKED ACCOUNTS // DOES NOT RETURN PEOPLE
		addOrganizationLinkedAccountsStart: (state, { payload }) => {
			return {
				...state,
				addOrganizationLinkedAccountSuccess: false,
			};
		},
		addOrganizationLinkedAccountsSuccess: (state, { payload }) => {
			const organizationAccount = payload as IOrganization;
			return {
				...state,
				addOrganizationLinkedAccountSuccess: true,
				organization: organizationAccount,
			};
		},
		addOrganizationLinkedAccountsFail: (state, { payload }) => {
			return {
				...state,
				addOrganizationLinkedAccountSuccess: false,
				error: payload,
			};
		},
		deleteOrganizationLinkedAccountsStart: (state, { payload }) => {
			return {
				...state,
				deleteOrganizationLinkedAccountSuccess: false,
				deleteOrganizationLinkedAccountFailure: false,
			};
		},
		deleteOrganizationLinkedAccountsSuccess: (state, { payload }) => {
			const organizationAccount = payload as IOrganization;
			return {
				...state,
				deleteOrganizationLinkedAccountSuccess: true,
				deleteOrganizationLinkedAccountFailure: false,
				organization: organizationAccount,
			};
		},
		deleteOrganizationLinkedAccountsFail: (state, { payload }) => {
			return {
				...state,
				deleteOrganizationLinkedAccountSuccess: false,
				deleteOrganizationLinkedAccountFailure: true,
				error: payload.error,
				failedLinkedAccountId: payload.failedLinkedAccountId,
			};
		},
	};
};

export default linkedAccountsReducer;
