import { TypeRelatedOrganization } from "../customer.typings";

const organizationReducer = () => {
	return {
		addOrganizationStart: (state, { payload }) => {
			return {
				...state,
				addOrganizationSuccess: false,
				addCustomerSuccess: false,
				addDependentSuccess: false,
				addLinkedAccountsSuccess: false,
				addOrgazanitionToAccountSuccess: false,
				addCustomerAssociatedSuccess: false,
				updateCustomerAssociatedSuccess: false,
				updateCustomerSuccess: false,
				updateOrganizationSuccess: false,
				updateDependentSuccess: false,
			};
		},
		addOrganizationComplete: (state, { payload }) => {
			return {
				...state,
				organization: payload,
				addOrganizationSuccess: true,
			};
		},
		addOrganizationFail: (state, { payload }) => {
			return {
				...state,
				error: payload,
				addOrganizationSuccess: false,
			};
		},
		updateOrganizationStart: (state, { payload }) => {
			return {
				...state,
				updateOrganizationSuccess: false,
				updateOrganizationFailure: false,
			};
		},
		updateOrganizationComplete: (state, { payload }) => {
			return {
				...state,
				organization: payload,
				updateOrganizationSuccess: true,
				updateOrganizationFailure: false,
			};
		},
		updateOrganizationFail: (state, { payload }) => {
			return {
				...state,
				error: payload,
				updateOrganizationSuccess: false,
				updateOrganizationFailure: true,
			};
		},
		addOrganizationToAccountComplete: (state, { payload }) => {
			const newRelatedOrganization = {
				accountId: payload.accountId,
				name: payload.organizationName,
				organizationId: payload.organizationId,
			} as TypeRelatedOrganization;

			let updatedCustomer = { ...state.customer };
			if (state.customer.organizations) {
				updatedCustomer = {
					...state.customer,
					organizations: [
						...state.customer.organizations.concat(newRelatedOrganization),
					],
				};
			}

			return {
				...state,
				addOrgazanitionToAccountSuccess: true,
				organization: payload,
				customer: updatedCustomer,
			};
		},
		fetchOrganizationStart: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				addCustomerSuccess: false,
				addDependentSuccess: false,
				addLinkedAccountsSuccess: false,
				addOrganizationSuccess: false,
				addOrganizationContactSuccess: false,
				removeOrganizationContactSuccess: false,
				fetchOrganizationSuccess: false,
			};
		},
		fetchOrganizationComplete: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				organization: payload,
				addCustomerSuccess: false,
				addDependentSuccess: false,
				addLinkedAccountsSuccess: false,
				addOrganizationSuccess: false,
				addOrganizationContactSuccess: false,
				removeOrganizationContactSuccess: false,
				fetchOrganizationSuccess: true,
			};
		},
		fetchOrganizationFail: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				error: payload,
				addCustomerSuccess: false,
				addDependentSuccess: false,
				addLinkedAccountsSuccess: false,
				addOrganizationSuccess: false,
				addOrganizationContactSuccess: false,
				removeOrganizationContactSuccess: false,
				fetchOrganizationSuccess: false,
			};
		},
		addOrganizationContactStart: (state, { payload }) => {
			return {
				...state,
				addOrganizationContactSuccess: false,
				removeOrganizationContactSuccess: false,
				addCustomerSuccess: false,
				addDependentSuccess: false,
				addLinkedAccountsSuccess: false,
				addOrganizationSuccess: false,
			};
		},
		addOrganizationContactComplete: (state, { payload }) => {
			return {
				...state,
				organization: payload,
				addOrganizationContactSuccess: true,
				removeOrganizationContactSuccess: false,
				addCustomerSuccess: false,
				addDependentSuccess: false,
				addLinkedAccountsSuccess: false,
				addOrganizationSuccess: false,
			};
		},
		addOrganizationContactFail: (state, { payload }) => {
			return {
				...state,
				error: payload,
				addOrganizationContactSuccess: false,
				removeOrganizationContactSuccess: false,
				addCustomerSuccess: false,
				addDependentSuccess: false,
				addLinkedAccountsSuccess: false,
				addOrganizationSuccess: false,
			};
		},
		removeOrganizationContactStart: (state, { payload }) => {
			return {
				...state,
				removeOrganizationContactSuccess: false,
				addOrganizationContactSuccess: false,
				addCustomerSuccess: false,
				addDependentSuccess: false,
				addLinkedAccountsSuccess: false,
				addOrganizationSuccess: false,
			};
		},
		removeOrganizationContactComplete: (state, { payload }) => {
			return {
				...state,
				organization: {
					...state.organization,
					people: payload.people,
				},
				removeOrganizationContactSuccess: true,
				addOrganizationContactSuccess: false,
				addCustomerSuccess: false,
				addDependentSuccess: false,
				addLinkedAccountsSuccess: false,
				addOrganizationSuccess: false,
			};
		},
		removeOrganizationContactFail: (state, { payload }) => {
			return {
				...state,
				error: payload,
				removeOrganizationContactSuccess: false,
				addOrganizationContactSuccess: false,
				addCustomerSuccess: false,
				addDependentSuccess: false,
				addLinkedAccountsSuccess: false,
				addOrganizationSuccess: false,
			};
		},
		checkOrganizationDuplicatedStart: (state, { payload }) => {
			return {
				...state,
				organizationDuplicated: state.organizationDuplicated,
				checkDuplicatedOrganizationSuccess: false,
				checkingDuplicatedOrganization: true,
			};
		},
		checkOrganizationDuplicatedSuccess: (state, { payload }) => {
			return {
				...state,
				organizationDuplicated: payload.isDuplicated,
				duplicatedOrganization: payload.organization,
				checkDuplicatedOrganizationSuccess: true,
				checkingDuplicatedOrganization: false,
			};
		},
		checkOrganizationDuplicatedFail: (state, { payload }) => {
			return {
				...state,
				// I'm setting this as TRUE becuase if it fail, we should verify reason and block the form
				organizationDuplicated: true,
				checkDuplicatedOrganizationSuccess: false,
				checkingDuplicatedOrganization: false,
			};
		},
		resetOrganizationDuplicated: (state, { payload }) => {
			return {
				...state,
				organizationDuplicated: false,
				checkDuplicatedOrganizationSuccess: false,
				checkingDuplicatedOrganization: false,
			};
		},

		// Organization Associations
		fetchOrganizationAssociationsStart: (state, { payload }) => {
			return {
				...state,
				fetchOrganizationAssociationsSuccess: false,
				organizationAssociationsPagination: {
					...state.organizationAssociationsPagination,
					runPagination: true,
				},
			};
		},
		fetchOrganizationAssociationsComplete: (state, { payload }) => {
			const { data } = payload;
			const newPeople = data.results;
			if (
				state.organization.people !== undefined &&
				state.organization.people !== null
			) {
				state.organization.people
					.filter((a) => a.isContact)
					.map((a) => newPeople.push(a));
			}

			return {
				...state,
				fetchOrganizationAssociationsSuccess: true,
				organization: {
					...state.organization,
					people: newPeople,
				},
				organizationAssociationsPagination: {
					...state.organizationAssociationsPagination,
					pageSize: data.pageSize,
					pageNumber: data.currentPage,
					activeIndex: data.currentPage - 1,
					totalPages: data.totalPages,
					totalItems: data.totalItems,
					currentPage: data.currentPage,
					runPagination: false,
				},
			};
		},
		fetchOrganizationAssociationsFail: (state, { payload }) => {
			return {
				...state,
				fetchOrganizationAssociationsSuccess: false,
				organizationAssociationsPagination: {
					...state.organizationAssociationsPagination,
					runPagination: false,
				},
			};
		},
		// Organization Contacts
		fetchOrganizationContactsStart: (state, { payload }) => {
			return {
				...state,
				fetchOrganizationContactsSuccess: false,
			};
		},
		fetchOrganizationContactsComplete: (state, { payload }) => {
			const newPeople = payload;

			if (
				state.organization.people !== undefined &&
				state.organization.people !== null
			) {
				state.organization.people
					.filter((a) => !a.isContact)
					.map((a) => newPeople.push(a));
			}

			return {
				...state,
				fetchOrganizationContactsSuccess: true,
				organization: {
					...state.organization,
					people: newPeople,
				},
			};
		},
		fetchOrganizationContactsFail: (state, { payload }) => {
			return {
				...state,
				fetchOrganizationContactsSuccess: false,
			};
		},

		// PAGINATION EVENTS
		setOrganizationPagination: (state, { payload }) => {
			return {
				...state,
				organizationAssociationsPagination: payload,
			};
		},
	};
};

export default organizationReducer;
