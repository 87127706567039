import { ICustomerMembershipLayout } from "../customerMembership.typings";

export const layoutStates = {
	layout: {
		isAddSubscriptionDrawerOpen: false,
		isBillingDrawerOpen: false,
		isMembersDrawerOpen: false,
		isMemberDeleteModalOpen: false,
		isPlanOwnerModalOpen: false,
		isTokenDrawerOpen: false,
		isPayErrorModalOpen: false,
		isPaymentDrawerOpen: false,
		isNoRemoveAssociationOpen: false,
		isSubscriptionBirthdateError: false,
	} as ICustomerMembershipLayout,
};

export const layoutReducer = () => {
	return {
		updateLayout: (state, { payload }) => {
			let {
				isAddSubscriptionDrawerOpen,
				isBillingDrawerOpen,
				isMembersDrawerOpen,
				isPlanOwnerModalOpen,
				isMemberDeleteModalOpen,
				isTokenDrawerOpen,
				isPayErrorModalOpen,
				isPaymentDrawerOpen,
				isNoRemoveAssociationOpen,
				isSubscriptionBirthdateError,
			} = state.layout;

			if (
				payload.isMemberDeleteModalOpen !== undefined &&
				payload.isMemberDeleteModalOpen !== null
			) {
				isMemberDeleteModalOpen = payload.isMemberDeleteModalOpen;
			}

			if (
				payload.isPlanOwnerModalOpen !== undefined &&
				payload.isPlanOwnerModalOpen !== null
			) {
				isPlanOwnerModalOpen = payload.isPlanOwnerModalOpen;
			}

			if (
				payload.isBillingDrawerOpen !== undefined &&
				payload.isBillingDrawerOpen !== null
			) {
				isBillingDrawerOpen = payload.isBillingDrawerOpen;
			}

			if (
				payload.isAddSubscriptionDrawerOpen !== undefined &&
				payload.isAddSubscriptionDrawerOpen !== null
			) {
				isAddSubscriptionDrawerOpen = payload.isAddSubscriptionDrawerOpen;
			}

			if (
				payload.isMembersDrawerOpen !== undefined &&
				payload.isMembersDrawerOpen !== null
			) {
				isMembersDrawerOpen = payload.isMembersDrawerOpen;
			}

			if (
				payload.isTokenDrawerOpen !== undefined &&
				payload.isTokenDrawerOpen !== null
			) {
				isTokenDrawerOpen = payload.isTokenDrawerOpen;
			}

			if (
				payload.isPayErrorModalOpen !== undefined &&
				payload.isPayErrorModalOpen !== null
			) {
				isPayErrorModalOpen = payload.isPayErrorModalOpen;
			}

			if (
				payload.isPaymentDrawerOpen !== undefined &&
				payload.isPaymentDrawerOpen !== null
			) {
				isPaymentDrawerOpen = payload.isPaymentDrawerOpen;
			}

			if (
				payload.isNoRemoveAssociationOpen !== undefined &&
				payload.isNoRemoveAssociationOpen !== null
			) {
				isNoRemoveAssociationOpen = payload.isNoRemoveAssociationOpen;
			}

			if (
				payload.isSubscriptionBirthdateError !== undefined &&
				payload.isSubscriptionBirthdateError !== null
			) {
				isSubscriptionBirthdateError = payload.isSubscriptionBirthdateError;
			}

			return {
				...state,
				layout: {
					isAddSubscriptionDrawerOpen,
					isBillingDrawerOpen,
					isMembersDrawerOpen,
					isPlanOwnerModalOpen,
					isMemberDeleteModalOpen,
					isTokenDrawerOpen,
					isPayErrorModalOpen,
					isPaymentDrawerOpen,
					isNoRemoveAssociationOpen,
					isSubscriptionBirthdateError,
				},
			};
		},
	};
};
