import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		toast: {
			"&.Toastify__toast-container": {
				width: "auto",
				[theme.breakpoints.down("xs")]: {
					width: "90%",
					left: "5%",
				},
				"&.Toastify__toast-container--top-center": {
					left: "50%",
					marginRight: "auto",
					marginLeft: "auto",
					transform: "translateX(-50%)",
					[theme.breakpoints.down("xs")]: {
						top: "10%",
					},
				},
			},
		},
	};
});

export default useStyles;
