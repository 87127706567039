import { DateTime } from "luxon";
import store from "../store/configureStore";
import { IFacilitySettings } from "../store/modules/enviroment/enviroment.slice";

/* eslint-disable no-console */
const calculateAge = (value: Date | string | undefined): number => {
	// if undefined get out
	if (!value) return 0;

	const myDate = DateTime.fromISO(value);

	// check if date is valid after conversion
	if (myDate.isValid === false) {
		console.log("invalid date");
		return 0;
	}

	const { timezoneCode }: IFacilitySettings =
		store.getState().enviroment.facilitySettings;
	// convert the base date to the facility timezone date
	const facilityDateZone = DateTime.local().setZone(timezoneCode);

	// make sure the conversion for the timezone is valid
	if (facilityDateZone.isValid === false) {
		console.log("invalid timezone");
		return 0;
	}

	// discard time from the date
	const facilityDate = DateTime.fromISO(facilityDateZone.toFormat("yyyy-MM-dd"));
	// use luxon diff fuction to get the difference in years
	const { years } = facilityDate.diff(myDate, ["years"]).toObject();

	// get the int part of it
	const age = Math.floor(years as number);
	return age;
};

export default calculateAge;
