import React from "react";
import { useLocation } from "react-router-dom";
import classnames from "classnames";

// ASSETS
import useStyles from "./ShellClassic.styles";

const ShellClassic = ({ children }) => {
	// ? HOOKS
	const classes = useStyles();
	const location = useLocation();

	// ? STYLES
	const cssContainer = classnames(classes.container, {
		[classes.menu]: location.pathname.includes("header"),
	});

	if (location.pathname.includes("header")) {
		return <div className={cssContainer}>{children}</div>;
	}

	return <>{children}</>;
};

export default ShellClassic;
