import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		root: {
			borderRadius: 3,
			cursor: "pointer",
			display: "flex",
			height: 40,
			"&:hover $filterLabel": {
				color: theme.palette.NEUTRAL700,
			},
			"&$rootSmall": {
				height: 32,
			},
		},
		rootActive: {
			"& $filterButton": {
				backgroundColor: theme.palette.NEUTRAL030,
				borderRadius: "3px 0 0 3px",
			},
			"& $filterClose": {
				display: "flex",
			},
			"& $filterLabel": {
				color: theme.palette.NEUTRAL700,
			},
		},
		rootSmall: {
			"& $filterButton": {
				padding: theme.spacing(1, 2),
				paddingRight: theme.spacing(0.25),
			},
			"& $filterClose": {
				height: 32,
			},
		},
		filterButton: {
			cursor: "pointer",
			display: "flex",
			alignItems: "center",
			position: "relative",
			fontSize: theme.typography.pxToRem(14),
			lineHeight: theme.typography.pxToRem(17),
			color: theme.palette.NEUTRAL700,
			padding: theme.spacing(2, 2),
			paddingRight: theme.spacing(0.25),
			borderRadius: 3,
			"&:hover": {
				backgroundColor: theme.palette.NEUTRAL040,
				color: theme.palette.NEUTRAL900,
			},
			"&:focus": {
				backgroundColor: theme.palette.NEUTRAL040,
				outline: "none",
			},
			"$rootSmall &": {
				fontSize: theme.typography.pxToRem(12),
			},
		},
		filterLabel: {
			marginRight: theme.spacing(0.5),
			color: theme.palette.NEUTRAL600,
		},
		filterValue: {},
		filterClose: {
			display: "none",
			backgroundColor: theme.palette.NEUTRAL040,
			alignItems: "center",
			borderRadius: "0 3px 3px 0",
			height: 40,
			width: 28,
			minWidth: 28,
			justifyContent: "center",
			outline: "none",
			"&:hover": {
				backgroundColor: theme.palette.NEUTRAL050,
			},
		},
	};
});

export default useStyles;
