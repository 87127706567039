import { takeLatest, put, call } from "redux-saga/effects";
// import { SeverityLevel } from "@microsoft/applicationinsights-web";

// HELPERS
import buildCustomersQueryString, { buildFilterObject } from "./customers.helpers";
import {
	fetchCustomersStart,
	fetchCustomersComplete,
	fetchCustomersFail,
} from "./customers.slice";
import CustomersAPI from "./customers.api";
import { saveState } from "../../../utils/sessionStorage";

export function* fetchCustomers({ payload }) {
	try {
		// const { appInsights } = store.getState().appInsights as any;
		const queryString = buildCustomersQueryString();

		// appInsights.trackTrace({
		// 	message: `Customers - Initiated request`,
		// 	properties: { queryString },
		// 	severityLevel: SeverityLevel.Information
		// });

		if (payload.saveQueryState && navigator.cookieEnabled) {
			saveState("customersFilter", JSON.stringify(buildFilterObject()));
		}

		const { data } = yield call(CustomersAPI.fetchCustomers, queryString);

		// appInsights.trackTrace({
		// 	message: `Customers - Request success`,
		// 	properties: { data },
		// 	severityLevel: SeverityLevel.Information
		// });

		yield put(fetchCustomersComplete({ data }));
	} catch (e: any) {
		// const { appInsights } = store.getState().appInsights as any;

		// appInsights.trackTrace({
		// 	message: `Customers - Request failed`,
		// 	properties: { error: e },
		// 	severityLevel: SeverityLevel.Information
		// });
		yield put(fetchCustomersFail(e.message));
	}
}

// Root Sagas
export function* watchCustomers() {
	return [yield takeLatest(fetchCustomersStart, fetchCustomers)];
}
