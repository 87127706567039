import { takeLatest, call, put } from "redux-saga/effects";
import ScheduleCustomerApi from "./scheduleCustomer.api";
import {
	updateCustomerSuccess,
	updateCustomerFailure,
	updateOrganizationRequest,
	updateAssociationRequest,
	updateCustomerRequest,
	getSelectedProfileFailure,
	getSelectedProfileSuccess,
	getSelectedProfileStart,
} from "./scheduleCustomer.slice";
import CustomerAPI from "../customer/customer.api";

export function* updateCustomerProfile({ payload }): any {
	try {
		const response = yield call(ScheduleCustomerApi.updateCustomer, payload);
		yield put(updateCustomerSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(updateCustomerFailure({ error }));
	}
}

export function* updateDependent({ payload }): any {
	try {
		const response = yield call(ScheduleCustomerApi.updateCustomer, payload);
		yield put(updateCustomerSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(updateCustomerFailure({ error }));
	}
}

export function* updateOrganization({ payload }): any {
	try {
		const response = yield call(ScheduleCustomerApi.updateCustomer, payload);
		yield put(updateCustomerSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(updateCustomerFailure({ error }));
	}
}

export function* getSelectedProfile({ payload }): any {
	try {
		const { accountId, isOrganization } = payload;

		const { data } = isOrganization
			? yield call(CustomerAPI.fetchOrganization, accountId as number)
			: yield call(CustomerAPI.fetchCustomer, accountId as number);

		yield put(getSelectedProfileSuccess({ data }));
	} catch (error: any) {
		yield put(getSelectedProfileFailure({ error }));
	}
}

// WATCHERS
export function* watchScheduleCustomer() {
	return [
		yield takeLatest(updateCustomerRequest, updateCustomerProfile),
		yield takeLatest(updateAssociationRequest, updateDependent),
		yield takeLatest(updateOrganizationRequest, updateOrganization),
		yield takeLatest(getSelectedProfileStart, getSelectedProfile),
	];
}
