import { AxiosPromise } from "axios";

// NPM PACKAGES
import Http from "../../../utils/restClient";

export default class ProductsApi {
	public static GetProductsList(payload: any) {
		return Http.axios().get(
			`/api/v1/products?pageNumber=${payload.page}&search=${payload.search}&category=${payload.category}`
		) as AxiosPromise<any>;
	}

	public static GetProductsBarCode(barCode) {
		return Http.axios().get(`/api/v1/products/barCode/${barCode}`);
	}
}
