import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {
			padding: theme.spacing(2, 0),
		},
		orderList: {
			backgroundColor: theme.palette.NEUTRAL030,
			padding: theme.spacing(3, 5),
			maxHeight: 430,
			overflowY: "auto",
			overflowX: "hidden",
			[theme.breakpoints.down("xs")]: {
				maxHeight: 385,
			},
			"&::-webkit-scrollbar-track": {
				boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
				borderRadius: 0,
				backgroundColor: theme.palette.NEUTRAL020,
			},
			"&::-webkit-scrollbar": {
				width: 4,
				backgroundColor: "#F5F5F5",
			},
			"&::-webkit-scrollbar-thumb": {
				borderRadius: 10,
				boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
				backgroundColor: theme.palette.NEUTRAL100,
			},
		},
		orderSearchLinkContainer: {
			borderTop: `1px solid ${theme.palette.NEUTRAL040}`,
			padding: theme.spacing(2, 4),
			fontSize: theme.typography.pxToRem(13),
			"& button": {
				color: theme.palette.BLUE400,
				backgroundColor: "transparent",
				border: "none",
				borderRadius: 4,
				padding: theme.spacing(1.5, 2),
				cursor: "pointer",
				"&:hover": {
					backgroundColor: theme.palette.BLUE050,
				},
			},
		},
		noResultsContainer: {
			marginTop: theme.spacing(2),
			padding: theme.spacing(2, 4),
		},
		loadingContainer: {
			display: "flex",
			alignItems: "center",
			height: "100%",
			width: "100%",
			justifyContent: "space-around",
			marginBottom: theme.spacing(4),
		},
	};
});

export default useStyles;
