import {
	buildSlotGrid,
	createScreen,
	DEFAULT_PROFILE_IDENTIFIER,
	deleteProfileFromLocalStorage,
	initialScreen,
	saveLastActivePOSProfileId,
} from "../pointOfSale.helpers";
import { IProfile, IScreen, IScreenButton, IScreenState } from "../pointOfSale.typings";

// Define the initial profile object
const initialProfile = {
	identifier: DEFAULT_PROFILE_IDENTIFIER,
	name: "Main Profile",
	screens: [initialScreen],
	totalScreens: 0,
	id: 0,
	order: 0,
};

export const profilesState = {
	unsavedScreenChanges: false,
	hasChangedScreenPosition: false,
	immutableSelectedScreen: {} as IScreen,
	immutableSelectedProfile: {} as IProfile,
	selectedScreen: {
		saveStateLoading: {
			started: false,
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		screen: initialScreen,
	} as IScreenState,
	selectedProfile: initialProfile,
	profiles: {
		fetchStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		fetchDetailStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		saveStateLoading: {
			started: false,
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		updateStateLoading: {
			started: false,
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		deleteStateLoading: {
			started: false,
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		duplicateStateLoading: {
			started: false,
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		items: [] as Array<IProfile>,
	},
};

const profilesReducer = () => {
	return {
		fetchProfilesStart: (state, { payload }) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					fetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchProfilesComplete: (state, { payload }) => {
			const { posProfiles, profileDetails } = payload;

			//select the first profile by default
			let selectedProfile = initialProfile;

			if (profileDetails) {
				selectedProfile = profileDetails;
			} else if (posProfiles.length) {
				selectedProfile = posProfiles[0];
			}

			let selectedScreen = initialScreen;
			if (profileDetails) {
				selectedScreen = profileDetails.screens[0];
			} else if (selectedProfile.screens && selectedProfile.screens.length > 0) {
				selectedScreen = selectedProfile.screens[0];
			}

			if (!selectedScreen) {
				selectedScreen = initialScreen;
			}

			return {
				...state,
				profiles: {
					...state.profiles,
					fetchStateLoading: {
						...state.profiles.fetchStateLoading,

						loading: false,
						success: true,
					},
					items: posProfiles,
				},
				selectedProfile: selectedProfile,
				selectedScreen: {
					...state.selectedScreen,
					screen: selectedScreen,
				},
				immutableSelectedScreen: selectedScreen,
				immutableSelectedProfile: selectedProfile,
			};
		},
		fetchProfilesFail: (state, { payload }) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					fetchStateLoading: {
						...state.profiles.fetchStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		fetchProfileDetailsStart: (state, { payload }) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					fetchDetailStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchProfileDetailsCompleted: (state, { payload }) => {
			const selectedProfile = payload.data.results.posProfile;
			let selectedScreen;

			saveLastActivePOSProfileId(payload.facilityId, selectedProfile);

			if (payload.data.keepSelectedScreen === false) {
				selectedScreen = selectedProfile.screens.length
					? selectedProfile.screens[0]
					: initialScreen;
			} else {
				selectedScreen = state.selectedScreen.screen;
			}

			return {
				...state,
				profiles: {
					...state.profiles,
					fetchDetailStateLoading: {
						...state.profiles.fetchDetailStateLoading,
						loading: false,
						success: true,
					},
				},
				selectedProfile: selectedProfile,
				selectedScreen: {
					...state.selectedScreen,
					screen: selectedScreen,
				},
				immutableSelectedScreen: selectedScreen,
				immutableSelectedProfile: selectedProfile,
			};
		},
		fetchProfileDetailsFailure: (state, { payload }) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					fetchDetailStateLoading: {
						...state.profiles.fetchDetailStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		addNewProfileStart: (state, { payload }) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					saveStateLoading: {
						started: true,
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
				},
			};
		},
		addNewProfileCompleted: (state, { payload }) => {
			const addedProfile = payload.data.results.posProfile;
			addedProfile.totalScreens = 1;

			const isFirstProfile = !payload.data.hasSetProfiles;

			return {
				...state,
				profiles: {
					...state.profiles,
					saveStateLoading: {
						...state.profiles.saveStateLoading,
						success: true,
					},
					items: isFirstProfile
						? [addedProfile]
						: [...state.profiles.items, addedProfile].sort((a, b) =>
								a.name.localeCompare(b.name)
						  ),
				},
				selectedProfile: addedProfile,
				selectedScreen: {
					...state.selectedScreen,
					screen:
						isFirstProfile || !addedProfile.length
							? initialScreen
							: addedProfile[0],
				},
			};
		},
		addNewProfileFailure: (state, { payload }) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					saveStateLoading: {
						...state.profiles.saveStateLoading,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		updateProfileStart: (state, { payload }) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					updateStateLoading: {
						started: true,
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
				},
			};
		},
		updatedProfileCompleted: (state, { payload }) => {
			const updatedProfile = payload.data.results.posProfile;

			const updatedItems = state.profiles.items.map(
				(profile) =>
					([updatedProfile] as IProfile[]).find(
						(p) => p.identifier === profile.identifier
					) || profile
			);

			return {
				...state,
				profiles: {
					...state.profiles,
					updateStateLoading: {
						...state.profiles.updateStateLoading,
						success: true,
					},
					items: updatedItems.sort((a, b) => a.name.localeCompare(b.name)),
				},
				selectedProfile: updatedProfile,
			};
		},
		updateProfileFailure: (state, { payload }) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					updateStateLoading: {
						...state.profiles.updateStateLoading,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		resetDeletedFlag: (state) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					deleteStateLoading: {
						started: false,
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
				},
			};
		},
		deleteProfileStart: (state, { payload }) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					deleteStateLoading: {
						started: true,
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
				},
			};
		},
		deleteProfileCompleted: (state, { payload }) => {
			// const hasAnyProfile = payload.data.results.posProfiles.length;
			//
			const selectedProfile = payload.data.results.posProfiles[0];
			//
			// const items = hasAnyProfile
			// 	? payload.data.results.posProfiles
			// 	: [initialProfile];
			//
			// const screen =
			// 	selectedProfile.screens && selectedProfile.screens.length
			// 		? selectedProfile.screens[0]
			// 		: initialScreen;

			deleteProfileFromLocalStorage(payload.facilityId, selectedProfile.identifier);

			return {
				...state,
				profiles: {
					...state.profiles,
					deleteStateLoading: {
						...state.profiles.deleteStateLoading,
						success: true,
					},
					// items: items,
				},
				// selectedProfile: hasAnyProfile ? selectedProfile : initialProfile,
				// selectedScreen: {
				// 	...state.selectedScreen,
				// 	screen: screen,
				// },
			};
		},
		deleteProfileFailure: (state, { payload }) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					deleteStateLoading: {
						...state.profiles.deleteStateLoading,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		duplicateProfileStart: (state, { payload }) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					duplicateStateLoading: {
						started: true,
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
				},
			};
		},
		duplicateProfileCompleted: (state, { payload }) => {
			const duplicatedProfile = payload.data.results.posProfile;

			return {
				...state,
				profiles: {
					...state.profiles,
					duplicateStateLoading: {
						...state.profiles.deleteStateLoading,
						success: true,
					},
					items: payload.data.results.profiles.map(
						(profile) =>
							([duplicatedProfile] as IProfile[]).find(
								(p) => p.identifier === profile.identifier
							) || profile
					),
				},
				selectedProfile: duplicatedProfile,
				selectedScreen: {
					...state.selectedScreen,
					screen: duplicatedProfile.screens[0],
				},
			};
		},
		duplicateProfileFailure: (state, { payload }) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					duplicateStateLoading: {
						...state.profiles.deleteStateLoading,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		setProfileAddNewMode: (state) => {
			return {
				...state,
				profiles: {
					...state.profiles,
					items: [initialProfile],
				},
			};
		},
		saveScreenStart: (state, { payload }) => {
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					saveStateLoading: {
						started: true,
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
				},
			};
		},
		saveScreenCompleted: (state, { payload }) => {
			const savedScreen = payload.data.results.posScreen;
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					saveStateLoading: {
						started: false,
						success: true,
					},
					screen: savedScreen,
				},
				selectedProfile: {
					...state.selectedProfile,
					screens: state.selectedProfile.screens.map(
						(screen) =>
							([savedScreen] as IScreen[]).find(
								(s) => s.name === screen.name
							) || screen
					),
					totalScreens: state.selectedProfile.totalScreens + 1,
				},
				unsavedScreenChanges: false,
			};
		},
		saveScreenFailure: (state, { payload }) => {
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					saveStateLoading: {
						...state.selectedScreen.saveStateLoading,
						errorMessage: payload.error,
						hasError: true,
					},
				},
			};
		},
		saveScreenPositionStart: (state, { payload }) => {
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					saveStateLoading: {
						started: true,
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
				},
			};
		},
		saveScreenPositionCompleted: (state, { payload }) => {
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					saveStateLoading: {
						success: true,
					},
				},
				hasChangedScreenPosition: false,
			};
		},
		saveScreenPositionFailure: (state, { payload }) => {
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					saveStateLoading: {
						...state.selectedScreen.saveStateLoading,
						errorMessage: payload.error,
						hasError: true,
					},
				},
			};
		},
		deleteScreenStart: (state, { payload }) => {
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					deleteStateLoading: {
						...state.selectedScreen.deleteStateLoading,
						started: false,
						success: false,
					},
				},
			};
		},
		deleteScreenCompleted: (state, { payload }) => {
			let screens = payload.data.results.screens;
			let unsavedChanges = false;

			if (!screens.length) {
				screens = [initialScreen];
				unsavedChanges = true;
			}

			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					deleteStateLoading: {
						...state.selectedScreen.deleteStateLoading,
						started: false,
						success: true,
					},
					// screen: screens[0],
				},
				selectedProfile: {
					...state.selectedProfile,
					screens: screens,
				},
				unsavedScreenChanges: unsavedChanges,
			};
		},
		deleteScreenFailure: (state, { payload }) => {
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					deleteStateLoading: {
						...state.selectedScreen.deleteStateLoading,
						errorMessage: payload,
						hasError: true,
						success: false,
					},
				},
			};
		},
		resetDeleteScreenFlag: (state) => {
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					deleteStateLoading: {
						...state.selectedScreen.deleteStateLoading,
						success: false,
					},
				},
			};
		},
		addScreenStart: (state) => {
			const newScreen = createScreen(
				"New Screen",
				state.selectedProfile.screens.length + 1
			);

			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					screen: newScreen,
				},
				selectedProfile: {
					...state.selectedProfile,
					screens: [...state.selectedProfile.screens, newScreen],
				},
				unsavedScreenChanges: true,
			};
		},
		fetchScreenStart: (state, { payload }) => {
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					fetchStateLoading: {
						...state.selectedScreen.fetchStateLoading,
						started: true,
						errorMessage: "",
						hasError: false,
						loading: false,
						success: false,
					},
				},
			};
		},
		fetchScreenCompleted: (state, { payload }) => {
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					fetchStateLoading: {
						...state.selectedScreen.fetchStateLoading,
						started: false,
						success: true,
					},
					screen: payload.data.results.screen,
				},
				immutableSelectedScreen: payload.data.results.screen,
			};
		},
		fetchScreenFailure: (state, { payload }) => {
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					fetchStateLoading: {
						...state.selectedScreen.fetchStateLoading,
						started: true,
						errorMessage: payload,
						hasError: true,
					},
				},
			};
		},
		updateScreenName: (state, { payload }) => {
			const { selectedScreen } = state;
			selectedScreen.screen.name = payload;

			//update name in list
			state.selectedProfile.screens.map((screen) => {
				if (screen.identifier === selectedScreen.screen.identifier) {
					screen.name = payload;
				}

				return null;
			});

			state.unsavedScreenChanges = true;

			//update selected profile
		},
		onCancelChanges: (state) => {
			return {
				...state,
				selectedScreen: {
					...state.selectedScreen,
					screen: state.immutableSelectedScreen,
				},
				selectedProfile: state.immutableSelectedProfile,
				unsavedScreenChanges: false,
				hasChangedScreenPosition: false,
			};
		},
		buildScreenDefaultScreen: (state) => {
			// const { screen } = state.selectedScreen;
			const { selectedProfile } = state;

			if (!selectedProfile.screens.length) {
				state.unsavedScreenChanges = true;
				selectedProfile.screens[0] = initialScreen;
			}
		},
		buildScreenDefaultGridSlots: (state) => {
			const { screen } = state.selectedScreen;

			if (screen && screen.slots && !screen.slots.length) {
				screen.slots = buildSlotGrid(
					screen.columns as number,
					screen.rows as number
				);
			}
		},
		updateScreenPosition: (state, { payload }) => {
			const newPosition = parseInt(payload, 10);
			const { selectedScreen } = state;

			const previousPosition = selectedScreen.screen.position;
			const isMovingForward = newPosition > previousPosition;

			selectedScreen.screen.position = newPosition;
			state.unsavedScreenChanges = true;
			state.hasChangedScreenPosition = true;

			//update position in list
			state.selectedProfile.screens.map((screen) => {
				if (screen.identifier === selectedScreen.screen.identifier) {
					screen.position = newPosition;
				}
			});

			if (isMovingForward) {
				state.selectedProfile.screens.map((screen) => {
					if (
						screen.identifier !== selectedScreen.screen.identifier &&
						screen.position <= newPosition
					) {
						if (screen.position > previousPosition) {
							screen.position -= 1;
						}
					}

					return null;
				});
			} else {
				state.selectedProfile.screens.map((screen) => {
					if (
						screen.identifier !== selectedScreen.screen.identifier &&
						screen.position >= newPosition
					) {
						if (
							screen.position < state.selectedProfile.screens.length &&
							screen.position < previousPosition
						) {
							screen.position += 1;
						}
					}

					return null;
				});
			}

			const sortedScreens = state.selectedProfile.screens;

			sortedScreens.sort((a, b) => a.position - b.position);

			state.selectedProfile.screens = sortedScreens;

			sortedScreens.forEach((s) => {
				// console.log(s.name, s.position);
			});
		},
		updateScreenColumns: (state, { payload }) => {
			const { selectedScreen } = state;

			selectedScreen.screen.columns = Number(payload);
			selectedScreen.screen.slots = buildSlotGrid(
				payload as number,
				selectedScreen.screen.rows as number
			);

			state.unsavedScreenChanges = true;
		},
		updateScreenRows: (state, { payload }) => {
			const { selectedScreen } = state;

			selectedScreen.screen.rows = Number(payload);
			selectedScreen.screen.slots = buildSlotGrid(
				selectedScreen.screen.columns as number,
				payload as number
			);

			state.unsavedScreenChanges = true;
		},
		updateScreenShowImage: (state, { payload }) => {
			const { selectedScreen } = state;

			selectedScreen.screen.showImages = Boolean(Number(payload));

			state.unsavedScreenChanges = true;
		},
		updateDefaultColorButton: (state, { payload }) => {
			const { selectedScreen } = state;

			selectedScreen.screen.defaultButtonColor = payload;

			state.unsavedScreenChanges = true;
		},
		addButtonToScreen: (state, { payload }) => {
			state.selectedScreen.screen.buttons = [
				...state.selectedScreen.screen.buttons,
				payload,
			];
		},
		updateButtonOnScreen: (state, { payload }) => {
			state.selectedScreen.screen.buttons = state.selectedScreen.screen.buttons.map(
				(button) =>
					([payload] as IScreenButton[]).find(
						(s) => s.screenButtonId === button.screenButtonId
					) || button
			);
		},
		removeButtonFromScreen: (state, { payload }) => {
			state.selectedScreen.screen.buttons =
				state.selectedScreen.screen.buttons.filter(
					(b) => b.screenButtonId !== payload
				);

			state.buttons.deletedScreenButtonId = 0;
		},
		changeButtonSlot: (state, { payload }) => {
			const { column, row, screenButtonId, slotId } = payload.screenButton;

			const { selectedScreen } = state;

			selectedScreen.screen.buttons.forEach((button) => {
				if (screenButtonId === button.screenButtonId) {
					button.column = column;
					button.row = row;
					button.slotId = slotId;
				}
			});
		},
	};
};

export default profilesReducer;
