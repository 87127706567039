import { takeLatest, put, call } from "redux-saga/effects";
import { getEmailLogStart, getEmailLogComplete, getEmailLogFail } from "./emailLog.slice";
import EmailLogAPI from "./emailLog.api";

export function* getEmailLogById({ payload }) {
	try {
		const { selectedEmailLogId } = payload;

		const { data } = yield call(EmailLogAPI.getEmailLogById, selectedEmailLogId);

		yield put(getEmailLogComplete(data));
	} catch (e: any) {
		yield put(getEmailLogFail(e.message));
	}
}

// Root Sagas
export function* watchEmailLog() {
	return [yield takeLatest(getEmailLogStart, getEmailLogById)];
}
