import { clear } from "console";
import {
	IAccountToken,
	ITransientAccountOwnerState,
	ITransientBillingInformationState,
	ITransientMember,
	ITransientMembersState,
} from "../customerMembership.typings";
import { updateRebuildByArray, updateRebuildTransientMembers } from "./transient.helper";

export const transientStates = {
	transientMembers: {
		fetchStateLoading: {
			loading: false,
			success: false,
			hasError: false,
			errorMessage: "",
		},
		items: [] as Array<ITransientMember>,
	} as ITransientMembersState,
	transientBillingInformation: {
		fetchStateLoading: {
			loading: false,
			success: false,
			hasError: false,
			errorMessage: "",
		},
		accountOwnerId: 0,
		billingCyclePeriod: 0,
		nextBillingDate: undefined,
		accountTokenId: 0,
	} as ITransientBillingInformationState,
	transientAccountOwner: {
		fetchStateLoading: {
			loading: false,
			success: false,
			hasError: false,
			errorMessage: "",
		},
		tokenCanBeDeletedStateLoading: {
			loading: false,
			success: false,
			hasError: false,
			errorMessage: "",
		},
		accountId: 0,
		firstName: "",
		lastName: "",
		fullName: "",
		email: "",
		avatar: "",
		accountTokens: {
			removeTokenStateLoading: {
				loading: false,
				success: false,
				hasError: false,
				errorMessage: "",
			},
			saveTokenStateLoading: {
				loading: false,
				success: false,
				hasError: false,
				errorMessage: "",
			},
			items: [] as Array<IAccountToken>,
		},
	} as ITransientAccountOwnerState,
};

export const transientReducer = () => {
	return {
		resetTransientReducer: (state) => {
			return {
				...state,
				...transientStates,
			};
		},
		/// /
		updateTransientMembersByArray: (state, { payload }) => {
			const { addedMembers, removedMembers } = payload;
			const transientMembers = state.transientMembers.items;
			const { autoSave, members } = state.subscription;

			const transientFinal = updateRebuildByArray(
				addedMembers,
				removedMembers,
				autoSave,
				members,
				transientMembers
			);

			return {
				...state,
				transientMembers: {
					...state.transientMembers,
					items: transientFinal,
				},
			};
		},
		updateTransientMembers: (state, { payload }) => {
			const {
				selected,
				accountId,
				mode,
				isPayingActivationFee,
				receivesReceipt,
				receivesWelcomeEmail,
			} = payload;

			const returningMembers = updateRebuildTransientMembers(
				selected,
				accountId,
				mode,
				receivesWelcomeEmail,
				receivesReceipt,
				isPayingActivationFee,
				state.subscription.autoSave,
				state.subscription.members,
				state.transientMembers.items
			);

			return {
				...state,
				transientMembers: {
					...state.transientMembers,
					items: returningMembers,
				},
			};
		},
		/// /
		fetchTransientBillingInformationStart: (state, { payload }) => {
			return {
				...state,
				transientBillingInformation: {
					...state.transientBillingInformation,
					fetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchTransientBillingInformationComplete: (state, { payload }) => {
			return {
				...state,
				transientBillingInformation: {
					...state.transientBillingInformation,
					...payload,
					fetchStateLoading: {
						...state.transientBillingInformation.fetchStateLoading,
						loading: false,
						success: true,
					},
				},
			};
		},
		fetchTransientBillingInformationFail: (state, { payload }) => {
			return {
				...state,
				transientBillingInformation: {
					...state.transientBillingInformation,
					fetchStateLoading: {
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		/// /
		fetchTransientOwnerAccountStart: (state, { payload }) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					fetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchTransientOwnerAccountComplete: (state, { payload }) => {
			const { newAccountOwnerId, data } = payload;

			const { items } = state.transientMembers;
			let { firstName, lastName, fullName, email, avatar, accountId } =
				state.transientAccountOwner;
			const newAccountOwner = items.filter(
				(a) => a.accountId === newAccountOwnerId
			)[0];

			if (newAccountOwner !== undefined && newAccountOwner !== null) {
				firstName = newAccountOwner.person.firstName;
				lastName = newAccountOwner.person.lastName;
				fullName = newAccountOwner.person.fullname;
				email = newAccountOwner.person.email;
				avatar = newAccountOwner.person.avatar;
				accountId = newAccountOwner.accountId;
			}

			return {
				...state,
				transientBillingInformation: {
					...state.transientBillingInformation,
					accountOwnerId: newAccountOwnerId,
					accountTokenId: null,
				},
				transientAccountOwner: {
					...state.transientAccountOwner,
					fetchStateLoading: {
						loading: false,
						success: true,
						hasError: false,
						errorMessage: "",
					},
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						items: data,
					},
					accountId,
					firstName,
					lastName,
					fullName,
					email,
					avatar,
					items: data,
				},
				flagStatus: {
					...state.flagStatus,
					billingCardFlags: {
						...state.flagStatus.billingCardFlags,
						hasChangedAccountOwner: true,
					},
				},
				layout: {
					...state.layout,
					isPlanOwnerModalOpen: false,
				},
				subscription: {
					...state.subscription,
					saveStateLoading: {
						...state.subscription.saveStateLoading,
						shouldFire: true,
					},
				},
			};
		},
		fetchTransientOwnerAccountFail: (state, { payload }) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					fetchStateLoading: {
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		addTokenToAccountLoading: (state) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						saveTokenStateLoading: {
							loading: true,
							success: false,
							hasError: false,
							errorMessage: "",
						},
					},
				},
			};
		},
		addTokenToAccountStart: (state, { payload }) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						saveTokenStateLoading: {
							loading: true,
							success: false,
							hasError: false,
							errorMessage: "",
						},
					},
				},
			};
		},
		addTokenToAccountComplete: (state, { payload }) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						saveTokenStateLoading: {
							loading: false,
							success: true,
							hasError: false,
							errorMessage: "",
						},
						items: payload,
					},
				},
			};
		},
		addTokenToAccountFailure: (state, { payload }) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						saveTokenStateLoading: {
							loading: false,
							success: false,
							hasError: true,
							errorMessage: payload,
						},
					},
				},
			};
		},
		clearSaveTokenStateLoadingState: (state) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						saveTokenStateLoading: {
							loading: false,
							success: false,
							hasError: false,
							errorMessage: "",
						},
					},
				},
			};
		},
		canDeleteTokenCheckStart: (state, { payload }) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					tokenCanBeDeletedStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		canDeleteTokenCheckSuccess: (state) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					tokenCanBeDeletedStateLoading: {
						loading: false,
						success: true,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		canDeleteTokenCheckFailure: (state, { payload }) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					tokenCanBeDeletedStateLoading: {
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		resetCanDeleteCheck: (state) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					tokenCanBeDeletedStateLoading: {
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		removeTokenToAccountStart: (state, { payload }) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						removeTokenStateLoading: {
							loading: true,
							success: false,
							hasError: false,
							errorMessage: "",
						},
					},
				},
			};
		},
		removeTokenToAccountComplete: (state, { payload }) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						removeTokenStateLoading: {
							loading: false,
							success: true,
							hasError: false,
							errorMessage: "",
						},
						items: payload,
					},
				},
			};
		},
		removeTokenToAccountFailure: (state, { payload }) => {
			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						removeTokenStateLoading: {
							loading: false,
							success: false,
							hasError: false,
							errorMessage: payload,
						},
					},
				},
			};
		},
		updateTransientBillingCycle: (state, { payload }) => {
			let hasChangedBillingCycle = false;

			if (
				state.subscription.billingInformation.scheduledBillingCycle !==
					undefined &&
				state.subscription.billingInformation.scheduledBillingCycle !== null
			) {
				hasChangedBillingCycle =
					state.subscription.billingInformation.scheduledBillingCycle !==
					payload;
			} else {
				hasChangedBillingCycle =
					state.subscription.billingInformation.billingCyclePeriod !== payload;
			}

			return {
				...state,
				transientBillingInformation: {
					...state.transientBillingInformation,
					billingCyclePeriod: payload,
				},
				flagStatus: {
					...state.flagStatus,
					billingCardFlags: {
						...state.flagStatus.billingCardFlags,
						hasChangedBillingCycle,
					},
				},
			};
		},
		updateTransientAccounToken: (state, { payload }) => {
			return {
				...state,
				transientBillingInformation: {
					...state.transientBillingInformation,
					accountTokenId: payload,
				},
				flagStatus: {
					...state.flagStatus,
					billingCardFlags: {
						...state.flagStatus.billingCardFlags,
						hasChangedAccountToken: true,
					},
				},
			};
		},
		fetchTransientInfoStart: (state, { payload }) => {
			return {
				...state,
			};
		},
		fetchTransientInfoComplete: (state, { payload }) => {
			const {
				transientAccountOwner,
				transientMembers,
				transientBillingInformation,
			} = payload;

			return {
				...state,
				transientAccountOwner: {
					...state.transientAccountOwner,
					...transientAccountOwner,
					accountTokens: {
						...state.transientAccountOwner.accountTokens,
						items: transientAccountOwner.accountTokens.items,
					},
				},
				transientBillingInformation: {
					...state.transientBillingInformation,
					...transientBillingInformation,
				},
				transientMembers: {
					...state.transientMembers,
					items: transientMembers,
				},
			};
		},
	};
};
