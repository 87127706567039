import { IPaymentCompletedResponse } from "../payments.typings";

export const debitState = {
	debitPay: {
		applyStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			started: false,
			success: false,
		},
		paymentResponse: {} as IPaymentCompletedResponse,
	},
};

const debitPayReducer = () => {
	return {
		postDebitPayStart: (state, { payload }) => {
			return {
				...state,
				debitPay: {
					...state.debitPay,
					applyStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		postDebitPayComplete: (state, { payload }) => {
			return {
				...state,
				debitPay: {
					...state.debitPay,
					applyStateLoading: {
						...state.debitPay.applyStateLoading,
						started: true,
						loading: false,
						success: true,
					},
					paymentResponse: payload.data.results,
				},
			};
		},
		postDebitPayFail: (state, { payload }) => {
			return {
				...state,
				debitPay: {
					...state.debitPay,
					applyStateLoading: {
						...state.debitPay.applyStateLoading,
						started: true,
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		resetDebitPaymentState: (state) => {
			return {
				...state,
				debitPay: {
					applyStateLoading: {
						started: false,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
					paymentResponse: {} as IPaymentCompletedResponse,
				},
			};
		},
	};
};
export default debitPayReducer;
