import { createSlice } from "@reduxjs/toolkit";
import { ICustomer } from "../customer/customer.typings";

const initialCustomers = {
	items: [] as ICustomer[],
	isLoading: false,
	hasInitiated: false,
	searchSuccess: false,
	error: "",
	totalPages: 0,
	totalItems: 0,
	filtered: false,
	searchText: "",
	orderBy: "CreatedOn",
	orderDirection: "asc",
	filters: {
		customerStatuses: ["Active"],
		waiverStatuses: [],
		partyDateStart: "",
		partyDateEnd: "",
		customerLabels: [],
		customerTypes: [],
		membershipPlans: [],
		membershipStatuses: [],
	},
	page: {
		pageSize: 10,
		pageNumber: 1,
	},
	firstRowOnPage: 0,
	lastRowOnPage: 0,
	hasNextPage: false,
	hasPreviousPage: false,
	layout: {
		expandedView: false,
		isCustomerModalOpen: false,
	},
	saveQueryState: true,
};

const customersSlice = createSlice({
	name: "CUSTOMERS",
	initialState: initialCustomers,
	reducers: {
		fetchCustomersStart: (state, { payload }) => {
			const {
				persistPageNumber,
				searchText,
				orderBy,
				orderDirection,
				page,
				filters,
				expandedView,
				saveQueryState,
			} = payload;

			return {
				...state,
				isLoading: true,
				hasInitiated: true,
				searchText,
				orderBy,
				orderDirection,
				saveQueryState,
				page: {
					...state.page,
					pageSize: page.pageSize,
					pageNumber: persistPageNumber ? page.pageNumber : 1,
				},
				filters: {
					...state.filters,
					customerStatuses: filters.customerStatuses,
					partyDateStart: filters.partyDateStart,
					partyDateEnd: filters.partyDateEnd,
					waiverStatuses: filters.waiverStatuses,
					customerLabels: filters.customerLabels,
					customerTypes: filters.customerTypes,
					membershipPlans: filters.membershipPlans,
					membershipStatuses: filters.membershipStatuses,
				},
				layout: {
					...state.layout,
					expandedView,
				},
			};
		},
		fetchCustomersComplete: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				searchSuccess: true,
				items: payload.data.results,
				totalItems: payload.data.totalItems,
				totalPages: payload.data.totalPages,
				firstRowOnPage: payload.data.firstRowOnPage,
				lastRowOnPage: payload.data.lastRowOnPage,
			};
		},
		fetchCustomersFail: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				searchSuccess: false,
				error: payload,
			};
		},
		toggleExpandedView: (state) => {
			const layout = { ...state.layout };
			const finalLayout = {
				...layout,
				expandedView: !layout.expandedView,
			};

			return { ...state, layout: { ...finalLayout } };
		},
		toggleExpandedViewWithParam: (state, { payload }) => {
			const layout = { ...state.layout };
			const finalLayout = {
				...layout,
				expandedView: payload,
			};

			return { ...state, layout: { ...finalLayout } };
		},
		setCustomerModalOpen: (state, { payload }) => {
			const layout = { ...state.layout };
			const finalLayout = {
				...layout,
				isCustomerModalOpen: payload,
			};

			return { ...state, layout: { ...finalLayout } };
		},
		updateWaiverStatusToConfirmed: (state, { payload }) => {
			const items = [...state.items];
			const itemsList = items.map((item) => {
				const waiverInfo = { ...item.waiverInfo };
				if (waiverInfo.customerWaiverId === payload) {
					const newItem = {
						...item,
						waiverInfo: { ...item.waiverInfo, waiverStatus: "Confirmed" },
					};
					return newItem;
				}
				return item;
			});
			return {
				...state,
				items: itemsList,
			};
		},
		updateWaiverStatusToExpired: (state, { payload }) => {
			const items = [...state.items];
			const itemsList = items.map((item) => {
				const waiverInfo = { ...item.waiverInfo };
				if (waiverInfo.customerWaiverId === payload) {
					const newItem = {
						...item,
						waiverInfo: { ...item.waiverInfo, waiverStatus: "Expired" },
					};
					return newItem;
				}
				return item;
			});
			return {
				...state,
				items: itemsList,
			};
		},
		clearSearchText: (state) => {
			return {
				...state,
				searchText: "",
			};
		},
	},
});

export const {
	fetchCustomersStart,
	fetchCustomersComplete,
	fetchCustomersFail,
	toggleExpandedView,
	updateWaiverStatusToConfirmed,
	updateWaiverStatusToExpired,
	toggleExpandedViewWithParam,
	setCustomerModalOpen,
	clearSearchText,
} = customersSlice.actions;

export default customersSlice.reducer;
