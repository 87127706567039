import { ICustomer } from "../../customer/customer.typings";

export type CustomerOrderContext = "Create_Customer" | "Associate_Customer";
export interface IOrderCustomerStates {
	customerOrderContext: CustomerOrderContext;
	addOrderToCustomerSuccess: boolean;
	updateOrderCustomerSuccess: boolean;
	loadingCustomerState: {
		isLoading: boolean;
	};
}

export const orderCustomerState = {
	customerOrderContext: "Associate_Customer",
	addOrderToCustomerSuccess: false,
	updateOrderCustomerSuccess: false,
	loadingCustomerState: {
		isLoading: false,
	},
} as IOrderCustomerStates;

const orderCustomerReducer = () => {
	return {
		setCustomerOrderContext: (
			state,
			{ payload }: { payload: CustomerOrderContext }
		) => {
			return {
				...state,
				customerOrderContext: payload,
			};
		},
		// ADD NEW ORDER TO A CUSTOMER
		addOrderToCustomerRequest: (state, { payload }) => {
			return {
				...state,
				addOrderToCustomerSuccess: false,
				loadingCustomerState: {
					...state.loadingCustomerState,
					isLoading: true,
				},
			};
		},
		addOrderToCustomerSuccess: (state, { payload }) => {
			return {
				...state,
				addOrderToCustomerSuccess: true,
				order: payload.data.results.order,
				loadingCustomerState: {
					...state.loadingCustomerState,
					isLoading: false,
				},
			};
		},
		addOrderToCustomerFailure: (state, { payload }) => {
			return {
				...state,
				addOrderToCustomerSuccess: false,
				error: payload,
				loadingCustomerState: {
					...state.loadingCustomerState,
					isLoading: false,
				},
			};
		},
		// UPDATE CUSTOMER FROM AN ORDER
		updateOrderCustomerRequest: (state, { payload }) => {
			return {
				...state,
				updateOrderCustomerSuccess: false,
				loadingCustomerState: {
					...state.loadingCustomerState,
					isLoading: true,
				},
			};
		},
		updateOrderCustomerSuccess: (state, { payload }) => {
			return {
				...state,
				updateOrderCustomerSuccess: true,
				order: payload.data.results.order,
				loadingCustomerState: {
					...state.loadingCustomerState,
					isLoading: false,
				},
			};
		},
		updateOrderCustomerFailure: (state, { payload }) => {
			return {
				...state,
				updateOrderCustomerSuccess: false,
				error: payload,
				loadingCustomerState: {
					...state.loadingCustomerState,
					isLoading: false,
				},
			};
		},
		setCustomer: (state, { payload }: { payload: ICustomer }) => {
			state.customer = payload;
		},
	};
};

export default orderCustomerReducer;
