import { createSlice } from "@reduxjs/toolkit";

export type Tax = {
	taxId: number;
	description: string;
	taxReate: number;
};

const taxesSlice = createSlice({
	name: "TAXES",
	initialState: {
		taxes: [] as Tax[],
		isLoading: false,
		error: "",
	},
	reducers: {
		taxesListStart: (state) => {
			return {
				...state,
				isLoading: true,
				error: "",
			};
		},
		taxesListSuccess: (state, { payload }) => {
			return {
				...state,
				taxes: payload.data,
				isLoading: false,
				error: "",
			};
		},
		taxesListFailure: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				taxes: [] as Tax[],
				error: payload,
			};
		},
	},
});

export const { taxesListStart, taxesListSuccess, taxesListFailure } = taxesSlice.actions;

export default taxesSlice.reducer;
