import { TypeRelatedPerson } from "../../customer/customer.typings";

const customerDependentsReducer = () => {
	return {
		updateAssociationRequest: (state, { payload }) => {
			return {
				...state,
				requestSuccess: false,
				error: "",
			};
		},
		updateAssociationSuccess: (state, { payload }) => {
			return {
				...state,
				requestSuccess: true,
				relatedPeople: payload,
				error: "",
			};
		},
		updateAssociationFailure: (state, { payload }) => {
			return {
				...state,
				requestSuccess: false,
				relatedPeople: undefined,
				error: payload,
			};
		},
		addSelectedCustomerPeople: (state, { payload }) => {
			return {
				...state,
				selectedCustomer: {
					...state.selectedCustomer,
					people: [
						...state.selectedCustomer.people,
						{
							accountId: payload.accountId,
							personId: payload.personId,
							name: `${payload.firstName} ${payload.lastName}`,
							firstName: payload.firstName,
							lastName: payload.lastName,
							avatar: payload.avatar,
							accountStatus: payload.accountStatus,
							gender: payload.gender,
							isDependent: payload.isDependent,
						} as TypeRelatedPerson,
					],
				},
			};
		},
	};
};

export default customerDependentsReducer;
