import React, { useMemo } from "react";
import { useSelector } from "react-redux";

// COMPONENTS
import OrderResultItem from "../OrderResultItem/OrderResultItem";

// SELECTOR
import {
	getSearchFetchStateLoading,
	getSearchResults,
	getLastTenOrdersVisited,
} from "../../../../store/modules/headerNav/headerNav.selector";

// ASSETS
import useStyles from "./SearchResults.styles";
import { CircularProgress } from "@material-ui/core";
import { getOrderSearchLink } from "../../../../utils/classic.helper";

const SearchResults: React.FC = () => {
	const searchResult = useSelector(getSearchResults);
	const lastTenViewed = useSelector(getLastTenOrdersVisited);
	const { loading, noResults } = useSelector(getSearchFetchStateLoading);
	const classes = useStyles();

	const handleOrderSearchClick = () => {
		const classicRoute = getOrderSearchLink();

		if (window.parent !== undefined)
			window.parent.window.location.href = `${classicRoute}`;
		else window.location.href = `${classicRoute}`;
	};

	const displayedResults = useMemo(() => {
		if (searchResult.length > 0) {
			return searchResult;
		}
		return lastTenViewed;
	}, [searchResult, lastTenViewed]);

	const renderLoading = (
		<div className={classes.loadingContainer}>
			<CircularProgress size={40} />
		</div>
	);

	const renderOrderList = (
		<div className={classes.orderList}>
			{noResults ? (
				<div className={classes.noResultsContainer}>
					<span>No Matching Results</span>
				</div>
			) : (
				displayedResults.map((item) => {
					const {
						orderId,
						orderNumber,
						orderStatus,
						orderType,
						customer,
						customerId,
						orderDate,
						payments,
					} = item;
					return (
						<OrderResultItem
							key={orderId}
							orderId={orderId}
							orderNumber={orderNumber}
							orderStatus={orderStatus}
							orderType={orderType}
							customerId={customerId}
							customer={customer}
							orderDate={orderDate}
							payments={payments}
						/>
					);
				})
			)}
		</div>
	);

	return (
		<div className={classes.container}>
			{loading ? renderLoading : renderOrderList}
			<div className={classes.orderSearchLinkContainer}>
				<button type="button" onClick={() => handleOrderSearchClick()}>
					Order Search
				</button>
			</div>
		</div>
	);
};

export default SearchResults;
