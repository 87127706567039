/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Magnify from "mdi-material-ui/Magnify";
import CheckboxMarkedCircle from "mdi-material-ui/CheckboxMarkedCircle";
import classnames from "classnames";

// ACTIONS
import {
	closeFacilityAliasModal,
	getCookiesInfo,
	getfacilityAlias,
	openFacilityAliasModal,
} from "../../../../../store/modules/enviroment/enviroment.slice";

// SELECTORS
import {
	getCookiesInfoState,
	getFacilityAliasState,
} from "../../../../../store/modules/enviroment/enviroment.selector";

// ASSETS
import { Box, Button, Modal, Row, TextField } from "@agilekit/ui";
import useStyles from "./FacilityAliasModal.styles";
import { IFacilityAlias } from "../../../../../store/modules/enviroment/reducers/facilityAlias.reducer";
import { useLocation } from "react-router-dom";

const FacilityAliasModal: React.FC = () => {
	// ? HOOKS
	const dispatch = useDispatch();
	const classes = useStyles();
	const { results, isFacilityAliasModalOpen } = useSelector(getFacilityAliasState);
	const cookiesInfo = useSelector(getCookiesInfoState);
	const [searchText, setSearchText] = useState("");
	const location = useLocation();

	// ? EFFECTS
	useEffect(() => {
		if (location.pathname.includes("facilityaliasmodal")) {
			if (navigator.cookieEnabled) {
				dispatch(getCookiesInfo());
			}
			dispatch(getfacilityAlias());
			dispatch(openFacilityAliasModal());
		}
	}, [location]);

	const actualFacility = useMemo(
		() =>
			results.find(
				(x) => x.facilityId.toString() === cookiesInfo.facilityId.toString()
			),
		[cookiesInfo, results]
	);

	const filteredResults = useMemo(() => {
		const data = results.filter((x) => x.facilityId !== actualFacility?.facilityId);
		if (searchText === "") return data;
		return data.filter(
			(x) =>
				x.facilityName
					.toLocaleLowerCase()
					.includes(searchText.toLocaleLowerCase()) ||
				x.facilityId.toString().includes(searchText)
		);
	}, [searchText, results, actualFacility]);

	// ? EVENTS
	const onSelect = (data: IFacilityAlias) => {
		window.parent.postMessage(
			{
				action: "switchTo",
				userGuid: data.userGuid,
				hostedDomain: data.hostedDomain,
			},
			"*"
		);
		closeModal();
	};

	const closeFullScreenModal = () => {
		window.parent.postMessage(
			{
				action: "closeFullScreenModal",
				component: "FacilityAliasModal",
			},
			"*"
		);
	};

	const closeModal = () => {
		dispatch(closeFacilityAliasModal());
		closeFullScreenModal();
	};

	const onSearchTextChange = (e: any) => {
		const { value } = e.target;
		setSearchText(value as string);
	};

	// ? STYLES
	const cssItemSelectable = classnames(classes.container);
	const cssCurrentFacility = classnames(classes.container, classes.containerHover);
	const cssModal = classnames({
		[classes.modalClassic]: location.pathname.includes("facilityaliasmodal"),
	});
	const cssModalPaper = classnames({
		[classes.modalClassicPaper]: location.pathname.includes("facilityaliasmodal"),
	});

	return (
		<div>
			<Modal
				open={isFacilityAliasModalOpen}
				title="Switch To Facility"
				onClose={closeModal}
				size="medium"
				modalClasses={cssModal as any}
				paperClasses={cssModalPaper as any}
				footer={
					<Row justify="end" type="flex">
						<Box pr={1}>
							<Button subtle onClick={closeModal}>
								Close
							</Button>
						</Box>
					</Row>
				}
			>
				<div>
					<Box pb={2} pt={2}>
						{actualFacility && (
							<Box>
								<Box pl={2} pr={2} className={classes.titleText}>
									<span>Current Facility:</span>
								</Box>
								<div
									key={actualFacility.facilityId}
									className={classes.sectionContainerDisabled}
								>
									<div className={classes.mainItem}>
										<button disabled>
											<div>
												<span>{actualFacility.facilityName}</span>
											</div>
											<div className={classes.smallText}>
												<span>Facility: </span>{" "}
												<strong>
													{actualFacility.facilityId}
												</strong>
											</div>
										</button>
									</div>
								</div>
							</Box>
						)}
					</Box>
					<Box pl={2} pr={2} pb={2}>
						<TextField
							value={searchText}
							placeholder="Search Facility..."
							iconLeft={<Magnify />}
							onChange={onSearchTextChange}
						/>
					</Box>
					<div className={classes.listContainer}>
						{filteredResults.map((item) => {
							return (
								<div
									key={item.facilityId}
									className={cssItemSelectable}
									role="button"
									tabIndex={0}
									onClick={() => onSelect(item)}
								>
									<div className={classes.left}>
										<div className={classes.title}>
											<div>
												<span>{item.facilityName}</span>
											</div>
											<div className={classes.smallText}>
												<span>Facility: </span>{" "}
												<strong>{item.facilityId}</strong>
											</div>
										</div>
									</div>
									<div className={classes.right}>
										<div
											className={classes.selectIcon}
											role="button"
											tabIndex={0}
										>
											<CheckboxMarkedCircle
												htmlColor="#0052CC"
												viewBox="2 0 24 24"
											/>
										</div>
									</div>
								</div>
								// <div
								// 	key={item.facilityId}
								// 	className={classes.sectionContainer}
								// >
								// 	<div className={classes.mainItem}>
								// 		<button
								// 			onClick={() => onSelect(item)}
								// 			disabled={
								// 				cookiesInfo.facilityId === item.facilityId
								// 			}
								// 		>
								// 			<div>
								// 				<span>{item.facilityName}</span>
								// 			</div>
								// 			<div className={classes.smallText}>
								// 				<span>Facility: </span>{" "}
								// 				<strong>{item.facilityId}</strong>
								// 			</div>
								// 		</button>
								// 	</div>
								// </div>
							);
						})}
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default FacilityAliasModal;
