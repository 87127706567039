import React from "react";
import { ToastContainer } from "react-toastify";

// ASSETS
import useStyles from "./Toaster.styles";

const Toaster: React.FC = (props: any) => {
	const classes = useStyles();

	return <ToastContainer className={classes.toast} {...props} />;
};

export default Toaster;
