import { takeLatest, put, call, all, delay } from "redux-saga/effects";

import {
	enviromentRequest,
	enviromentSuccess,
	enviromentFailure,
	fetchSocialMediasFailure,
	fetchSocialMediasStart,
	fetchSocialMediasSuccess,
	fetchPaymentSettingsStart,
	fetchPaymentSettingsSuccess,
	fetchPaymentSettingsFailure,
	getfacilityAlias,
	getfacilityAliasComplete,
	getfacilityAliasFail,
	fetchStripeCredentialStart,
	fetchStripeCredentialComplete,
	fetchStripeCredentialFail,
} from "./enviroment.slice";

import EnviromentAPI from "./enviroment.api";

export function* fetchFacilitySettings() {
	try {
		const settings = yield all([
			call(EnviromentAPI.fetchFacilitySettings),
			call(EnviromentAPI.fetchCustomerRequiredFields),
			call(EnviromentAPI.fetchFacilityReferrals),
			delay(1000),
		]);

		const facilitySettings = settings[0].data;
		facilitySettings.customerRequiredFields = settings[1].data;
		facilitySettings.referrals = settings[2].data;

		// issue with iframe is blocking localstorage
		// localStorage.setItem("facilitySettings", JSON.stringify(facilitySettings));

		yield put(enviromentSuccess({ facilitySettings }));
	} catch (error: any) {
		yield put(enviromentFailure({ error }));
	}
}

export function* fetchFacilitySocialMedia() {
	try {
		const { data } = yield call(EnviromentAPI.fetchFacilitySocialMedias);

		yield put(fetchSocialMediasSuccess({ data }));
	} catch (error: any) {
		yield put(fetchSocialMediasFailure({ error }));
	}
}

export function* fetchPaymentSettings() {
	try {
		const { data } = yield call(EnviromentAPI.fetchPaymentsSettings);

		yield put(fetchPaymentSettingsSuccess({ data }));
	} catch (error: any) {
		yield put(fetchPaymentSettingsFailure({ error }));
	}
}

export function* fetchFacilityAlias() {
	try {
		const { data } = yield call(EnviromentAPI.fetchFacilityAlias);

		yield put(getfacilityAliasComplete({ data }));
	} catch (error: any) {
		yield put(getfacilityAliasFail({ error }));
	}
}

export function* fetchStripeCredential() {
	try {
		const { data } = yield call(EnviromentAPI.fetchStripePublishableKey);

		yield put(fetchStripeCredentialComplete({ data }));
	} catch (error: any) {
		yield put(fetchStripeCredentialFail({ error }));
	}
}

export function* watchEnviroment() {
	return [
		yield takeLatest(enviromentRequest, fetchFacilitySettings),
		yield takeLatest(fetchSocialMediasStart, fetchFacilitySocialMedia),
		yield takeLatest(fetchPaymentSettingsStart, fetchPaymentSettings),
		yield takeLatest(getfacilityAlias, fetchFacilityAlias),
		yield takeLatest(fetchStripeCredentialStart, fetchStripeCredential),
	];
}
