/* eslint-disable react/jsx-no-bind */
import React from "react";
import HelpCircleOutline from "mdi-material-ui/HelpCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

// ACTIONS
import { closeHelp, openHelp } from "../../../../store/modules/headerNav/headerNav.slice";

// SELECTORS
import {
	getAcknowledgeState,
	getCommunicationsNotYetAcknowledgedCount,
	isAcknowledgeModalOpen,
	isHelpOpen,
} from "../../../../store/modules/headerNav/headerNav.selector";

// ASSETS
import useStyles from "./Help.styles";
import AcknowledgeModal from "./AcknowledgeModal/AcknowledgeModal";
import { useLocation } from "react-router-dom";

const Help = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const location = useLocation();

	const helpIsOpen = useSelector(isHelpOpen);
	const acknowledgeModalIsOpen = useSelector(isAcknowledgeModalOpen);
	const { selectedCommunication } = useSelector(getAcknowledgeState);
	const communicationsNotYetAcknowledgedCount = useSelector(
		getCommunicationsNotYetAcknowledgedCount
	);

	// EVENTS
	const handleClick = () => {
		if (location.pathname.includes("menu")) {
			window.parent.postMessage(
				{
					action: "openFullScreenModal",
					component: "GlobalSupport",
				},
				"*"
			);
		} else {
			helpIsOpen ? dispatch(closeHelp()) : dispatch(openHelp());
		}
	};

	// STYLES
	const cssHelp = classNames(classes.help, {
		[classes.helpOpen]: helpIsOpen,
	});

	return (
		<div className={classes.helpContainer}>
			<div className={cssHelp}>
				<div className={classes.helpIcon}>
					<button
						id="helpButton"
						type="button"
						className={classes.sectionRightButton}
						onClick={() => handleClick()}
					>
						<HelpCircleOutline id="helpButtonIcon" />
					</button>
					{communicationsNotYetAcknowledgedCount > 0 && (
						<div id="helpCount" className={classes.helpCountContainer}>
							<span className={classes.helpCount}>
								{communicationsNotYetAcknowledgedCount}
							</span>
						</div>
					)}
				</div>
			</div>
			{acknowledgeModalIsOpen && (
				<AcknowledgeModal
					communication={selectedCommunication}
					open={acknowledgeModalIsOpen}
				/>
			)}
		</div>
	);
};

export default Help;
