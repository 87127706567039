import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {
			background: theme.palette.NEUTRAL000,
			padding: theme.spacing(2, 0),
			margin: theme.spacing(2, 0),
			borderRadius: 4,
			boxShadow: theme.shadows[2],
			"&:hover": {
				backgroundColor: theme.palette.NEUTRAL020,
			},
		},
		main: {
			display: "grid",
			gridTemplateColumns: "repeat(4, 1fr)",
			justifyContent: "space-between",
			color: theme.palette.NEUTRAL600,
			fontSize: theme.typography.pxToRem(14),
			[theme.breakpoints.down("xs")]: {
				gridTemplateColumns: "repeat(2, 1fr)",
			},
			"& strong": {
				color: theme.palette.NEUTRAL900,
				fontWeight: 500,
			},
			"& button": {
				color: theme.palette.BLUE400,
				backgroundColor: "transparent",
				border: "none",
				borderRadius: 4,
				padding: theme.spacing(1.5, 2),
				cursor: "pointer",
				"&:hover": {
					backgroundColor: theme.palette.BLUE050,
				},
			},
			"& > div": {
				display: "flex",
				alignItems: "center",
			},
		},
		mainItem: {
			display: "flex",
			alignItems: "center",
		},
		mainFirstItem: {
			display: "flex",
			alignItems: "center",
			paddingLeft: theme.spacing(2),
		},
		status: {
			backgroundColor: theme.palette.NEUTRAL030,
			color: theme.palette.NEUTRAL800,
			fontSize: theme.typography.pxToRem(12),
			fontWeight: 600,
			height: 22,
			borderRadius: 4,
			display: "flex",
			alignItems: "center",
			paddingRight: theme.spacing(2),
			marginRight: theme.spacing(4),
			"&::before": {
				content: "''",
				backgroundColor: theme.palette.NEUTRAL050,
				width: 6,
				height: "100%",
				borderRadius: "4px 0 0 4px",
				marginRight: theme.spacing(2),
			},
		},
		statusOpen: {
			backgroundColor: theme.palette.GREEN050,
			color: theme.palette.NEUTRAL900,
			"&::before": {
				backgroundColor: theme.palette.GREEN300,
			},
		},
		statusCancelled: {
			backgroundColor: theme.palette.RED050,
			color: theme.palette.NEUTRAL900,
			"&::before": {
				backgroundColor: theme.palette.RED300,
			},
		},
		statusPendingReview: {
			backgroundColor: theme.palette.YELLOW050,
			color: theme.palette.NEUTRAL900,
			"&::before": {
				backgroundColor: theme.palette.YELLOW300,
			},
		},
		statusQuote: {
			backgroundColor: theme.palette.BLUE050,
			color: theme.palette.NEUTRAL900,
			"&::before": {
				backgroundColor: theme.palette.BLUE300,
			},
		},
		payments: {
			marginTop: theme.spacing(1.5),
		},
		paymentItem: {
			display: "flex",
			padding: theme.spacing(0, 2),
			color: theme.palette.NEUTRAL300,
			marginTop: theme.spacing(2),
			fontSize: theme.typography.pxToRem(14),
			[theme.breakpoints.down("xs")]: {
				flexDirection: "column",
				gap: 4,
			},
			"& strong": {
				fontWeight: 500,
			},
		},
		highlight: {
			background: "yellow",
		},
	};
});

export default useStyles;
