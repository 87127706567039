import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: {
		translation: {
			"Welcome to React": "Welcome to React and react-i18next",
		},
	},
	fr: {
		translation: {
			"Welcome to React": "Bienvenue chez React",
			"Expanded View": "Vue étendue",
			Search: "Chercher",
			Customers: "Les clients",
			"Add Customer": "Ajouter un client",
			NAME: "NOM",
			"NEXT PARTY": "PROCHAINE FÊTE",
			"LAST PARTY": "DERNIÈRE FÊTE",
			WAIVER: "RENONCIATION",
			Waiver: "Renonciation",
			PEOPLE: "GENS",
			ORGANIZATIONS: "ORGANISATIONS",
			BIRTHDATE: "DATE DE NAISSANCE",
			EMAIL: "EMAIL",
			PHONE: "TÉLÉPHONE",
			"DO NO SOLICIT": "NE SOLLICITEZ PAS",
			"REFERRED BY": "RÉFÉRENCÉ PAR",
			"Filter Options": "Filtrer les clients",
			"Party Date Range": "Plage de dates de fête",
			"Select a date range": "Sélectionnez une plage de dates",
			None: "Aucun",
			Signed: "Signé",
			Accepted: "Accepté",
			Expired: "Expiré",
			Voided: "Annulé",
			Active: "Actif",
			Inactive: "Inactif",
			"Profile Status": "Statut du compte",
		},
	},
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: "en",

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
