import { createSlice } from "@reduxjs/toolkit";

export interface IEmailLog {
	emailId: number;
	orderId?: number;
	orderNumber?: number;
	orderType: string;
	timestamp: string;
	emailTo: string;
	emailReplyTo: string;
	emailFrom: string;
	emailSubject: string;
	emailCC: string;
	emailBCC: string;
	emailBodyHtml: string;
	emailBodyText: string;
}

const initialEmailLog = {
	emailLog: {} as IEmailLog,
	isLoading: false as boolean,
	error: "" as string,
};

const emailLogSlice = createSlice({
	name: "EMAILLOG",
	initialState: initialEmailLog,
	reducers: {
		getEmailLogStart: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				error: "",
			};
		},
		getEmailLogComplete: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				emailLog: payload,
				error: "",
			};
		},
		getEmailLogFail: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				emailLog: {} as IEmailLog,
				error: payload,
			};
		},
	},
});

export const { getEmailLogStart, getEmailLogComplete, getEmailLogFail } =
	emailLogSlice.actions;

export default emailLogSlice.reducer;
