import { IFlagStatus } from "../customerMembership.typings";

export const flagStatusStates = {
	flagStatus: {
		activationStatus: "",
		billingCardFlags: {
			billingCardStatus: undefined,
			billingCardWarnningMessage: "",
			hasChangedAccountOwner: false,
			hasChangedAccountToken: false,
			hasChangedBillingCycle: false,
		},
		memberCardFlags: {
			isDeactivatedPlanAndUnlocked: false,
			hasChangedCommunication: false,
			hasNewMembers: false,
			hasRemovedMembers: false,
			hasChangedActivationFees: false,
			membersCardStatus: undefined,
			membersCardWarningMessage: "",
		},
	} as IFlagStatus,
};

export const flagStatusReducer = () => {
	return {
		updateDeactivatedPlanAndUnlocked: (state, { payload }) => {
			const { isDeactivatedPlanAndUnlocked } = payload;

			return {
				...state,
				flagStatus: {
					...state.flagStatus,
					memberCardFlags: {
						...state.flagStatus.memberCardFlags,
						isDeactivatedPlanAndUnlocked,
					},
				},
			};
		},
		refreshBillingCardFlags: (state) => {
			let {
				billingCardStatus,
				billingCardWarnningMessage,
				hasChangedAccountOwner,
				hasChangedAccountToken,
				hasChangedBillingCycle,
			} = state.flagStatus.billingCardFlags;

			const {
				accountOwnerId,
				accountTokenId,
				billingCyclePeriod,
				scheduledBillingCycle,
			} = state.subscription.billingInformation;

			hasChangedAccountOwner =
				accountOwnerId !== state.transientBillingInformation.accountOwnerId;

			hasChangedAccountToken =
				accountTokenId !== state.transientBillingInformation.accountTokenId;

			hasChangedBillingCycle =
				billingCyclePeriod !==
				state.transientBillingInformation.billingCyclePeriod;

			if (scheduledBillingCycle === undefined || scheduledBillingCycle === null) {
				hasChangedBillingCycle =
					billingCyclePeriod !==
					state.transientBillingInformation.billingCyclePeriod;
			} else {
				hasChangedBillingCycle =
					scheduledBillingCycle !==
					state.transientBillingInformation.billingCyclePeriod;
			}

			if (
				state.transientBillingInformation.accountOwnerId === 0 ||
				state.transientBillingInformation.billingCyclePeriod === 0 ||
				state.transientBillingInformation.accountTokenId === 0 ||
				state.transientBillingInformation.accountTokenId === undefined ||
				state.transientBillingInformation.accountTokenId === null
			) {
				billingCardWarnningMessage = "Billing information must be added";
				billingCardStatus = "warning";
			} else if (
				hasChangedAccountOwner ||
				hasChangedAccountToken ||
				hasChangedBillingCycle
			) {
				billingCardWarnningMessage =
					"Unsaved changes. Please scroll up to click continue to save";
				billingCardStatus = "warning";
			} else {
				billingCardWarnningMessage = "";
				billingCardStatus = "success";
			}

			return {
				...state,
				flagStatus: {
					...state.flagStatus,
					billingCardFlags: {
						...state.flagStatus.billingCardFlags,
						billingCardStatus,
						billingCardWarnningMessage,
						hasChangedAccountOwner,
						hasChangedAccountToken,
						hasChangedBillingCycle,
					},
				},
			};
		},
		refreshMemberCardFlags: (state) => {
			let {
				hasChangedCommunication,
				hasNewMembers,
				hasRemovedMembers,
				hasChangedActivationFees,
				membersCardStatus,
				membersCardWarningMessage,
			} = state.flagStatus.memberCardFlags;

			const { items } = state.transientMembers;
			const { members } = state.subscription;

			if (items.length > 0) {
				hasNewMembers = false;
				items
					.filter((a) => a.selected && !a.isRemoved && !a.isDeleted)
					.forEach((tMember) => {
						if (
							members
								.filter((a) => !a.isRemoved)
								.filter((a) => a.accountId === tMember.accountId)
								.length === 0
						) {
							hasNewMembers = true;
						}
					});

				hasRemovedMembers = false;
				items.forEach((tMember) => {
					const found = members.filter(
						(a) => a.accountId === tMember.accountId
					)[0];

					if (
						found !== null &&
						found !== undefined &&
						(found.isDeleted !== tMember.isDeleted ||
							found.isRemoved !== (tMember.isDeleted && !tMember.selected))
					) {
						hasRemovedMembers = true;
					}
				});

				hasChangedActivationFees = false;
				items
					.filter((a) => a.selected)
					.forEach((tMember) => {
						const found = members.filter(
							(a) => a.accountId === tMember.accountId
						)[0];
						if (
							found !== null &&
							found !== undefined &&
							tMember.isPayingActivationFee !== undefined &&
							tMember.isPayingActivationFee !== found.activationFeeChecked
						) {
							hasChangedActivationFees = true;
						}
					});

				hasChangedCommunication = false;
				items
					.filter((a) => a.selected)
					.forEach((tMember) => {
						const found = members.filter(
							(a) => a.accountId === tMember.accountId
						)[0];
						if (
							found !== null &&
							found !== undefined &&
							(tMember.receivesWelcomeEmail !==
								found.receivesWelcomeEmail ||
								tMember.receivesReceipt !== found.receivesReceipt)
						) {
							hasChangedCommunication = true;
						}
					});
			}

			if (items.filter((a) => a.selected).length === 0) {
				membersCardWarningMessage =
					"The subscription must have at least one member";
				membersCardStatus = "warning";
			} else if (
				hasNewMembers ||
				hasRemovedMembers ||
				hasChangedActivationFees ||
				hasChangedCommunication
			) {
				membersCardWarningMessage =
					"Unsaved changes. Please scroll up to click continue to save";
				membersCardStatus = "warning";
			} else {
				membersCardWarningMessage = "";
				membersCardStatus = "success";
			}

			return {
				...state,
				flagStatus: {
					...state.flagStatus,
					memberCardFlags: {
						...state.flagStatus.memberCardFlags,
						hasNewMembers,
						hasRemovedMembers,
						hasChangedActivationFees,
						membersCardStatus,
						membersCardWarningMessage,
						hasChangedCommunication,
					},
				},
			};
		},
	};
};
