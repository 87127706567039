import { find, replace, sortBy, split, forEach } from "lodash-es";

export function renderHtml(html) {
	return { __html: html };
}

export function renderSvg(text, width, height) {
	return `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="${width}px" height="${height}px" viewBox="0 0 ${width} ${height}" preserveAspectRatio="none">${text}</svg>`;
}

export function renderGrid(dataParam) {
	if (dataParam === "") {
		return "";
	}

	let html = "<div>";
	const people = split(dataParam, "~");
	forEach(people, (person) => {
		const values = split(person, "|");
		forEach(values, (value) => {
			html += `<div style='border:solid 1px black;float:left;padding:5px;width:200px;word-wrap: break-word;'>${value}</div>`;
		});
		html += "<div style='clear:both'></div>";
	});
	html += "</div>";

	return html;
}

export function replaceCustomerDataFields(customerWaiver, text) {
	let finalText = text.textDecoded;
	const customerName = find(customerWaiver?.data, (d) => {
		return d.dataTypeId === 1;
	});
	const customerDOB = find(customerWaiver?.data, (d) => {
		return d.dataTypeId === 2;
	});
	const customerLastName = find(customerWaiver?.data, (d) => {
		return d.dataTypeId === 3;
	});
	const customerPhone = find(customerWaiver?.data, (d) => {
		return d.dataTypeId === 4;
	});
	const customerFirstName = find(customerWaiver?.data, (d) => {
		return d.dataTypeId === 5;
	});
	const customerAddress = find(customerWaiver?.data, (d) => {
		return d.dataTypeId === 6;
	});
	const customerParticipants = find(customerWaiver?.data, (d) => {
		return d.dataTypeId === 7;
	});
	const customerEmail = find(customerWaiver?.data, (d) => {
		return d.dataTypeId === 8;
	});
	const customerInfoGrid = find(customerWaiver?.data, (d) => {
		return d.dataTypeId === 9;
	});
	const participantInfoGrid = find(customerWaiver?.data, (d) => {
		return d.dataTypeId === 10;
	});

	finalText = replace(finalText, "[[customerName]]", customerName?.data);
	finalText = replace(finalText, "[[customerDOB]]", customerDOB?.data);
	finalText = replace(finalText, "[[customerLastName]]", customerLastName?.data);
	finalText = replace(finalText, "[[customerPhone]]", customerPhone?.data);
	finalText = replace(finalText, "[[customerFirstName]]", customerFirstName?.data);
	finalText = replace(finalText, "[[customerAddress]]", customerAddress?.data);
	finalText = replace(
		finalText,
		"[[customerParticipants]]",
		customerParticipants?.data
	);
	finalText = replace(finalText, "[[customerEmail]]", customerEmail?.data);
	finalText = replace(
		finalText,
		"[[customerInfoGrid]]",
		renderGrid(customerInfoGrid?.data)
	);

	finalText = replace(
		finalText,
		"[[participantInfoGrid]]",
		renderGrid(participantInfoGrid?.data)
	);

	const sorted = sortBy(customerWaiver?.initials, ["index"]);

	forEach(sorted, (initial: any) => {
		finalText = replace(
			finalText,
			"[[customerInitials]]",
			renderSvg(initial.text, initial.width, initial.height)
		);
	});

	return finalText;
}
