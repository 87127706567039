import React from "react";
import { Box } from "@agilekit/ui";
import CheckboxMarkedCircle from "mdi-material-ui/CheckboxMarkedCircle";
import classnames from "classnames";

// ASSETS
import useStyles from "./ListItem.styles";

interface ListItemProps {
	picture: React.ReactNode;
	title: string;
	description?: string;
	onClick?: any;
	selected?: boolean;
	disabled?: boolean;
	disabledMessage?: string;
}

const ListItem: React.FC<ListItemProps> = ({
	picture,
	title,
	description,
	onClick,
	selected = false,
	disabled = false,
	disabledMessage = "",
}) => {
	// HOOKS
	const classes = useStyles();

	// STYLES
	const cssCustomerItem = classnames(classes.container, {
		[classes.containerHover]: selected && !disabled,
		[classes.disabled]: disabled,
		[classes.containerEnabled]: !disabled,
	});

	const handleClick = () => {
		if (onClick && !disabled) {
			onClick(picture);
		}
	};

	return (
		<div
			className={cssCustomerItem}
			onClick={handleClick}
			role="presentation"
			data-testid="customerSearchListItem"
		>
			<div className={classes.content}>
				<div>{picture}</div>
				<Box pl={2}>
					<span className={classes.title}>{title}</span>
					{disabled && disabledMessage !== "" && (
						<span className={classes.disabledMessage}>{disabledMessage}</span>
					)}
					<br />
					<span className={classes.description}>{description}</span>
				</Box>
			</div>
			{!disabled && (
				<div className={classes.selectIcon} role="button" tabIndex={0}>
					<CheckboxMarkedCircle htmlColor="#0052CC" viewBox="2 0 24 24" />
				</div>
			)}
		</div>
	);
};

export default ListItem;
