import { has } from "cypress/types/lodash";
import { ILookupPrepaid, StateLoading } from "../pointOfSale.typings";

export const lookupPrepaid = {
	lookupPrepaid: {
		fetchStateLoading: {
			started: false,
			loading: false,
			success: false,
			hasError: false,
			errorMessage: "",
		} as StateLoading,
	} as ILookupPrepaid,
};

const lookupPrepaidReducer = () => {
	return {
		getLookupPrepaiStart: (state, { payload }) => {
			return {
				...state,
				lookupPrepaid: {
					...state.lookupPrepaid,
					fetchStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		getLookupPrepaiSuccess: (state, { payload }) => {
			return {
				...state,
				lookupPrepaid: {
					...state.lookupPrepaid,
					...payload.data.results,
					fetchStateLoading: {
						...state.lookupPrepaid.fetchStateLoading,
						loading: false,
						success: true,
						hasError: false,
					},
				},
			};
		},
		getLookupPrepaiFail: (state, { payload }) => {
			return {
				...state,
				lookupPrepaid: {
					fetchStateLoading: {
						started: false,
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload.error.response.data,
					},
				},
			};
		},
		resetLookupPrepaid: (state) => {
			console.log("resetLookupPrepaid");
			return {
				...state,
				lookupPrepaid: {
					...state.lookupPrepaid,
					orderNumber: "",
					balanceAmount: 0,
					issuedOn: "",
					message: "",
					success: false,
					cardNumber: 0,
					cashBonus: 0,
					tokenBonus: 0,
					points: 0,
					fetchStateLoading: {} as StateLoading,
				} as ILookupPrepaid,
			};
		},
	};
};

export default lookupPrepaidReducer;
