import { IOrderItem } from "../../order/order.typings";
import {
	IActivateGiftCardParams,
	IEmailLog,
	IGiftCardDetails,
	StateLoading,
} from "../pointOfSale.typings";

export const giftCardState = {
	giftCardDetails: {
		selectedOrderItem: {} as IOrderItem,
		emailLog: {} as IEmailLog,
		fetchStateLoading: {
			started: false,
			loading: false,
			success: false,
			hasError: false,
			errorMessage: "",
		} as StateLoading,
		sendEmailStateLoading: {
			started: false,
			loading: false,
			success: false,
			hasError: false,
			errorMessage: "",
		} as StateLoading,
	} as IGiftCardDetails,
	activateGiftCard: {
		activateParams: [] as IActivateGiftCardParams[],
		selectedOrderItemId: 0,
		activateStateLoading: {
			started: false,
			loading: false,
			success: false,
			hasError: false,
			errorMessage: "",
		} as StateLoading,
	},
};

const giftCardReducer = () => {
	return {
		setSelectedGiftCardOrderItem: (state, { payload }: { payload: IOrderItem }) => {
			state.giftCardDetails.selectedOrderItem = payload;
		},
		unsetSelectedGiftCardOrderItem: (state) => {
			state.giftCardDetails.selectedOrderItem = null;
		},
		fetchGiftCardStart: (state, { payload }) => {
			return {
				...state,
				giftCardDetails: {
					...state.giftCardDetails,
					fetchStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchGiftCardComplete: (state, { payload }) => {
			return {
				...state,
				giftCardDetails: {
					...state.giftCardDetails,
					emailLog: payload.data,
					fetchStateLoading: {
						...state.giftCardDetails.fetchStateLoading,
						loading: false,
						success: true,
					},
				},
			};
		},
		fetchGiftCardFail: (state, { payload }) => {
			return {
				...state,
				giftCardDetails: {
					...state.giftCardDetails,
					fetchStateLoading: {
						...state.giftCardDetails.fetchStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		sendEmailGiftCardStart: (state, { payload }) => {
			return {
				...state,
				giftCardDetails: {
					...state.giftCardDetails,
					sendEmailStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		sendEmailGiftCardSuccess: (state, { payload }) => {
			return {
				...state,
				giftCardDetails: {
					...state.giftCardDetails,
					sendEmailStateLoading: {
						...state.giftCardDetails.sendEmailStateLoading,
						success: true,
					},
				},
			};
		},
		sendEmailGiftCardFail: (state, { payload }) => {
			return {
				...state,
				giftCardDetails: {
					...state.giftCardDetails,
					sendEmailStateLoading: {
						...state.giftCardDetails.sendEmailStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		activateGiftCardStart: (state, { payload }) => {
			return {
				...state,
				activateGiftCard: {
					...state.activateGiftCard,
					selectedOrderItemId: payload.orderItemId,
					activateStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		activateGiftCardSuccess: (state, { payload }) => {
			return {
				...state,
				activateGiftCard: {
					...state.activateGiftCard,
					activateStateLoading: {
						...state.activateGiftCard.activateStateLoading,
						success: payload.data.ok,
					},
					selectedOrderItemId: 0,
					activateParams: [
						...state.activateGiftCard.activateParams,
						payload.data.results,
					],
				},
			};
		},
		activateGiftCardFail: (state, { payload }) => {
			return {
				...state,
				activateGiftCard: {
					...state.activateGiftCard,
					activateStateLoading: {
						...state.activateGiftCard.activateStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		reloadGiftCardStart: (state, { payload }) => {
			return {
				...state,
				activateGiftCard: {
					...state.activateGiftCard,
					selectedOrderItemId: payload.orderItemId,
					activateStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		reloadGiftCardSuccess: (state, { payload }) => {
			return {
				...state,
				activateGiftCard: {
					...state.activateGiftCard,
					activateStateLoading: {
						...state.activateGiftCard.activateStateLoading,
						success: payload.data.ok,
					},
					selectedOrderItemId: 0,
					activateParams: [
						...state.activateGiftCard.activateParams,
						payload.data.results,
					],
				},
			};
		},
		reloadGiftCardFail: (state, { payload }) => {
			return {
				...state,
				activateGiftCard: {
					...state.activateGiftCard,
					activateStateLoading: {
						...state.activateGiftCard.activateStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
	};
};

export default giftCardReducer;
