import { DateTime } from "luxon";
import store from "../store/configureStore";
import { IFacilitySettings } from "../store/modules/enviroment/enviroment.slice";

const formatDateTime = (
	value: string | Date | null | undefined,
	includeTime = false,
	useTimezoneOffset = true,
	customDateFormat = "",
	shortFormat = false
): string => {
	const { isValid } = DateTime.fromISO(value);

	if (!isValid) return "";

	const { dateDisplay, timezoneCode }: IFacilitySettings =
		store.getState().enviroment.facilitySettings;

	if (customDateFormat.length > 0) {
		if (useTimezoneOffset) {
			const date = DateTime.fromISO(value, { zone: "utc" });

			return includeTime
				? date.setZone(timezoneCode).toFormat(`${customDateFormat} hh:mm a`)
				: date.setZone(timezoneCode).toFormat(customDateFormat);
		}

		const date = DateTime.fromISO(value, { zone: "utc" });

		return includeTime
			? date.toFormat(`${customDateFormat} hh:mm a`)
			: date.toFormat(customDateFormat);
	}

	let finalDateDisplay = dateDisplay
		.replaceAll("-", "/")
		.replace("MM", "M")
		.replace("dd", "d");

	if (shortFormat) {
		finalDateDisplay = finalDateDisplay.replace("/yyyy", "");
	}

	if (useTimezoneOffset) {
		const date = DateTime.fromISO(value, { zone: "utc" });

		return includeTime
			? date.setZone(timezoneCode).toFormat(`${finalDateDisplay} hh:mm a`)
			: date.setZone(timezoneCode).toFormat(finalDateDisplay);
	}

	const date = DateTime.fromISO(value, { zone: "utc" });

	return includeTime
		? date.toFormat(`${finalDateDisplay} hh:mm a`)
		: date.toFormat(finalDateDisplay);
};

export default formatDateTime;
