import { IProductsState, IScreenButton } from "../pointOfSale.typings";

export const buttonsState = {
	products: {
		verifyProducts: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		fetchStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
		},
		items: [],
	} as IProductsState,
	buttons: {
		saveStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
			started: false,
		},
		updateStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
			started: false,
		},
		deleteStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
			started: false,
		},
		updateSlotStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
			started: false,
		},
		updateButtonImageLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
			started: false,
		},
		addedButton: {} as IScreenButton,
		selectedButton: {} as IScreenButton,
		deletedScreenButtonId: 0,
	},
};

const buttonReducer = () => {
	return {
		fetchProductsStart: (state) => {
			return {
				...state,
				products: {
					...state.products,
					fetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchProductsComplete: (state, { payload }) => {
			return {
				...state,
				products: {
					...state.products,
					fetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
					items: payload.data.results,
				},
			};
		},
		fetchProductsFail: (state, { payload }) => {
			return {
				...state,
				products: {
					...state.products,
					fetchStateLoading: {
						...state.profiles.fetchStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		validateButtonStart: (state, { payload }) => {
			return {
				...state,
				products: {
					...state.products,
					verifyProducts: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		validateButtonComplete: (state, { payload }) => {
			let error;
			for (const key in payload.data.errors) {
				error = payload.data.errors[key];
			}
			return {
				...state,
				products: {
					...state.products,
					verifyProducts: {
						loading: false,
						success: payload.data.ok,
						hasError: false,
						errorMessage: error,
					},
				},
			};
		},
		validateButtonFail: (state, { payload }) => {
			return {
				...state,
				products: {
					...state.products,
					verifyProducts: {
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload.data.errors,
					},
				},
			};
		},
		addNewScreenButtonStart: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					saveStateLoading: {
						started: true,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		addNewScreenButtonCompleted: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					saveStateLoading: {
						...state.buttons.saveStateLoading,
						success: true,
					},
					addedButton: payload.data.results.posScreenButton,
				},
			};
		},
		addNewScreenButtonFail: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					saveStateLoading: {
						...state.buttons.saveStateLoading,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		updateScreenButtonStart: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					updateStateLoading: {
						started: true,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		updateScreenButtonCompleted: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					updateStateLoading: {
						...state.buttons.updateStateLoading,
						success: true,
					},
					addedButton: payload.data.results.posScreenButton,
				},
			};
		},
		updateScreenButtonFail: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					updateStateLoading: {
						...state.buttons.updateStateLoading,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		deleteScreenButtonStart: (state, { payload }) => {
			state.editScreen.layout.buttonDrawerOpen = false;
			state.buttons.deleteStateLoading = {
				started: true,
				loading: false,
				success: false,
				hasError: false,
				errorMessage: "",
			};
			state.buttons.deletedScreenButtonId = payload.screenButtonId;
		},
		deleteScreenButtonCompleted: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					deleteStateLoading: {
						...state.buttons.deleteStateLoading,
						success: true,
					},
					addedButton: {},
				},
			};
		},
		deleteScreenButtonFail: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					deleteStateLoading: {
						...state.buttons.deleteStateLoading,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		resetAddedButton: (state) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					addedButton: null,
				},
			};
		},

		saveChangeButtonSlotStart: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					updateSlotStateLoading: {
						started: true,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		saveChangeButtonSlotCompleted: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					updateSlotStateLoading: {
						...state.buttons.updateSlotStateLoading,
						success: true,
					},
				},
			};
		},
		saveChangeButtonSlotFail: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					deleteStateLoading: {
						...state.buttons.deleteStateLoading,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		setSelectedEditButton: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					selectedButton: payload,
				},
			};
		},
		unsetSelectedEditButton: (state) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					selectedButton: {},
				},
			};
		},
		updateButtonImageStart: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					updateButtonImageLoading: {
						started: true,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		updateButtonImageCompleted: (state, { payload }) => {
			const screenButton = payload.data.results.posScreenButton;

			return {
				...state,
				buttons: {
					...state.buttons,
					updateButtonImageLoading: {
						...state.buttons.updateButtonImageLoading,
						success: true,
					},
				},
				selectedScreen: {
					...state.selectedScreen,
					screen: {
						...state.selectedScreen.screen,
						buttons: state.selectedScreen.screen.buttons.map(
							(button) =>
								([screenButton] as IScreenButton[]).find(
									(s) => s.screenButtonId === button.screenButtonId
								) || button
						),
					},
				},
			};
		},
		updateButtonImageFail: (state, { payload }) => {
			return {
				...state,
				buttons: {
					...state.buttons,
					updateButtonImageLoading: {
						...state.buttons.updateButtonImageLoading,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
	};
};

export default buttonReducer;
