import { ICustomer } from "../customer/customer.typings";

export interface IScheduleProduct {
	productId: number;
	productName: string;
	productDescription: string;
	defaultPrice: number;
	isActive: boolean;
	productType: string;
	category: string;
	breakoutGuestFees: boolean;
	overallGuestCapacity: number;
	overallGuestMinimum: number;
	minimumGuestCount1: number;
	minimumGuestCount2: number;
	standardGuestCount1: number;
	standardGuestCount2: number;
	maximumGuestCount1: number;
	maximumGuestCount2: number;
	guestCapacity1: number;
	guestCapacity2: number;
	additionalGuestFee1: number;
	additionalGuestFee2: number;
	overTheLimitGuestFee1: number;
	overTheLimitGuestFee2: number;
	imageUrl: string;
	labelChild: string;
	labelChildren: string;
	labelAdult: string;
	labelAdults: string;
	labelPerson: string;
	labelPeople: string;
	goHSetting: string;
	slotStartAdjustment: number;
	slotEndAdjustment: number;
	packageItemsPricing: Array<IScheduleProductPricing>;
	depositAmount?: number;
}

export interface IScheduleDetail {
	id: number;
	orderId: number;
	slotStartDateTime: Date;
	slotEndDateTime: Date;
	scheduleHeaderId: number;
	isOneTime: boolean;
	defaultProduct: IScheduleProduct;
	allSlotProducts: Array<IScheduleProduct>;
}

export interface IScheduleOrder {
	scheduleId: number;
	orderId: number;
	selectedProduct: IScheduleProduct;
	selectedCustomer: ICustomer;
	guestsOfHonor: Array<IScheduleOrganizationGOH>;
	children: number;
	adults: number;
	guests: number;
	preTaxTotal: number;
}

export interface IScheduleOrganizationGOH {
	accountId: number;
	orderId: number;
	type: AccountType;
	birthDate: string;
	firstName: string;
	lastName: string;
	fullName: string;
	gender: string;
	avatar: string;
	isDependent: boolean;
	isOrganization: boolean;
}

export interface IScheduleProductPricing {
	price: number;
	dependsOnGuestCount: boolean;
	errorMessage: string;
}

export enum AccountType {
	CUSTOMER = 0,
	DEPENDENT = 1,
	ORGANIZATION = 2,
}
