import { createSlice } from "@reduxjs/toolkit";
import { IScheduleProduct, IScheduleOrganizationGOH } from "./partySchedule.typings";
import gohReducer from "./reducers/goh.reducer";
import orderCustomerReducer from "./reducers/orderCustomer.reducer";
import bookingAssociationReducer from "./reducers/bookingAssociation.reducer";
import { ICustomer } from "../customer/customer.typings";
import { IScheduleCustomer } from "../scheduleCustomer/scheduleCustomer.typings";
import calendarReducer from "./reducers/calendar.reducer";

const initialState = {
	scheduleDetail: {
		id: 0,
		orderId: 0,
		defaultProduct: {
			productId: 0,
		} as unknown as IScheduleProduct,
		allSlotProducts: [] as Array<IScheduleProduct>,
		slotEndDateTime: undefined as unknown as Date,
		slotStartDateTime: undefined as unknown as Date,
	},
	selectedProduct: {
		productId: 0,
	} as unknown as IScheduleProduct,
	preTotalTax: 0,
	selectedParty: "",
	selectedCustomer: {} as unknown as ICustomer,
	partyGuests: {
		adultsField: "",
		childrenField: "",
		guestField: "",
	},
	layout: {
		isGuestCountDisabled: true,
	},
	partyGoH: [] as Array<IScheduleOrganizationGOH>,
	orderId: 0,
	continueDisabled: true,
	continueToOrderSuccess: false,
	continueToOrderFailure: false,
	backToScheduleSuccess: false,
	backToScheduleFailure: false,
	updateOrderCustomerSuccess: false,
	// page control variables
	updateScheduleProductSuccess: false,
	isLoading: false,
	error: {} as any,

	// Booking Customer and Associations
	bookingCustomer: {} as unknown as IScheduleCustomer,
	isLoadingBookingCustomer: false,
	isLoadingAssociations: false,

	// Calendar Update Events
	calenderUpdateEvents: {
		loading: false,
		success: false,
		error: "",
	},
};

const scheduleSlice = createSlice({
	name: "SCHEDULE",
	initialState,
	reducers: {
		...gohReducer(),
		...orderCustomerReducer(),
		...bookingAssociationReducer(),
		...calendarReducer(),
		// GET SCHEDULE
		getScheduleSuccess: (state, { payload }) => {
			return {
				...state,
				scheduleDetail: payload.data,
				isLoading: false,
				error: "",
				getScheduleSuccess: true,
			};
		},
		getScheduleFailure: (state, { payload }) => {
			return {
				...state,
				error: payload,
				isLoading: false,
				getScheduleSuccess: false,
			};
		},
		getScheduleRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				getScheduleSuccess: false,
			};
		},

		// UPDATE SCHEDULE PRODUCT
		updateScheduleProductSuccess: (state, { payload }) => {
			return {
				...state,
				selectedProduct: payload.data,
				isLoading: false,
				error: "",
				getScheduleSuccess: false,
				updateScheduleProductSuccess: true,
			};
		},

		updateScheduleProductFailure: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				error: payload,
				getScheduleSuccess: false,
				updateScheduleProductSuccess: true,
			};
		},

		updateScheduleProductRequest: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				error: "",
				getScheduleSuccess: false,
				updateScheduleProductSuccess: true,
			};
		},

		// CUSTOMER
		getCustomerFromModalSuccess: (state, { payload }) => {
			return {
				...state,
				selectedCustomer: payload.data,
				isLoading: false,
				error: "",
				getCustomerFroModalSuccess: true,
			};
		},

		getCustomerFromModalFailure: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				error: payload,
				selectedCustomer: {} as unknown as ICustomer,
				getCustomerFroModalSuccess: true,
			};
		},

		setSelectedProduct: (state, { payload }) => {
			return {
				...state,
				selectedProduct: payload,
			};
		},

		getCustomerFromModal: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				error: "",
				getCustomerFromModalSuccess: true,
			};
		},
		// Party Guests
		updatePartyChildren: (state, { payload }) => {
			return {
				...state,
				partyGuests: {
					childrenField: payload,
					adultsField: state.partyGuests.adultsField,
					guestField: state.partyGuests.guestField,
				},
			};
		},
		updatePartyAdults: (state, { payload }) => {
			return {
				...state,
				partyGuests: {
					childrenField: state.partyGuests.childrenField,
					adultsField: payload,
					guestField: state.partyGuests.guestField,
				},
			};
		},
		updatePartyGuests: (state, { payload }) => {
			return {
				...state,
				partyGuests: {
					childrenField: state.partyGuests.childrenField,
					adultsField: state.partyGuests.adultsField,
					guestField: payload,
				},
			};
		},
		setContinueDisabled: (state, { payload }) => {
			return {
				...state,
				continueDisabled: payload,
			};
		},

		// CONTINUE BUTTON
		continueRequest: (state, { payload }) => {
			return {
				...state,
				continueDisabled: true,
				error: "",
				continueToOrderSuccess: false,
				continueToOrderFailure: false,
			};
		},
		continueSuccess: (state, { payload }) => {
			return {
				...state,
				error: "",
				continueToOrderSuccess: true,
				continueToOrderFailure: false,
				orderId: payload.data.data.orderId,
			};
		},
		continueFailure: (state, { payload }) => {
			return {
				...state,
				error: payload.data.errorMessage,
				continueToOrderSuccess: false,
				continueToOrderFailure: true,
				continueDisabled: false,
			};
		},

		// Back to Schedule
		backToScheduleRequest: (state, { payload }) => {
			return {
				...state,
				continueDisabled: true,
				error: "",
				backToScheduleSuccess: false,
				backToScheduleFailure: false,
			};
		},

		backToScheduleSuccess: (state, { payload }) => {
			return {
				...state,
				error: "",
				backToScheduleSuccess: true,
				backToScheduleFailure: false,
			};
		},
		backToScheduleFailure: (state, { payload }) => {
			return {
				...state,
				error: payload.errorMessage,
				backToScheduleSuccess: false,
				backToScheduleFailure: true,
				continueDisabled: false,
			};
		},
		setPreTotalTax: (state, { payload }) => {
			return {
				...state,
				preTotalTax: payload,
			};
		},
		setSelectedParty: (state, { payload }) => {
			return {
				...state,
				selectedParty: payload,
			};
		},
	},
});

export const {
	getScheduleSuccess,
	getScheduleFailure,
	getScheduleRequest,
	updateScheduleProductSuccess,
	updateScheduleProductFailure,
	updateScheduleProductRequest,

	setSelectedProduct,

	getCustomerFromModalSuccess,
	getCustomerFromModalFailure,
	getCustomerFromModal,

	setContinueDisabled,

	updatePartyChildren,
	updatePartyAdults,
	updatePartyGuests,

	addGoHDependents,
	addGoHOrganizations,
	addGoHCustomer,
	removeGoHCustomer,
	resetGoH,

	continueRequest,
	continueFailure,
	continueSuccess,

	backToScheduleRequest,
	backToScheduleSuccess,
	backToScheduleFailure,

	addCustomerToOrderStart,
	addCustomerToOrderSuccess,
	addCustomerToOrderFailure,
	removeCustomerFromOrderStart,
	removeCustomerFromOrderSuccess,
	removeCustomerFromOrderFailure,

	fetchBookingCustomer,
	successFetchBookingCustomer,
	failedFetchBookingCustomer,
	setBookingCustomer,
	resetBookingCustomer,

	setPreTotalTax,
	setSelectedParty,

	updateCalendarEventsStart,
	updateCalendarEventsSuccess,
	updateCalendarEventsFailure,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
