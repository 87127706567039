import { AxiosPromise } from "axios";

// HELPERS
import Http from "../../../utils/restClient";
import { IRefundRequest } from "./order.typings";
import { IAddOrderRequest } from "../../../containers/pages/PointOfSale/sections/Sales/components/SalesGrid/SalesGrid.helper";

export default class OrderApi {
	public static GetOrderInfo(payload) {
		return Http.axios().get(`/api/v1/orders/${payload}`) as AxiosPromise<any>;
	}

	public static AddOrder(model: IAddOrderRequest) {
		return Http.axios().post("/api/v1/orders/pos", model) as AxiosPromise<any>;
	}

	public static UpdateOrder(model: any) {
		return Http.axios().put("/api/v1/orders/pos", model) as AxiosPromise<any>;
	}

	public static UpdateOrderCustomer(model: any) {
		return Http.axios().put(
			"/api/v1/orders/pos/customer",
			model
		) as AxiosPromise<any>;
	}

	public static AddItem(payload: any) {
		return Http.axios().post(
			`/api/v1/orders/pos/${payload.orderId}`,
			payload.model
		) as AxiosPromise<any>;
	}

	public static RemoveItem(payload: any) {
		return Http.axios().delete(
			`/api/v1/orders/pos/${payload.orderId}/items/${payload.itemId}`
		) as AxiosPromise<any>;
	}

	public static UpdateQuantity(payload: any) {
		return Http.axios().put(
			`/api/v1/orders/pos/${payload.orderId}/items/${payload.itemId}`,
			payload.model
		) as AxiosPromise<any>;
	}

	public static ClearOrder(payload: any) {
		return Http.axios().delete(
			`/api/v1/orders/pos/${payload.orderId}/items`
		) as AxiosPromise<any>;
	}

	public static DiscountOrder(payload: any) {
		return Http.axios().post(
			`/api/v1/orders/pos/${payload.orderId}/discount`,
			payload
		) as AxiosPromise<any>;
	}

	public static DiscountOrderItem(orderId, discountId, orderItemId) {
		return Http.axios().post(
			`/api/v1/orders/pos/${orderId}/discount/${discountId}/orderItemId/${orderItemId}`
		) as AxiosPromise<any>;
	}

	public static UpdateOrderItemNotes(
		orderId,
		noteText,
		orderItemId,
		displayNotesOnReceipt
	) {
		return Http.axios().post(
			`/api/v1/orders/pos/${orderId}/notes/orderItemId/${orderItemId}`,
			{
				notes: noteText,
				displayNotesOnReceipt,
			}
		) as AxiosPromise<any>;
	}

	public static UpdateOrderItemPrice(orderId, updatedPrice, orderItemId) {
		return Http.axios().post(
			`/api/v1/orders/pos/${orderId}/price/orderItemId/${orderItemId}`,
			{
				updatedPrice,
			}
		) as AxiosPromise<any>;
	}

	public static AddTip(orderId, tipAmount) {
		return Http.axios().post(`/api/v1/orders/pos/${orderId}/tip`, {
			tipAmount,
		}) as AxiosPromise<any>;
	}

	public static AddCreditTip(orderId, tipAmount, paymentModuleTransactionId) {
		return Http.axios().post(`/api/v1/orders/pos/${orderId}/tip/credit`, {
			tipAmount,
			paymentModuleTransactionId,
		}) as AxiosPromise<any>;
	}

	public static VisitOrder(payload: any) {
		return Http.axios().post("/api/v1/orders/visit", payload) as AxiosPromise<any>;
	}

	public static GetPaymentHistory(orderId) {
		return Http.axios().get(
			`/api/v1/orders/${orderId}/payments/history`
		) as AxiosPromise<any>;
	}

	public static RefundOrderItems(orderId: number, refundRequest: IRefundRequest) {
		return Http.axios().post(
			`/api/v1/orders/pos/${orderId}/refund/items`,
			refundRequest
		) as AxiosPromise<any>;
	}
}
