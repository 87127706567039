import React from "react";
import AccountOff from "mdi-material-ui/AccountOff";

// ASSETS
import useStyles from "./NoCustomerFound.styles";

const NoCustomerFound = ({ notFoundMessage = "" }) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<div className={classes.icon}>
				<AccountOff fontSize="inherit" />
			</div>
			<div className={classes.content}>
				<p>{notFoundMessage !== "" ? notFoundMessage : "No results found"}</p>
			</div>
		</div>
	);
};

export default NoCustomerFound;
