import { TypeRelatedPerson } from "../customer/customer.typings";
import { BillingCycleEnum } from "./customerMembership.typings";
// import { MembershipPlanPricing } from "../memberships/memberships.typings";

// TODO: Remove this file by the end of refactoring
export interface ICustomerMemberships {
	memberships: ICustomerMembership[];
}

export enum SubscriptionStatusEnum {
	Active = 1,
	OnHold,
	FailedPayment,
	Inactive,
	Cancelled,
	Expiring,
	HoldScheduled,
}

export const SubscriptionStatusEnumLabel = new Map<SubscriptionStatusEnum, string>([
	[SubscriptionStatusEnum.Active, "Active"], // AutoSave === false
	[SubscriptionStatusEnum.OnHold, "On Hold"], // AutoSave === true
	[SubscriptionStatusEnum.FailedPayment, "Failed Payment"], // AutoSave === true
	[SubscriptionStatusEnum.Inactive, "Inactive"], // AutoSave === true
	[SubscriptionStatusEnum.Cancelled, "Cancelled"], // AutoSave === true
	[SubscriptionStatusEnum.Expiring, "Expiring"], // AutoSave === false
	[SubscriptionStatusEnum.HoldScheduled, "Hold Scheduled"], // AutoSave === false
]);

export interface ICustomerMembership {
	planId: number;
	accountOwnerId: number;
	subscriptionId: number;
	productId: number;
	planColor: string;
	planName: string;
	FirstName: string;
	LastName: string;
	isActive: boolean;
	isPlanActive: boolean;
	isCancelled: boolean;
	isSetupComplete: boolean;
	isDeleted: boolean;
	isCustomerMembershipReadOnly: boolean;
	accountTokenId: number;
	activationFeePerPerson: boolean;
	activationFee: number;
	activationFeeName: string;
	welcomeTemplateId?: number;
	welcomeTemplateName: string;
	receiptTemplateId?: number;
	receiptTemplateName: string;
	billingReferenceDate?: Date;
	nextBillingDate?: Date;
	billingCyclePeriod?: BillingCycleEnum;
	status: SubscriptionStatusEnum;
	statusDate: Date;
	expiringDate?: Date;
	onHoldStartDate?: Date;
	onHoldEndDate?: Date;
	OnHoldScheduled: boolean;
	isMonthly: boolean;
	isYearly: boolean;

	planActivationFee?: number;
	planActivationFeePaidOn?: Date;
	planActivationFeeName?: string;
	hasPaidActivationFee: boolean;

	members: IMembershipMember[];
	// prices: MembershipPlanPricing[];
	prices: Array<{}>;
}

export interface IMembershipMember {
	subscriptionMemberId: number;
	subscriptionId: number;
	accountId: number;
	firstName: string;
	lastName: string;
	fullName: string;
	isDependent: boolean;
	isDeleted: boolean;
	isRemoved: boolean;
	receivesWelcomeEmail: boolean;
	receivesReceipt: boolean;
	avatar: string;
}

export interface ISubscriptionCustomerPeople {
	accountId: number;
	selected: boolean;
	showMemberInDrawer: boolean;
	isAccountOwner: boolean;
	person: TypeRelatedPerson;
	hasAnotherSubscription: boolean;
	receivesWelcomeEmail: boolean;
	receivesReceipt: boolean;
	isDeleted: boolean;
	isRemoved: boolean;
	activationFeePaidOn?: Date;
	activationFeePaid?: number;
	hasPaidActivationFee: boolean;
	activationFeeName: string;
	isPayingActivationFee: boolean;
}

export interface IAccountToken {
	accountTokenId: number;
	token: string;
	nameOnAccount: string;
	cardFlag: string;
	cardLastFour: string;
}
export interface IAccountOwner {
	accountId: number;
	firstName: string;
	lastName: string;
	avatar: string;
	email: string;
	fullName: string;
	accountTokens: IAccountToken[];
	cantDelete: boolean;
}

export interface ISubscriptionSave {
	subscriptionId: number;
	subscriptionPlanId: number;
	billingCyclePeriod: number;
	accountOwnerId: number;
	accountTokenId: number;
	activationFeeAmount: number;
	shownValue?: number;
	isPayingActivationFeePerPlan?: boolean;
	subscriptionMembers: Array<ISubscriptionMembersSave>;
	subscriptionPlanHasChanged: boolean;
	reactivateSubscription: boolean;
}

export interface ISubscriptionSaveCancel {
	subscriptionId: number;
	subscriptionPlanId: number;
	accountOwnerId: number;
	accountId: number;
	subscriptionMembers: Array<ISubscriptionMembersSave>;
}

export interface ISubscriptionMembersSave {
	accountId: number;
	receivesWelcomeEmail: boolean;
	receivesReceipt: boolean;
	isAdded: boolean;
	isDeleted: boolean;
	isHardDelete: boolean;
	isPayingActivationFee: boolean;
	hasPaidActivationFee: boolean;
	isNewMember: boolean;
}

export interface ISubscriptionTaxes {
	subscriptionId: string;
	productId: number;
	amount: number;
	productPriceWithoutTaxes: number;
	taxesIncluded: number;
	taxesAfter: number;
	fullAmountWithTaxes: number;
	taxes: Array<IProductTaxInfo>;
}

export interface IProductTaxInfo {
	productId: number;
	taxId: number;
	productPrice: number;
	taxName: string;
	taxRate: number;
	inclusive: boolean;
	taxValue: number;
}

export interface IHoldSubscription {
	startDate: Date;
	endDate: Date;
}

export interface IActivationFeeUpdate {
	accountId: number;
	subscriptionId: number;
	planId: number;
	subscriptionMemberId: number;
	isPayingActivationFee: boolean;
}

export type CardSettingsStatus = "warning" | "success" | "changed";
