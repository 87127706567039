import { takeLatest, call, put } from "redux-saga/effects";
import { taxesListFailure, taxesListStart, taxesListSuccess } from "./taxes.slice";
import TaxesApi from "./taxes.api";

export function* getTaxesList({ payload }): any {
	try {
		const response = yield call(TaxesApi.GetTaxesList);
		yield put(taxesListSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(taxesListFailure({ error }));
	}
}

export function* watchTaxes() {
	return [yield takeLatest(taxesListStart, getTaxesList)];
}
