import { ISubscriptionMember, ITransientMember } from "../customerMembership.typings";

export const updateRebuildTransientMembers = (
	selected,
	accountId,
	mode,
	receivesWelcomeEmail,
	receivesReceipt,
	isPayingActivationFee,
	autoSave,
	subscriptionMembers: Array<ISubscriptionMember>,
	transientMembers: Array<ITransientMember>
): Array<ITransientMember> => {
	const rebuiltTransientMembers = transientMembers.map((a) => {
		const result = { ...a };
		if (a.accountId === accountId) {
			result.selected = selected;
			result.isDeleted = false;

			if (receivesReceipt !== null && typeof receivesReceipt === "boolean") {
				result.receivesReceipt = receivesReceipt;
			}
			if (
				receivesWelcomeEmail !== null &&
				typeof receivesWelcomeEmail === "boolean"
			) {
				result.receivesWelcomeEmail = receivesWelcomeEmail;
			}

			if (a.person.isDependent) {
				result.receivesWelcomeEmail = false;
				result.receivesReceipt = false;
			}

			if (mode !== null) {
				switch (mode) {
					case "SOFT":
						result.isDeleted = true;
						result.selected = true;
						result.showMemberInDrawer = false;
						break;
					case "HARD":
						result.isDeleted = true;
						result.selected = false;
						result.showMemberInDrawer =
							subscriptionMembers.filter(
								(member) => member.accountId === result.accountId
							).length === 0;
						break;
					case "RESTORE":
						result.isDeleted = false;
						result.selected = true;
						result.showMemberInDrawer = false;
						break;
					// that is the case when the customer added some member but remove without saving
					case "UNDO":
						result.selected = false;
						break;
					default:
						break;
				}
			}

			if (isPayingActivationFee !== undefined) {
				const actFee = { ...result.activationFees };
				actFee.isPayingActivationFee = isPayingActivationFee;
				result.activationFees = actFee;
			}
		}

		return result;
	});
	return rebuiltTransientMembers;
};

export const updateRebuildByArray = (
	addedMembers: Array<number>,
	removedMembers: Array<number>,
	autoSave: boolean,
	subscriptionMembers: Array<ISubscriptionMember>,
	transientMembers: Array<ITransientMember>
) => {
	let addedRebuilt = [...transientMembers];
	addedMembers.forEach((added) => {
		const toAdd = updateRebuildTransientMembers(
			true,
			added,
			null,
			null,
			null,
			true,
			autoSave,
			subscriptionMembers,
			addedRebuilt
		);
		addedRebuilt = toAdd;
	});
	removedMembers.forEach((removed) => {
		const toRemove = updateRebuildTransientMembers(
			false,
			removed,
			null,
			null,
			null,
			false,
			autoSave,
			subscriptionMembers,
			addedRebuilt
		);

		addedRebuilt = toRemove;
	});

	return addedRebuilt;
};
