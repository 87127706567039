import { takeLatest, call, put } from "redux-saga/effects";
import {
	getProductsBarCodeStart,
	getProductsBarCodeFailure,
	getProductsBarCodeSuccess,
} from "./products.slice";
import ProductsApi from "./products.api";

export function* getProductsBarCode({ payload }): any {
	try {
		const response = yield call(ProductsApi.GetProductsBarCode, payload);

		yield put(getProductsBarCodeSuccess({ data: response.data }));
	} catch (error: any) {
		yield put(getProductsBarCodeFailure({ error }));
	}
}

export function* watchProducts() {
	return [yield takeLatest(getProductsBarCodeStart, getProductsBarCode)];
}
