import * as React from "react";

function logoPCSWhite(props) {
	return (
		<svg width="1em" height="1em" viewBox="0 0 24 28" fill="none" {...props}>
			<g clipPath="url(#logo-white_svg__clip0)" fill="#fff">
				<path d="M11.997 0L0 6.974l11.997 6.969L24 6.974 11.997 0zM0 9.41l10.6 6.16v12.306L0 21.716V9.41zM24 9.41l-10.6 6.16v12.306l10.6-6.16V9.41z" />
			</g>
			<defs>
				<clipPath id="logo-white_svg__clip0">
					<path fill="#fff" d="M0 0h24v27.876H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default logoPCSWhite;
