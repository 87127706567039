/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useMediaQuery, useTheme } from "@agilekit/ui";
import { truncate } from "lodash-es";

// ACTIONS
import {
	closeUserActions,
	openUserActions,
} from "../../../../store/modules/headerNav/headerNav.slice";

// SELECTORS
import { isUserActionsOpen } from "../../../../store/modules/headerNav/headerNav.selector";
import {
	getCookiesInfoState,
	getFacilityAliasState,
} from "../../../../store/modules/enviroment/enviroment.selector";

// ASSETS
import useStyles from "./UserActions.styles";
import MenuUp from "mdi-material-ui/MenuUp";
import MenuDown from "mdi-material-ui/MenuDown";
import FacilityAliasModal from "./FacilityAliasModal/FacilityAliasModal";
import { useLocation } from "react-router-dom";

const UserActions = () => {
	const theme = useTheme();
	const classes = useStyles();
	const dispatch = useDispatch();
	const location = useLocation();

	const userActionsIsOpen = useSelector(isUserActionsOpen);
	const cookiesInfo = useSelector(getCookiesInfoState);
	const { isFacilityAliasModalOpen } = useSelector(getFacilityAliasState);

	const [jsFiles, setJsFiles] = useState<Array<any>>([]);

	const screenXS = useMediaQuery(theme.breakpoints.down("xs"));

	const domClick = (event) => {
		if (
			event.target.id !== "userButton" &&
			event.target.id !== "userButtonInfo" &&
			userActionsIsOpen
		) {
			dispatch(closeUserActions());
		}
	};

	useEffect(() => {
		document.addEventListener("click", domClick);

		return () => {
			document.removeEventListener("click", domClick);
		};
	}, [userActionsIsOpen]);

	useEffect(() => {
		const scriptFileNames = Array.from(document.querySelectorAll("script"))
			.filter((script) => script.src)
			.map((script) => {
				const url = new URL(script.src);
				return url.pathname.split("/").pop();
			});

		setJsFiles(scriptFileNames);
	}, []);

	// EVENTS
	const handleClick = () => {
		if (location.pathname.includes("menu")) {
			window.parent.postMessage(
				{
					action: "openFullScreenModal",
					component: "GlobalUserOptions",
				},
				"*"
			);
		} else {
			userActionsIsOpen
				? dispatch(closeUserActions())
				: dispatch(openUserActions());
		}
	};

	// STYLES
	const css = classNames(classes.userActions, {
		[classes.userActionsOpen]: userActionsIsOpen,
	});

	return (
		<div className={classes.userActionsContainer}>
			<div className={css}>
				<button
					id="userButton"
					type="button"
					className={classes.sectionRightButton}
					onClick={() => handleClick()}
				>
					<div id="userButtonInfo" className={classes.userActions}>
						<strong id="userButtonInfo">
							{screenXS
								? cookiesInfo.userFirstLastName[0]
								: truncate(cookiesInfo.userFirstLastName, {
										length: 23,
								  })}
						</strong>
						{userActionsIsOpen ? (
							<MenuUp id="userButtonInfo" />
						) : (
							<MenuDown id="userButtonInfo" />
						)}
					</div>
				</button>
				{isFacilityAliasModalOpen && <FacilityAliasModal />}
			</div>
		</div>
	);
};

export default UserActions;
