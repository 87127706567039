import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		container: {
			borderRadius: 4,
			backgroundColor: theme.palette.NEUTRAL000,
			boxShadow: theme.shadows[1],
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			padding: theme.spacing(2, 4),
			marginBottom: theme.spacing(4),
			"& p": {
				fontSize: theme.typography.pxToRem(14),
				lineHeight: theme.typography.pxToRem(20),
				color: theme.palette.NEUTRAL700,
				margin: 0,
			},
			"& button": {
				backgroundColor: "transparent",
				border: "none",
				cursor: "pointer",
				"&:focus": {
					outline: "none",
				},
			},
		},
		titleContainer: {
			display: "flex",
			alignItems: "center",
		},
		title: {
			fontWeight: 500,
		},
	};
});

export default useStyles;
