import { takeLatest, call, put } from "redux-saga/effects";

import {
	orderSearchStart,
	orderSearchComplete,
	orderSearchFail,
	fetchLastTenOrdersVisitedStart,
	fetchLastTenOrdersVisitedComplete,
	fetchLastTenOrdersVisitedFail,
	fetchHelpStart,
	fetchHelpComplete,
	fetchHelpFail,
	acknowledgeHelpStart,
	acknowledgeHelpComplete,
	acknowledgeHelpFail,
} from "./headerNav.slice";
import SearchApi from "./headerNav.api";

export function* getOrderSearch({ payload }): any {
	try {
		const response = yield call(SearchApi.GetOrderSearch, payload);

		yield put(orderSearchComplete({ data: response.data }));
	} catch (error: any) {
		yield put(orderSearchFail({ error }));
	}
}

export function* fetchLastTenOrdersVisited(): any {
	try {
		const response = yield call(SearchApi.FetchLastTenOrdersVisited);

		yield put(fetchLastTenOrdersVisitedComplete({ data: response.data }));
	} catch (error: any) {
		yield put(fetchLastTenOrdersVisitedFail({ error }));
	}
}

export function* fetchHelpData(payload): any {
	try {
		const response = yield call(SearchApi.FetchSupportCommunications, payload);

		yield put(fetchHelpComplete({ data: response.data }));
	} catch (error: any) {
		yield put(fetchHelpFail({ error }));
	}
}

export function* acknowledgeHelp(payload): any {
	try {
		const response = yield call(
			SearchApi.AcknowledgeSupportCommunication,
			payload.payload
		);

		yield put(acknowledgeHelpComplete({ data: response.data }));
	} catch (error: any) {
		yield put(acknowledgeHelpFail({ error }));
	}
}

export function* watchHeaderNav() {
	return [
		yield takeLatest(orderSearchStart, getOrderSearch),
		yield takeLatest(fetchLastTenOrdersVisitedStart, fetchLastTenOrdersVisited),
		yield takeLatest(fetchHelpStart, fetchHelpData),
		yield takeLatest(acknowledgeHelpStart, acknowledgeHelp),
	];
}
