import { AxiosPromise } from "axios";

// NPM PACKAGES
import Http from "../../../utils/restClient";

export default class EnviromentAPI {
	public static fetchImageResources() {
		return Http.axios().get("/api/v1/images/resources/sas") as AxiosPromise<any>;
	}

	public static fetchImageResourcesPos() {
		return Http.axios().get("/api/v1/images/resources/sas/pos") as AxiosPromise<any>;
	}
}
