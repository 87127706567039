const loyaltyReducer = () => {
	return {
		fetchCustomerLoyaltyStart: (state, { payload }) => {
			return {
				...state,
				isLoyaltyLoading: true,
				loyalty: {
					...state.loyalty,
					page: {
						pageNumber: payload.page.pageNumber,
						pageSize: payload.page.pageSize,
					},
				},
			};
		},
		fetchCustomerLoyaltyComplete: (state, { payload }) => {
			return {
				...state,
				isLoyaltyLoading: false,
				loyalty: {
					...state.loyalty,
					page: {
						...state.loyalty.page,
						pageNumber: payload.data.currentPage,
					},
					results: payload.data.results,
					membershipLoyalty: payload.data.membershipLoyaltyModels,
					totalPages: payload.data.totalPages,
					totalItems: payload.data.totalItems,
					firstRowOnPage: payload.data.firstRowOnPage,
					lastRowOnPage: payload.data.lastRowOnPage,
					hasNextPage: payload.data.hasNextPage,
					hasPreviousPage: payload.data.hasPreviousPage,
					firstOrderDate: payload.data.firstOrderDate,
					lastOrderDate: payload.data.lastOrderDate,
					totalRevenue: payload.data.totalRevenue,
				},
			};
		},
		fetchCustomerLoyaltyFail: (state, { payload }) => {
			return {
				...state,
				isLoyaltyLoading: false,
				error: payload,
			};
		},
	};
};

export default loyaltyReducer;
