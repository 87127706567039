import { Action, Reducer } from "redux";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ICounterState {
	count: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface IIncrementCountAction {
	type: "INCREMENT_COUNT";
}
export interface IDecrementCountAction {
	type: "DECREMENT_COUNT";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = IIncrementCountAction | IDecrementCountAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
	increment: () => ({ type: "INCREMENT_COUNT" } as IIncrementCountAction),
	decrement: () => ({ type: "DECREMENT_COUNT" } as IDecrementCountAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ICounterState> = (
	state: ICounterState | undefined,
	incomingAction: Action
): ICounterState => {
	if (state === undefined) {
		return { count: 0 };
	}

	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "INCREMENT_COUNT":
			return { count: state.count + 1 };
		case "DECREMENT_COUNT":
			return { count: state.count - 1 };
		default:
			return state;
	}
};
