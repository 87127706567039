import { IScheduleCustomer } from "../../scheduleCustomer/scheduleCustomer.typings";

const bookingAssociationReducer = () => {
	return {
		fetchBookingCustomer: (state, { payload }) => {
			return {
				...state,
				isLoadingBookingCustomer: true,
				bookingCustomer: {} as unknown as IScheduleCustomer,
			};
		},
		successFetchBookingCustomer: (state, { payload }) => {
			return {
				...state,
				bookingCustomer: payload.data,
				isLoadingBookingCustomer: false,
				error: "",
			};
		},
		failedFetchBookingCustomer: (state, { payload }) => {
			return {
				...state,
				bookingCustomer: {} as unknown as IScheduleCustomer,
				isLoadingBookingCustomer: false,
				error: payload,
			};
		},

		setBookingCustomer: (state, { payload }) => {
			return {
				...state,
				bookingCustomer: payload,
			};
		},

		resetBookingCustomer: (state, { payload }) => {
			return {
				...state,
				bookingCustomer: payload,
			};
		},
	};
};

export default bookingAssociationReducer;
