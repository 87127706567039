import { IScreen } from "./pointOfSale.typings";

export const DEFAULT_PROFILE_IDENTIFIER = "259c3940-98ef-4fee-b82d-2df51f464390";
export const DEFAULT_SCREEN_IDENTIFIER = "3b862481-0d9b-4ed3-9319-d60516e84fdb";

export const buildSlotGrid = (numCols = 4, numRows = 5) => {
	const grid = new Array(numCols);

	for (let i = 0; i < numCols; i++) {
		grid[i] = new Array(numRows);
		for (let j = 0; j < numRows; j++) {
			grid[i][j] = {
				id: i * numRows + j + 1,
				name: `Slot ${i * numRows + j + 1}`,
				item: null,
				col: i + 1,
				row: j + 1,
			};
		}
	}

	return grid;
};
export const createScreen = (name, position) =>
	({
		identifier: DEFAULT_SCREEN_IDENTIFIER,
		name,
		showImages: true,
		position,
		columns: 4,
		rows: 5,
		defaultButtonColor: "#DE350B",
		slots: buildSlotGrid(),
		buttons: [], //tempButtonsData
	} as IScreen);

export const initialScreen = createScreen("New Screen", 1);

export const findSelectedPosProfileAndScreen = (state) => {
	const selectedPosProfile = state.profiles.items.find(
		(profile) => profile.id === state.selectedProfile
	);
	const selectedScreen = state.profiles.screen.screen;

	return { selectedPosProfile, selectedScreen };
};

// export const saveLastSelectedPOSProfile = (facilityId, selectedProfile) => {
// 	const localSelectedPOSProfile = JSON.parse(
// 		localStorage.getItem("lastSelectedPOSProfile") || "[]"
// 	);

// 	const prepareToSave = localSelectedPOSProfile;

// 	const foundIndex = prepareToSave.findIndex(
// 		(item) => Number(item.facilityId) === Number(facilityId)
// 	);

// 	if (foundIndex !== -1) {
// 		prepareToSave[foundIndex] = { facilityId, selectedProfile };
// 	} else {
// 		prepareToSave.push({ facilityId, selectedProfile });
// 	}

// 	localStorage.setItem("lastSelectedPOSProfile", JSON.stringify(prepareToSave));
// };

// export const getSavedLastSelectedPOSProfile = (facilityId) => {
// 	const savedInLocal = localStorage.getItem("lastSelectedPOSProfile");
// 	const lastSelectedPOSProfile = JSON.parse(savedInLocal || "[]");

// 	if (lastSelectedPOSProfile.length > 0) {
// 		const selectedProfile = lastSelectedPOSProfile.find(
// 			(profile) => Number(profile.facilityId) === Number(facilityId)
// 		);

// 		if (selectedProfile) {
// 			return selectedProfile.selectedProfile;
// 		}
// 	}

// 	return null;
// };

export const saveLastActivePOSProfileId = (facilityId, selectedProfile) => {
	const localActivePOSProfile = JSON.parse(
		localStorage.getItem("lastActivePOSProfile") || "[]"
	);

	const prepareToSave = localActivePOSProfile;

	const foundIndex = prepareToSave.findIndex(
		(item) => Number(item.facilityId) === Number(facilityId)
	);

	if (foundIndex !== -1) {
		prepareToSave[foundIndex] = {
			facilityId,
			selectedProfileId: selectedProfile.identifier,
		};
	} else {
		prepareToSave.push({ facilityId, selectedProfileId: selectedProfile.identifier });
	}

	localStorage.setItem("lastActivePOSProfile", JSON.stringify(prepareToSave));
};

export const getLastActivePOSProfileId = (facilityId) => {
	const savedInLocal = localStorage.getItem("lastActivePOSProfile");
	const lastActivePOSProfileId = JSON.parse(savedInLocal || "[]");

	if (lastActivePOSProfileId.length > 0) {
		const selectedProfile = lastActivePOSProfileId.find(
			(profile) => Number(profile.facilityId) === Number(facilityId)
		);

		if (selectedProfile) {
			return selectedProfile.selectedProfileId;
		}
	}

	return null;
};

export const deleteProfileFromLocalStorage = (facilityId, nextSelectedProfileId) => {
	const savedInLocal = localStorage.getItem("lastActivePOSProfile");
	const lastActivePOSProfileId = JSON.parse(savedInLocal || "[]");

	if (lastActivePOSProfileId.length > 0) {
		const selectedProfile = lastActivePOSProfileId.find(
			(profile) => Number(profile.facilityId) === Number(facilityId)
		);

		if (selectedProfile) {
			selectedProfile.selectedProfileId = nextSelectedProfileId;

			const prepareToSave = Array<any>();

			prepareToSave.push(selectedProfile);

			localStorage.setItem("lastActivePOSProfile", JSON.stringify(prepareToSave));
		}
	}
};
