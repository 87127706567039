import {
	IPricingFee,
	IPricingTier,
	IPricingTax,
	StateSaving,
} from "../customerMembership.typings";

export const pricingFeeStates = {
	pricingAndFees: {
		fetchStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			success: false,
			shouldFire: false,
		} as StateSaving,
		recurringBillingAmount: 0,
		recurringBillingAmountAfterTaxes: 0,
		payingNowAmount: 0,
		payingNowAmountAfterTaxes: 0,
		pricingTiers: [] as Array<IPricingTier>,
		proRatedPricingTiers: [] as Array<IPricingTier>,
		taxes: [] as Array<IPricingTax>,
	} as IPricingFee,
};

export const pricingFeeReducer = () => {
	return {
		fetchPricingFeesStart: (state, { payload }) => {
			return {
				...state,
				pricingAndFees: {
					...state.pricingAndFees,
					fetchStateLoading: {
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		fetchPricingFeesComplete: (state, { payload }) => {
			return {
				...state,
				pricingAndFees: {
					...state.pricingAndFees,
					fetchStateLoading: {
						...state.pricingAndFees.fetchStateLoading,
						loading: false,
						success: true,
						shouldFire: false,
					},
					recurringBillingAmount: payload.recurringBillingAmount,
					payingNowAmount: payload.payingNowAmount,
					recurringBillingAmountAfterTaxes:
						payload.recurringBillingAmountAfterTaxes,
					payingNowAmountAfterTaxes: payload.payingNowAmountAfterTaxes,
					pricingTiers: payload.pricingTiers,
					proRatedPricingTiers: payload.proRatedPricingTiers,
					taxes: payload.taxes,
				},
			};
		},
		fetchPricingFeesFail: (state, { payload }) => {
			return {
				...state,
				pricingAndFees: {
					...state.pricingAndFees,
					fetchStateLoading: {
						...state.pricingAndFees.fetchStateLoading,
						loading: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		fetchPricingFeesShouldFire: (state) => {
			return {
				...state,
				pricingAndFees: {
					...state.pricingAndFees,
					fetchStateLoading: {
						...state.pricingAndFees.fetchStateLoading,
						shouldFire: true,
					},
				},
			};
		},
	};
};
