import { ApiResult } from "../../helpers/typings";
import { IRefundItemStateModel } from "../../pointOfSale/pointOfSale.typings";
import { IRefundSummary } from "../payments.typings";

export interface IPostRefundData {
	transactionId: string;
	refundAmount: number;
	refundRecordOnly: boolean;
}

export interface IPostRefundsPayload {
	orderId: number;
	transactions: Array<IPostRefundData>;
}

export interface IGetRefundSummaryPayload {
	orderId: number;
	request: {
		selectedItems: Array<IRefundItemStateModel>;
	};
}

export const refundState = {
	refund: {
		applyRefundsStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			started: false,
			success: false,
		},
		applySingleRefundStateLoading: {
			errorMessage: "",
			hasError: false,
			loading: false,
			started: false,
			success: false,
		},
		refundSummary: {
			loadingState: {
				loading: false,
				success: false,
				error: "",
			},
			totalToRefund: 0,
			subtotalToRefund: 0,
			taxesTotal: 0,
			includedTaxesTotal: 0,
			itemsTotal: 0,
			maximumToRefund: 0,
			orderLevelDiscountTotal: 0,
			items: [] as Array<IRefundItemStateModel>,
		},
	},
};

const refundReducer = () => {
	return {
		postRefundsStart: (state, { payload }: { payload: IPostRefundsPayload }) => {
			return {
				...state,
				refund: {
					...state.refund,
					applyRefundsStateLoading: {
						started: true,
						loading: true,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		postRefundsComplete: (state, { payload }) => {
			return {
				...state,
				refund: {
					...state.refund,
					applyRefundsStateLoading: {
						...state.refund.applyRefundsStateLoading,
						started: true,
						loading: false,
						success: true,
					},
				},
			};
		},
		postRefundsFail: (state, { payload }) => {
			return {
				...state,
				refund: {
					...state.refund,
					applyRefundsStateLoading: {
						...state.refund.applyRefundsStateLoading,
						started: true,
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		postSingleRefundComplete: (state, { payload }) => {
			return {
				...state,
				refund: {
					...state.refund,
					applySingleRefundStateLoading: {
						...state.refund.applySingleRefundStateLoading,
						started: true,
						loading: false,
						success: true,
					},
					paymentResponse: payload.data.results,
				},
			};
		},
		postSingleRefundFail: (state, { payload }) => {
			return {
				...state,
				refund: {
					...state.refund,
					applySingleRefundStateLoading: {
						...state.refund.applySingleRefundStateLoading,
						started: true,
						loading: false,
						success: false,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
		resetRefundState: (state) => {
			return {
				...state,
				refund: {
					applyRefundsStateLoading: {
						started: false,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
					applySingleRefundStateLoading: {
						started: false,
						loading: false,
						success: false,
						hasError: false,
						errorMessage: "",
					},
				},
			};
		},
		// REFUND SUMMARY
		getRefundSummaryStart: (
			state,
			{ payload }: { payload: IGetRefundSummaryPayload }
		) => {
			return {
				...state,
				refund: {
					...state.refund,
					refundSummary: {
						...state.refund.refundSummary,
						loadingState: {
							...state.refund.refundSummary.loadingState,
							loading: true,
							success: false,
							error: "",
						},
					},
				},
			};
		},
		getRefundSummaryComplete: (
			state,
			{ payload }: { payload: ApiResult<IRefundSummary> }
		) => {
			return {
				...state,
				refund: {
					...state.refund,
					refundSummary: {
						...state.refund.refundSummary,
						loadingState: {
							...state.refund.refundSummary.loadingState,
							loading: false,
							success: true,
							error: "",
						},
						totalToRefund: payload.data.results.total,
						subtotalToRefund: payload.data.results.subtotal,
						taxesTotal: payload.data.results.taxesTotal,
						includedTaxesTotal: payload.data.results.includedTaxesTotal,
						itemsTotal: payload.data.results.itemsTotal,
						maximumToRefund: payload.data.results.maximumToRefund,
						items: payload.data.results.items,
						orderLevelDiscountTotal:
							payload.data.results.orderLevelDiscountTotal,
					},
				},
			};
		},
		getRefundSummaryFail: (state, { payload }) => {
			return {
				...state,
				refund: {
					...state.refund,
					refundSummary: {
						...state.refund.refundSummary,
						loadingState: {
							...state.refund.refundSummary.loadingState,
							loading: false,
							success: false,
							error: payload,
						},
					},
				},
			};
		},
		resetRefundSummaryItems: (state) => {
			return {
				...state,
				refund: {
					...state.refund,
					refundSummary: {
						...state.refund.refundSummary,
						items: [],
					},
				},
			};
		},
		setTotalsForCustomAmmount: (state, { payload }: { payload: number }) => {
			return {
				...state,
				refund: {
					...state.refund,
					refundSummary: {
						...state.refund.refundSummary,
						loadingState: {
							...state.refund.refundSummary.loadingState,
							loading: false,
							success: false,
							error: "",
						},
						totalToRefund: payload,
						subtotalToRefund: payload,
						taxesTotal: 0,
						includedTaxesTotal: 0,
						itemsTotal: 0,
					},
				},
			};
		},
		resetSuccessStateForNewRequest: (state) => {
			return {
				...state,
				refund: {
					...state.refund,
					refundSummary: {
						...state.refund.refundSummary,
						loadingState: {
							...state.refund.refundSummary.loadingState,
							loading: false,
							success: false,
							error: "",
						},
					},
				},
			};
		},
	};
};
export default refundReducer;
