import { makeStyles, ThemeAgile } from "@agilekit/ui";

const useStyles = makeStyles((theme: ThemeAgile) => {
	return {
		startIcon: {
			display: "flex",
			fontSize: theme.typography.pxToRem(16),
			color: theme.palette.NEUTRAL400,
			marginRight: 7,
		},
		menuItem: {
			display: "flex",
			alignItems: "center",
			width: "100%",
			border: "none",
			cursor: "pointer",
			outline: "none",
			padding: "10px 15px",
			background: "none",
			textAlign: "left",
			borderRadius: 3,
			transition: "background-color 0.3s ease",
			"&:hover": {
				backgroundColor: theme.palette.NEUTRAL040,
			},
		},
	};
});

export default useStyles;
