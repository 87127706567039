export const loadState = (stateName: string) => {
	try {
		if (!navigator.cookieEnabled) {
			return undefined;
		}
		const serializedState = sessionStorage.getItem(stateName);
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};

export const saveState = (stateName: string, state) => {
	try {
		if (navigator.cookieEnabled) {
			const serializedState = JSON.stringify(state);
			sessionStorage.setItem(stateName, serializedState);
		}
	} catch {
		// ignore write errors
	}
};

export const clearState = (stateName) => {
	if (navigator.cookieEnabled) {
		sessionStorage.removeItem("customersFilter");
	}
};
