import { createSlice } from "@reduxjs/toolkit";

type StateLoading = {
	started?: boolean;
	loading: boolean;
	success: boolean;
	hasError: boolean;
	errorMessage: "";
};

export interface IScannedProduct {
	barCode: string;
	productId: number;
	productName: string;
	productType: string;
	productPrice: number;
}

interface IScannedProducts {
	fetchStateLoading: StateLoading;
	product: IScannedProduct;
}

const productsSlice = createSlice({
	name: "PRODUCTS",
	initialState: {
		scannedProducts: {
			fetchStateLoading: {
				errorMessage: "",
				hasError: false,
				loading: false,
				success: false,
			} as StateLoading,
			product: {
				barCode: "",
				productId: 0,
				productName: "",
				productType: "",
				productPrice: 0,
			} as IScannedProduct,
		} as IScannedProducts,
	},
	reducers: {
		getProductsBarCodeStart: (state, { payload }) => {
			return {
				...state,
				scannedProducts: {
					...state.scannedProducts,
					fetchStateLoading: {
						...state.scannedProducts.fetchStateLoading,
						loading: true,
					},
				},
			};
		},
		getProductsBarCodeSuccess: (state, { payload }) => {
			return {
				...state,
				scannedProducts: {
					...state.scannedProducts,
					fetchStateLoading: {
						...state.scannedProducts.fetchStateLoading,
						success: true,
						loading: false,
					},
					product: payload.data.results,
				},
			};
		},
		getProductsBarCodeFailure: (state, { payload }) => {
			return {
				...state,
				scannedProducts: {
					...state.scannedProducts,
					fetchStateLoading: {
						...state.scannedProducts.fetchStateLoading,
						hasError: true,
						errorMessage: payload,
					},
				},
			};
		},
	},
});

export const {
	getProductsBarCodeFailure,
	getProductsBarCodeStart,
	getProductsBarCodeSuccess,
} = productsSlice.actions;

export default productsSlice.reducer;
