const themesReducer = () => {
	return {
		fetchEmailThemeStart: (state, { payload }) => {
			return {
				...state,
				isLoading: true,
				error: "",
			};
		},
		fetchEmailThemeSuccess: (state, { payload }) => {
			return {
				...state,
				isLoading: false,
				emailThemes: payload,
				// select the first option as default to render the UI with validation at glance
				selectedEmailTheme: payload[0],
				error: "",
			};
		},
		fetchEmailThemeFailure: (state, { payload }) => {
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		},
	};
};

export default themesReducer;
